import React, { Component } from 'react'
import '../../../global/global.css'
// import lov from '../../actions/lov'

import { trackPromise } from 'react-promise-tracker';
import {
  Card,
  Button,
  Row,
  Col,
  Typography,
  Popconfirm
} from 'antd'
import { UItable, UISearch } from '../../../components'
import moment from 'moment';
import { ModalJadwal } from '../modal';
import { alert } from '../../../api';
import { endtpointJadwal } from '../../../api/jadwal';
import { confirmDialog } from '../../../utils/alert';

const { Text } = Typography
const initialOrderBy = ''
const initialTemp = {
  name : undefined,
  nameEng : undefined,
  date : undefined,
  timeStart : undefined,
  timeEnd : undefined,
  waktu: undefined,
  desc : undefined,
  descEng : undefined,
  kuotaLoyola : undefined,
  kuotaUmum : undefined,
  isOnline : true,
  mediaApps : undefined,
  urlMedia : undefined,
  usernameMedia : undefined,
  passwordMedia : undefined,
  thumbnailUrl : undefined
}
class ListJadwal extends Component {
	constructor(props) {
		super(props)
		this.state = {
		initial: '',
		orderBy: initialOrderBy,
		size: 10,
		page: 1,
		search: '',
		visibleCreate: false,
		initalData: initialTemp,
		dataId: undefined,
		isEdit: false,
		dataProfil: {
      data: [],
      totalData: 0
    },
		dataLOV: [],
    loadingSubmit: false,
    loadingExport: false
		}
	}

	componentDidMount() {
    const {orderBy, size, page, search} = this.state
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: page,
      search: search,
    }
    this.getData(body)
  }

	getData = param => {
		trackPromise(
      endtpointJadwal.getData(param).then(res => {
        this.setState({
          dataProfil: res
        })
      })
    )
	}

	handleSearch = key => {
    const {orderBy, size, page } = this.state
    this.setState({
      search: key,
      page: 1
    })
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: 1,
      search: key,
    }
    this.getData(body)
  }

	handleTableChange = (page, filter, sorter) => {
    const {size, search } = this.state
    const body = {
      token: this.props.token,
      sort: sorter,
      size: size,
      page: page,
      search: search,
    }
    this.setState({
      page: page,
      orderBy: sorter
    }, () => {
      this.getData(body)
    })
  }

	openModal = () => {
    this.setState({
      visibleCreate: true,
      isEdit: false,
      initalData: initialTemp,
    })
  }

	handleCloseModal = () => {
    this.setState({
      visibleCreate: false,
      isEdit: false
    })
  }


	handleFinish = values => {
    this.setState({
      loadingSubmit: true
    })
    let body = {
      token: this.props.token,
      sort: this.state.orderBy,
      size: 10,
      page: 1,
      search: this.state.search,
    }
    let dataTemp = {
      ...values,
      media: values.mediaApps,
      date: moment(values.date).format('YYYY-MM-DD'),
      timeStart: moment(values.waktu[0]).format('HH:mm'),
      timeEnd: moment(values.waktu[1]).format('HH:mm'),
      userId: this.props.userId,
    }
    delete dataTemp.waktu
    delete dataTemp.mediaApps
    console.log('kirim data : ',dataTemp)
    if (this.state.isEdit) {
      this.handleSubmitEdit(body, dataTemp)
    }else {
      body = {
        token: this.props.token,
        sort: this.state.orderBy,
        size: 10,
        page: 1,
        search: this.state.search,
      }
      this.handleSubmitCreate(body, dataTemp)
    }
  }

	handleSubmitCreate = (body, bodySend) => {
    trackPromise(
      endtpointJadwal.create(this.props.token, bodySend).then(res => {
        if (res === 'success') {
          this.setState({
            search: '',
            page: 1,
            orderBy: initialOrderBy,
            visibleCreate: false,
            loadingSubmit: false
          }, () => {
            this.getData(body)
          })
        }else{
          this.setState({
            loadingSubmit: false
          })
        }
      })
    )
  }

	handleSubmitEdit = (body, bodySend) => {
    trackPromise(
      endtpointJadwal.edit(this.props.token, this.state.dataId, bodySend).then(res => {
        if (res === 'success') {
          this.setState({
            search: '',
            page: 1,
            orderBy: initialOrderBy,
            visibleCreate: false,
            loadingSubmit: false
          }, () => {
            this.getData(body)
          })
        }else{
          this.setState({
            loadingSubmit: false
          })
        }
      })
    )
  }

	handleEditClick = data => {
    this.setState({
      initalData: {
        name : data.name,
        nameEng : data.nameEng,
        date : moment(data.date, 'DD-MM-YYYY'),
        timeStart : data.timeStart,
        timeEnd : data.timeEnd,
        waktu: [moment(data.timeStart, 'HH:mm'),moment(data.timeEnd, 'HH:mm')],
        desc : data.desc,
        descEng : data.descEng,
        loyolaQuota : data.loyolaQuota,
        nonLoyolaQuota : data.nonLoyolaQuota,
        isOnline : data.isOnline,
        mediaApps : data.mediaApps,
        mediaUrl : data.mediaUrl,
        mediaJoinId : data.mediaJoinId,
        mediaPassword : data.mediaPassword,
        thumbnailUrl : data.thumbnailUrl,
        imageUrl : data.imageUrl,
        location: data.location
      },
      dataId: data.eventId,
      visibleCreate: true,
      isEdit: true
    })
	}

	handleReject = (id,isPassed) => {
		const body = {
      token: this.props.token,
      sort: this.state.orderBy,
      size: 10,
      page: 1,
      search: this.state.search,
    }
    
    let textMessage = 'Apakah Anda yakin akan menghapus data ini dan mengirim e-mail pembatalan acara ke seluruh peserta acara?'
    if(isPassed){
      textMessage = 'Apakah Anda yakin akan menghapus data ini?'
    }

    confirmDialog({
      icon: 'warning',
      title: 'Hapus Data Jadwal',
      text: textMessage
    }).then(()=>{
      trackPromise(
        endtpointJadwal.deleteData(this.props.token, this.props.userId, id).then(res => {
          if (res === 'success') {
            this.setState({
              search: this.state.search || '',
              page: 1,
              orderBy: this.state.orderBy || initialOrderBy,
              visibleCreate: false
            }, () => {
              this.getData(body)
            })
          }
        })
      )
    })
	}

  handleExport = () => {
    this.setState({
      loadingExport: true
    })
    trackPromise(
      endtpointJadwal.getExport(this.props.token).then(res => {
        if(res !== 'error'){
          window.open(res)
          this.setState({
            loadingExport: false
          })
        }
      })
    )
  }

	handleRedirectDetail = id => {
    this.props.history.push(`jadwal-detail/${id}`)
	}

	render() {

		const {page, visibleCreate, initalData, isEdit, dataProfil, dataLOV, loadingSubmit, loadingExport } = this.state

    const text = 'Apakah Anda yakin akan menghapus data ini?'

    const columnsTable = [
      {
        title: 'Nama Jadwal',
        dataIndex: 'name',
        key: 'f_event_name',
        sorter: true,
        render: name => <Text style={{ color: '#222222', fontWeight: 600, cursor: 'pointer' }}>{name}</Text>,
        onCell: record => {
          return{
            onClick: () => {
              this.handleRedirectDetail(record.eventId)
            }
          }
        }
      },
      {
        title: 'Hari',
        dataIndex: 'day',
        key: 'f_event_day',
        sorter: true,
      },
      {
        title: 'Tanggal',
        dataIndex: 'date',
        key: 'f_event_date',
        sorter: true,
      },
      {
        title: 'Jam',
        dataIndex: 'time',
        key: 'f_event_time',
        sorter: true,
      },
      {
        title: 'Media',
        dataIndex: 'media',
        key: 'presentasi',
        sorter: true,
      },
      {
        title: 'Aksi',
        dataIndex: '',
        key: '',
        render: (data) => <>
        {
          !data.isPassed &&
          <Button
            onClick={() => this.handleEditClick(data)}
            type='link'
            style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600 }}
          >
            Ubah
          </Button>
        }{
          <Button
            type='link'
            style={{ color: '#E22828', cursor: 'pointer', fontWeight: 600 }}
            onClick={()=> this.handleReject(data.eventId,data.isPassed)}
          >
            Hapus
          </Button>
        }
        </>
      },
    ]

		return(
			<div className='root'>
				<Card className='bodyCard_style'>
					<Row gutter={[16,0]}>
            <Col span={8} xs={12} md={8}>
              <Button onClick={() => this.openModal()} className='addButton'>+ Tambah</Button>
            </Col>
            <Col span={8} xs={12} md={8} style={{textAlign: 'right'}}>
              <Button className='blueButton' loading={loadingExport} onClick={() => this.handleExport()}>Export</Button>
            </Col>
            <Col span={8} xs={24} md={8}>
              <UISearch placeholder='Nama Jadwal' handleSearch={key => this.handleSearch(key)} />
            </Col>
          </Row>
					<Card 
            bordered={false}
            style={{minHeight: '300px'}}>
            <UItable
              scroll={{x: 800}}
              dataTable={dataProfil.data}
              columns={columnsTable}
              withRows={false}
              page={page}
              totalData={dataProfil.totalData}
              sortDefault=''
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
					<ModalJadwal
						visible={visibleCreate}
						initialValues={initalData}
						title={ isEdit ? 'Ubah Data Jadwal' : 'Tambah Data Jadwal'}
						widthDivider='250px'
						close={() => this.handleCloseModal()}
						checkFinish={ values => this.handleFinish(values)}
            dataLOV={dataLOV}
            loadingSubmit={loadingSubmit}
            isEdit={isEdit}
            token={this.props.token}
					/>
				</Card>
			</div>
		)
	}
}


export default ListJadwal