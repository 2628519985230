import { InputNumber } from 'antd';
import React from 'react';

const largeStyle = {
  lineHeight: '32px',
  height: '40px',
};

export default function MoneyInput({ value, onChange, ...props }) {
  let style = {
    paddingTop: '2px',
    verticalAlign: 'middle',
    display: 'inline-table',
    lineHeight: '24px',
    height: '32px',
  };
  if (props.size === 'large') {
    style = {
      ...style,
      ...largeStyle,
    };
  }
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <div className="ant-input-group-addon" style={style}>
        {'Rp '}
      </div>
      <InputNumber
        {...props}
        value={value}
        onChange={onChange}
        style={{
          verticalAlign: 'middle',
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          width: 'auto',
          flex: '1',
        }}
        className="app-input"
        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/(,*)/g, '')}
      />
    </div>
  );
}

// export const MoneyInput;
