import Downloader from 'js-file-downloader';

export const downloadFile = link => {
  return new Promise((resolve, reject) => {
    new Downloader({ url: link })
    .then(file => {
      resolve(file);
    })
    .catch(err => {
      reject(err);
    });
  });
};
