import React, { useEffect, useState } from 'react'
import { Button, Col, DatePicker, Form, Input, Modal, Radio, Row, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import { RedStar } from '../../../components';
import { confirmDialog, dialog } from '../../../utils/alert';
import masterApi from '../../../api/master';

export default function ModalTambahPenerima(props) {
  const {visible, onCancel, onFinish, data} = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const isEdit = (!!data && typeof data === 'object');

  const handleSubmit = () => {
    form.validateFields().then(_ => {
      confirmDialog({
        title: `${isEdit ? 'Ubah' : 'Tambah'} Data Status Proyek?`,
      }).then(async _ => {
        const token = sessionStorage.getItem('token');
        const usersId = sessionStorage.getItem('userId');
        const body = {
          ...form.getFieldsValue(),
          usersId
        }
        setIsLoading(true);
        try {
          const res = await masterApi.editMasterStatusProyek(token, data.key, body);
          if (!res.data || res.data.rc !== '00') {
            throw res.data ? res.data : res;
          }
          dialog({
            icon: res.data.rc == "00" ? 'success' : 'error',
            title: res.data.message  || 'Data Status Proyek Berhasil Diubah',
          })
          onFinish();
        } catch (err) {
          console.log(err.message);
          dialog({
            title: 'Error',
            text: err.message || 'Unknown Error',
            icon: 'error'
          });
        }
        setIsLoading(false);
      }).catch(_ => {});
    }).catch(_ => {});
  }

  const onReset = () => {
    form.setFieldsValue(data);
  }

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  return (
    <Modal
      className="app-modal"
      width={800}
      footer={null}
      visible={visible}
      onCancel={_ => {
        onReset();
        onCancel();
      }}
    >
      <Spin spinning={isLoading}>
        <Title level={4} className="titleModalGalery" style={{marginBottom: '1em'}}>Ubah Data Status Proyek</Title>
        <Form form={form}>
          <Row gutter={14}>
            <Col span={12}>
              <Text className="form-label">Status Proyek <RedStar /></Text>
              <Form.Item
                rules={[{required: true, message: 'Status Proyek (IND) harus diisi!'}]}
                name="value">
                <Input maxLength={256} placeholder="Status Proyek" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Project Status <RedStar /></Text>
              <Form.Item
                rules={[{required: true, message: 'Status Proyek (ENG) harus diisi!'}]}
                name="valueEng">
                <Input maxLength={256} placeholder="Project Status" />
              </Form.Item>
            </Col>
            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '1em' }}>
              <Button className="app-btn" size="large" type="link" style={{marginLeft: '1em'}} onClick={onReset}>Reset</Button>
              <Button className="app-btn" size="large" style={{marginLeft: '1em'}} type="primary" onClick={handleSubmit} >Submit</Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}
