import React, { Component } from 'react'
import '../../../global/global.css'
import { trackPromise } from 'react-promise-tracker';
import {
  Card,
  Row,
  Col,
  Typography,
  Modal,
  Button,
  Tabs,
  Table,
  Spin
} from 'antd'
import ImagePreviewComponent from '../../../components/imagePreview'
import { endpoint } from '../../../api/galeri';
import { UISearch } from '../../../components';
import { CaretRightOutlined } from '@ant-design/icons';
import {GlobalVideoCheck} from '../../../global/checkingVideo'
import Title from 'antd/lib/typography/Title';

const { Text } = Typography
const { TabPane } = Tabs;
const columns = [
  {
    title: 'Nama Lengkap',
    dataIndex: 'participantName',
    // sorter: true
  }
];

class GaleriDetail extends Component {
	constructor(props) {
		super(props)
		this.state = {
      initial: {},
      listGambar:[],
      listVideo:[],
      validateVideo: '',
      visible: false,
      isTab: "1",
      isGambar: true,
      loading: true
		}
	}

  changeTab = key => {
    this.setState({
      isTab: key
    })
  }

  handleOpenVideo = url => {
    this.setState({
      validateVideo: url,
    }, () => {
      this.setState({
        visible: true
      })
    })
  }

  handleIsGambar = e => {
    this.setState({
      isGambar: e
    })
  }

  handleCloseVideo = () => {
    this.setState({
      validateVideo: '',
      visible: false
    })
  }

	componentDidMount() {
    const id = this.props.location.pathname.split('/')[2]
    const body = {
      token: this.props.token,
      id: id
    }
    this.getData(body)
  }

	getData = param => {
		trackPromise(
      endpoint.getDataDetail(param).then(res => {
        this.setState({
          initial: res.data.data.detailEvent,
          listGambar: res.data.data.eventImages,
          listVideo: res.data.data.eventVideos,
          thumbnailUrl: res.data.data.eventThumbnailUrl,
          loading: false
        })
      })
    )
	}
handleSearch = key => {
  console.log('adalah ',key)
}

changeTab = key => {
  this.setState({
    isTab: key
  })
}

	render() {
    const {initial, quota, isGambar, listGambar, listVideo, loading, isTab, thumbnailUrl} = this.state

		return(
			<div className='root'>
        <Spin spinning={loading}>
          <Card className='bodyCard_style'>
            <Row gutter={[8,8]}>
              <Col span={24}>
                <span className='header_text_detail'>
                  Thumbnail
                </span>
              </Col>
              <Col span={12}>
                <div style={{ width: '70%'}}>
                  <ImagePreviewComponent
                    styles={{ width: '100%' }}
                    uri={thumbnailUrl}
                    imageBorder={'6px'}
                  />
                </div>
              </Col>
              <Col span={12}/>
              <Col span={12} xs={24} md={12}>
                <Row gutter={[8,8]}>
                  <Col span={24}>
                    <span className='header_text_detail'>
                      Nama Acara
                    </span>
                  </Col>
                  <Col span={24}>
                    <span className='text_detail_institusi'>
                      {initial.eventName}
                    </span>
                  </Col>
                  <Col span={24}>
                    <span className='header_text_detail'>
                      Hari, Tanggal Acara
                    </span>
                  </Col>
                  <Col span={24}>
                    <span className='text_detail_institusi'>
                      {initial.eventDay}
                    </span>
                  </Col>
                  <Col span={24}>
                    <span className='header_text_detail'>
                      Jam
                    </span>
                  </Col>
                  <Col span={24}>
                    <span className='text_detail_institusi'>
                      {initial.eventTime}
                    </span>
                  </Col>
                  <Col span={24}>
                    <span className='header_text_detail'>
                      Media
                    </span>
                  </Col>
                  <Col span={24}>
                    <span className='text_detail_institusi'>
                      {initial.eventIsOnline ? 'Online' : 'Offline'}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={12} xs={24} md={12}>
                <Row gutter={[8,8]}>
                  <Col span={24}>
                    <span className='header_text_detail'>
                      Deskripsi
                    </span>
                  </Col>
                  <Col span={24}>
                    <span className='text_detail_institusi' style={{whiteSpace: 'pre-line'}}>
                      {initial.eventDesc}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[50,8]}>
              <Col span={12} style={{textAlign: 'right'}}>
                <span className={isGambar ? 'header_text_detail' : 'text_detail_institusi'} style={{cursor: 'pointer'}} onClick={()=>this.handleIsGambar(true)}>
                  Foto
                </span>
              </Col>
              <Col span={12} style={{textAlign: 'left'}}>
                <span className={!isGambar ? 'header_text_detail' : 'text_detail_institusi'} style={{cursor: 'pointer'}} onClick={()=>this.handleIsGambar(false)}>
                  Video
                </span>
              </Col>
              <Col span={24}>
                <div className='style-divider' />
              </Col>
            </Row>
            <Row gutter={[8,8]} style={{alignItems: 'baseline'}}>
              {
                isGambar ? 
                (listGambar && listGambar.length > 0 ? listGambar.map(res => {
                  return(
                    <Col span={4} xs={12} md={8} lg={4}>
                      <ImagePreviewComponent
                        isMobile={false}
                        styles={{ width: '100%', cursor: 'pointer', height: '100px' }}
                        uri={res.url ? res.url : ""}
                        isPreview={true}
                        imageBorder={'6px'}
                      />
                    </Col>
                  )
                }) :
                <Col span={24} style={{ textAlign: 'center'}}>
                  <Title level={4}>Foto Tidak Tersedia</Title> 
                </Col>)
                :
                (listVideo && listVideo.length > 0 ? listVideo.map(res => {
                  return(
                    <Col span={4} xs={12} md={8} lg={4}>
                      <div className='gradientCard'>
                        <CaretRightOutlined 
                          className='playButtonMini'
                          onClick={() => this.handleOpenVideo(res.url ? res.url : '')}
                        />
                        <ImagePreviewComponent
                          isMobile={false}
                          styles={{ width: '100%', cursor: 'pointer' }}
                          uri={res.path}
                          imageBorder={'6px'}
                        />
                      </div>
                    </Col>
                  )
                }) :
                <Col span={24} style={{ textAlign: 'center'}}>
                  <Title level={4}>Video Tidak Tersedia</Title> 
                </Col>)
              }
            </Row>
          </Card>
        </Spin>
        <Modal
          visible={this.state.visible}
          centered
          className={'cardVideo'}
          footer={null}
          width={'75%'}
          onCancel={() => this.handleCloseVideo()}
        >
          {GlobalVideoCheck.checking(this.state.validateVideo) ? 
          (
            <iframe
              style={{width: '-webkit-fill-available', height: '500px'}}
              id="modalVideo"
              title='modalVideo'
              src={this.state.validateVideo}
              frameBorder="0"
              allow="accelerometer;
              autoplay;
              encrypted-media;
              gyroscope;
              picture-in-picture"
              allowFullScreen="allowfullscreen"
            />
          ) : 
          (
            <span className='data_text_detail'>
              Url Video Tidak Valid/Error
            </span>
          )
        }
        </Modal>
			</div>
		)
	}
}


export default GaleriDetail