import { Button, Card, Col, Row, Spin } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph';
import Text from 'antd/lib/typography/Text';
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import masterApi from '../../../api/danaPengembangan';
import ModalDaftarPenerimaDonorBeasiswa from './modalDaftarPenerimaDonorBeasiswa';

export default function DetailKategoriDonorBeasiswa(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [data, setData] = useState({});
  const defaultParams = {keyword: '', page: 1, sort: 'sd_fiscal_year,desc', size: 10}

  const togglePreview = () => {
    setIsPreview(!isPreview);
  }

  useEffect(() => {
    const id = props.location.pathname.split('/')[2]
    loadData(defaultParams,id);
  }, []);

  const loadData = async (param,id) => {
    setIsLoading(true);
    const token = props.token;
    await masterApi.getDetailKategoriDonorBeasiswa(token, param,id).then(res => {
      let response = res.data ? res.data : {}
      if(response.rc == "00"){
        console.log(response.data.scholarshipList[0]);
        setData(response.data.scholarshipList[0])
      }else{

      }
    }).catch(err => {
    })
    setIsLoading(false);
  }

  return (
    <div className="root">
      <Card className="app-card">
        <Spin spinning={isLoading}>
          <Row gutter={[56, 56]}>
            <Col span={24}>
              <div className="app-subtitle bold" style={{marginBottom: '.25em'}}>Banner</div>
              <div
                className="image-preview"
                title="Show full Image"
                style={{height: '300px', width: '50%', cursor: 'pointer'}}
                onClick={togglePreview}
              >
                <img src={data && data.thumbailImage || ''} style={{width: '100%', height: '100%', objectFit: 'contain'}} />
              </div>
            </Col>

            <Col span={12}>
              <Row gutter={[0, 7]} style={{flexDirection: 'column'}}>
                <Col>
                  <div className="app-subtitle bold">Kategori Beasiswa</div>
                  <Text className="app-value">{data && data.nameInd || '-'}</Text>
                </Col>
                <Col>
                  <div className="app-subtitle bold">Deskripsi</div>
                  <Paragraph style={{textAlign: 'justify', whiteSpace: 'pre-line'}} className="app-value">
                    {data && data.descriptionInd || ''}
                  </Paragraph>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row gutter={[0, 7]} style={{flexDirection: 'column'}}>
                <Col>
                  <div className="app-subtitle bold">Tahun Anggaran</div>
                  <Text className="app-value">{data && data.fiscalYear || '-'}</Text>
                </Col>
                <Col>
                  <div className="app-subtitle bold">Kebutuhan Donasi</div>
                  <Text className="app-value">{data && data.estimation || '-'}</Text>
                </Col>
                <Col>
                  <div className="app-subtitle bold">Jumlah Penerima</div>
                  <Text className="app-value">{data && data.grantee || '-'}</Text>
                </Col>
                <Col>
                  <div className="app-subtitle bold">Daftar Penerima</div>
                  <Link onClick={()=>setIsVisible(true)} className="app-value">Lihat Daftar Penerima</Link>
                </Col>
                <Col>
                  <div className="app-subtitle bold">Contact Person</div>
                  <Text className="app-value">{data && data.contactPerson || '-'}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </Card>
      <ModalDaftarPenerimaDonorBeasiswa
        visible={isVisible}
        onCancel={()=>setIsVisible(false)}
        title={'Daftar Penerima Beasiswa'}
        id={props.location.pathname.split('/')[2]}
        token={props.token}
      />
    </div>
  )
}
