import React, { Component } from 'react'
import '../../../global/global.css'
// import lov from '../../actions/lov'
import { endpoint } from '../../../api'

import { trackPromise } from 'react-promise-tracker';
import {
  Card,
  Button,
  Row,
  Col,
  Typography,
  Popconfirm
} from 'antd'
import { UItable, UISearch, UIModalCreate } from '../../../components'
import moment from 'moment';

const { Text } = Typography

class ListProfilPengurus extends Component {
	constructor(props) {
		super(props)
		this.state = {
		initial: '',
		orderBy: '',
		size: 10,
		page: 1,
		search: '',
		visibleCreate: false,
		initalData: {
			managementName: undefined,
			managementPosition: undefined,
			managementPositionEng: undefined,
			managementPeriod: undefined,
			managementProfil: undefined,
			managementProfilEng: undefined,
			managementUrl: undefined
		},
		idFaq: undefined,
		isEdit: false,
		dataProfil: {
      data: [],
      totalData: 0
    },
		dataLOV: [],
		}
	}

	componentDidMount() {
    const {orderBy, size, page, search} = this.state
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: page,
      search: search,
    }
    this.getData(body)
  }

	getData = param => {
		trackPromise(
      endpoint.getDataProfil(param).then(res => {
        this.setState({
          dataProfil: res
        })
      })
    )
	}

	handleSearch = key => {
    const {orderBy, size, page } = this.state
    this.setState({
      search: key,
      page: 1
    })
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: 1,
      search: key,
    }
    this.getData(body)
  }

	handleTableChange = (page, filter, sorter) => {
    const {size, search } = this.state
    const body = {
      token: this.props.token,
      sort: sorter,
      size: size,
      page: page,
      search: search,
    }
    this.setState({
      page: page,
      orderBy: sorter
    }, () => {
      this.getData(body)
    })
  }

	openModal = () => {
    this.setState({
      visibleCreate: true,
      isEdit: false,
      initalData: {
				managementName: undefined,
				managementPosition: undefined,
				managementPositionEng: undefined,
				managementPeriod: undefined,
				managementProfil: undefined,
				managementProfilEng: undefined,
				managementUrl: undefined
      },
    })
  }

  async handleReset () {
    const {initalData, isEdit } = this.state
    let dataTemp = await {...initalData}
    if(!isEdit){
      dataTemp = await {
        managementName: undefined,
        managementPosition: undefined,
        managementPositionEng: undefined,
        managementPeriod: undefined,
        managementProfil: undefined,
        managementProfilEng: undefined,
        managementUrl: undefined
      }
    }
    this.setState({
      initalData: dataTemp
    })
    return dataTemp
  }

	handleCloseModal = () => {
    this.setState({
      visibleCreate: false,
      isEdit: false
    })
  }


	handleFinish = values => {

		const normalizeDate = {
			from: moment(values.managementPeriod[0]).format('YYYY'),
			to: moment(values.managementPeriod[1]).format('YYYY')
		}
    const bodySend = {
			usersId: this.props.userId,
      managementName: values.managementName,
      managementPosition: values.managementPosition,
      managementPositionEng: values.managementPositionEng,
      managementPeriodFrom: normalizeDate.from,
      managementPeriodTo: normalizeDate.to,
		  managementUrl: values.managementUrl,
		  managementProfil: values.managementProfil,
		  managementProfilEng: values.managementProfilEng
    }

    const body = {
      token: this.props.token,
      sort: '',
      size: 10,
      page: 1,
      search: this.state.search,
    }
    this.setState({
      page: 1,
      orderBy: ''
    }, () => {
      this.getData(body)
    })
    if (this.state.isEdit) {
      this.handleSubmitEdit(body, bodySend)
    }else {
      this.handleSubmitCreate(body, bodySend)
    }
  }

	handleSubmitCreate = (body, bodySend) => {
    trackPromise(
      endpoint.createProfil(this.props.token, bodySend).then(res => {
        if (res === 'success') {
          this.setState({
            page: 1,
            orderBy: '',
            visibleCreate: false
          }, () => {
            this.getData(body)
          })
        }
      })
    )
  }

	handleSubmitEdit = (body, bodySend) => {
    trackPromise(
      endpoint.editProfil(this.props.token, this.state.idFaq, bodySend).then(res => {
        if (res === 'success') {
          this.setState({
            page: 1,
            orderBy: '',
            visibleCreate: false
          }, () => {
            this.getData(body)
          })
        }
      })
    )
  }

	handleEditClick = data => {
		const dataDate = [
			moment(data.managementPeriodFrom),
			moment(data.managementPeriodTo)
		]
		this.setState({
      initalData: {
        managementName: data.managementName,
        managementPosition: data.managementPosition,
				managementPositionEng: data.managementPositionEng,
        managementPeriod: dataDate,
        managementProfil: data.managementProfil,
        managementProfilEng: data.managementProfilEng,
				managementUrl: data.managementUrl
      },
      idFaq: data.id,
      visibleCreate: true,
      isEdit: true
    })
	}

	handleReject = id => {
		const body = {
      token: this.props.token,
      sort: this.state.orderBy,
      size: 10,
      page: 1,
      search: this.state.search,
    }
		trackPromise(
      endpoint.deleteDataProfil(this.props.token, this.props.userId, id).then(res => {
        if (res === 'success') {
          this.setState({
            page: 1,
            orderBy: this.state.orderBy || '',
            visibleCreate: false
          }, () => {
            this.getData(body)
          })
        }
      })
    )
	}

	handleRedirectDetail = id => {
    this.props.history.push(`profil-pengurus-detail/${id}`)
	}

  handleExport = () => {
    trackPromise(
      endpoint.getExport(this.props.token).then(res => {
        var link = document.createElement("a");
        link.download = res.data.data;
        link.href = res.data.data;
        link.target = '_blank'
        link.rel = 'noopener noreferrer'
        link.click();
      })
    )
  }

	render() {

    const text = 'Apakah Anda yakin akan menghapus data ini?'

    const columnsTable = [
      {
        title: 'Nama Lengkap',
        dataIndex: 'managementName',
        key: 'name',
        sorter: true,
        render: managementName => <Text style={{ color: '#222222', fontWeight: 600, cursor: 'pointer' }}>{managementName}</Text>,
        onCell: record => {
          return{
            onClick: () => {
              this.handleRedirectDetail(record.id)
            }
          }
        }
      },
      {
        title: 'Jabatan',
        dataIndex: 'managementPosition',
        key: 'position',
        sorter: true,
      },
			{
        title: 'Periode Jabatan',
        dataIndex: 'managementPeriod',
        key: 'period',
        sorter: true,
      },
      {
        title: 'Aksi',
        dataIndex: '',
        key: '',
        render: (data) => <>
          <Button
            onClick={() => this.handleEditClick(data)}
            type='link'
            style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600 }}
          >
            Ubah
          </Button>
          <Popconfirm
            placement="bottomRight"
            title={text}
            onConfirm={() => this.handleReject(data.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type='link'
              style={{ color: '#E22828', cursor: 'pointer', fontWeight: 600 }}
            >
              Hapus
            </Button>
          </Popconfirm>
        </>
      },
    ]

    const formItem = [
			{
        name: 'managementName',
        type: 'input',
        colNumber: 24,
        maxLength: 256,
        rules: [
          {
            required: true,
            message: 'Nama Lengkap harus diisi!'
          }
        ],
        placeholder: 'Nama Lengkap'
      },
			{
        name: 'managementPosition',
        type: 'input',
        colNumber: 12,
        maxLength: 256,
        rules: [
          {
            required: true,
            message: 'Jabatan (IND) harus diisi!'
          }
        ],
        placeholder: 'Jabatan'
      },
			{
        name: 'managementPositionEng',
        type: 'input',
        colNumber: 12,
        maxLength: 256,
        rules: [
          {
            required: true,
            message: 'Jabatan (ENG) harus diisi!'
          }
        ],
        placeholder: 'Position'
      },
			{
        name: 'managementPeriod',
        type: 'dateRange',
				picker: "year",
        colNumber: 24,
        rules: [
          {
            required: true,
            message: 'Periode Jabatan harus diisi!'
          }
        ],
        placeholder: 'Periode Jabatan'
      },
			{
        name: 'managementProfil',
        type: 'textArea',
        rowsText: 5,
        colNumber: 12,
        rules: [
          {
            required: true,
            message: 'Profil (IND) harus diisi!'
          }
        ],
        placeholder: 'Profil'
      },
      {
        name: 'managementProfilEng',
        type: 'textArea',
        rowsText: 5,
        colNumber: 12,
        rules: [
          {
            required: true,
            message: 'Profil (ENG) harus diisi!'
          }
        ],
        placeholder: 'Profile'
      },
			{
        name: 'managementUrl',
        type: 'input',
        colNumber: 24,
        rules: [
          {
						required: true,
						message: 'URL Video harus diisi!',
					},
					{
						pattern: /^http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/ ,
						message: 'Harap masukkan URL Video YouTube yang valid!'
					}
        ],
        placeholder: 'URL Video'
			}
    ]

		const {page, visibleCreate, initalData, isEdit, dataProfil, search } = this.state
		return(
			<div className='root'>
				<Card className='bodyCard_style'>
					<Row gutter={[16,0]}>
            <Col span={8} xs={12} md={8}>
              <Button onClick={() => this.openModal()} className='addButton'>+ Tambah</Button>
            </Col>
            <Col span={8} xs={12} md={8} style={{textAlign: 'right'}}>
              <Button className='blueButton' onClick={() => this.handleExport()}>Export</Button>
            </Col>
            <Col span={8} xs={24} md={8}>
              <UISearch value={search} placeholder='Nama Lengkap' handleSearch={key => this.handleSearch(key)} />
            </Col>
          </Row>
					<Card
            bordered={false}
            style={{minHeight: '300px'}}>
            <UItable
              scroll={{x: 800}}
              dataTable={dataProfil.data}
              columns={columnsTable}
              withRows={false}
              page={page}
              totalData={dataProfil.totalData}
              sortDefault=''
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
					<UIModalCreate
						formItem={formItem}
						visible={visibleCreate}
						initialValues={initalData}
						title={ isEdit ? 'Ubah Data Profil Pengurus' : 'Tambah Data Profil Pengurus'}
						widthDivider='250px'
            changeInput={() => {return true}}
						close={() => this.handleCloseModal()}
            onReset={() => this.handleReset()}
						checkFinish={ values => this.handleFinish(values)}
					/>
				</Card>
			</div>
		)
	}
}


export default ListProfilPengurus
