import React, { useEffect, useState } from 'react'
import { Button, Col, DatePicker, Form, Input, Modal, Radio, Row, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import config from '../../services/config'
import { RedStar } from '../index';
import { confirmDialog, dialog } from '../../utils/alert';
import axios from 'axios';
const { BASE_URL_LOGIN } = config;


const changePass = (token, userId, body) => {
  const isEng = false;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  };
  const url = `${BASE_URL_LOGIN}be/api/users/password/update?isEng=${isEng}&usersId=${userId}`;
  return axios.put(url, body, config);
};

export default function ModalUbahPassword(props) {
  const {visible, onCancel, onFinish} = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    form.validateFields().then(_ => {
      confirmDialog({
        title: 'Ubah Password',
        text: 'Apakah Anda yakin akan mengubah password akun Anda?'
      }).then(async _ => {
        const token = sessionStorage.getItem('token');
        const usersId = sessionStorage.getItem('userId');
        const body = form.getFieldsValue();
        setIsLoading(true);
        try {
          const res = await changePass(token, usersId, body);
          if (!res.data || res.data.responseType !== 'Success') {
            throw res.data ? res.data : res;
          }
          dialog({
            icon: 'success',
            title: 'Password Berhasil Diubah',
          }).then(_ => { onFinish(); })

        } catch (err) {
          console.log(err.message);
          dialog({
            title: 'Error',
            text: err.message || 'Unknown Error',
            icon: 'error'
          });
        }
        setIsLoading(false);
      }).catch(_ => {});
    }).catch(_ => {});
  }

  return (
    <Modal
      className="app-modal"
      width={400}
      footer={null}
      visible={visible}
      onCancel={onCancel}
    >
      <Spin spinning={isLoading}>
        <Title level={4} className="titleModalGalery" style={{marginBottom: '1em'}}>Ubah Password</Title>
        <Form form={form}>
          <Row gutter={14}>
            <Col span={24}>
              <Text className="form-label">Password Lama <RedStar /></Text>
              <Form.Item
                rules={[{required: true, message: 'Password Lama harus diisi!'}]}
                name="oldPassword">
                <Input.Password maxLength={256} placeholder="Password Lama" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Text className="form-label">Password Baru <RedStar /></Text>
              <Form.Item
                rules={[{required: true, message: 'Password Baru harus diisi!'}]}
                name="newPassword">
                <Input.Password maxLength={256} placeholder="Password Baru" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Text className="form-label">Konfirmasi Password Baru <RedStar /></Text>
              <Form.Item
                rules={[{required: true, message: 'Konfirmasi Password Baru!'}]}
                name="confirmationPassword">
                <Input.Password maxLength={256} placeholder="Konfirmasi Password Baru" />
              </Form.Item>
            </Col>
            <Col span={24} style={{ display: 'flex', justifyContent: 'center', paddingTop: '1em' }}>
              <Button className="app-btn" size="large" style={{marginLeft: '1em'}} type="primary" onClick={handleSubmit} >Ubah Password</Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}
