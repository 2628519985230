import React, { Component } from 'react'
import '../../../global/global.css'
import { endpoint } from '../../../api'
import {CaretRightOutlined} from '@ant-design/icons';
import { trackPromise } from 'react-promise-tracker';
import {
  Card,
  Row,
  Col,
  Typography,
  Modal
} from 'antd'
import ImagePreviewComponent from '../../../components/imagePreview'
import {GlobalVideoCheck} from '../../../global/checkingVideo'

const { Text } = Typography

class FAQDetail extends Component {
	constructor(props) {
		super(props)
		this.state = {
      initial: {
        urlVideoImage: '',
        urlVideo: ''
      },
      validateVideo: '',
      visible: false
		}
	}

  handleOpenVideo = url => {
    this.setState({
      validateVideo: url,
    }, () => {
      this.setState({
        visible: true
      })
    })
  }

  handleCloseVideo = () => {
    this.setState({
      validateVideo: '',
      visible: false
    })
  }

	componentDidMount() {
    const id = this.props.location.pathname.split('/')[2]
    const body = {
      token: this.props.token,
      id: id
    }
    this.getData(body)
  }

	getData = param => {
		trackPromise(
      endpoint.getDataFaqDetail(param).then(res => {
        this.setState({
          initial: res.data.data
        })
      })
    )
	}


	render() {
    const {initial} = this.state

		return(
			<div className='root'>
        <Card className='bodyCard_style'>
          <Row gutter={[8,8]}>
            <Col span={24}>
              <span className='header_text_detail'>
                Kategori
              </span>
            </Col>
            <Col span={24}>
              <span className='text_detail_institusi'>
                {initial.faqCategory}
              </span>
            </Col>
            <Col span={12}>
              <Row gutter={[8,8]}>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Pertanyaan
                  </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi'>
                    {initial.faqQuestionInd}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={[8,8]}>
                <Col span={24}>
                  <span className='header_text_detail'>
                  Question
                  </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi'>
                    {initial.faqQuestionEng}
                  </span>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row gutter={[8,8]}>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Jawaban
                  </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi' style={{whiteSpace: 'pre-line'}}>
                    {initial.faqAnswerInd}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={[8,8]}>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Solution
                  </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi' style={{whiteSpace: 'pre-line'}}>
                    {initial.faqAnswerEng}
                  </span>
                </Col>
              </Row>
            </Col>

          </Row>
        </Card>
        <Modal
          visible={this.state.visible}
          centered
          className={'cardVideo'}
          footer={null}
          width={'75%'}
          onCancel={() => this.handleCloseVideo()}
        >
          {GlobalVideoCheck.checking(this.state.validateVideo) ? 
          (
            <iframe
              style={{width: '-webkit-fill-available', height: '500px'}}
              id="modalVideo"
              title='modalVideo'
              src={this.state.validateVideo}
              frameBorder="0"
              allow="accelerometer;
              autoplay;
              encrypted-media;
              gyroscope;
              picture-in-picture"
              allowFullScreen="allowfullscreen"
            />
          ) : 
          (
            <span className='data_text_detail'>
              Url Video Tidak Valid/Error
            </span>
          )
        }
        </Modal>
			</div>
		)
	}
}


export default FAQDetail