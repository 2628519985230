import React, { useEffect, useState} from 'react'

import { 
  Modal, 
  Form, 
  Row, 
  Col,
  Typography,
  Input,
  Button,
  DatePicker,
  InputNumber,
  Radio,
  TimePicker
} from 'antd'
import { EyeInvisibleOutlined,UploadOutlined } from '@ant-design/icons'
import { usePromiseTracker } from "react-promise-tracker";
import TextArea from 'antd/lib/input/TextArea';
import UploadComponentValues from '../../../components/uploadComponentValues';

import { trackPromise } from 'react-promise-tracker';
import { endtpointJadwal } from '../../../api/jadwal';
import { alert } from '../../../api';
import moment from 'moment'
import { confirmDialog } from '../../../utils/alert';
import { RedStar } from '../../../components';

const {Title,Text} = Typography

const mediaLov = [
  {
    key: true,
    value: 'Online'
  },
  {
    key: false,
    value: 'Offline'
  }
]

const ModalCreate = props => {

  const { promiseInProgress } = usePromiseTracker();
  const [form] = Form.useForm()
  const [isOnline, setIsOnline] = useState(true)
  const [loadingUploadImage, setLoadingImage] = useState(false)
  const [imageDbThumbnail, setImageDbThumbnail] = useState('')
  const [isImageThumbnailChange, setIsImageThumbnailChange] = useState(false)

  const changeFileThumbnail = (e) => {
    let valueTemp = form.getFieldsValue()
    setIsImageThumbnailChange(true)
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    trackPromise(
      endtpointJadwal.uploadBanner(props.token, formData, 'imageSmall?isEng=0&path=donor-beasiswa').then(res => {
        if(res == 'error'){
          setImageDbThumbnail('Failed')
          setIsImageThumbnailChange(false)
          form.setFieldsValue({...valueTemp, thumbnailUrl: ''})
        }else{
          if(res.fileName){
            setImageDbThumbnail(res.url)
            setIsImageThumbnailChange(false)
            form.setFieldsValue({...valueTemp, thumbnailUrl: res.fileName})
          }
        }
      })
    )
  }

  useEffect(() => {
    if (props.visible === true) {
      form.resetFields();
      setImageDbThumbnail(props.initialValues.imageUrl)
      setIsOnline(props.initialValues.isOnline)
      form.setFieldsValue(props.initialValues)
    }
  }, [props.visible])

  const handleClose = () => {
    setImageDbThumbnail(null)
    setIsImageThumbnailChange(false)
    setIsOnline(true)
    form.resetFields();
    props.close()
  }

  const checkFinish = values => {
    let dataTemp = values
    if(props.isEdit){
      confirmDialog({
        icon: 'warning',
        title: 'Ubah Data Jadwal',
        text: 'Apakah Anda yakin akan mengubah data ini dan mengirim e-mail perubahan informasi acara ke seluruh peserta acara?'
      }).then(()=>{
        props.checkFinish(dataTemp)
      })
    }else{
      confirmDialog({
        title: 'Simpan Data Jadwal',
        text: 'Pastikan data Jadwal sudah sesuai!'
      }).then(()=>{
        props.checkFinish(dataTemp)
      })
    }
  }

  const onFinishFailed = errorInfo => {
  };

  const handleResetForm = () => {
      let dataTemp = isOnline
      form.resetFields(); 
      if(!props.isEdit){
        setIsOnline(dataTemp)
        setImageDbThumbnail(null)
        form.setFieldsValue({isOnline: dataTemp})
      }else{
        setImageDbThumbnail(props.initialValues.imageUrl)
        setIsOnline(props.initialValues.isOnline)
        form.setFieldsValue(props.initialValues)
      }
  };
  
  const handleIsMedia = e => {
    setIsOnline(e.target.value)
  }
  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().add(-1, 'days');
  }

  return (
    <Modal 
      visible={props.visible}
      footer={null}
      className='modal_galery'
      width={633}
      centered
      onCancel={() => handleClose()}
      destroyOnClose={true}
    >
      <Row>
        <Col span={24}>
          <div className='headerForm'>
            <Title className='titleModalGalery' level={4}>{props.title}</Title>
          </div>
        </Col>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={values => checkFinish(values)}
            onFinishFailed={onFinishFailed}
            initialValues={props.initialValues}
          >
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Text className="form-label">Nama Acara <RedStar /></Text>
                <Form.Item name='name'
                  rules={[{required: true,message: 'Nama Acara [IND] harus diisi!' }
                ]}>
                  <Input placeholder={'Nama Acara'} size="large" className='input_style' maxLength={256}/> 
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Event Name <RedStar /></Text>
                <Form.Item name='nameEng'
                  rules={[{required: true,message: 'Nama Acara [ENG] harus diisi!' }
                ]}>
                  <Input placeholder={'Event Name'}  size="large" className='input_style' maxLength={256}/> 
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Hari, Tanggal Acara <RedStar /></Text>
                <Form.Item name='date'
                  rules={[{required: true,message: 'Hari, Tanggal Acara harus diisi!' }
                ]}>
                  <DatePicker 
                  disabledDate={disabledDate}
                  placeholder={'Hari, Tanggal Acara'}  className='input_style' 
                  format={'dddd,DD-MM-YYYY'}/> 
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Jam <RedStar /></Text>
                <Form.Item name='waktu'
                  rules={[{required: true,message: 'Jam harus diisi!' }
                ]}>
                  <TimePicker.RangePicker 
                    placeholder={['Dari', 'Sampai']} format={'HH:mm'} className='input_style'/> 
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Deskripsi <RedStar /></Text>
                <Form.Item name='desc'
                  rules={[{required: true,message: 'Deskripsi [IND] harus diisi!' }
                ]}>
                  <TextArea placeholder={'Deskripsi'} size="large" className='input_style'/> 
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Description <RedStar /></Text>
                <Form.Item name='descEng'
                  rules={[{required: true,message: 'Deskripsi [ENG] harus diisi!' }
                ]}>
                  <TextArea placeholder={'Description'} size="large" className='input_style'/> 
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text className="form-label">Banner Acara <RedStar /></Text>
                <Form.Item name='thumbnailUrl'
                  rules={[{required: true,message: 'Banner Acara harus di-upload!' }
                ]}>
                  <UploadComponentValues
                    pathName='banner'
                    changeFile={e => changeFileThumbnail(e, false)}
                    dataFile={imageDbThumbnail}
                    loading={isImageThumbnailChange}
                    name={'thumbnailUrl'}
                    accept={".jpg,.jpeg,.png"}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Text className="form-label">Kuota Loyola <RedStar /></Text>
                <Form.Item name='loyolaQuota'
                  rules={[{required: true,message: 'Kuota Loyola harus diisi!' },
                  { pattern: /^[0-9]*$/gm, message: 'Harap masukkan angka!',}
                ]}>
                  <InputNumber min={0} placeholder={'Kuota Loyola'} className='input_style'  maxLength={9}/> 
                </Form.Item>
              </Col>
              <Col span={6}>
                <Text className="form-label">Kuota Umum <RedStar /></Text>
                <Form.Item name='nonLoyolaQuota'
                  rules={[{required: true,message: 'Kuota Umum harus diisi!' },
                  { pattern: /^[0-9]*$/gm, message: 'Harap masukkan angka!',}
                ]}>
                  <InputNumber min={0} placeholder={'Kuota Umum'} className='input_style' maxLength={9}/> 
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Media <RedStar /></Text>
                {
                  mediaLov && 
                  <Form.Item name='isOnline'
                    rules={[{required: true,message: 'Media harus diisi!' }
                  ]}>
                    <Radio.Group onChange={handleIsMedia}>
                      <Row gutter={[0,8]}>
                        {mediaLov.map((res) => (
                          <Col span={12} key={res.key}>
                            <Radio key={res.key} value={res.key}>
                              {res.value}
                            </Radio>
                          </Col>
                        ))}
                      </Row>
                    </Radio.Group>
                  </Form.Item>
                }
              </Col>
              {
                isOnline ? 
                <>
                  <Col span={12}>
                  <Text className="form-label">Nama Aplikasi <RedStar /></Text>
                    <Form.Item name='mediaApps'
                      rules={[{required: true,message: 'Nama Aplikasi harus diisi!' }
                    ]}>
                      <Input placeholder={'Nama Aplikasi'} size="large" className='input_style'  maxLength={256}/> 
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                  <Text className="form-label">URL Room <RedStar /></Text>
                    <Form.Item name='mediaUrl'
                      rules={[{required: true,message: 'URL Room harus diisi!'},
                      {
                        pattern: /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/, 
                        message: 'URL Room tidak valid'}
                    ]}>
                      <Input placeholder={'URL Room'} size="large" className='input_style'/> 
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                  <Text className="form-label">Username</Text>
                    <Form.Item name='mediaJoinId'>
                      <Input placeholder={'Username'} size="large" className='input_style'/> 
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                  <Text className="form-label">Password</Text>
                    <Form.Item name='mediaPassword'>
                      <Input placeholder={'Password'} 
                      // suffix={<EyeInvisibleOutlined/>} 
                      />
                    </Form.Item>
                  </Col>
                </> :
                <>
                  <Col span={12}>
                    <Text className="form-label">Lokasi <RedStar /></Text>
                    <Form.Item name='location'
                      rules={[{required: true,message: 'Lokasi harus diisi!' }
                    ]}>
                      <Input placeholder={'Lokasi'} size="large" className='input_style'  maxLength={256}/> 
                    </Form.Item>
                  </Col>
                </>
              }
              <Col span={24}>
                <Form.Item
                  style={{
                    textAlign: 'right',
                    marginBottom: '0px'
                  }}
                >
                  <Button
                    type="link"
                    className='resetButton'
                    size={'small'}
                    onClick={() => handleResetForm()}
                    disabled={props.loadingSubmit}
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    htmlType='submit'
                    className='blueButton'
                    loading={promiseInProgress}
                    size={'small'}
                    loading={props.loadingSubmit || loadingUploadImage}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>

        {/* <pre>
          {JSON.stringify(props.initialValues,'',2)}
        </pre> */}
      </Row>
    </Modal>
  )
}

export const ModalJadwal = ModalCreate