import { DeleteOutlined, DownloadOutlined, DownOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, notification, Popconfirm, Row, Select, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { UItable } from '../../../components';
import SearchInput from '../../../components/search-input';
import { confirmDialog, dialog } from '../../../utils/alert';
// import '../index.scss';
import danaPengembanganApi from '../../../api/danaPengembangan';
import informasiApi from '../../../api/informasi';
import { useHistory } from 'react-router';
import Text from 'antd/lib/typography/Text';
import { Link } from 'react-router-dom';

export default function Pengumuman() {
  const [data, setData] = useState(false);
  const [pagination, setPagination] = useState({current: 1, total: 0});
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const defaultParams = {keyword: search, page: pagination.current, sort: 'sg_nisn,asc', size: 10, category: 1};
  const history = useHistory();

  const columns = [
    {
      title: 'Judul Pengumuman',
      dataIndex: 'announceTitleIndVer',
      key: 'title',
      sorter: true,
      width: '30%',
      render: title => <Text style={{ color: '#222222', fontWeight: 600, cursor: 'pointer' }}>{title}</Text>,
      onCell: record => {
        return{
          onClick: () => {handleDetail(record)}
        }
      }
    },
    {
      title: 'Kategori',
      dataIndex: 'announceCategoryName',
      key: 'category',
      sorter: true,
    },
    {
      title: 'Tanggal',
      dataIndex: 'announceDate',
      key: 'date',
      sorter: true,
    },
    {
      title: 'Visibilitas',
      dataIndex: 'isActive',
      key: 'visibility',
      width: '150px',
      render: (e, row) => (
        <div>
          <Select size="small" style={{width: '100%'}} defaultValue={e} onChange={ev => {handleChangeStatus(row, ev)}}>
            <Select.Option value={true}>Tampilkan</Select.Option>
            <Select.Option value={false}>Sembunyikan</Select.Option>
          </Select>
        </div>
      )
    },
    {
      title: 'Aksi',
      align: 'center',
      width: '200px',
      render: (e, row) => (
        <Row justify="center">
          <Button type="link" className="app-btn warning" onClick={_ => handleEdit(row)}>Ubah</Button>
          <Popconfirm
            placement="bottomRight"
            title="Apakah Anda yakin akan menghapus data ini?"
            onConfirm={() => deleteData(row)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" className="app-btn danger" style={{marginLeft: '1em'}}>Hapus</Button>
          </Popconfirm>
        </Row>
      )
    },
  ];


  useEffect(() => {
    loadData(defaultParams);
  }, []);


  const loadData = async (param) => {
    setIsLoading(true);
    const token = sessionStorage.getItem('token');
    await informasiApi.getListPengumuman(token, param).then(res => {
      console.log(res.data.data);
      const { listAnnouncement, totalData, urlTemplate } = res.data.data;
      setData(listAnnouncement);
      setPagination({current: param.page, total: totalData});
    }).catch(err => {
      dialog({
        title: 'Error',
        message: err.message || 'Unknown Error',
        icon: 'error'
      });
    })
    setIsLoading(false);
  }


  const deleteData = async (data) => {
    const token = sessionStorage.getItem('token');
    const usersId = sessionStorage.getItem('userId');
    const params = {
      usersId,
      announceId: data.announceId,
    }
    setIsLoading(true);
    try {
      const res = await informasiApi.deletePengumuman(token, params);
      if (!res.data || res.data.responseType !== 'Success') { throw res.data; }
      dialog({
        icon: 'success',
        title: 'Data Pengumuman Berhasil Dihapus',
      });
      loadData(defaultParams);
    } catch (err) {
      dialog({
        icon: 'error',
        title: err ? err.message : 'Unknown error',
      })
    }
    setIsLoading(false);
  }

  const handleChangeStatus = async (row, status) => {
    const token = sessionStorage.getItem('token');
    const usersId = sessionStorage.getItem('userId');
    const params = {
      usersId,
      announceId: row.announceId,
      isActive: status
    }
    setIsLoading(true);
    try {
      const res = await informasiApi.changeStatusPengumuman(token, params);
      if (!res.data || res.data.responseType !== 'Success') { throw res.data; }
      dialog({
        icon: 'success',
        title: status ? 'Data Pengumuman Berhasil Ditampilkan ke Web Yayasan Loyola' : 'Data Pengumuman Berhasil Disembunyikan dari Web Yayasan Loyola',
      });
      loadData(defaultParams);
    } catch (err) {
      dialog({
        icon: 'error',
        title: err ? err.message : 'Unknown error',
      })
    }
    setIsLoading(false);
  }

  const handleSearch = (searchTerm) => {
    setSearch(searchTerm);
    const param = { ...defaultParams, keyword: searchTerm, page: 1 };
    loadData(param);
  }

  const handleTableChange = (page, filter, sort) => {
    console.log(sort, typeof sort)
    loadData({
      ...defaultParams,
      page: page,
      sort: sort || 'sg_nisn,asc'
    })
  }

  const handleTambah = () => {
    history.push('/tambah-pengumuman');
  };

  const handleEdit = (data) => {
    history.push('/edit-pengumuman/'+data.announceId);
  }

  const handleDetail = (data) => {
    history.push('/detail-pengumuman/'+data.announceId);
  }

  return (
    <div className="root">
      <Card className="app-card">
        <Spin spinning={isLoading}>
          <Row gutter={14}>
            <Col span={8} xs={12} md={16}>
              <Button className="addButton" onClick={handleTambah}><PlusOutlined /> Tambah</Button>
            </Col>
            <Col span={8} xs={24} md={8}>
              <SearchInput onChange={search => {handleSearch(search)}}  placeholder={'Judul Pengumuman'}/>
            </Col>
          </Row>
          <Row style={{padding: '24px'}}>
            <Col span={24}>
              <UItable
                dataTable={data}
                columns={columns}
                withRows={false}
                page={pagination.current}
                totalData={pagination.total}
                sortDefault=''
                handleTableChange={handleTableChange}
                scroll={{x: 800}}
              />
            </Col>
          </Row>
        </Spin>
      </Card>
    </div>
  )
}
