import React, { useEffect, useState } from 'react'
import { Button, Col, DatePicker, Form, Input, Modal, Radio, Row, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import { RedStar } from '../../../components';
import MoneyInput from '../../../components/money-input';
import { confirmDialog, dialog } from '../../../utils/alert';
import TextArea from 'antd/lib/input/TextArea';
import danaPengembanganApi from '../../../api/danaPengembangan';

export default function ModalTambahPenerima(props) {
  const {visible, onCancel, onFinish, donationId, data} = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  const saveData = async (formValue) => {
    setIsLoading(true);
    const token = sessionStorage.getItem('token')
    const body = formValue;
    try {
      const res = await danaPengembanganApi.postPenerimaDonasiBeasiswa(token, body);
      if (!res.data || res.data.responseType !== 'Success') { throw res.data; }
      dialog({
        icon: 'success',
        title: 'Data Penerima Beasiswa Berhasil Ditambah',
      });
      onReset();
      onFinish();
    } catch (err) {
      dialog({
        icon: 'error',
        title: err.message || '',
      })
    }
    setIsLoading(false);
  }

  const updateData = async (formValue) => {
    setIsLoading(true);
    const token = sessionStorage.getItem('token')
    const body = {
      ...formValue,
      scholarshipGranteeId: data.id
    }
    try {
      const res = await danaPengembanganApi.putPenerimaDonasiBeasiswa(token, body);
      if (!res.data || res.data.responseType !== 'Success') { throw res.data; }
      console.log(res);
      dialog({
        icon: 'success',
        title: 'Data Penerima Beasiswa Berhasil Diubah',
      });
      onReset();
      onFinish();
    } catch (err) {
      dialog({
        icon: 'error',
        title: err.message || '',
      })
    }
    setIsLoading(false);
  }



  const handleSubmit = () => {
    form.validateFields().then(_ => {
      confirmDialog({
        title: `${isEdit ? 'Ubah' : 'Tambah'} Data Penerima Beasiswa?`,
      }).then(_ => {
        const usersId = sessionStorage.getItem('userId');
        const formValue = {
          ...form.getFieldsValue(),
          scholarshipDonationId: donationId,
          usersId,
        };
        if (!!data.id) {
          updateData(formValue);
        } else {
          saveData(formValue);
        }
      }).catch(_ => {});
    }).catch(_ => {});
  }


  const onReset = () => {
    form.resetFields();
    // form.setFieldsValue(data);
  }

  const isEdit = (!!data && typeof data === 'object');

  return (
    <Modal
      className="app-modal"
      width={800}
      footer={null}
      visible={visible}
      onCancel={_ => {
        onReset();
        onCancel();
      }}
    >
      <Spin spinning={isLoading}>
        <Title level={4} className="titleModalGalery" style={{marginBottom: '1em'}}>{isEdit ? 'Ubah' : 'Tambah'} Data Penerima Beasiswa</Title>
        {/* <hr className="app-hr" /> */}
        <Form form={form}>
          <Row gutter={14}>
            <Col span={24}>
              <Text className="form-label">Nama Lengkap <RedStar /></Text>
              <Form.Item
                rules={[{required: true, message: 'Nama Lengkap harus diisi!'}]}
                name="name">
                <Input placeholder="Nama Lengkap" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">NISN <RedStar /></Text>
              <Form.Item
                rules={[
                  {min: 10, message: 'NISN harus 10 digit!'},
                  {required: true, message: 'NISN harus diisi'},
                  {pattern: new RegExp('^[0-9]+$'), message: "NISN harus diisi dengan angka"}
                ]}
                name="nisn">
                <Input placeholder="NISN" maxLength={10} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Nominal <RedStar /></Text>
              <Form.Item
                rules={[{required: true, message: 'Nominal harus diisi!'}]}
                name="nominal">
                <MoneyInput placeholder="Nominal" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Keterangan <RedStar /></Text>
              <Form.Item
                rules={[{required: true, message: 'Keterangan harus diisi!'}]}
                name="reasonInd">
                <TextArea placeholder="Keterangan" autoSize={{minRows: '6'}} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Information <RedStar /></Text>
              <Form.Item
                rules={[{required: true, message: 'Information harus diisi!'}]}
                name="reasonEng">
                <TextArea placeholder="Information" autoSize={{minRows: '6'}} />
              </Form.Item>
            </Col>
            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '1em' }}>
              {!isEdit && <Button className="app-btn" size="large" type="link" style={{marginLeft: '1em'}} onClick={onReset}>Reset</Button>}
              <Button className="app-btn" size="large" style={{marginLeft: '1em'}} type="primary" onClick={handleSubmit} >Submit</Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}
