import { get, put, post } from 'axios'
import config from '../services/config'
import qs from 'querystring'
import swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import normalize from './normalizeData'
const { BASE_URL_LOGIN, TIMEOUT, BASE_URL_NOAUTH } = config

const normalizeQuery = (url) => {
  return qs.stringify(url)
}

const alert = {
  showAlert(message = 'Error', status = '') {
    swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
      confirmButtonText: 'Reload',
      allowOutsideClick: true,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    }).then(result => {
      if (status === 401) {
        sessionStorage.clear()
        window.location.href = '/'
      }
      else if (result.value) {

        window.location.reload();
      }
    });
  },
  alertCreateEditInfo(message = '') {
    swal.fire({
      icon: 'info',
      title: `Info`,
      text: message,
      confirmButtonText: 'OK',
      allowOutsideClick: true,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    })
  },
  alertCreateEditSuccess(message = 'Success') {
    swal.fire({
      icon: 'success',
      title: `Success`,
      text: message,
      confirmButtonText: 'OK',
      allowOutsideClick: true,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    })
  },
  alertCreateEditError(message = 'Error', from = '', type = '') {
    swal.fire({
      icon: 'error',
      title: `${type} ${from} Failed`,
      text: message,
      cancelButtonText: 'Close',
      allowOutsideClick: true,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonColor: '#f27474',
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    })
  },
};

const endpoint = {
  getLovFaq(){
    let url = `${BASE_URL_LOGIN}noAuth/be/api/lov/faqCategory`
    // let url = `https://demo5436246.mockable.io/listApapun`
    return get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: TIMEOUT
    }).then(response => {
      return response.data.data
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return 'error'
    })
  },
  getDataFaq(param){
    const urlString = normalizeQuery({
      sort: param.sort,
      size: param.size,
      page: param.page - 1,
      keyword: param.search,
      isEng: 0
    })
    return get(`${BASE_URL_LOGIN}be/api/admin/setting/faq?${urlString}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${param.token}`,
      },
      timeout: TIMEOUT
    }).then(res => {
      if(res.data.rc == "00"){
        return normalize.normalizeDataFaq(res)
      }else{
        alert.showAlert(res.data.message, res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {
        data: [],
        totalData: 0,
        status: 'error',
      }
    })
  },
  getDataFaqDetail(param){
    const urlString = normalizeQuery({
      faqId: param.id,
    })
    return get(`${BASE_URL_LOGIN}be/api/admin/setting/faq/detail?${urlString}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${param.token}`,
      },
      timeout: TIMEOUT
    }).then(response => {
      return response
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {}
    })
  },
  createFaq(token, body) {
    return post(`${BASE_URL_LOGIN}be/api/admin/setting/faq/insert`, 
      body,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        timeout: TIMEOUT
      }
    ).then(res => {
      if(res.data.rc == "00"){
        alert.alertCreateEditSuccess(res.data.message)
        return 'success'
      }else{
        alert.alertCreateEditError(res.data.message, 'Data', 'Create', res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Data', 'Create', err.response.status)
      return 'error'
    })
  },
  editFaq(token, id, body) {
    const urlString = normalizeQuery({
      faqId: id,
    })
    return put(`${BASE_URL_LOGIN}be/api/admin/setting/faq/update?${urlString}`,
      body,
      {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          timeout: TIMEOUT
      }
    ).then(res => {
      if(res.data.rc == "00"){
        alert.alertCreateEditSuccess(res.data.message)
        return 'success'
      }else{
        alert.alertCreateEditError(res.data.message, 'Data', 'Edit', res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Data', 'Edit', err.response.status)
      return 'error'
    })
  },
  deleteDataFaq(token, userId, id) {
    const urlString = normalizeQuery({
      faqId: id,
      usersId: userId
    })
    return put(`${BASE_URL_LOGIN}be/api/admin/setting/faq/delete?${urlString}`,
      {},
      {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          timeout: TIMEOUT
      }
    ).then(res => {
      if(res.data.rc == "00"){
        alert.alertCreateEditSuccess(res.data.message)
        return 'success'
      }else{
        alert.alertCreateEditError(res.data.message, 'Data', 'Delete', res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Data', 'Delete', err.response.status)
      return 'error'
    })
  },

  getHeaderFooter(){
    let url = `${BASE_URL_LOGIN}noAuth/be/api/admin/headerFooter`
    // let url = `https://demo5436246.mockable.io/listApapun`
    return get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: TIMEOUT
    }).then(response => {
      return response
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
    })
  },
  getLovCountry(){
    let url = `${BASE_URL_LOGIN}noAuth/be/api/lov/country`
    return get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: TIMEOUT
    }).then(response => {
      return response.data
    }).catch(err => {
    })
  },
  getLovProvincy(id){
    let url = `${BASE_URL_LOGIN}noAuth/be/api/lov/province?countryId=`+id
    return get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: TIMEOUT
    }).then(response => {
      return response.data
    }).catch(err => {
    })
  },
  getLovCity(id){
    let url = `${BASE_URL_LOGIN}noAuth/be/api/lov/city?provinceId=`+id
    return get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: TIMEOUT
    }).then(response => {
      return response.data
    }).catch(err => {
    })
  },
  editHeaderFooter(token, userId, body) {
    return put(`${BASE_URL_LOGIN}be/api/admin/headerFooter/submit`, 
      body,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        timeout: TIMEOUT
      }
    ).then(res => {
      if(res.data.rc == "00"){
        alert.alertCreateEditSuccess(res.data.message)
        return 'success'
      }else{
        alert.alertCreateEditError(res.data.message, 'Header Footer', 'Update')
        return 'warning'
      }
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Header Footer', 'Update')
      return 'error'
    })
  },

  // profil pengurus
  getDataProfil(param){
    console.log(param)
    const urlString = normalizeQuery({
      sort: param.sort,
      size: param.size,
      page: param.page - 1,
      keyword: param.search
    })
    return get(`${BASE_URL_LOGIN}be/api/admin/daftarProfilPengurus?${urlString}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${param.token}`,
      },
      timeout: TIMEOUT
    }).then(response => {
      return normalize.normalizeDataProfil(response)
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {
        data: [],
        totalData: 0,
        status: 'error',
      }
    })
  },
  getDataDetailProfil(param){
    const urlString = normalizeQuery({
      managementId: param.id,
    })
    return get(`${BASE_URL_LOGIN}be/api/admin/daftarProfilPengurus/detail?${urlString}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${param.token}`,
      },
      timeout: TIMEOUT
    }).then(response => {
      return response
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {}
    })
  },
  getExport(token){
    return get(`${BASE_URL_LOGIN}be/api/admin/daftarProfilPengurus/export`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }).then(response => {
      return response
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {}
    })
  },
  createProfil(token, body) {
    return post(`${BASE_URL_LOGIN}be/api/admin/daftarProfilPengurus/insert`, 
      body,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        timeout: TIMEOUT
      }
    ).then(res => {
      if(res.data.rc == "00"){
        alert.alertCreateEditSuccess(res.data.message)
        return 'success'
      }else{
        alert.alertCreateEditError(res.data.message, 'Profil Pengurus', 'Create', res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Profil Pengurus', 'Create', err.response.status)
      return 'error'
    })
  },
  editProfil(token, id, body) {
    const urlString = normalizeQuery({
      managementId: id,
    })
    return put(`${BASE_URL_LOGIN}be/api/admin/daftarProfilPengurus/update?${urlString}`,
      body,
      {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          timeout: TIMEOUT
      }
    ).then(res => {
      if(res.data.rc == "00"){
        alert.alertCreateEditSuccess(res.data.message)
        return 'success'
      }else{
        alert.alertCreateEditError(res.data.message, 'Profil Pengurus', 'Edit', res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Profil Pengurus', 'Edit', err.response.status)
      return 'error'
    })
  },
  deleteDataProfil(token, userId, id) {
    const urlString = normalizeQuery({
      managementId: id,
      usersId: userId
    })
    return put(`${BASE_URL_LOGIN}be/api/admin/daftarProfilPengurus/delete?${urlString}`,
      {},
      {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          timeout: TIMEOUT
      }
    ).then(res => {
      alert.alertCreateEditSuccess(res.data.message)
      return 'success'
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Profil Pengurus', 'Delete', err.response.status)
      return 'error'
    })
  },

  // Informasi Pendirian
  getPendirianSebelum(param){
    const urlString = normalizeQuery({
      sort: param.sort,
      size: param.size,
      page: param.page - 1,
      keyword: param.search
    })
    return get(`${BASE_URL_LOGIN}be/api/admin/informasiSebelum2017?${urlString}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${param.token}`,
      },
      timeout: TIMEOUT
    }).then(response => {
      return normalize.normalizePendirianSebelum(response)
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {
        data: [],
        totalData: 0,
        status: 'error',
      }
    })
  },
  getLOVDokumen(token){
    return get(`${BASE_URL_LOGIN}be/api/lov/docFomat`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }).then(response => {
      return normalize.normalizeLOVDokumen(response)
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {
        data: [],
        status: 'error'
      }
    })
  },
  getLOVTahunPendirian(token){
    return get(`${BASE_URL_LOGIN}be/api/lov/yearBefore2017`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }).then(response => {
      return normalize.normalizeLOVTahun(response)
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {
        data: [],
        status: 'error'
      }
    })
  },
  uploadBerkasPendirian(token, body, url) {
    return post(`${BASE_URL_LOGIN}be/api/yayasanLoyola/upload/${url} `, 
      body,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      }
    ).then(res => {
      alert.alertCreateEditSuccess('Upload file berhasil')
      return res.data
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Informasi Pendirian', 'Upload', err.response.status)
      return 'error'
    })
  },
  createPendirianSebelum(token, body) {
    return post(`${BASE_URL_LOGIN}be/api/admin/informasiSebelum2017/insert`, 
      body,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        timeout: TIMEOUT
      }
    ).then(res => {
      alert.alertCreateEditSuccess(res.data.message)
      return 'success'
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Informasi Pendirian', 'Create', err.response.status)
      return 'error'
    })
  },
  editPendirianSebelum(token, id, body) {
    const urlString = normalizeQuery({
      docOwnerId: id,
    })
    return put(`${BASE_URL_LOGIN}be/api/admin/informasiSebelum2017/update?${urlString}`,
      body,
      {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          timeout: TIMEOUT
      }
    ).then(res => {
      alert.alertCreateEditSuccess(res.data.message)
      return 'success'
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Profil Pengurus', 'Edit', err.response.status)
      return 'error'
    })
  },
  deleteDataPendirian(token, userId, id) {
    const urlString = normalizeQuery({
      docOwnerId: id,
      usersId: userId
    })
    return put(`${BASE_URL_LOGIN}be/api/admin/informasiSebelum2017/delete?${urlString}`,
      {},
      {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          timeout: TIMEOUT
      }
    ).then(res => {
      alert.alertCreateEditSuccess(res.data.message)
      return 'success'
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Profil Pengurus', 'Delete', err.response.status)
      return 'error'
    })
  },
  getDataDetailPendirian(param){
    const urlString = normalizeQuery({
      docOwnerId: param.id,
    })
    return get(`${BASE_URL_LOGIN}be/api/admin/informasiSebelum2017/detail?${urlString}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${param.token}`,
      },
      timeout: TIMEOUT
    }).then(response => {
      return response
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {}
    })
  },

  // informasi setelah
  getPendirianSetelah(param){
    const urlString = normalizeQuery({
      sort: param.sort,
      size: param.size,
      page: param.page - 1,
      keyword: param.search
    })
    return get(`${BASE_URL_LOGIN}be/api/admin/informasiSejak2017?${urlString}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${param.token}`,
      },
      timeout: TIMEOUT
    }).then(response => {
      return normalize.normalizePendirianSetelah(response)
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {
        data: [],
        totalData: 0,
        status: 'error',
      }
    })
  },
  getLOVTahunSetelah(token){
    return get(`${BASE_URL_LOGIN}be/api/lov/yearSince2017`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }).then(response => {
      return normalize.normalizeLOVTahun(response)
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {
        data: [],
        status: 'error'
      }
    })
  },
  getDataDetailPendirianSetelah(param){
    const urlString = normalizeQuery({
      docOwnerId: param.id,
    })
    return get(`${BASE_URL_LOGIN}be/api/admin/informasiSejak2017/detail?${urlString}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${param.token}`,
      },
      timeout: TIMEOUT
    }).then(response => {
      return response
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {}
    })
  },
  createPendirianSetelah(token, body) {
    return post(`${BASE_URL_LOGIN}be/api/admin/informasiSejak2017/insert`, 
      body,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        timeout: TIMEOUT
      }
    ).then(res => {
      alert.alertCreateEditSuccess(res.data.message)
      return 'success'
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Informasi Pendirian', 'Create', err.response.status)
      return 'error'
    })
  },
  editPendirianSetelah(token, id, body) {
    const urlString = normalizeQuery({
      docOwnerId: id,
    })
    return put(`${BASE_URL_LOGIN}be/api/admin/informasiSejak2017/update?${urlString}`,
      body,
      {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          timeout: TIMEOUT
      }
    ).then(res => {
      alert.alertCreateEditSuccess(res.data.message)
      return 'success'
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Profil Pengurus', 'Edit', err.response.status)
      return 'error'
    })
  },
  deleteDataPendirianSetelah(token, userId, id) {
    const urlString = normalizeQuery({
      docOwnerId: id,
      usersId: userId
    })
    return put(`${BASE_URL_LOGIN}be/api/admin/informasiSejak2017/delete?${urlString}`,
      {},
      {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          timeout: TIMEOUT
      }
    ).then(res => {
      alert.alertCreateEditSuccess(res.data.message)
      return 'success'
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Profil Pengurus', 'Delete', err.response.status)
      return 'error'
    })
  },
}

export { endpoint, alert }