import React, { useState } from 'react'
import { put } from 'axios'
import config from '../../services/config'
import urlLoyola from '../../global/assets/logo.png'
import { Row, Col, Input, Button, Card, Form, notification } from 'antd'
import { MailOutlined } from '@ant-design/icons'
import { useHistory } from "react-router-dom";

const { BASE_URL_NOAUTH, BASE_URL_LOGIN } = config



function ForgotPassword (props) {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [form] = Form.useForm();
  const history = useHistory();

  const handleSubmit = () => {
    form.validateFields().then(async _ => {
      const email = form.getFieldValue('email');
      setIsLoading(true);
      try {
        const res = await put(`${BASE_URL_LOGIN}noAuth/be/api/users/forgotPassword?isEng=false&isAdmin=true`, { email });
        if (!res.data || res.data.responseType !== 'Success') { throw res.data; }
        notification.success({
          message: 'E-mail Reset Password Berhasil Dikirim',
          description: 'Silahkan cek pada e-mail Anda!',
          placement: 'bottomRight',
        });
        form.resetFields();
      } catch (err) {
        notification.error({
          message: 'Error',
          description: err.message,
          placement: 'bottomRight',
        });
      }
      setIsLoading(false);
    }).catch(err => {})
  }

  const handleLogin = () => {
    history.push('/')
  }

  return (
    <div className="content">
      <div className="container">
        <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
          <Row className="login-row" align="middle" gutter={[20, 20]}>
            <Col lg={18} md={14} span={24}>
              <Row align="middle" gutter={[20, 20]}>
                <Col>
                  <img className="loyolaLogo" alt="loyolaLogo" src={urlLoyola}/>
                </Col>
                <Col>
                  <span className="text-login-content">PORTAL ADMIN<br />YAYASAN LOYOLA</span>
                </Col>
              </Row>
            </Col>

            <Col lg={6} md={10} span={24}>
              <Card className="app-card" style={{padding: '0px 10px'}}>
                <Form form={form}>
                  <div className="login-title">Lupa Password</div>
                  <Row justify='center'>
                    <Col span={24} style={{ marginBottom: '2em' }}>
                      <Form.Item
                        name="email"
                        rules={[
                          {type: 'email', message: 'Harap masukkan format e-mail!'},
                          {required: true, message: 'E-mail harus diisi!'},
                        ]}
                      >
                        <Input
                          className="input-style-login"
                          prefix={<MailOutlined />}
                          placeholder="E-mail"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} style={{ textAlign: 'center', marginBottom: '.5em'}}>
                      <Button
                        size="large"
                        className="app-btn primary"
                        type="primary"
                        onClick={handleSubmit}
                        loading={isLoading}
                      >
                        Submit
                      </Button>
                    </Col>
                    {email}
                    <Col span={24} style={{ textAlign: 'center'}}>
                      <Button onClick={handleLogin} className="app-btn" type="link" >
                        <span style={{ textDecoration: 'underline' }}>Login di sini</span>
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}


export default ForgotPassword
