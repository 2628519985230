import React, { Component } from 'react'
import '../../../global/global.css'
import { trackPromise } from 'react-promise-tracker';
import {
  Card,
  Row,
  Col,
  Typography,
  Modal,
  Button,
  Tabs,
  Table
} from 'antd'
import ImagePreviewComponent from '../../../components/imagePreview'
import { endtpointJadwal } from '../../../api/jadwal';
import { UISearch } from '../../../components';
import Title from 'antd/lib/typography/Title';

const { Text } = Typography
const { TabPane } = Tabs;
const columns = [
  {
    title: 'Nama Lengkap',
    dataIndex: 'participantName',
    // sorter: true
  }
];

class JadwalDetail extends Component {
	constructor(props) {
		super(props)
		this.state = {
      initial: {},
      quota:{},
      validateVideo: '',
      visible: false,
      isTab: "1",
      listUmum: [],
      listLoyola: []
		}
	}

  handleOpenVideo = url => {
    this.setState({
      validateVideo: url,
    }, () => {
      this.setState({
        visible: true
      })
    })
  }

  handleCloseVideo = () => {
    this.setState({
      validateVideo: '',
      visible: false
    })
  }

	componentDidMount() {
    const id = this.props.location.pathname.split('/')[2]
    const body = {
      token: this.props.token,
      id: id
    }
    this.getData(body)
  }

	getData = param => {
		trackPromise(
      endtpointJadwal.getDataDetail(param).then(res => {
        this.setState({
          initial: res.data.data.jadwal,
          quota: res.data.data.quota,
          listUmum: res.data.data.quota && res.data.data.quota.participantsPublic,
          listLoyola: res.data.data.quota && res.data.data.quota.participantsLoyola
        })
      })
    )
	}
handleSearch = searchText => {
  const { quota:{participantsPublic,participantsLoyola} } = this.state
  let list = this.state.isTab == "1" ? participantsLoyola : participantsPublic
  const filteredEvents = list.filter(({ participantName }) => {
    participantName = participantName.toLowerCase();
    return participantName.includes(searchText);
  });

  this.setState({
    listUmum: filteredEvents
  });
}

changeTab = key => {
  this.setState({
    isTab: key
  })
}

	render() {
    const {initial, quota, isTab, listUmum, listLoyola} = this.state

		return(
			<div className='root'>
        <Card className='bodyCard_style'>
          <Row gutter={[8,8]}>
            <Col span={24}>
              <span className='header_text_detail'>
                Banner
              </span>
            </Col>
            <Col span={12}>
                <div style={{ width: '70%'}}>
                  {/* <CaretRightOutlined 
                    className='playButton'
                    onClick={() => this.handleOpenVideo(initial.urlVideoImage)}
                  /> */}
                  <ImagePreviewComponent
                    isMobile={false}
                    styles={{ width: '100%' }}
                    uri={initial.thumbnailUrl}
                    imageBorder={'6px'}
                  />
                </div>
            </Col>
            <Col span={12}/>
            <Col span={12} xs={24} md={12}>
              <Row gutter={[8,8]}>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Nama Jadwal
                  </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi'>
                    {initial.name}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Hari, Tanggal
                  </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi'>
                    {initial.day && initial.day+', '}{initial.date}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Jam
                  </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi'>
                    {initial.time}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Deskripsi
                  </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi' style={{whiteSpace: 'pre-line'}}>
                    {initial.desc}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={12} xs={24} md={12}>
              <Row gutter={[8,8]}>
                <Col span={12}>
                  <span className='header_text_detail'>
                    Kuota
                  </span>
                </Col>
                <Col span={12}>
                  <Button onClick={()=>this.setState({visible: true})}>
                    Lihat
                  </Button>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Media
                  </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi'>
                    {initial.media}
                  </span>
                </Col>
                {
                  initial.media && initial.media.toLowerCase() == 'online' ?
                  <>
                    <Col span={24}>
                      <span className='header_text_detail'>
                        Aplikasi
                      </span>
                    </Col>
                    <Col span={24}>
                      <span className='text_detail_institusi'>
                        {initial.mediaApps}
                      </span>
                    </Col>
                    <Col span={24}>
                      <span className='header_text_detail'>
                        Url Aplikasi
                      </span>
                    </Col>
                    <Col span={24}>
                      <span className='text_detail_institusi'>
                        {initial.mediaUrl}
                      </span>
                    </Col>
                    <Col span={24}>
                      <span className='header_text_detail'>
                        Username
                      </span>
                    </Col>
                    <Col span={24}>
                      <span className='text_detail_institusi'>
                        {initial.mediaJoinId || '-'}
                      </span>
                    </Col>
                    <Col span={24}>
                      <span className='header_text_detail'>
                        Password
                      </span>
                    </Col>
                    <Col span={24}>
                      <span className='text_detail_institusi'>
                        {initial.mediaPassword || '-'}
                      </span>
                    </Col>
                  </> :
                  <>
                    <Col span={24}>
                      <span className='header_text_detail'>
                        Lokasi
                      </span>
                    </Col>
                    <Col span={24}>
                      <span className='text_detail_institusi'>
                        {initial.location}
                      </span>
                    </Col>
                  </>
                }
              </Row>
            </Col>
          </Row>
        </Card>
        <Modal
          visible={this.state.visible}
          centered
          className={'cardVideo'}
          footer={null}
          width={'50%'}
          onCancel={() => this.handleCloseVideo()}
        >
          <Row>
            <Col span={24}>
              <div className='headerForm'>
                <Title className='titleModalGalery' level={4}>Daftar Partisipan</Title>
              </div>
            </Col>
            <Col span={8}>
              <Text className="form-label">Loyola : {quota.amountParticipantLoyola}/{quota.loyolaQuota}</Text>
            </Col>
            <Col span={8}>
              <Text className="form-label">Umum : {quota.amountParticipantPublic}/{quota.nonLoyolaQuota}</Text>
            </Col>
            <Col span={8}>
              <UISearch placeholder='Nama Lengkap' handleSearch={key => this.handleSearch(key)}/>
            </Col>
            <Col span={24}>
              <Tabs defaultActiveKey={isTab} onChange={e => this.changeTab(e)}>
                <TabPane tab="Loyola" key="1">
                  <Table
                    columns={columns}
                    dataSource={listLoyola}
                    pagination={{pageSize: 10}}
                  />
                </TabPane>
                <TabPane tab="Umum" key="2">
                  <Table
                    columns={columns}
                    dataSource={listUmum}
                    pagination={{pageSize: 10}}
                  />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Modal>
			</div>
		)
	}
}


export default JadwalDetail