import React, { Component } from 'react'
import '../../../../global/global.css'
// import lov from '../../actions/lov'
import { endpoint } from '../../../../api/daftarpersetujuaninformasi'

import { trackPromise } from 'react-promise-tracker';
import {
  Card,
  Row,
  Col,
  Typography
} from 'antd'
import { UItable, UISearch } from '../../../../components'

const { Text } = Typography

class ListPersetujuanInformasi extends Component {
	constructor(props) {
		super(props)
		this.state = {
      initial: '',
      orderBy: '',
      size: 10,
      page: 1,
      search: '',
      dataUser: {},
      dataProfil: {
        data: [],
        totalData: 0
      },
    }
	}

	componentDidMount() {
    const {orderBy, size, page, search} = this.state
    const dataUser = JSON.parse(this.props.userData)
    this.setState({
      dataUser: dataUser,
    })
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: page,
      search: search,
    }
    this.getData(body)
  }

	getData = param => {
		trackPromise(
      endpoint.getData(param).then(res => {
        this.setState({
          dataProfil: res
        })
      })
    )
	}

	handleSearch = key => {
    const {orderBy, size, page } = this.state
    this.setState({
      search: key,
      page: 1
    })
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: 1,
      search: key,
    }
    this.getData(body)
  }

	handleTableChange = (page, filter, sorter) => {
    const {size, search } = this.state
    const body = {
      token: this.props.token,
      sort: sorter,
      size: size,
      page: page,
      search: search,
    }
    this.setState({
      page: page,
      orderBy: sorter
    }, () => {
      this.getData(body)
    })
  }

	handleRedirectDetail = id => {
    this.props.history.push(`daftar-persetujuan-informasi-detail/${id}`)
	}

	render() {
    const text = 'Apakah Anda yakin menghapus data ini ?'
    const columnsTable = [
      {
        title: 'Judul Dokumentasi',
        dataIndex: 'docOwnerTitle',
        key: 'title',
        sorter: true,
        render: managementName => <Text style={{ color: '#222222', fontWeight: 600, cursor: 'pointer' }}>{managementName}</Text>,
        onCell: record => {
          return{
            onClick: () => {
              this.handleRedirectDetail(record.docOwnerId)
            }
          }
        }
      },
      {
        title: 'Nama Lengkap',
        dataIndex: 'docOwnerName',
        key: 'name',
        sorter: true,
      },
			{
        title: 'Periode',
        dataIndex: 'docPeriodName',
        key: 'year',
        sorter: true,
      },
      {
        title: 'Status',
        dataIndex: 'docStatus',
        key: 'status',
        sorter: true,
        render: status => <div style={{ 
          height: 'auto',
          padding: '2px 35px',
          color: '#fff',
          textAlign: 'center',
          width: '100%',
          borderRadius: '10px',
          backgroundColor: status == 'Rejected' ? '#FF0000' : status == 'Approved' ? '#2EC57C' : '#85B6ED', 
          cursor: 'default' }}>{status && status ? status : 'On Approval'}</div>
      },
    ]

		const {
      page, 
      dataProfil,
    } = this.state
		return(
			<div className='root'>
				<Card className='bodyCard_style'>
					<Row gutter={[16,0]}>
            <Col span={16}/>
            <Col span={8} xs={24} md={8}>
              <UISearch placeholder='Judul Dokumentasi' handleSearch={key => this.handleSearch(key)} />
            </Col>
          </Row>
					<Card 
            bordered={false}
            style={{minHeight: '300px'}}>
            <UItable
              scroll={{x: 800}}
              dataTable={dataProfil.data}
              columns={columnsTable}
              withRows={false}
              page={page}
              totalData={dataProfil.totalData}
              sortDefault=''
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
				</Card>
			</div>
		)
	}
}


export default ListPersetujuanInformasi