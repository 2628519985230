const dotenv = require('dotenv');

dotenv.config();

const env = process.env.NODE_ENV

// console.log('env config: ', env)

const options = {
	development: {
		BASE_URL: "https://portal.yayasanloyola.org",
		BASE_URL_LOGIN: "https://portal.yayasanloyola.org:9025/", // dev
		// BASE_URL_LOGIN: "https://loyola.cloudias79.com:9115/", // prod
		// BASE_URL_LOGIN: "http://25.15.225.89:9015/",
		BASE_URL_NOAUTH: "https://portal.yayasanloyola.org:9022/", // dev
		// BASE_URL_NOAUTH: "https://loyola.cloudias79.com:9112/", // prod
		// BASE_URL_NOAUTH: "http://25.55.2.201:9012/",
		COPYRIGHT: '20200716',
		VERSION: '0.0.2',
		TIMEOUT: 30000,
	},
	production: {
		BASE_URL: "https://portal.yayasanloyola.org/",
		BASE_URL_LOGIN: "https://portal.yayasanloyola.org:9025/",
		BASE_URL_NOAUTH: "https://portal.yayasanloyola.org:9022/",
		COPYRIGHT: '20200716',
		VERSION: '0.0.2',
		TIMEOUT: 30000,
	},
}
const config = options[env]

export default {
	...config
}


