import React, {Component} from 'react'
import '../../global/global.css'
// import lov from '../../actions/lov'
import { endpoint } from '../../api'
import { trackPromise } from 'react-promise-tracker';
import {
  Breadcrumb,
  Spin,
  Typography,
} from 'antd'
import { UIFormHeaderFooter } from '../../components';

const { Text } = Typography

class HeaderFooter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataHeaderFooter: null,
      loading: true,
      loadingSubmit: false
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    trackPromise(
      endpoint.getHeaderFooter().then(res => {
        this.setState({
          dataHeaderFooter: res.data,
          loading: false,
          loadingSubmit: false,
        })
      })
    )
  }

  editHeaderFooter = (data) => {
    this.setState({
      loadingSubmit: true
    })
    let body = {
      ...data,
      hfLogoName: data.hfLogoName && data.hfLogoName.fileName,
      usersId: this.props.userId
    }
    trackPromise(
      endpoint.editHeaderFooter(this.props.token, this.props.userId, body).then((res) => {
        if(res == 'success'){
          this.getData()
        }else{
          this.setState({
            loadingSubmit: false
          })
        }
      })
    )
  }

  render(){
    const { dataHeaderFooter, loading, loadingSubmit } = this.state
    return (
      <div className='root'>
        <Spin spinning={loading}>
          <UIFormHeaderFooter 
            data={dataHeaderFooter}
            onFinish={(e)=>this.editHeaderFooter(e)}
            loading={loadingSubmit}
          />
        </Spin>
      </div>
    )
  }
}


export default HeaderFooter
