import React, { useState, useEffect } from 'react'
import { Table, Row, Col } from 'antd'
import { usePromiseTracker } from "react-promise-tracker";

const TableCore = (props) => {
  const [dafaultRow, setDefaultRow] = useState(props.rowSelect)
  const { promiseInProgress } = usePromiseTracker();

  useEffect(() => {
    setDefaultRow(props.rowSelect);
  }, [props.rowSelect]);
  const defaultColums = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name'
    },
  ]

  const handleTableChange = (page, filters, sorter) => {
    if (sorter.order === "ascend") {
      sorter.order = "asc";
    } else if (sorter.order === "descend") {
        sorter.order = "desc";
    }
    const sortingPage = `${sorter.order ? `${sorter.columnKey},${sorter.order}` : props.sortDefault}`
    props.handleTableChange(page.current, filters, sortingPage)
  }

  const onSelectChange = selectedRowKeys => {
    props.checkedBox(selectedRowKeys)
    setDefaultRow(selectedRowKeys)
  };
  const dataTable = props.dataTable || []
  const pagination = {
    defaultCurrent: 1,
    current: props.page,
    pageSize: 10,
    showSizeChanger: false,
    size: 'small',
    total: props.totalData
  }
  // const className = props.className || ''
  const columns = props.columns || defaultColums
  const rowSelection = {
    selectedRowKeys: dafaultRow,
    onChange: onSelectChange,
    hideSelectAll: true
  };

  const withRows = props.withRows === false ? props.withRows : true
  return(
    <Row>
      <Col span={24}>
      {withRows ? (
        <Table
          size={props.size}
          dataSource={dataTable}
          columns={columns}
          loading={promiseInProgress}
          pagination= {pagination}
          rowSelection={rowSelection}
          rowKey={record => record.id}
          onChange={(page, filters, sorter) => handleTableChange(page, filters, sorter)}
          scroll={props.scroll}
        />
      ) : (
        <Table
          size={props.size}
          dataSource={dataTable}
          columns={columns}
          loading={promiseInProgress}
          pagination= {pagination}
          rowKey={record => record.id}
          onChange={(page, filters, sorter) => handleTableChange(page, filters, sorter)}
          scroll={props.scroll}
        />
      )}
      </Col>
    </Row>
  )
}

export const UItable = TableCore
