import React, { useEffect} from 'react'

import { 
  Modal, 
  Form, 
  Row, 
  Col,
  Typography,
  Input,
  Select,
  Button,
  DatePicker,
  Radio
} from 'antd'

import { EyeInvisibleOutlined } from '@ant-design/icons'
import { usePromiseTracker } from "react-promise-tracker";
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import Text from "antd/lib/typography/Text";
import { RedStar } from '../../../components';

const {Title} = Typography
const {Option} = Select

const pendidikanLov= [
  {
    key: 1,
    value: 'SD'
  },
  {
    key: 2,
    value: 'SMP'
  },
  {
    key: 3,
    value: 'SMA/SMK'
  },
  {
    key: 4,
    value: 'D3'
  },
  {
    key: 5,
    value: 'D4'
  },
  {
    key: 6,
    value: 'S1'
  },
  {
    key: 7,
    value: 'S2'
  },
  {
    key: 8,
    value: 'S3'
  }
]

const jenisKelaminLov = [
  {
    key: 1,
    value: 'Laki-laki'
  },
  {
    key: 2,
    value: 'Perempuan'
  }
]

const statusLov = [
  {
    key: true,
    value: 'Active'
  },
  {
    key: false,
    value: 'Non-Active'
  }
]

const ModalCreate = props => {

  const { promiseInProgress } = usePromiseTracker();
  const [form] = Form.useForm()
  useEffect(() => {
    if (props.visible === true) {
      form.resetFields();
      form.setFieldsValue(props.initialValues)
    }
  }, [props.visible])

  const handleClose = () => {
    form.resetFields();
    props.close()
  }

  const checkFinish = values => {
    props.checkFinish(values)
  }

  const onFinishFailed = errorInfo => {
  };

  const handleResetForm = () => {
      form.resetFields(); 
  };

  function disabledDate(current) {
    return current && current > moment().endOf('day');
  }
  
  const validatePassword = (rule, value, callback) => {
    const passwordTemp = form.getFieldsValue().password
    console.log(' data ', passwordTemp, value)
    return new Promise((resolve, reject) => {
      if (value && value !== passwordTemp) {
        reject('Password tidak sesuai');
      } else { resolve(); }
    })
  };

  return (
    <Modal 
      visible={props.visible}
      footer={null}
      className='modal_galery'
      width={633}
      centered
      onCancel={() => handleClose()}
      destroyOnClose={true}
    >
      <Row>
        <Col span={24}>
          <div className='headerForm'>
            <Title className='titleModalGalery' level={4}>{props.title}</Title>
          </div>
        </Col>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={values => checkFinish(values)}
            onFinishFailed={onFinishFailed}
            initialValues={props.initialValues}
          >
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Text className="form-label">Nama Lengkap <RedStar /></Text>
                <Form.Item name='name'
                  rules={[{required: true,message: 'Nama Lengkap harus diisi!' }
                ]}>
                  <Input placeholder={'Nama Lengkap'} size="large" className='input_style' maxLength={256}/> 
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Jenis Kelamin <RedStar /></Text>
                <Form.Item name='jenisKelamin'
                  rules={[{required: true,message: 'Jenis Kelamin harus diisi!' }
                ]}>
                  <Radio.Group>
                    <Row gutter={[0,8]}>
                      {jenisKelaminLov.map((res) => (
                        <Col span={12} key={res.key}>
                          <Radio key={res.key} value={res.key}>
                            {res.value}
                          </Radio>
                        </Col>
                      ))}
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Tanggal Lahir <RedStar /></Text>
                <Form.Item name='tanggalLahir'
                  rules={[{required: true,message: 'Tanggal Lahir harus diisi!' }
                ]}>
                  <DatePicker placeholder={'Tanggal Lahir'} size="large" className='input_style' disabledDate={disabledDate}/> 
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label='Alamat' name='alamat'>
                  <TextArea placeholder={'Alamat'} size="large" className='input_style'/> 
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label='Pekerjaan' name='pekerjaan'>
                  <Input placeholder={'Pekerjaan'} size="large" className='input_style' maxLength={256}/> 
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label='Nama Institusi Tempat Bekerja' name='namaTempatBekerja'>
                  <Input placeholder={'Nama Institusi Tempat Bekerja'} size="large" className='input_style' maxLength={500}/> 
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label='Jenjang Pendidikan Terakhir' name='jenjangPendidikanTerakhir'>
                  <Radio.Group>
                    <Row gutter={[0,8]}>
                      {pendidikanLov.map((res) => (
                        <Col span={6} key={res.key}>
                          <Radio key={res.key} value={res.key}>
                            {res.value}
                          </Radio>
                        </Col>
                      ))}
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label='Nama Institusi Pendidikan Terakhir' name='namaTempatPendidikanTerakhir'>
                  <Input placeholder={'Nama Institusi Pendidikan Terakhir'} size="large" className='input_style' maxLength={256}/> 
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">No. Telepon <RedStar /></Text>
                <Form.Item name='noTelepon'
                  rules={[{required: true,message: 'No. Telepon harus diisi!' },
                  {
                    pattern: /^[0-9]*$/gm,
                    message: 'Harap masukkan angka!',
                  },
                  {
                    min: 9,
                    message: 'No. Telepon minimal 9 digit! ',
                  }
                ]}>
                  <Input placeholder={'No. Telepon'} size="large" className='input_style' maxLength={20}/> 
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">WhatsApp <RedStar /></Text>
                <Form.Item name='whatsapp'
                  rules={[{required: true,message: 'WhatsApp harus diisi!' },
                  {
                    pattern: /^[0-9]*$/gm,
                    message: 'Harap masukkan angka!',
                  },
                  {
                    min: 10,
                    message: 'WhatsApp minimal 10 digit!',
                  }
                ]}>
                  <Input placeholder={'WhatsApp'} size="large" className='input_style' maxLength={20}/> 
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">E-mail <RedStar /></Text>
                <Form.Item name='email'
                  rules={[{required: true, message: 'E-mail harus diisi!' },
                  {type: 'email',message: 'Harap masukan format e-mail!' }
                ]}>
                  <Input placeholder={'E-mail'} size="large" className='input_style' maxLength={50}/> 
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Username <RedStar /></Text>
                <Form.Item name='username'
                  rules={[{required: true,message: 'Username harus diisi!' }
                ]}>
                  <Input placeholder={'Username'} size="large" className='input_style' maxLength={15}/> 
                </Form.Item>
              </Col>
              {
                !props.isEdit &&
                <Col span={12}>
                  <Text className="form-label">Password <RedStar /></Text>
                  <Form.Item name='password'
                    rules={[{required: true,message: 'Password harus diisi!' }
                  ]}>
                    <Input.Password placeholder={'Password'} suffix={<EyeInvisibleOutlined/>} />
                  </Form.Item>
                </Col>
              }
              {
                !props.isEdit &&
                <Col span={12}>
                  <Text className="form-label">Confirm Password <RedStar /></Text>
                  <Form.Item  name='confirmPassword'
                    rules={[{required: true,message: 'Confirm Password harus diisi!' },
                    {validator: validatePassword}
                  ]}>
                    <Input.Password placeholder={'Confirm Password'} suffix={<EyeInvisibleOutlined/>} />
                  </Form.Item>
                </Col>
              }
              <Col span={12}>
                <Text className="form-label">Status <RedStar /></Text>
                <Form.Item name='status'
                  rules={[{required: true,message: 'Status harus diisi!' }
                ]}>
                  <Radio.Group disabled={props.isStatus}>
                    <Row gutter={[0,8]}>
                      {statusLov.map((res) => (
                        <Col span={12} key={res.key}>
                          <Radio key={res.key} value={res.key}>
                            {res.value}
                          </Radio>
                        </Col>
                      ))}
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Role <RedStar /></Text>
                <Form.Item name='roleId'
                  rules={[{required: true,message: 'Role harus diisi!' }
                ]}>
                  <Select placeholder={'Role'} className="select">
                    { 
                      props.dataLOV ? props.dataLOV.map((item) => (
                        <Option value={item.key}>{item.value}</Option>  
                      )):( <></>)
                    }
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  style={{
                    textAlign: 'right',
                    marginBottom: '0px'
                  }}
                >
                  <Button
                    type="link"
                    className='resetButton'
                    size={'small'}
                    onClick={() => handleResetForm()}
                    disabled={props.loadingSubmit}
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    htmlType='submit'
                    className='blueButton'
                    loading={promiseInProgress}
                    size={'small'}
                    loading={props.loadingSubmit}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

export const ModalUser = ModalCreate