import React, { Component } from 'react'
import '../../../../global/global.css'
// import lov from '../../actions/lov'
import { endpoint } from '../../../../api'

import { trackPromise } from 'react-promise-tracker';
import {
  Card,
  Button,
  Row,
  Col,
  Typography,
  Popconfirm
} from 'antd'
import { UItable, UISearch, UIModalCreate } from '../../../../components'

const { Text } = Typography

class ListPendirianSebelum extends Component {
	constructor(props) {
		super(props)
		this.state = {
      initial: '',
      orderBy: '',
      size: 10,
      page: 1,
      search: '',
      visibleCreate: false,
      initalData: {
        nama: undefined,
        noHp: undefined,
        email: undefined,
        docOwnerTitle: undefined,
        docOwnerYear: undefined,
        'radio:0': '1,false,Foto'
      },
      idFaq: undefined,
      isEdit: false,
      dataProfil: {
        data: [],
        totalData: 0
      },
      indexField: 1,
      dataLOVDokumen: [],
      dataLOVTahun: [],
      dataUser: {},
      formItem: [],
      dataFile: [{
        docFormat: 1,
        url: ''
      }]
    }
	}

	componentDidMount() {
    const {orderBy, size, page, search} = this.state
    const dataUser = JSON.parse(this.props.userData)
    this.setState({
      dataUser: dataUser,
    })
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: page,
      search: search,
    }
    this.getData(body)
    this.getDataLOV(this.props.token)
  }

	getData = param => {
		trackPromise(
      endpoint.getPendirianSebelum(param).then(res => {
        this.setState({
          dataProfil: res
        })
      })
    )
	}
  getDataLOV = token => {
    trackPromise(
      endpoint.getLOVDokumen(token).then(res => {
        this.setState({
          dataLOVDokumen: res.data
        }, () => this.getDataLOVTahun(this.props.token, res.data))
      })
    )
  }

    
  getDataLOVTahun = (token, dataDokumen) => {
    trackPromise(
      endpoint.getLOVTahunPendirian(token).then(res => {
        this.setState({
          dataLOVTahun: res.data
        }, () => this.initFormItem(res.data, dataDokumen))
      })
    )
  }

  

  initFormItem = (dataTahun, dataDokumen) => {
    this.setState({
      indexField: 1,
      formItem: [
        {
          name: 'nama',
          type: 'input',
          disabled: true,
          colNumber: 24,
          rules: [
            {
              required: true, 
              message: 'Nama Lengkap harus diisi!' 
            }
          ],
          placeholder: 'Nama Lengkap'
        },
        {
          name: 'noHp',
          type: 'input',
          disabled: true,
          colNumber: 24,
          rules: [
            {
              required: true, 
              message: 'Please input phone number (WA)!' 
            }
          ],
          placeholder: 'No. Handphone (WA)'
        },
        {
          name: 'email',
          type: 'input',
          disabled: true,
          colNumber: 24,
          rules: [
            {
              required: true, 
              message: 'E-mail harus diisi!' 
            }
          ],
          placeholder: 'E-mail'
        },
        {
          name: 'docOwnerTitle',
          type: 'input',
          colNumber: 24,
          rules: [
            {
              required: true, 
              message: 'Judul Dokumentasi harus diisi!' 
            }
          ],
          placeholder: 'Judul Dokumentasi'
        },
        {
          name: 'docOwnerYear',
          type: 'LOV',
          dataLOV: dataTahun,
          colNumber: 24,
          rules: [
            {
              required: true, 
              message: 'Tahun Dokumentasi harus diisi!' 
            }
          ],
          placeholder: 'Tahun Dokumentasi'
        },
        {
          isAditional: true,
          dataAditional: [
            {
              name: 'labelRadio',
              type: 'label',
              values: 'Bentuk Dokumentasi',
              colNumber: 12,
              placeholder: ''
            },
            {
              name: 'radio:0',
              type: 'radio',
              radioLOV: dataDokumen,
              colNumber: 12,
              rules: [
                {
                  required: true, 
                  message: 'Bentuk Dokumentasi harus diisi!' 
                }
              ],
              placeholder: ''
            },
            {
              name: 'file:0',
              type: 'file',
              fileName: '',
              colNumber: 24,
              rules: [
                {
                  required: true, 
                  message: 'File Foto harus di-upload!' 
                }
              ],
              placeholder: ''
            }
          ]
        },
      ]
    })
  }

	handleSearch = key => {
    const {orderBy, size, page } = this.state
    this.setState({
      search: key,
      page: 1
    })
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: 1,
      search: key,
    }
    this.getData(body)
  }

	handleTableChange = (page, filter, sorter) => { 
    const {size, search } = this.state
    const body = {
      token: this.props.token,
      sort: sorter,
      size: size,
      page: page,
      search: search,
    }
    
    this.setState({
      page: page,
      orderBy: sorter
    }, () => {
      this.getData(body)
    })
  }

	openModal = () => {
    this.setState({
      visibleCreate: true,
      isEdit: false,
      indexField: 1,
      initalData: {
				nama: this.state.dataUser.udName,
        noHp: this.state.dataUser.udPhone,
        email: this.state.dataUser.udEmail,
				docOwnerTitle: undefined,
				docOwnerYear: undefined,
				'radio:0': '1,false,Foto'
      },
    })
  }

	handleCloseModal = () => {
    this.setState({
      visibleCreate: false,
      isEdit: false
    }, () => this.handleReset())
  }

  async handleReset () {
    const dataForm = await {
      nama: this.state.dataUser.udName,
      noHp: this.state.dataUser.udPhone,
      email: this.state.dataUser.udEmail,
      docOwnerTitle: undefined,
      docOwnerYear: undefined,
      'radio:0': '1,false,Foto'
    }
    this.setState({
      indexField: 1,
      initalData: dataForm,
      dataFile: [{
        docFormat: 1,
        url: ''
      }],
      formItem: [
        {
          name: 'nama',
          type: 'input',
          disabled: true,
          colNumber: 24,
          rules: [
            {
              required: true, 
              message: 'Nama Lengkap harus diisi!' 
            }
          ],
          placeholder: 'Nama Lengkap'
        },
        {
          name: 'noHp',
          type: 'input',
          disabled: true,
          colNumber: 24,
          rules: [
            {
              required: true, 
              message: 'Please input phone number (WA)!' 
            }
          ],
          placeholder: 'No. Handphone (WA)'
        },
        {
          name: 'email',
          type: 'input',
          disabled: true,
          colNumber: 24,
          rules: [
            {
              required: true, 
              message: 'E-mail harus diisi!' 
            }
          ],
          placeholder: 'E-mail'
        },
        {
          name: 'docOwnerTitle',
          type: 'input',
          colNumber: 24,
          rules: [
            {
              required: true, 
              message: 'Judul Dokumentasi harus diisi!' 
            }
          ],
          placeholder: 'Judul Dokumentasi'
        },
        {
          name: 'docOwnerYear',
          type: 'LOV',
          dataLOV: this.state.dataLOVTahun,
          colNumber: 24,
          rules: [
            {
              required: true, 
              message: 'Tahun Dokumentasi harus diisi!' 
            }
          ],
          placeholder: 'Tahun Dokumentasi'
        },
        {
          isAditional: true,
          dataAditional: [
            {
              name: 'labelRadio',
              type: 'label',
              values: 'Bentuk Dokumentasi',
              colNumber: 12,
              placeholder: ''
            },
            {
              name: 'radio:0',
              type: 'radio',
              radioLOV: this.state.dataLOVDokumen,
              colNumber: 12,
              rules: [
                {
                  required: true, 
                  message: 'Bentuk Dokumentasi harus diisi!' 
                }
              ],
              placeholder: ''
            },
            {
              name: 'file:0',
              type: 'file',
              fileName: '',
              colNumber: 24,
              rules: [
                {
                  required: true, 
                  message: 'File Foto harus di-upload!' 
                }
              ],
              placeholder: ''
            }
          ]
        },
      ]
    })
    return dataForm
  }

  changeRadio = (data, name) => {
    const dataSplit = data.target.value.split(',')
    const nameSplit = name.split(':')
    const tempInit = { 
      ...this.state.initalData,
      ['file:'+nameSplit[1]]: undefined
    }
    let dataFileTemp = this.state.dataFile
    dataFileTemp[nameSplit[1]] = {
      docFormat: parseInt(dataSplit[0]),
      url: ''
    }
    this.setState({
      dataFile: dataFileTemp,
    })
    let parrentTemp = []
    let temp = []
    this.state.formItem.map(res => {
      if (res.isAditional) {
        res.dataAditional.map(dataArray => {
          if (dataArray.name === ('file:'+nameSplit[1])) {
            if (dataSplit[1] === 'true') {
              let tempMessage = ''
              let messageInvalid = ''
              let tempPattern = ''
              let placeholderTemp = ''
              if (dataSplit[2] === 'Video'){
                tempMessage = 'Youtube URL harus diisi!'
                tempPattern = /^http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/
                messageInvalid = 'YouTube URL tidak valid'
                placeholderTemp = 'Youtube URL'
              }else if (dataSplit[2] === 'Tautan') {
                tempMessage = `${dataSplit[2]} harus diisi!`
                tempPattern = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/
                messageInvalid = `${dataSplit[2]} tidak valid`
                placeholderTemp = 'Tautan'
              }else {
                tempMessage = `Tautan ${dataSplit[2]} harus diisi!`
                tempPattern = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/
                messageInvalid = `Tautan ${dataSplit[2]} tidak valid`
                placeholderTemp = `Tautan ${dataSplit[2]}`
              }
              temp.push({
                name: 'file:' + (nameSplit[1]),
                type: 'input',
                colNumber: 24,
                rules: [
                  {
                    required: true, 
                    message: `${tempMessage}` 
                  },
                  { 
                    pattern: tempPattern, 
                    message: messageInvalid 
                  }
                ],
                placeholder: placeholderTemp
              })
            }else if (dataSplit[1] === 'false') {
              temp.push({
                name: 'file:' + (nameSplit[1]),
                type: 'file',
                colNumber: 24,
                rules: [
                  {
                    required: true, 
                    message: `File ${dataSplit[2]} harus di-upload`
                  }
                ],
                placeholder: ''
              })
            }
          }else {
            temp.push({
              ...dataArray
            })
          }
        })
      }else {
        parrentTemp.push({
          ...res
        })
      }
    })
    parrentTemp.push({
      isAditional: true,
      dataAditional: temp
    })
    this.setState({
      formItem: parrentTemp,
      initalData: tempInit
    })
  }


	handleFinish = values => {
    const bodySend = {
			usersId: this.props.userId,
      docOwnerTitle: values.docOwnerTitle,
      docOwnerName: values.nama,
      docOwnerYear: values.docOwnerYear,
      docOwnerPhone: values.noHp,
      docOwnerEmail: values.email,
		  docs: this.state.dataFile,
    }
    const body = {
      token: this.props.token,
      sort: '',
      size: 10,
      page: 1,
      search: this.state.search,
    }
    if (this.state.isEdit) {
      this.handleSubmitEdit(body, bodySend)
    }else {
      this.handleSubmitCreate(body, bodySend)
    }
  }

	handleSubmitCreate = (body, bodySend) => {
    trackPromise(
      endpoint.createPendirianSebelum(this.props.token, bodySend).then(res => {
        if (res === 'success') {
          this.setState({
            page: 1,
            orderBy: '',
          }, () => {
            this.handleCloseModal()
            this.getData(body)
          })
        }
      })
    )
  }

	handleSubmitEdit = (body, bodySend) => {
    trackPromise(
      endpoint.editPendirianSebelum(this.props.token, this.state.idFaq, bodySend).then(res => {
        if (res === 'success') {
          this.setState({
            page: 1,
            orderBy: '',
          }, () => {
            this.handleCloseModal()
            this.getData(body)
          })
        }
      })
    )
  }

	handleEditClick = data => {
    let temp = []
    let tempForm = this.state.formItem
    let objTemp = {}
    let tempDataFile = []
    let tempFormAditional = []
    let indexField = data.docs.length || 1
    tempForm.pop()
    data.docs.map(res => {
      const dataTemp = this.state.dataLOVDokumen.find((dataArray) => dataArray.key === res.docFormat)
      temp.push({
        ...dataTemp,
        ...res
      })
    })
    temp.map((res, index) => {
      tempDataFile.push({
        docFormat: res.key,
        url: res.fileName,
        docId: res.docId
      })
      const name = 'radio:'+index
      const fileName = 'file:'+index
      objTemp = {
        ...objTemp,
        [name]: res.key+ ',' + res.isUrl + ','+ res.value,
        [fileName]: res.fileName
      }
      let dataAditional = [
        {
          name: 'labelRadio',
          type: 'label',
          values: 'Bentuk Dokumentasi',
          colNumber: 12,
          placeholder: ''
        },
        {
          name: name,
          type: 'radio',
          radioLOV: this.state.dataLOVDokumen,
          colNumber: 12,
          rules: [
            {
              required: true, 
              message: 'Bentuk Dokumentasi harus diisi!' 
            }
          ],
          placeholder: ''
        },
      ]
      if (res.isUrl) {
        dataAditional.push({
          name: 'file:' + index,
          type: 'input',
          colNumber: 24,
          rules: [
            {
              required: true, 
              message: 'Tautan harus diisi!' 
            }
          ],
          placeholder: 'Tautan'
        })
      }else {
        dataAditional.push({
          name: 'file:' + index,
          type: 'file',
          colNumber: 24,
          rules: [
            {
              required: true, 
              message: 'File Foto harus di-upload!' 
            }
          ],
          placeholder: ''
        })
      }
      tempFormAditional.push(
        ...dataAditional
      )
    })
    tempForm.push({
      isAditional: true,
      dataAditional: tempFormAditional
    })
		this.setState({
      formItem: tempForm,
      dataFile: tempDataFile,
      initalData: {
        nama: data.docOwnerName,
        noHp: data.docOwnerPhone,
				email: data.docOwnerEmail,
        docOwnerTitle: data.docOwnerTitle,
        docOwnerYear: data.docOwnerYear,
        ...objTemp
      },
      idFaq: data.id,
      visibleCreate: true,
      isEdit: true,
      indexField: indexField
    })
	}

	handleReject = id => {
		const body = {
      token: this.props.token,
      sort: this.state.orderBy,
      size: 10,
      page: 1,
      search: this.state.search,
    }
		trackPromise(
      endpoint.deleteDataPendirian(this.props.token, this.props.userId, id).then(res => {
        if (res === 'success') {
          this.setState({
            page: 1,
            orderBy: this.state.orderBy || '',
            visibleCreate: false
          }, () => {
            this.getData(body)
          })
        }
      })
    )
	}

	handleRedirectDetail = id => {
    this.props.history.push(`pendirian-detail/${id}`)
	}

  changeFile = (e, name,) => {
    const dataFile = e.target.files[0]
    this.handleFileInput(dataFile, name, 'file')
  }

  changeInput = (e, name) => {
    if (name.includes('file')) {
      const dataFile = e.target.value
      this.handleFileInput(dataFile, name, 'url')
    }
  }

  handleFileInput(data, name, type) {
    const nameSplit = name.split(':')
    let dataFileTemp = this.state.dataFile
    let typeFile = ''
    this.state.dataLOVDokumen.map((res) => {
      if (res.key === dataFileTemp[nameSplit[1]].docFormat) {
        return typeFile = res.value
      }
    })
    if (type === 'file') {
      this.handleUpload(data, typeFile, nameSplit[1])
    }else {
      this.handleListPost(data, nameSplit[1])
    }
  }

  handleUpload = (data, type, index) => {
    let pathName = ''
    let typeName = ''
    if (type === 'Foto' ) {
      pathName = 'imageAdmin'
      typeName = 'image'
    }else if (type !== 'PPT' && type !== 'PDF') {
      pathName = 'otherAdmin'
      typeName = 'other'
    }else{
      pathName = type.toLowerCase()+'Admin'
      typeName = type.toLowerCase()
    }
    const formData = new FormData()
    formData.append('file', data)
    formData.append('path', pathName)
    trackPromise(
      endpoint.uploadBerkasPendirian(this.props.token, formData, typeName).then(res => {
        this.handleListPost(res.fileName, index)
      })
    )
  }

  handleListPost = (data, index) => {
    let temp = this.state.dataFile
    temp[index] = {
      ...temp[index],
      url: data
    }
    this.setState({
      dataFile: temp
    })
  }

  addAditional = () => {
    let tempForm = this.state.formItem
    const radioName = 'radio:' + this.state.indexField
    const fileName = 'file:' + this.state.indexField
    const tempIndexField = this.state.indexField + 1
    const tempData = [
      {
        name: 'labelRadio',
        type: 'label',
        values: 'Bentuk Dokumentasi',
        colNumber: 12,
        rules: [
          {
            required: false, 
            message: '' 
          }
        ],
        placeholder: ''
      },
      {
        name: radioName,
        type: 'radio',
        radioLOV: this.state.dataLOVDokumen,
        colNumber: 12,
        rules: [
          {
            required: true, 
            message: 'Bentuk Dokumentasi harus diisi!' 
          }
        ],
        placeholder: ''
      },
      {
        name: fileName,
        type: 'file',
        colNumber: 24,
        rules: [
          {
            required: true, 
            message: 'File Foto harus di-upload!' 
          }
        ],
        placeholder: ''
      }
    ]
    this.state.formItem.map((res, index) => {
      if (res.isAditional) {
        tempForm[index].dataAditional.push(
          ...tempData
        )
      }
    })
    this.state.dataFile.push({
      docFormat: 1,
      url: ''
    })
    this.setState({
      indexField: tempIndexField
    })
  }

	render() {
    const text = 'Apakah Anda yakin akan menghapus data ini?'
    const columnsTable = [
      {
        title: 'Judul Dokumentasi',
        dataIndex: 'docOwnerTitle',
        key: 'title',
        sorter: true,
        render: managementName => <Text style={{ color: '#222222', fontWeight: 600, cursor: 'pointer' }}>{managementName}</Text>,
        onCell: record => {
          return{
            onClick: () => {
              this.handleRedirectDetail(record.id)
            }
          }
        }
      },
      {
        title: 'Nama Lengkap',
        dataIndex: 'docOwnerName',
        key: 'name',
        sorter: true,
      },
			{
        title: 'Tahun Dokumentasi',
        dataIndex: 'docOwnerYear',
        key: 'year',
        sorter: true,
      },
      {
        title: 'Aksi',
        dataIndex: '',
        key: '',
        render: (data) => <>
          <Button
            onClick={() => this.handleEditClick(data)}
            type='link'
            style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600 }}
          >
            Ubah
          </Button>
          <Popconfirm
            placement="bottomRight"
            title={text}
            onConfirm={() => this.handleReject(data.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type='link'
              style={{ color: '#E22828', cursor: 'pointer', fontWeight: 600 }}
            >
              Hapus
            </Button>
          </Popconfirm>
        </>
      },
    ]

		const {
      page, 
      visibleCreate, 
      initalData, 
      isEdit, 
      dataProfil,
      formItem,
      dataFile
    } = this.state
		return(
			<div className='root'>
				<Card className='bodyCard_style'>
					<Row gutter={[16,0]}>
            <Col span={16} xs={12} md={16}>
              <Button onClick={() => this.openModal()} className='addButton'>+ Tambah</Button>
            </Col>
            <Col span={8} xs={24} md={8}>
              <UISearch placeholder='Judul Dokumentasi' handleSearch={key => this.handleSearch(key)} />
            </Col>
          </Row>
					<Card 
            bordered={false}
            style={{minHeight: '300px'}}>
            <UItable
              scroll={{x: 800}}
              dataTable={dataProfil.data}
              columns={columnsTable}
              withRows={false}
              page={page}
              totalData={dataProfil.totalData}
              sortDefault=''
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
					<UIModalCreate
						formItem={formItem} 
						visible={visibleCreate}
						initialValues={initalData}
						title={ isEdit ? 'Ubah Data Informasi Pendirian s.d. Sebelum Tahun 2017' : 'Tambah Data Informasi Pendirian s.d. Sebelum Tahun 2017'}
						widthDivider='250px'
            changeRadio= {this.changeRadio}
						close={() => this.handleCloseModal()}
            addAditional={this.addAditional}
            onReset={() => this.handleReset()}
            dataFile={dataFile}
            changeFile={this.changeFile}
            changeInput={this.changeInput}
						checkFinish={ values => this.handleFinish(values)}
					/>
				</Card>
			</div>
		)
	}
}


export default ListPendirianSebelum