import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Spin } from 'antd';
import { UploadOutlined, LoadingOutlined, CheckCircleFilled } from '@ant-design/icons';
// import { dialog } from '../functions/alert';
import axios from 'axios';
import Text from 'antd/lib/typography/Text';

UploadComponentValues.propTypes = {
};


UploadComponentValues.defaultProps  = {
}

function UploadComponentValues({accept = '', ...props}) {

  const [imgUrl, setImgUrl] = useState(null)
  const [isChangeImg, setIsChangeImg] = useState(false)

  useEffect(()=>{
    if(props.dataFile && !isChangeImg){
      setImgUrl(props.dataFile)
    }
    if(props.dataFile !== imgUrl){ //handle remove update index
      setImgUrl(props.dataFile)
      if(props.dataFile == 'failed'){
        document.getElementById(`upload-file${props.name}`).value = "";
      }
    }
  },[props])

  const setUploadImage = (e) => {
    let isBiger = false
    if(props.maxSize){
      var fi = document.getElementById(`upload-file${props.name}`); 
      let maximum = props.maxSize || 1
      if (fi.files.length > 0) {
          for (var i = 0; i <= fi.files.length - 1; i++) {
              var fsize = fi.files.item(i).size;
              isBiger = (fsize / 1024 / 1024) > (maximum)
              console.log(fsize,maximum,isBiger)
          }
      }
    }
    if(isBiger){
      setImgUrl('failed')
      document.getElementById(`upload-file${props.name}`).value = ""
    }else{
      if(!props.disabled){
        setImagePreview(e)
        setIsChangeImg(true)
        props.changeFile(e, props.name)
      }
    }
  }

  const setImagePreview = (file) => {
    setImgUrl(URL.createObjectURL(file.target.files[0]))
  }

  return (
    <>
      <Row key={props.key}>
        <Col span={12}>
          <label htmlFor={`upload-file${props.name}`} className='custom-file-upload'>
          <UploadOutlined style={{color: '#232F9D', fontSize: '24px'}} /><br />
            Pilih File
          </label>
          <input type='file' id={`upload-file${props.name}`} accept={accept} onChange={setUploadImage} />
        </Col>
        <Col span={12}>
          <Spin spinning={props.loading ? props.loading : false}>
            {
              imgUrl &&
              <div style={{height: 100}}>
                <img src={imgUrl} alt={imgUrl} height={100}/>
              </div>
            }
          </Spin>
        </Col>
      </Row>
    </>
  );
}

export default UploadComponentValues;
