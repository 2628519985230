import React, { Component, useState } from 'react'
import {version} from '../../../package.json';
import { post } from 'axios'
import config from '../../services/config'
import urlLoyola from '../../global/assets/logo.png'
import { Row, Col, Input, Button, notification, Card, Form } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import {
  Link, useHistory
} from "react-router-dom";

import './index.scss';

// const { Title } = Typography
const { BASE_URL_LOGIN } = config

function LandingPage () {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();

  const handleFinish = () => {
    form.validateFields().then(_ => {
      const { username, password } = form.getFieldsValue();
      setIsLoading(true);
      post(`${BASE_URL_LOGIN}auth/login`, {
        username: username,
        password: password,
      })
      .then(res => {
        let data = res && res.data && res.data.data
        let auth = res && res.data && res.data.auth
        const roleGroup = data && data.authorities && data.authorities[0].authority
        if (roleGroup === "Admin Keuangan" || roleGroup === "Admin" || roleGroup === "Admin Dashboard" ) {
          sessionStorage.setItem('token', auth.authorization)
          sessionStorage.setItem('userRole', roleGroup)
          sessionStorage.setItem('userData', JSON.stringify(data.usersDetail))
          sessionStorage.setItem('name', data.usersDetail && data.usersDetail.udName)
          sessionStorage.setItem("userId", data.userId);
          const redirection = {
            'Admin': '/dashboard',
            'Admin Keuangan': '/crowd-funding',
          }
          history.push(redirection[roleGroup]);
        }else{
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: 'Username atau Password salah!',
          });
        }
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: error.data
            ? error.data.Message
            : 'Username atau Password salah!',
        });
        setIsLoading(false);
      })
    }).catch(err => {})
  }

  const handleForgotPassword = () => {
    history.push(`/ForgetPassword/`)
  }

  return (
    <div className="content">
      <div className="container">
        <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
          <Row className="login-row" align="middle" gutter={[20, 20]}>
            <Col lg={18} md={14} span={24}>
              <Row align="middle" gutter={[20, 20]} className="text-login-contentainer">
                <Col>
                  <img className="loyolaLogo" alt="loyolaLogo" src={urlLoyola}/>
                </Col>
                <Col>
                  <span className="text-login-content">PORTAL ADMIN<br />YAYASAN LOYOLA</span>
                </Col>
              </Row>
            </Col>

            <Col lg={6} md={10} span={24} className="contentainer">
              <Card className="app-card" style={{padding: '0px 10px'}}>
                <Form name="formLogin" form={form} onFinish={values => handleFinish()}>
                  <div className="login-title">LOGIN</div>
                  <Row justify='center'>
                    <Col span={24} style={{ marginBottom: '15px' }}>
                    <Form.Item
                      name='username'
                      style={{marginBottom: '0em'}}
                      rules={[{required: true, message: 'Username Harus diisi!'}]}
                    >
                      <Input
                        prefix={<UserOutlined />}
                        placeholder="Username"
                        className="input-style-login"
                      />
                    </Form.Item>
                    </Col>
                    <Col span={24} style={{ marginBottom: '5px' }}>
                      <Form.Item
                        name='password'
                        style={{marginBottom: '0em'}}
                        rules={[{required: true, message: 'Password Harus diisi!'}]}
                      >
                        <Input.Password
                          prefix={<LockOutlined />}
                          placeholder="Password"
                          className="input-style-login"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} style={{ textAlign: 'right', marginBottom: '30px'}}>
                      <Link onClick={handleForgotPassword} className="lupa-password-style">Lupa Password ?</Link>
                    </Col>
                    <Col span={24} style={{ textAlign: 'center'}}>
                      <Button
                        htmlType='submit'
                        size="large"
                        className="app-btn primary"
                        type="primary"
                        loading={isLoading}
                      >
                        Login
                      </Button>
                    </Col>
                    <Col span={24} style={{ textAlign: 'center'}}>
                      <span style={{fontSize: 10}}>version {version}</span>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}


export default LandingPage
