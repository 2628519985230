import React, { useEffect, useState } from 'react'
import { Button, Col, DatePicker, Form, Input, Modal, Radio, Row, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import { confirmDialog, dialog } from '../../../utils/alert';
import XLSX from 'xlsx';
import danaPengembanganApi from '../../../api/danaPengembangan';
import UploadComponent from '../../../components/uploadComponent/upload-component';

const tableKeys = {
  name: "Nama Siswa",
  nisn: "NISN",
  nominal: "Nominal",
  keteranganInd: "Keterangan (indonesia)",
  keteranganEng: "Keterangan (inggris)"
}

export default function ModalUpload(props) {
  const {visible, onCancel, onFinish, donationId} = props;
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState();

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsBinaryString(img);
  }

  const handleFile = (file) => {
    return new Promise((resolve, reject) => {
      let newFileList = [...file.fileList];
      newFileList = newFileList.slice(-1);
      getBase64(newFileList[0].originFileObj, (binary) => {
        const wb = XLSX.read(binary, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        let rawValue = XLSX.utils.sheet_to_json(ws, {raw: false});
        if (rawValue.length > 0) {
          const data = rawValue.map(row => {
            let newRow = {};
            Object.keys(tableKeys).forEach(e => {
              newRow[e] = row[tableKeys[e]];
            });
            return newRow;
          });
          resolve(data);
        } else {
          resolve([]);
        }
      });
    });
  }

  const handleSubmit = async() => {
    if (file) {
      confirmDialog({
        title: 'Upload Daftar Penerima Beasiswa'
      }).then(async _ => {
        setIsLoading(true);
        try {
          const token = sessionStorage.getItem('token');
          const usersId = sessionStorage.getItem('userId');
          const body = await handleFile(file);
          const params = {
            usersId,
            scholarshipDonationId: donationId
          };
          const res = await danaPengembanganApi.uploadPenerimaDonasiBeasiswa(token, params, body);
          if (!res.data || res.data.responseType !== 'Success') { throw res.data; }
          onFinish();
        } catch(err) {
          dialog({
            icon: 'error',
            title: err ? err.message : 'Unknown error',
          })
        }
        setIsLoading(false);
      }).catch(err => {});
    }
  }

  return (
    <Modal
      className="app-modal"
      width={800}
      footer={null}
      visible={visible}
      onCancel={onCancel}
    >
      <Spin spinning={isLoading}>
        <Title level={4} className="titleModalGalery" style={{marginBottom: '1em'}}>Upload Daftar Penerima Beasiswa</Title>
        {/* <hr className="app-hr" /> */}
        <Row gutter={14}>
          <Col span={24}>
            <UploadComponent
              fluidBtn
              showDesc
              accept=".xls,.xlsx"
              onChange={e => {
                setFile(e);
              }}
            />
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '1em' }}>
            <Button className="app-btn" size="large" style={{marginLeft: '1em'}} type="primary" onClick={handleSubmit} >Submit</Button>
          </Col>
        </Row>
      </Spin>
    </Modal>
  )
}
