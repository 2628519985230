import { get, put, post } from 'axios'
import config from '../services/config'
import qs from 'querystring'
import { alert } from './index'
const { BASE_URL_LOGIN, TIMEOUT, BASE_URL_NOAUTH } = config

const normalizeQuery = (url) => {
  return qs.stringify(url)
}

const normalizeData = res => {
  let dataTemp = res.data
  let response = {
    data: dataTemp.data.scholarshipList,
    totalData: dataTemp.data.totalData,
    status: 'success',
    rc: dataTemp.rc
  }
  return response
}


const endpoint = {
  uploadImage(token, body, url){
    return post(`${BASE_URL_LOGIN}be/api/yayasanLoyola/upload/${url} `,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        timeout: TIMEOUT
      }
    ).then(res => {
      return res.data
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Image', 'Upload', err.response.status)
      return 'error'
    })
  },
  getData(param){
    const urlString = normalizeQuery({
      size: param.size,
      page: param.page - 1,
      keyword: param.search
    })
    return get(`${BASE_URL_LOGIN}be/api/admin/scholarshipDonation/list?${urlString}&sort=${param.sort}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${param.token}`,
      },
      timeout: TIMEOUT
    }).then(res => {
      if(res.data.rc == "00"){
        return normalizeData(res)
      }else{
        alert.showAlert(res.data.message, res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {
        data: [],
        totalData: 0,
        status: 'error',
      }
    })
  },
  getDataDetail(param){
    const urlString = normalizeQuery({
      id: param.id,
    })
    let url = `${BASE_URL_LOGIN}be/api/admin/jadwal/detail?${urlString}`
    // let url = `https://demo5436246.mockable.io/listApapun?${urlString}`
    return get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${param.token}`,
      },
      timeout: TIMEOUT
    }).then(response => {
      return response
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {}
    })
  },
  getExport(token){
    return get(`${BASE_URL_LOGIN}be/api/admin/scholarshipDonation/export`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    })
  },
  create(token, body) {
    return post(`${BASE_URL_LOGIN}be/api/admin/scholarshipDonation/insert`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        timeout: TIMEOUT
      }
    ).then(res => {
      if(res.data.rc == "00"){
        alert.alertCreateEditSuccess(res.data.message)
        return 'success'
      }else{
        alert.alertCreateEditError(res.data.message, 'Data', 'Create', res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Data', 'Create', err.response.status)
      return 'error'
    })
  },
  edit(token, id, body) {
    const urlString = normalizeQuery({
      eventId: id,
    })
    return put(`${BASE_URL_LOGIN}be/api/admin/scholarshipDonation/update?${urlString}`,
      body,
      {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          timeout: TIMEOUT
      }
    ).then(res => {
      if(res.data.rc == "00"){
        alert.alertCreateEditSuccess(res.data.message)
        return 'success'
      }else{
        alert.alertCreateEditError(res.data.message, 'Data', 'Edit', res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Data', 'Edit', err.response.status)
      return 'error'
    })
  },
  deleteData(token, userId, id) {
    const urlString = normalizeQuery({})
    return put(`${BASE_URL_LOGIN}be/api/admin/scholarshipDonation/delete?${urlString}`,
      {
        id: id,
        usersId: userId
      },
      {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          timeout: TIMEOUT
      }
    ).then(res => {
      if(res.data.rc == "00"){
        alert.alertCreateEditSuccess(res.data.message)
        return 'success'
      }else{
        alert.alertCreateEditError(res.data.message, 'Data', 'Delete', res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Data', 'Delete', err.response.status)
      return 'error'
    })
  },

}
export { endpoint }
