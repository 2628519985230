import React, { useState } from "react";
import { Breadcrumb, Card, Col, Dropdown, Menu, Row } from "antd";
import { DownOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import { getListMenuByRole } from "../../global/constant/list-menu";
import './index.css'
import ModalUbahPassword from "../modalUbahPassword";

const findPath = (path, obj) => {
  const route = [];
  obj.forEach(e => {
    if (e.path && e.path === path) {
      route[0] = e.label;
    } else if (e.children) {
      e.children.forEach(ec => {
        if (ec.path && ec.path === path) {
          route[0] = e.label;
          route[1] = ec.label;
        }
      });
    }
  });
  return route
}

const HeaderComponent = (props) => {
  const [showModal, setShowModal] = useState(false);
  const currentPath = (props.location.pathname || '/').split('/')[1];
  const listMenu = getListMenuByRole();
  const paths = findPath(currentPath, listMenu) || [];
  const currentRole = sessionStorage.getItem('userRole');

  const menu = () => (
    <Menu>
      <Menu.Item onClick={toggleModal}>Ubah Password</Menu.Item>
      <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
    </Menu>
  )

  const handleLogout = () => {
    sessionStorage.clear()
    props.history.push("/")
  }

  const onChangePass = () => {
    toggleModal();
    handleLogout();
  }

  const toggleModal = () => {
    setShowModal(!showModal);
  }

  return (
    <div className="header">
      <Row justify="center">
        <Col span={24}>
          <Card className="app-card">
            <Row justify="space-between">
              <Col style={{ flex: 1, textAlign: 'left' }}>
                <Breadcrumb>
                  <Breadcrumb.Item>{currentRole}</Breadcrumb.Item>
                  {paths.map((breadCrumb, i) => {
                    return (<Breadcrumb.Item key={`breadCrumb-${i}`}>{breadCrumb}</Breadcrumb.Item>)
                  })}
                </Breadcrumb>
              </Col>
              <Dropdown overlay={menu} placement="bottomCenter" arrow trigger={['click']}>
                <Col style={{ cursor: 'pointer' }}>
                  <span>Hi, </span>
                  <span className="bold_text" style={{marginRight: '20px'}}>{props.name} <DownOutlined /></span>
                </Col>
              </Dropdown>
            </Row>
          </Card>
        </Col>
      </Row>
      <ModalUbahPassword visible={showModal} onCancel={toggleModal} onFinish={onChangePass} />
    </div>
  )
};

export default withRouter(HeaderComponent);
