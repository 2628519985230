import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import React from 'react'
import debounce from 'lodash.debounce'

export default function SearchInput(props) {
  const {delay = 1000, placeholder = 'Search', onChange = () => {}, ...restProps} = props;
  let debouncedFn;

  const handleChange = async (info) => {
    const keyword = info.target.value;
    if (!debouncedFn) {
      debouncedFn = debounce(async (val) => {
        onChange(val);
      }, delay);
    }
    await debouncedFn(keyword);
  }

  return (
    <div style={{width: '100%'}}>
      <Input style={{ border: 'none', borderBottom: '1px solid #d9d9d9', width: props.width}} {...restProps} suffix={<SearchOutlined />} onChange={handleChange} placeholder={placeholder} />
    </div>
  )
}
