import React from 'react'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import _debounce from 'lodash.debounce'

const InputCore = props => {
  const placeholder = props.placeholder || ''
  const onSearchChange = event => {
    const key = event.target.value
    processSearch(key)
  }

  const processSearch = _debounce(key => {
    return props.handleSearch(key)
  }, 500)

  // const onReset = () => {
  //   document.getElementById('inputSearch').value = 'changes'
  // }
  
  return(
    <Input 
      style={{ border: 'none', borderBottom: '1px solid #d9d9d9', width: props.width}}
      placeholder={placeholder}
      // id={'inputSearch'}
      suffix={<SearchOutlined style={{color: '#797979'}}/>}
      onChange={val => onSearchChange(val)}
      // onClick={() => onReset()}
    >
    </Input>
  )
}

export const UISearch = InputCore

