import { get, put, post } from 'axios'
import config from '../services/config'
const { BASE_URL_LOGIN, TIMEOUT } = config

// Crowdfunding

const getListCrowdFunding = (token, param = {sort: '', keyword: '', page: 2, size: '', period: 2021}) => {
  return get(`${BASE_URL_LOGIN}be/api/admin/keuangan/crowdFunding?sort=${param.sort}&page=${param.page - 1}&size=${param.size}&periode=${param.period}&id=&keyword=${param.keyword}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const getLovYearCrowdFunding = (token) => {
  return get(`${BASE_URL_LOGIN}be/api/admin/keuangan/crowdFunding/periode`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const setStatusCrowdFunding = (token, vcId, userId) => {
  return put(`${BASE_URL_LOGIN}be/api/admin/keuangan/crowdFunding/update/payment?vcId=${vcId}&usersId=${userId}`, null,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const addDonaturCrowdFunding = (token, data) => {
  return post(`${BASE_URL_LOGIN}be/api/admin/keuangan/crowdFunding/insert`, data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const createDonaturCrowdFunding = (token, data) => {
  return post(`${BASE_URL_LOGIN}be/api/admin/keuangan/crowdFunding/insertUser`, data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const exportCrowdFunding = (token, period) => {
  return get(`${BASE_URL_LOGIN}be/api/admin/keuangan/crowdFunding/export?periode=${period}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}




// Donor Beasiswa

const getListDonorBeasiswa = (token, param = {sort: '', keyword: '', page: 2, size: '', period: 2021}) => {
  return get(`${BASE_URL_LOGIN}be/api/adminKeuangan/donorBeasiswa/load?sort=${param.sort}&keyword=${param.keyword}&page=${param.page - 1}&size=${param.size}&yearTransaction=${param.period}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const getListUsers = (token, param = {sort: '', keyword: '', page: 2, size: '', period: 2021}) => {
  return get(`${BASE_URL_LOGIN}be/api/adminKeuangan/donorBeasiswa/getUser?sort=${param.sort}&keyword=${param.keyword}&page=${param.page - 1}&size=${param.size}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const getLovYearDonorBeasiswa = (token, max = 5) => {
  return get(`${BASE_URL_LOGIN}be/api/adminKeuangan/donorBeasiswa/periode`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const setStatusDonorBeasiswa = (token, vcId, userId) => {
  return put(`${BASE_URL_LOGIN}be/api/admin/keuangan/donorBeasiswa/update/payment?vcId=${vcId}&usersId=${userId}`, null,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const addDonaturDonorBeasiswa = (token, data) => {
  return post(`${BASE_URL_LOGIN}be/api/adminKeuangan/donorBeasiswa/add`, data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const createDonaturDonorBeasiswa = (token, data) => {
  return post(`${BASE_URL_LOGIN}be/api/adminKeuangan/donorBeasiswa/add`, data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const exportDonorBeasiswa = (token, period) => {
  return post(`${BASE_URL_LOGIN}be/api/adminKeuangan/donorBeasiswa/export?periode=${period}`, null,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}




// Donasi Sukarela

const getListDonasiSukarela = (token, param = {sort: '', keyword: '', page: 2, size: '', period: 2021}) => {
  return get(`${BASE_URL_LOGIN}be/api/admin/keuangan/donasiSukarela?sort=${param.sort}&page=${param.page - 1}&size=${param.size}&periode=${param.period}&id=&keyword=${param.keyword}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const getLovYearDonasiSukarela = (token) => {
  return get(`${BASE_URL_LOGIN}be/api/admin/keuangan/donasiSukarela/periode`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const setStatusDonasiSukarela = (token, vcId, userId) => {
  return put(`${BASE_URL_LOGIN}be/api/admin/keuangan/donasiSukarela/update/payment?vcId=${vcId}&usersId=${userId}`, null,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const addDonaturDonasiSukarela = (token, data) => {
  return post(`${BASE_URL_LOGIN}be/api/admin/keuangan/donasiSukarela/insert`, data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const createDonaturDonasiSukarela = (token, data) => {
  return post(`${BASE_URL_LOGIN}be/api/admin/keuangan/donasiSukarela/insertUser`, data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const exportDonasiSukarela = (token, period) => {
  return get(`${BASE_URL_LOGIN}be/api/admin/keuangan/donasiSukarela/export?periode=${period}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}




// Laporan

const getLaporan = (token) => {
  return get(`${BASE_URL_LOGIN}be/api/admin/keuangan/report`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const saveLaporan = (token, data) => {
  return post(`${BASE_URL_LOGIN}be/api/admin/keuangan/fundDevelopment/report/submit`, data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}




export default {
  getListCrowdFunding,
  getLovYearCrowdFunding,
  setStatusCrowdFunding,
  addDonaturCrowdFunding,
  createDonaturCrowdFunding,
  getListUsers,
  exportCrowdFunding,

  getListDonorBeasiswa,
  getLovYearDonorBeasiswa,
  setStatusDonorBeasiswa,
  addDonaturDonorBeasiswa,
  createDonaturDonorBeasiswa,
  exportDonorBeasiswa,

  setStatusDonasiSukarela,
  exportDonasiSukarela,
  getListDonasiSukarela,
  getLovYearDonasiSukarela,
  addDonaturDonasiSukarela,
  createDonaturDonasiSukarela,

  getLaporan,
  saveLaporan,
}
