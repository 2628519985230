import { get, put, post } from 'axios'
import config from '../services/config'
const { BASE_URL_LOGIN, BASE_URL_NOAUTH, TIMEOUT } = config


const getListPengumuman = (token, param = {sort: '', keyword: '', page: 1, size: '', category: 1}) => {
  return get(`${BASE_URL_LOGIN}be/api/admin/announcement?sort=${param.sort}&page=${param.page - 1}&size=${param.size}&scholarshipDonationId=${param.category}&id=&keyword=${param.keyword}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const getDetailPengumuman = (token, id) => {
  return get(`${BASE_URL_LOGIN}be/api/admin/announcement/detail?announceId=${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const deletePengumuman = (token, params = {}) => {
  return put(`${BASE_URL_LOGIN}be/api/admin/announcement/delete?announceId=${params.announceId}&usersId=${params.usersId}`, null,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const changeStatusPengumuman = (token, params = {}) => {
  return put(`${BASE_URL_LOGIN}be/api/admin/announcement/hide?announceId=${params.announceId}&isActive=${params.isActive}&usersId=${params.usersId}`, null,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const getLovKategoriPengumuman = (token) => {
  return get(`${BASE_URL_LOGIN}be/api/lov/announcementCategory`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const tambahPengumuman = (token, body) => {
  return post(`${BASE_URL_LOGIN}be/api/admin/announcement/insert`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const updatePengumuman = (token, body, announceId) => {
  return put(`${BASE_URL_LOGIN}be/api/admin/announcement/update?announceId=${announceId}`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const putPenerimaDonasiBeasiswa = (token, body) => {
  return put(`${BASE_URL_LOGIN}be/api/admin/recipient/update`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}


const uploadPenerimaDonasiBeasiswa = (token, params, body) => {
  return post(`${BASE_URL_LOGIN}be/api/admin/recipient/upload?usersId=${params.usersId}&scholarshipDonationId=${params.scholarshipDonationId}`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}



const getListKategoriDonor = (token, param = {sort: '', keyword: '', page: 1, size: '', category: 1}) => {
  return get(`${BASE_URL_LOGIN}be/api/admin/scholarshipDonation/list?sort=${param.sort}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}





export default {
  getListPengumuman,
  getDetailPengumuman,
  changeStatusPengumuman,
  deletePengumuman,
  getLovKategoriPengumuman,
  tambahPengumuman,
  updatePengumuman,

  putPenerimaDonasiBeasiswa,
  uploadPenerimaDonasiBeasiswa,
  getListKategoriDonor
}
