import React, { useEffect, useState} from 'react'

import {
  Modal,
  Form,
  Row,
  Col,
  Typography,
  Input,
  Button,
  DatePicker,
  InputNumber,
  Radio,
  TimePicker,
  Select
} from 'antd'
import { EyeInvisibleOutlined,UploadOutlined } from '@ant-design/icons'
import { usePromiseTracker } from "react-promise-tracker";
import TextArea from 'antd/lib/input/TextArea';
import UploadComponentValues from '../../../components/uploadComponentValues';

import { trackPromise } from 'react-promise-tracker';
import { alert } from '../../../api';
import MoneyInput from '../../../components/money-input';
import { endpoint } from '../../../api/daftarlangkahkedepan';
import moment from 'moment'
import { RedStar } from '../../../components';

const {Title,Text} = Typography
const {Option} = Select
const typeLov = [
  {
    key: 'Hari',
    value: 'Hari'
  },
  {
    key: 'Minggu',
    value: 'Minggu'
  },
  {
    key: 'Bulan',
    value: 'Bulan'
  },
  {
    key: 'Tahun',
    value: 'Tahun'
  },
]

const ModalCreate = props => {

  const { promiseInProgress } = usePromiseTracker();
  const [form] = Form.useForm()
  const [isOnline, setIsOnline] = useState(true)
  const [loadingUploadImage, setLoadingImage] = useState(false)
  const [imageDbThumbnail, setImageDbThumbnail] = useState('')
  const [isImageThumbnailChange, setIsImageThumbnailChange] = useState(false)

  const changeFileThumbnail = (e) => {
    let valueTemp = form.getFieldsValue()
    setIsImageThumbnailChange(true)
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    trackPromise(
      endpoint.uploadBanner(props.token, formData, 'imageSmall?path=dana-pengembangan').then(res => {
        if(res == 'error'){
          setImageDbThumbnail('Failed')
          setIsImageThumbnailChange(false)
          form.setFieldsValue({...valueTemp, fileName: ''})
        }else{
          if(res.fileName){
            setImageDbThumbnail(res.url)
            setIsImageThumbnailChange(false)
            form.setFieldsValue({...valueTemp, fileName: res.fileName})
          }
        }
      })
    )
  }

  useEffect(() => {
    if (props.visible === true) {
      setImageDbThumbnail(props.initialValues.projectThumbnail)
      form.resetFields();
      form.setFieldsValue(props.initialValues)
    }
  }, [props.visible])

  const handleClose = () => {
    form.resetFields();
    props.close()
  }

  const checkFinish = values => {
    let dataTemp = {
        ...values,
        projectDuration: values.durasi+' '+values.type,
        projectThumbnail: values.fileName
      }
      props.checkFinish(dataTemp)
  }

  const onFinishFailed = errorInfo => {
  };

  const handleResetForm = () => {
    form.resetFields();
  };

  const handleIsMedia = e => {
    setIsOnline(e.target.value)
  }

  const validateYear = (rule, value, callback) => {
    return new Promise((resolve, reject) => {
      if (value && value < 1949) {
        reject(`Tahun Lulus harus dalam kisaran tahun 1949 - ${moment().year()}!`);
      } else { resolve(); }
    })
  };
  const validateMin = (rule, value, callback) => {
    return new Promise((resolve, reject) => {
      if (value && value < 1) {
        reject(`Nilai Proyek harus lebih dari Rp0,00!`);
      } else { resolve(); }
    })
  };

  return (
    <Modal
      visible={props.visible}
      footer={null}
      className='modal_galery'
      width={633}
      centered
      onCancel={() => handleClose()}
      destroyOnClose={true}
    >
      <Row>
        <Col span={24}>
          <div className='headerForm'>
            <Title className='titleModalGalery' level={4}>{props.title}</Title>
          </div>
        </Col>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={values => checkFinish(values)}
            onFinishFailed={onFinishFailed}
            initialValues={props.initialValues}
          >
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Text className="form-label">Nama Proyek <RedStar /></Text>
                <Form.Item name='projectName'
                  rules={[{required: true,message: 'Nama Proyek [IND] harus diisi!' }
                ]}>
                  <Input placeholder={'Nama Proyek'} size="large" className='input_style' maxLength={256}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Project Name <RedStar /></Text>
                <Form.Item name='projectNameEng'
                  rules={[{required: true,message: 'Nama Proyek [ENG] harus diisi!' }
                ]}>
                  <Input placeholder={'Event Name'}  size="large" className='input_style' maxLength={256}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Kategori <RedStar /></Text>
                <Form.Item name='projectCatId'
                  rules={[{required: true,message: 'Kategori harus diisi!' }
                ]}>
                  <Select placeholder={'Kategori'} className="select">
                    {
                      props.dataLOV ? props.dataLOV.map((item) => (
                        <Option value={item.key}>{item.value}</Option>
                      )):( <></>)
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}/>
              <Col span={12}>
                <Text className="form-label">Tanggal Mulai <RedStar /></Text>
                <Form.Item name='projectStartDate'
                  rules={[{required: true,message: 'Tanggal Mulai harus diisi!' }
                ]}>
                  <DatePicker placeholder={'Tanggal Mulai'}  className='input_style'/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Row gutter={[10]}>
                  <Col span={6}>
                <Text className="form-label">Durasi <RedStar /></Text>
                    <Form.Item name='durasi'
                      rules={[{required: true,message: 'Durasi harus diisi!' }
                    ]}>
                      <Input placeholder={'Durasi'}  size="large" className='input_style'/>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                <Text className="form-label" style={{color: 'white'}}>.</Text>
                    <Form.Item name='type'
                      rules={[{required: true,message: 'Kategori harus diisi!' }
                    ]}>
                      <Select placeholder={'Kategori'} className="select">
                        {
                          typeLov ? typeLov.map((item) => (
                            <Option value={item.key}>{item.value}</Option>
                          )):( <></>)
                        }
                        </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Text className="form-label">Tahun Anggaran <RedStar /></Text>
                <Form.Item name='projectFiscalYear'
                  rules={[{required: true,message: 'Tahun Anggaran harus diisi!' },
                  { pattern: /^[0-9]*$/gm, message: 'Harap masukkan angka!'},
                  { min: 4,message: 'Tahun Anggaran harus 4 digit!'},
                  { validator: validateYear }
                ]}>
                  <Input placeholder={'Tahun Anggaran'}  size="large" className='input_style' maxLength={4} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Nilai Proyek <RedStar /></Text>
                <Form.Item name='projectValue'
                  rules={[{required: true,message: 'Nilai Proyek harus diisi!' },
                  { validator: validateMin }
                ]}>
                  <MoneyInput placeholder={'Nilai Proyek'}  className='input_style' maxLength={18}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Contact Person <RedStar /></Text>
                <Form.Item name='projectContactPerson'
                  rules={[{required: true,message: 'Contact Person harus diisi!' },
                  { min: 10,message: 'Contact Person minimal 10 digit!'},
                ]}>
                  <Input placeholder={'Contact Person'}  size="large" className='input_style'  maxLength={20}/>
                </Form.Item>
              </Col>
              <Col span={12}/>
              <Col span={12}>
                <Text className="form-label">Deskripsi <RedStar /></Text>
                <Form.Item name='projectDesc'
                  rules={[{required: true,message: 'Deskripsi (IND) harus diisi!' }
                ]}>
                  <TextArea placeholder={'Deskripsi'}  size="large" className='input_style'/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Description <RedStar /></Text>
                <Form.Item name='projectDescEng'
                  rules={[{required: true,message: 'Deskripsi (ENG) harus diisi!' }
                ]}>
                  <TextArea placeholder={'Description'}  size="large" className='input_style'/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Status <RedStar /></Text>
                <Form.Item name='projectStatusId'
                  rules={[{required: true,message: 'Status harus diisi!' }
                ]}>
                  <Select placeholder={'Status'} className="select">
                    {
                      props.dataLOVStatus ? props.dataLOVStatus.map((item) => (
                        <Option value={item.key}>{item.value}</Option>
                      )):( <></>)
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text className="form-label">Banner Proyek <RedStar /></Text>
                <Form.Item name='fileName'
                  rules={[{required: true,message: 'File Banner Proyek harus di-upload!' }
                ]}>
                  <UploadComponentValues
                    pathName='dana-pengembangan'
                    changeFile={e => changeFileThumbnail(e, false)}
                    dataFile={imageDbThumbnail}
                    loading={isImageThumbnailChange}
                    name={'fileName'}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  style={{
                    textAlign: 'right',
                    marginBottom: '0px'
                  }}
                >
                  <Button
                    type="link"
                    className='resetButton'
                    size={'small'}
                    onClick={() => handleResetForm()}
                    disabled={props.loadingSubmit}
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    htmlType='submit'
                    className='blueButton'
                    loading={promiseInProgress}
                    size={'small'}
                    loading={props.loadingSubmit || loadingUploadImage}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

export const ModalDaftarCrowdFunding = ModalCreate
