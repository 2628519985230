import React, {Component} from 'react'
import '../../../global/global.css'
// import lov from '../../actions/lov'
import { endpoint } from '../../../api/galeri'
import { trackPromise } from 'react-promise-tracker';
import {
  Breadcrumb,
  Spin,
  Typography,
} from 'antd'
import { UIFormGaleri } from './component';

const { Text } = Typography

class GaleriCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataHeaderFooter: null,
      loading: false,
      isEdit: false,
      initialData: {},
      eventId: null
    }
  }

  componentDidMount() {
    const id = this.props.location.pathname.split('/')[2]
    const type = this.props.location.pathname.split('/')[1]
    if(type == 'galeri-edit' && id){
      const body = {
        token: this.props.token,
        id: id
      }
      console.log('masuk edit : ',id)
      this.setState({
        eventId: id,
        loading: true,
        isEdit: true
      }, () => this.getDataId(body))
    }
  }

  getData = (e) => {
    if(e && e.length > 2){
      trackPromise(
        endpoint.getDataLov(e).then(res => {
          this.setState({
            dataHeaderFooter: res.data,
            loading: false
          })
        })
      )
    }
  }

  getDataId = param => {
		trackPromise(
      endpoint.getDataDetail(param).then(res => {
        let dataTemp = {
          ...res.data.data.detailEvent,
          listImage: res.data.data.eventImages,
          listVideo: res.data.data.eventVideos,
          eventThumbnailPath: res.data.data.eventThumbnailPath,
          eventThumbnailUrl: res.data.data.eventThumbnailUrl
        }
        this.setState({
          initialData: dataTemp,
          loading: false
        })
      })
    )
  }

  editHeaderFooter = (data) => {
    let body = {
      ...data,
      usersId: this.props.userId
    }
    if(this.state.isEdit){
      body.eventId = this.state.eventId
      console.log("di edit")
      trackPromise(
        endpoint.edit(this.props.token, this.props.userId, body).then((res) => {
          if(res == 'success'){
            console.log("di edit pindah")
            this.props.history.push('/galeri')
          }
        })
      )
    }else{
      if(body.eventId){
        trackPromise(
          endpoint.createbyId(this.props.token, body).then((res) => {
            if(res == 'success'){
              this.props.history.push('/galeri')
            }
          })
        )
      }else{
        console.log("Create baru")
        trackPromise(
          endpoint.create(this.props.token, body).then((res) => {
            if(res == 'success'){
              this.props.history.push('/galeri')
            }
          })
        )
      }
    }

    console.log("data ini adalah : ",body)
    // trackPromise(
    //   endpoint.editHeaderFooter(this.props.token, this.props.userId, body).then((res) => {
    //     if(res == 'success'){
    //       this.getData()
    //     }
    //   })
    // )
  }

  render(){
    const { dataHeaderFooter, loading, isEdit, initialData } = this.state
    return (
      <div className='root'>
        <Spin spinning={loading}>
          <UIFormGaleri 
            data={dataHeaderFooter}
            onFinish={(e)=>this.editHeaderFooter(e)}
            getLov={e => this.getData(e)}
            token = {this.props.token}
            isEdit = {isEdit}
            initialData = {initialData}
          />
        </Spin>
      </div>
    )
  }
}


export default GaleriCreate
