import React, { useEffect} from 'react'

import { 
  Modal, 
  Form, 
  Row, 
  Col,
  Typography,
  Input,
  Button,
  Select
} from 'antd'

import { usePromiseTracker } from "react-promise-tracker";
import TextArea from 'antd/lib/input/TextArea';
import { RedStar } from '../../../components';

const {Title,Text} = Typography
const {Option} = Select


const ModalCreate = props => {

  const { promiseInProgress } = usePromiseTracker();
  const [form] = Form.useForm()
  useEffect(() => {
    if (props.visible === true) {
      form.resetFields();
      form.setFieldsValue(props.initialValues)
    }
  }, [props.visible])

  const handleClose = () => {
    form.resetFields();
    props.close()
  }

  const checkFinish = values => {
    props.checkFinish(values)
  }

  const onFinishFailed = errorInfo => {
  };

  const handleResetForm = () => {
      form.resetFields(); 
  };
  
  return (
    <Modal 
      visible={props.visible}
      footer={null}
      className='modal_galery'
      width={633}
      centered
      onCancel={() => handleClose()}
      destroyOnClose={true}
    >
      <Row>
        <Col span={24}>
          <div className='headerForm'>
            <Title className='titleModalGalery' level={4}>{props.title}</Title>
          </div>
        </Col>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={values => checkFinish(values)}
            onFinishFailed={onFinishFailed}
            initialValues={props.initialValues}
          >
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Text className="form-label">Kategori <RedStar /></Text>
                <Form.Item name='faqCategoryId'
                  rules={[{required: true,message: 'Role harus diisi!' }
                ]}>
                  <Select placeholder={'Kategori'} className="select">
                    { 
                      props.dataLOV ? props.dataLOV.map((item) => (
                        <Option value={item.key}>{item.value}</Option>  
                      )):( <></>)
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Pertanyaan <RedStar /></Text>
                <Form.Item name='faqQuestionInd'
                  rules={[{required: true,message: 'Pertanyaan harus diisi!' }
                ]}>
                  <Input placeholder={'Pertanyaan'} size="large" className='input_style' maxLength={500}/> 
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Question <RedStar /></Text>
                <Form.Item name='faqQuestionEng'
                  rules={[{required: true,message: 'Profesi harus diisi!' }
                ]}>
                  <Input placeholder={'Question'} size="large" className='input_style' maxLength={500}/> 
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Jawaban <RedStar /></Text>
                <Form.Item name='faqAnswerInd'
                  rules={[{required: true,message: 'Deskripsi harus diisi!' }
                ]}>
                  <TextArea placeholder={'Jawaban'} size="large" className='input_style' maxLength={500}/> 
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Solution <RedStar /></Text>
                <Form.Item name='faqAnswerEng'
                  rules={[{required: true,message: 'Description harus diisi!' }
                ]}>
                  <TextArea placeholder={'Solution'} size="large" className='input_style' maxLength={500}/> 
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  style={{
                    textAlign: 'right',
                    marginBottom: '0px'
                  }}
                >
                  <Button
                    type="link"
                    className='resetButton'
                    size={'small'}
                    onClick={() => handleResetForm()}
                    disabled={props.loadingSubmit}
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    htmlType='submit'
                    className='blueButton'
                    loading={promiseInProgress}
                    size={'small'}
                    loading={props.loadingSubmit}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

export const ModalFAQ = ModalCreate