import React, { Component } from 'react'
import '../../../../global/global.css'
import { endpoint } from '../../../../api/daftarpersetujuaninformasi'
import {CaretRightOutlined, DownloadOutlined} from '@ant-design/icons';
import { trackPromise } from 'react-promise-tracker';
import {
  Card,
  Row,
  Col,
  Typography,
  Modal,
  Button,
  Popconfirm
} from 'antd'
import ImagePreviewComponent from '../../../../components/imagePreview'
import {GlobalVideoCheck} from '../../../../global/checkingVideo'
import Title from 'antd/lib/typography/Title';
import { confirmDialog } from '../../../../utils/alert';

const { Text } = Typography

class DetailPersetujuanInformasi extends Component {
	constructor(props) {
		super(props)
		this.state = {
      initial: {
        managementUrlVideoImage: '',
        managementUrlVideo: ''
      },
      dataDok: [],
      initDok: 'Teks',
      validateVideo: '',
      visible: false,
      loading: [
        true,true
      ]
		}
	}

  handleOpenVideo = url => {
    this.setState({
      validateVideo: url,
    }, () => {
      this.setState({
        visible: true
      })
    })
  }

  handleCloseVideo = () => {
    this.setState({
      validateVideo: '',
      visible: false
    })
  }

	componentDidMount() {
    const id = this.props.location.pathname.split('/')[2]
    const body = {
      token: this.props.token,
      id: id
    }
    this.getData(body)
  }

	getData = param => {
		trackPromise(
      endpoint.getDataDetail(param).then(res => {
        this.setState({
          initial: res.data.data,
          loading: [false,false]
        }, () => this.handleDataChange('Teks'))
      })
    )
	}

  handleDataChange = (name) => {
    const {initial} = this.state
    let temp = []
    if (name === 'Teks') {
      initial.documentation.docTeks.map(res => {
        temp.push({
          ...res,
        })
      })
    }else if (name === 'Foto') {
      initial.documentation.docImage.map(res => {
        temp.push({
          ...res,
        })
      })
    }else if (name === 'Video') {
      initial.documentation.docVideo.map(res => {
        temp.push({
          ...res,
        })
      })
    }
    this.setState({
      dataDok: temp,
      initDok: name
    })
  }

  downloadFile = (data) => {
    console.log(data)
    var link = document.createElement("a");
    link.download = data;
    link.href = data;
    link.target = '_blank'
    link.rel = 'noopener noreferrer'
    link.click();
  }

	handleReject = (dataId,isApproved) => {
    confirmDialog({
      icon: 'warning',
      title: isApproved ? 'Approve Dokumentasi' : 'Reject Dokumentasi' ,
      text: isApproved ? 'Apakah Anda yakin akan menyetujui dokumentasi ini?' : 'Apakah Anda yakin akan menolak dokumentasi ini?'
    }).then(_ => {

      let loadingTemp = this.state.loading
      loadingTemp[isApproved?0:1] = true
      this.setState({
        loading: loadingTemp
      })
      const body = {
        token: this.props.token,
        id: dataId,
        status: isApproved ? 'Approved' : 'Rejected',
        usersId: this.props.userId
      }
      trackPromise(
        endpoint.edit(this.props.token,dataId, body).then(res => {
          loadingTemp[isApproved?0:1] = false
          if (res === 'success') {
            this.props.history.push(`/daftar-persetujuan-informasi`)
          }
          this.setState({
            loading: loadingTemp
          })
        })
      )

    })
    .catch(() =>{})
	}


	render() {
    const {initial, dataDok, initDok, loading} = this.state

    const text = 'Apakah Anda yakin?'

		return(
			<div className='root'>
        <Card className='bodyCard_style'>
          <Row gutter={[8,8]}>
            <Col span={18} xs={24} md={18}>
              <Row gutter={[8,8]}>
                <Col span={6} xs={24} md={10}>
                  <span className='header_text_detail'>
                    Judul Dokumentasi
                  </span>
                </Col>
                <Col span={18} xs={24} md={14}>
                  <span className='text_detail_institusi'>
                    {initial.docOwnerTitle}
                  </span>
                </Col>
                <Col span={6} xs={24} md={10}>
                  <span className='header_text_detail'>
                    Periode
                  </span>
                </Col>
                <Col span={18} xs={24} md={14}>
                  <span className='text_detail_institusi'>
                    {initial.docPeriodName}
                  </span>
                </Col>
                <Col span={6} xs={24} md={10}>
                  <span className='header_text_detail'>
                    Tahun Dokumentasi
                  </span>
                </Col>
                <Col span={18} xs={24} md={14}>
                  <span className='text_detail_institusi'>
                    {initial.docOwnerYear}
                  </span>
                </Col>
                <Col span={6} xs={24} md={10}>
                  <span className='header_text_detail'>
                    Nama Lengkap
                  </span>
                </Col>
                <Col span={18} xs={24} md={14}>
                  <span className='text_detail_institusi'>
                    {initial.docOwnerName}
                  </span>
                </Col>
                <Col span={6} xs={24} md={10}>
                  <span className='header_text_detail'>
                    No.Handphone (WA)
                  </span>
                </Col>
                <Col span={18} xs={24} md={14}>
                  <span className='text_detail_institusi'>
                    {initial.docOwnerPhone}
                  </span>
                </Col>
                <Col span={6} xs={24} md={10}>
                  <span className='header_text_detail'>
                    E-mail
                  </span>
                </Col>
                <Col span={18} xs={24} md={14}>
                  <span className='text_detail_institusi'>
                    {initial.docOwnerEmail}
                  </span>
                </Col>
                <Col span={6} xs={24} md={10}>
                  <span className='header_text_detail'>
                    Status
                  </span>
                </Col>
                <Col span={6} xs={24} md={10}>
                  <div style={{ 
                      height: 'auto',
                      padding: '2px 35px',
                      color: '#fff',
                      textAlign: 'center',
                      borderRadius: '10px',
                      backgroundColor: initial.docStatus == 'Rejected' ? '#FF0000' : initial.docStatus == 'Approved' ? '#2EC57C' : '#85B6ED', 
                      cursor: 'default' }}>{initial.docStatus || 'On Approval'}</div>
                </Col>
              </Row>
            </Col>
            <Col span={6} xs={24} md={6}>
              <Row gutter={[8,8]}>
                <Col span={12} xs={12} md={24} lg={12}>
                  <Button
                    style={{ color: '#fff', cursor: 'pointer', fontWeight: 600,background: '#FF0000',
                    borderRadius: '10px' }}
                    onClick={() => this.handleReject(initial.docOwnerId,false)}
                    loading={loading[1]}
                  >
                    Rejected
                  </Button>
                </Col>
                <Col span={12} xs={12} md={24} lg={12}>
                  <Button
                    style={{ color: '#fff', cursor: 'pointer', fontWeight: 600, background: '#2EC57C',
                    borderRadius: '10px' }}
                    onClick={() => this.handleReject(initial.docOwnerId,true)}
                    loading={loading[0]}
                  >
                    Approved
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={8}>
                  <span 
                    className={initDok === 'Teks' ? 'header_text_detail' : 'text_detail_institusi'}
                    onClick={() => this.handleDataChange('Teks')}
                    style={{ cursor: 'pointer'}}
                  >
                    Dokumen Teks
                  </span>
                </Col>
                <Col span={8} style={{ textAlign: 'center'}}>
                  <span 
                    className={initDok === 'Foto' ? 'header_text_detail' : 'text_detail_institusi'}
                    onClick={() => this.handleDataChange('Foto')}
                    style={{ cursor: 'pointer'}}
                  >
                    Foto
                  </span>
                </Col>
                <Col span={8} style={{ textAlign: 'right'}}>
                  <span 
                    className={initDok === 'Video' ? 'header_text_detail' : 'text_detail_institusi'}
                    onClick={() => this.handleDataChange('Video')}
                    style={{ cursor: 'pointer'}}
                  >
                    Video
                  </span>
                </Col>
                <Col span={24}>
                  <div className='style-divider' />
                </Col>
                <Col span={24}>
                  <Row gutter={[8,8]} style={{ padding: '10px'}}>
                    {dataDok && dataDok.length > 0 ? dataDok.map((res, index) => (
                      <Col span={5} key={index} style={{ textAlign: 'center'}}>

                        {initDok === 'Teks' ? (
                          <div>
                            <ImagePreviewComponent
                              isMobile={false}
                              styles={{ width: '100%' }}
                              uri={''}
                              isFolder={true}
                              imageBorder={'6px'}
                            />
                            {/* <DownloadOutlined
                              onClick={() => this.downloadFile(res.docUrl)}
                              style={{
                                color: '#232F9D', 
                                fontSize: '20px', 
                                marginRight: '5px',
                                cursor: 'pointer'
                              }}
                            /> */}
                            <span 
                              onClick={() => this.downloadFile(res.docUrl)}
                              className='header_text_detail' 
                              style={{
                                color: '#232F9D', 
                                cursor: 'pointer',
                                overflowWrap: 'anywhere'
                              }}
                            >
                              {res.fileName}
                            </span>
                          </div>
                        ) : initDok === 'Foto' ? (
                          <ImagePreviewComponent
                            isMobile={false}
                            styles={{ width: '100%', cursor: 'pointer' }}
                            uri={res.docUrl}
                            isPreview={true}
                            imageBorder={'6px'}
                          />
                        ) : initDok === 'Video' && (
                          <div className='gradientCard'>
                            <CaretRightOutlined 
                              className='playButtonMini'
                              onClick={() => this.handleOpenVideo(res.docVideoEmbed)}
                            />
                            <ImagePreviewComponent
                              isMobile={false}
                              styles={{ width: '100%' }}
                              uri={res.docVideoImg}
                              imageBorder={'6px'}
                            />
                          </div>
                        )}
                      </Col>
                    )) : 
                      <Col span={24} style={{ textAlign: 'center'}}>
                        <Title level={4}>{initDok == 'Teks'? 'Dokumen Teks' : initDok } Tidak Tersedia</Title> 
                      </Col>
                    }
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <Modal
          visible={this.state.visible}
          centered
          className={'cardVideo'}
          footer={null}
          width={'75%'}
          onCancel={() => this.handleCloseVideo()}
        >
          {GlobalVideoCheck.checking(this.state.validateVideo) ? 
          (
            <iframe
              style={{width: '-webkit-fill-available', height: '500px'}}
              id="modalVideo"
              title='modalVideo'
              src={this.state.validateVideo}
              frameBorder="0"
              allow="accelerometer;
              autoplay;
              encrypted-media;
              gyroscope;
              picture-in-picture"
              allowFullScreen="allowfullscreen"
            />
          ) : 
          (
            <span className='data_text_detail'>
              Url Video Tidak Valid/Error
            </span>
          )
        }
        </Modal>
			</div>
		)
	}
}


export default DetailPersetujuanInformasi