import { get, put, post } from 'axios'
import config from '../services/config'
const { BASE_URL_LOGIN, BASE_URL_NOAUTH, TIMEOUT } = config


const getListMasterStatusProyek = (token, param) => {
  return get(`${BASE_URL_LOGIN}be/api/admin/master/projectStatus?sort=${param.sort}&page=${param.page - 1}&size=${param.size}&id=&keyword=${param.keyword}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const editMasterStatusProyek = (token, key, body) => {
  return put(`${BASE_URL_LOGIN}be/api/admin/master/projectStatus/update?key=${key}`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}


const getDashboardDokumenCrowdFunding = (token) => {
  return get(`${BASE_URL_LOGIN}noAuth/be/api/admin/dashboard/DocumentationCrowdfunding`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}
const getDashboardDonorBeasiswa = (token) => {
  return get(`${BASE_URL_LOGIN}noAuth/be/api/admin/dashboard/scholarshipDonation`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}
const getDashboardSukarela = (token) => {
  return get(`${BASE_URL_LOGIN}noAuth/be/api/admin/dashboard/voluntaryDonation`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}


// Master Kategori Pengumuman

const getListMasterKategoriPengumuman = (token, param) => {
  return get(`${BASE_URL_LOGIN}be/api/admin/master/announcementCategory?sort=${param.sort}&page=${param.page - 1}&size=${param.size}&id=&keyword=${param.keyword}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const addMasterKategoriPengumuman = (token, body) => {
  return post(`${BASE_URL_LOGIN}be/api/admin/master/announcementCategory/insert`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const editMasterKategoriPengumuman = (token, body, key) => {
  return put(`${BASE_URL_LOGIN}be/api/admin/master/announcementCategory/update?key=${key}`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const hapusMasterKategoriPengumuman = (token, key, usersId) => {
  return put(`${BASE_URL_LOGIN}be/api/admin/master/announcementCategory/delete?key=${key}&usersId=${usersId}`, null,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}



export default {
  getDashboardDokumenCrowdFunding,
  getDashboardDonorBeasiswa,
  getDashboardSukarela,
  getListMasterStatusProyek,
  editMasterStatusProyek,

  getListMasterKategoriPengumuman,
  editMasterKategoriPengumuman,
  addMasterKategoriPengumuman,
  hapusMasterKategoriPengumuman
}
