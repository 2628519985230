import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, notification, Row, Select, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { UItable } from '../../../components';
import SearchInput from '../../../components/search-input';
import { confirmDialog, dialog } from '../../../utils/alert';
import '../index.scss';
import ModalCreateDonatur from '../component/modal-create-donatur';
import ModalListDonatur from '../component/modal-list-donatur';
import financeApi from '../../../api/adminKeuangan';
import moment from 'moment';
import { downloadFile } from '../../../utils/download';
const defaultPeriod = new Date().getFullYear();

export default function DonorBeasiswa() {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(false);
  const [pagination, setPagination] = useState({current: 1, total: 0});
  const [period, setPeriod] = useState(defaultPeriod);
  const [years, setYears] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const defaultParams = {keyword: search, page: pagination.current, sort: '', size: 10, period: period}

  const columns = [
    {
      title: 'Nama Donatur',
      dataIndex: 'nama',
      key: 'ud.ud_name',
      sorter: true,
    },
    {
      title: 'No. HP',
      dataIndex: 'noHp',
      key: 'ud.ud_phone',
      sorter: true,
    },
    {
      title: 'Tanggal',
      dataIndex: 'tanggal',
      key: 'sc_transaction_date',
      sorter: true,
    },
    {
      title: 'Nominal',
      dataIndex: 'nominal',
      key: 'sc_nominal',
      sorter: true,
    },
    {
      title: 'Status Pembayaran',
      dataIndex: 'status',
      key: 'sc_payment_status',
      width: '20%',
      sorter: true,
      render: (val) => (
        <div className={`payment-status-label ${val === 'Waiting' ? 'warning' : 'completed'}`}>{val}</div>
      )
    },
  ];


  useEffect(() => {
    loadData(defaultParams);
    getYears();
  }, []);

  const handleSearch = (searchTerm) => {
    setSearch(searchTerm);
    const param = { ...defaultParams, keyword: searchTerm, page: 1 };
    loadData(param);
  }

  const handleTableChange = (page, filter, sort) => {
    console.log(sort, typeof sort)
    loadData({
      ...defaultParams,
      page: page,
      sort: sort || ''
    })
  }

  const handleAddDonatur = async (donatur) => {
    setIsLoading(true);
    setShowModal(false);
    const adminId = sessionStorage.getItem('userId');
    const token = sessionStorage.getItem('token');
    const body = {
      nominal: donatur.nominal,
      date: moment(donatur.tanggalBayar).format('YYYY-MM-DD'),
      userId: adminId,
      existingUserDonaturId: donatur.usersId
    };
    console.log(donatur, body);
    try {
      const res = await financeApi.addDonaturDonorBeasiswa(token, body);
      if (!res.data || res.data.responseType !== 'Success') { throw res.data; }
      dialog({text: 'Data Donatur Donor Beasiswa Berhasil Ditambah', icon: 'success'})
      loadData(defaultParams);
    } catch (err) {
      dialog({title: 'Error', text: `${err ? err.message : ''}`, icon: 'error'});
    }
    setIsLoading(false);
  }

  const handleCreateDonatur = (donatur) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      const adminId = sessionStorage.getItem('userId');
      const token = sessionStorage.getItem('token');
      const body = {
        ...donatur,
        udDateOfBirth : moment(donatur.udDateOfBirth).format('YYYY-MM-DD'),
        date : moment(donatur.date).format('YYYY-MM-DD'),
        userLastPlaceOfEdu: donatur.udLastPlaceOfEdu,
        adminId
      };
      delete body.udLastPlaceOfEdu;
      try {
        const res = await financeApi.createDonaturDonorBeasiswa(token, body);
        if (!res.data || res.data.responseType !== 'Success') { throw res.data; }
        dialog({text: ' Data Donatur Donor Beasiswa Berhasil Ditambah', icon: 'success'})
        loadData(defaultParams);
        resolve();
      } catch (err) {
        dialog({title: 'Error', text: `${err.message}`, icon: 'error'});
        reject();
      }
      setIsLoading(false);
    })
  }

  const handleExport = async () => {
    setIsLoading(true);
    try {
      const token = sessionStorage.getItem('token');
      const res = await financeApi.exportDonorBeasiswa(token, period);
      // await downloadFile(res.data.data.link);
      window.open(res.data.data.link)
    } catch (err) {
      dialog({title: 'Error', text: `${err.message}`, icon: 'error'});
    }
    setIsLoading(false);
  }

  const onChangePeriod = (period) => {
    const param = { ...defaultParams, period, page: 1 };
    setPagination({current: 1, total: 0})
    setPeriod(period);
    loadData(param);
  }

  const loadData = async (param) => {
    setIsLoading(true);
    const token = sessionStorage.getItem('token');
    await financeApi.getListDonorBeasiswa(token, param).then(res => {
      console.log(res.data.data);
      const { payload, totalItem } = res.data.data;
      setData(payload);
      setPagination({current: param.page, total: totalItem});
    }).catch(err => {

    })
    setIsLoading(false);
  }

  const loadUser = (param) => {
    return new Promise((resolve, reject) => {
      const token = sessionStorage.getItem('token');
      financeApi.getListUsers(token, param).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  }

  const getYears = async () => {
    const token = sessionStorage.getItem('token');
    await financeApi.getLovYearDonorBeasiswa(token).then(res => {
      setYears(res.data.data);
    }).catch(err => {

    })
  }

  const handleTambah = () => {
    confirmDialog({
      title: 'Apakah donatur sudah memiliki akun?',
      confirmButtonText: 'Sudah',
      cancelButtonText: 'Belum'
    }).then(_ => { setShowModal(0); })
    .catch(res =>  {
      if (res.dismiss === 'cancel') {
        setShowModal(1);
      }
    })
  };

  return (
    <div className="root">
      <Card className="app-card">
        <Spin spinning={isLoading}>
          <Row gutter={14} style={{marginBottom: '2em'}}>
            <Col>
              <Button className="app-btn bordered" type="primary" onClick={handleTambah}><PlusOutlined /> Tambah</Button>
            </Col>
            <Col flex='auto'>
              <Row>
                <Col flex='auto' style={{textAlign: 'left'}}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    Tahun Anggaran
                    <Select style={{width: '100px', marginLeft: '1em'}} value={period} onSelect={onChangePeriod}>
                      {years.map(e => <Select.Option key={`yearOption-${e.key}`} value={e.value}>{e.value}</Select.Option>)}
                    </Select>
                  </div>
                </Col>
                <Col style={{textAlign: 'right'}}>
                  <Button className="app-btn" type="primary" onClick={handleExport}>Export</Button>
                </Col>
              </Row>
            </Col>
            <Col lg={0} md={12}/>
            <Col lg={8} md={12} style={{flex: 1}}>
              <SearchInput placeholder="Nama Donatur" onChange={search => {handleSearch(search)}}  />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <UItable
                scroll={{x: 800}}
                dataTable={data}
                columns={columns}
                withRows={false}
                page={pagination.current}
                totalData={pagination.total}
                sortDefault=''
                handleTableChange={handleTableChange}
              />
            </Col>
          </Row>
        </Spin>
      </Card>
      {showModal === 0 && (
        <ModalListDonatur
          title="Daftar User"
          addTitle="Tambah Data Donatur Donor Beasiswa"
          visible={showModal === 0}
          loadDataFn={loadUser}
          onCancel={_ => setShowModal(null)}
          onAddDonatur={handleAddDonatur}
        />
      )}
      <ModalCreateDonatur
        title="Tambah Data Donatur Donor Beasiswa"
        visible={showModal === 1}
        onCancel={_ => setShowModal(null)}
        onAddDonatur={handleCreateDonatur}
      />
    </div>
  )
}
