import React, { Component } from 'react'
import '../../../../global/global.css'
// import lov from '../../actions/lov'

import { trackPromise } from 'react-promise-tracker';
import {
  Card,
  Button,
  Row,
  Col,
  Typography,
  Popconfirm
} from 'antd'
import { UItable, UISearch } from '../../../../components'
import { ModalForm } from '../modal';
import { endpoint } from '../../../../api/MasterKategoriProyek';

const { Text } = Typography
const initialSorting = ''
class ListMasterKategoriProyek extends Component {
	constructor(props) {
		super(props)
		this.state = {
		initial: '',
		orderBy: initialSorting,
		size: 10,
		page: 1,
		search: '',
		visibleCreate: false,
		initalData: {
      value: undefined,
      valueEng: undefined,
		},
		dataId: undefined,
		isEdit: false,
		dataProfil: {
      data: [],
      totalData: 0
    },
		dataLOV: [],
    loadingSubmit: false,
    loadingExport: false
		}
	}

	componentDidMount() {
    const {orderBy, size, page, search} = this.state
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: page,
      search: search,
    }
    this.getData(body)
  }

	getData = param => {
		trackPromise(
      endpoint.getData(param).then(res => {
        console.log('data ini adalah : ',res)
        this.setState({
          dataProfil: res
        })
      })
    )
	}

	handleSearch = key => {
    const {orderBy, size, page } = this.state
    this.setState({
      search: key,
      page: 1
    })
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: 1,
      search: key,
    }
    this.getData(body)
  }

	handleTableChange = (page, filter, sorter) => {
    const {size, search } = this.state
    const body = {
      token: this.props.token,
      sort: sorter,
      size: size,
      page: page,
      search: search,
    }
    this.setState({
      page: page,
      orderBy: sorter
    }, () => {
      this.getData(body)
    })
  }

	openModal = () => {
    this.setState({
      visibleCreate: true,
      isEdit: false,
      initalData: {
        value: undefined,
        valueEng: undefined,
      },
    })
  }

	handleCloseModal = () => {
    this.setState({
      visibleCreate: false,
      isEdit: false
    })
  }


	handleFinish = values => {
      this.setState({
        loadingSubmit: true
      })
      let body = {
        token: this.props.token,
        sort: this.state.orderBy,
        size: 10,
        page: 1,
        search: this.state.search,
      }
      let dataTemp = {
        ...values,
        usersId: this.props.userId,
      }
      if (this.state.isEdit) {
        this.handleSubmitEdit(body, dataTemp)
      }else {
        body = {
          token: this.props.token,
          sort: this.state.orderBy,
          size: 10,
          page: 1,
          search: this.state.search,
        }
        this.handleSubmitCreate(body, dataTemp)
      }
  }

	handleSubmitCreate = (body, bodySend) => {
    trackPromise(
      endpoint.create(this.props.token, bodySend).then(res => {
        if (res === 'success') {
          this.setState({
            page: 1,
            orderBy: initialSorting,
            visibleCreate: false,
            loadingSubmit: false
          }, () => {
            this.getData(body)
          })
        }else{
          this.setState({
            loadingSubmit: false
          })
        }
      })
    )
  }

	handleSubmitEdit = (body, bodySend) => {
    trackPromise(
      endpoint.edit(this.props.token, this.state.dataId, bodySend).then(res => {
        if (res === 'success') {
          this.setState({
            page: 1,
            orderBy: initialSorting,
            visibleCreate: false,
            loadingSubmit: false
          }, () => {
            this.getData(body)
          })
        }else{
          this.setState({
            loadingSubmit: false
          })
        }
      })
    )
  }

	handleEditClick = data => {
		this.setState({
      initalData: {
        value: data.value,
        valueEng: data.valueEng,
      },
      dataId: data.key,
      visibleCreate: true,
      isEdit: true
    })
	}

	handleReject = id => {
		const body = {
      token: this.props.token,
      sort: this.state.orderBy,
      size: 10,
      page: 1,
      search: this.state.search,
    }
		trackPromise(
      endpoint.deleteData(this.props.token, this.props.userId, id).then(res => {
        if (res === 'success') {
          this.setState({
            search: this.state.search || '',
            page: 1,
            orderBy: this.state.orderBy || initialSorting,
            visibleCreate: false
          }, () => {
            this.getData(body)
          })
        }
      })
    )
	}

  handleExport = () => {
    this.setState({
      loadingExport: true
    })
    trackPromise(
      endpoint.getExport(this.props.token).then(res => {
        if(res !== 'error'){
          window.open(res)
          this.setState({
            loadingExport: false
          })
        }
      })
    )
  }

	render() {

		const {page, visibleCreate, initalData, isEdit, dataProfil, dataLOV, loadingSubmit, loadingExport } = this.state

    const text = 'Apakah Anda yakin akan menghapus data ini?'

    const columnsTable = [
      {
        title: 'Kategori Proyek',
        dataIndex: 'value',
        key: 'name',
        sorter: true,
      },
      {
        title: 'Aksi',
        dataIndex: '',
        key: '',
        render: (data) => <>
          <Button
            onClick={() => this.handleEditClick(data)}
            type='link'
            style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600 }}
          >
            Ubah
          </Button>
          <Popconfirm
            placement="bottomRight"
            title={text}
            onConfirm={() => this.handleReject(data.key)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type='link'
              style={{ color: '#E22828', cursor: 'pointer', fontWeight: 600 }}
            >
              Hapus
            </Button>
          </Popconfirm>
        </>
      },
    ]

		return(
			<div className='root'>
				<Card className='bodyCard_style'>
					<Row gutter={[16,0]}>
            <Col span={16} xs={12} md={16}>
              <Button onClick={() => this.openModal()} className='addButton'>+ Tambah</Button>
            </Col>
            <Col span={8} xs={24} md={8}>
              <UISearch placeholder='Kategori Proyek' handleSearch={key => this.handleSearch(key)} />
            </Col>
          </Row>
					<Card 
            bordered={false}
            style={{minHeight: '300px'}}>
            <UItable
              dataTable={dataProfil.data}
              columns={columnsTable}
              withRows={false}
              page={page}
              totalData={dataProfil.totalData}
              sortDefault=''
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
					<ModalForm
						visible={visibleCreate}
						initialValues={initalData}
						title={ isEdit ? 'Ubah Data Kategori Proyek' : 'Tambah Data Kategori Proyek'}
						widthDivider='250px'
						close={() => this.handleCloseModal()}
						checkFinish={ values => this.handleFinish(values)}
            dataLOV={dataLOV}
            loadingSubmit={loadingSubmit}
            isEdit={isEdit}
					/>
				</Card>
			</div>
		)
	}
}


export default ListMasterKategoriProyek