const defaultNormalizeData = (res) => {
  return {
    totalData: res.data.data.totalData ? res.data.data.totalData : 0,
    status: 'success'
  }
}

const normalizeDataFaq = (res) => {
  let dataTemp = res.data
  let response = {
    data: dataTemp.data.listFAQ,
    totalData: dataTemp.data.totalData,
    status: 'success',
    rc: dataTemp.rc
  }
  return response
}

// LOV
const normalizeDataFaqCategory = (res) => {
  const pureData = res.data.Data.map(val =>{
    return {
      name: val.faqCategoryName,
      id: val.faqCategoryId
    }
  })
  return pureData
}

// Data Profil
const normalizeDataProfil = (res) => {
  const defaultData = defaultNormalizeData(res)
  const pureData = res.data.data.listPengurus.map(val =>{
    return {
      managementName: val.managementName,
      managementId: val.managementId,
      managementPeriod: val.managementPeriod,
      managementPosition: val.managementPosition,
      managementPositionEng: val.managementPositionEng,
      managementPeriodFrom: val.managementPeriodFrom,
      managementPeriodTo: val.managementPeriodTo,
      managementUrl: val.managementUrl,
      managementProfil: val.managementProfil,
      managementProfilEng: val.managementProfilEng,
      id: val.managementId
    }
  })
  return {
    data: pureData,
    totalData: defaultData.totalData,
    status: defaultData.status,
  }
}


// sebelum
const normalizePendirianSebelum = (res) => {
  const defaultData = defaultNormalizeData(res)
  const pureData = res.data.data.listDocumentation.map(val =>{
    return {
      docOwnerEmail: val.docOwnerEmail,
      docOwnerId: val.docOwnerId,
      docOwnerName: val.docOwnerName,
      docOwnerPhone: val.docOwnerPhone,
      docOwnerTitle: val.docOwnerTitle,
      docOwnerYear: val.docOwnerYear,
      docs: val.docs,
      id: val.docOwnerId
    }
  })
  return {
    data: pureData,
    totalData: defaultData.totalData,
    status: defaultData.status,
  }
}

const normalizeLOVDokumen = (res) => {
  const pureData =  res.data.data ? res.data.data.map(val =>{
    return {
      isUrl: val.isUrl,
      value: val.value,
      key: val.key,
      id: val.key
    }
  }) : []
  return {
    data: pureData,
    status: 'success'
  }
}

const normalizeLOVTahun = (res) => {
  const pureData = res.data.data.map(val =>{
    return {
      name: val.value,
      key: val.key,
      id: val.key
    }
  })
  return {
    data: pureData,
    status: 'success'
  }
}

// setelah

const normalizePendirianSetelah = (res) => {
  const defaultData = defaultNormalizeData(res)
  const pureData = res.data.data.listDocumentation.map(val =>{
    return {
      docOwnerEmail: val.docOwnerEmail,
      docOwnerId: val.docOwnerId,
      docOwnerName: val.docOwnerName,
      docOwnerPhone: val.docOwnerPhone,
      docOwnerTitle: val.docOwnerTitle,
      docOwnerYear: val.docOwnerYear,
      docs: val.docs,
      id: val.docOwnerId
    }
  })
  return {
    data: pureData,
    totalData: defaultData.totalData,
    status: defaultData.status,
  }
}

export default {
  normalizeDataFaq,
  normalizeDataFaqCategory,
  normalizeDataProfil,
  normalizePendirianSebelum,
  normalizeLOVDokumen,
  normalizeLOVTahun,
  normalizePendirianSetelah
}