import { Button, Col, Modal, Row, Spin } from 'antd'
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react'
import SearchInput from '../../../components/search-input';
import masterApi from '../../../api/danaPengembangan';
import { UItable } from '../../../components';

export default function ModalDaftarPenerimaDonorBeasiswa(props) {
  const {visible, onCancel, title, token, id} = props;
  const [data, setData] = useState(false);
  const [pagination, setPagination] = useState({current: 1, total: 0});
  const [isLoading, setIsLoading] = useState(false);
  const defaultParams = {keyword: '', page: pagination.current, sort: 'sg_nisn,asc', size: 10}

  const columns = [
    {
      title: 'NISN',
      dataIndex: 'nisn',
      key: 'sg_nisn',
      sorter: true,
    },
    {
      title: 'Nama Lengkap',
      dataIndex: 'name',
      key: 'sg_name',
      sorter: true,
    },
    {
      title: 'Nominal',
      dataIndex: 'nominal',
      key: 'sg_nominal',
      sorter: true,
    },
    {
      title: 'Keterangan',
      dataIndex: 'reasonInd',
      key: 'sg_reason_ind_ver',
      sorter: true,
    },
  ];

  useEffect(() => {
    if(props.visible){
      loadData(defaultParams);
    }
  }, [props])

  const handleSearch = (search) => {
    const param = { ...defaultParams, keyword: search, page: 1 };
    setPagination({current: 1, total: 0})
    loadData(param);
  }

  const handleTableChange = (page, filter, sort) => {
    loadData({
      ...defaultParams,
      page: page,
      sort: sort
    })
  }

  const loadData = async (param) => {
    setIsLoading(true);
    await masterApi.getDetailKategoriDonorBeasiswaListPenerima(token, param, id).then(res => {
      console.log(res.data.data);
      if(res.data.rc == "00"){
        const { siswaList, totalData } = res.data.data;
        setData(siswaList);
        setPagination({current: param.page, total: totalData});
      }else{

      }
    }).catch(err => {

    })
    setIsLoading(false);
  }

  return (
    <Modal
      className="app-modal"
      width={800}
      footer={null}
      visible={visible}
      onCancel={onCancel}
    >
      <Title level={4} style={{marginBottom: '1em'}}>{title}</Title>
      <Spin spinning={isLoading}>
        <Row>
          <Col span={16} style={{marginBottom: '2em'}}>
            Total Penerima : {pagination.total}
          </Col>
          <Col span={8} style={{marginBottom: '2em'}}>
            <SearchInput onChange={search => {handleSearch(search)}} placeholder={'Nama Lengkap'} />
          </Col>
          <Col span={24}>
            <UItable
              size="small"
              dataTable={data}
              columns={columns}
              withRows={false}
              page={pagination.current}
              totalData={pagination.total}
              sortDefault=''
              handleTableChange={handleTableChange}
            />
          </Col>
        </Row>
      </Spin>
    </Modal>
  )
}
