import React, { useEffect, useState } from "react";
import {
   Row, Col, Button, Form,
   Input, Card, TimePicker, DatePicker, Radio, AutoComplete, Spin,Typography
} from "antd";
import "../../../global/global.css";
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import UploadComponentValues from "../../../components/uploadComponentValues";
import moment from 'moment';
import { trackPromise } from 'react-promise-tracker';
import { endpoint } from '../../../api/galeri';
import { alert } from "../../../api";
import { RedStar } from '../../../components';

const {Text} = Typography

const lovMedia = [{
  key: true,
  value: 'Online'
},{
  key: false,
  value: 'Offline'
}]

const defaultListImage={
  fileName: '', //image preview
  dataFile: null, //path / data upload
  isUpdate: false, //hit api
}

function FormGaleri(props) {
	const [form] = Form.useForm();
  const [loading, setLoading] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [listImage, setListImage] = useState([])
  const [isOnline, setIsOnline] = useState(true)
  const [isOpen, setIsOpen] = useState(true)
  const [eventId, setEventId] = useState(null)
  const [isDisabled, setIsDisabled] = useState(false)
  const [dataLov, setDataLov] = useState(props.data)

  //kebutuhan upload image
  const [imageDbThumbnail, setImageDbThumbnail] = useState('')
  const [isImageThumbnailChange, setIsImageThumbnailChange] = useState(false)

  //kebutuhan upload image list
  const [listDataFile, setListDataFile] = useState([])

  useEffect(() => {
    if(props.data !== dataLov){
      setDataLov(props.data)
    }
  }, [props.data])

  useEffect(() => {
    if(props.initialData && props.isEdit){
      mappingValueDetail(props.initialData)
    }
  }, [props.initialData])

  const mappingValueDetail = e => {
    let listImageTemp = [...listImage]
    let listImageDataFileTemp = []
    let listVideoTemp = []
    if(e && e.listImage.length > 0){
      e.listImage.map(res => {
        listImageTemp.push({
          fileName: res.url,
          dataFile: res.path,
          isUpdate: false
        })

        listImageDataFileTemp.push(res.path)
      })
    }
    if(e && e.listVideo.length > 0){
      e.listVideo.map(res => {
        listVideoTemp.push(res.url)
      })
    }

    let body = {
      name : e.eventName,
      nameEng : e.eventNameEng ,
      date : moment(e.eventDate),
      waktu : [moment(e.eventTimeStart, 'HH:mm:ss'),moment(e.eventTimeFinish, 'HH:mm:ss')],
      desc : e.eventDesc,
      descEng : e.eventDescEng,
      isOnline : e.eventIsOnline,
      thumbnailUrl : e.eventThumbnailPath,
      eventVideo: listVideoTemp
    }
    form.setFieldsValue(body)
    setIsOnline(e.eventIsOnline)
    setImageDbThumbnail(e.eventThumbnailUrl)
    setListDataFile(listImageDataFileTemp)
    setListImage(listImageTemp)
    return body
  }

	const onFinish = async(values) => {
    let dataTemp = {
      eventVideo: values.eventVideo ? values.eventVideo : [],
      eventImage: listDataFile,
      eventId: eventId
    }
    if(!isDisabled){
      dataTemp = {
        ...dataTemp,
        date: moment(values.date).format('YYYY-MM-DD'),
        timeStart: moment(values.waktu[0]).format('HH:mm'),
        timeEnd: moment(values.waktu[1]).format('HH:mm'),
        thumbnailUrl: values.thumbnailUrl,
        isOnline: isOnline,
        name: values.name,
        nameEng: values.nameEng,
        desc: values.desc,
        descEng: values.descEng,
      }
      delete dataTemp.eventId
    }
    props.onFinish(dataTemp);
	}

	const onFinishFailed = error => {
		console.error(error);
	}

  const handleIsMedia = e => {
    setIsOnline(e.target.value)
  }

  const changeAutoComplete = (e,d) => {
    let reset = {
      ...e,
      name : undefined,
      nameEng : undefined,
      date : undefined,
      waktu : [undefined,undefined],
      desc : undefined,
      descEng : undefined,
      isOnline : undefined,
      thumbnailUrl : '',
    }
    if(!e && !d){
      setIsOpen(true)
      setIsDisabled(false)
      setEventId(null)
      setImageDbThumbnail('')
      setIsImageThumbnailChange(false)
      form.setFieldsValue(reset)
    }else{
      if(isDisabled){
        console.log('di changeAutoComplete reset enable : ',e,d)
        reset.name = e
        setIsOpen(true)
        setIsDisabled(false)
        setEventId(null)
        setImageDbThumbnail('')
        setIsImageThumbnailChange(false)
        form.setFieldsValue(reset)
      }
      props.getLov(e)
    }
  }
  const selectAutoComplete = (e,d) => {
    if(e || d){
      setIsOpen(false)
      setIsDisabled(true)
      setImageDbThumbnail(d.thumbnailUrl)
      setEventId(d.key)
      let values = mappingValueLov(d)
      form.setFieldsValue(values)
    }
  }

  const mappingValueLov = e => {
    let body = {
      ...e,
      name : e.name,
      nameEng : e.nameEng ,
      date : moment(e.date, 'DD-MM-YYYY'),
      waktu : [moment(e.timeStart, 'HH:mm'),moment(e.timeEnd, 'HH:mm')],
      desc : e.desc,
      descEng : e.descEng,
      isOnline : e.isOnline,
      thumbnailUrl : e.thumbnailPath,
    }

    setImageDbThumbnail(e.thumbnailUrl)
    return body
  }


  const changeFileThumbnail = (e) => {
    let valueTemp = form.getFieldsValue()
    setIsImageThumbnailChange(true)
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    trackPromise(
      endpoint.uploadBanner(props.token, formData, 'imageSmall?path=galeriBanner').then(res => {
        if(res == 'error'){
          setImageDbThumbnail('Failed')
          setIsImageThumbnailChange(false)
          form.setFieldsValue({...valueTemp, thumbnailUrl: ''})
        }else{
          if(res.fileName){
            setImageDbThumbnail(res.url)
            setIsImageThumbnailChange(false)
            form.setFieldsValue({...valueTemp, thumbnailUrl: res.fileName})
          }
        }
      })
    )
  }

  const changeFilesEventImage = (e, index) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    let dataUploadTemp = {
      index: index,
      data: {
        dataFile: formData,
        isUpdate: true,
        fileName: URL.createObjectURL(e.target.files[0])
      }
    }
    addUpload(dataUploadTemp)
  }

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  }

  const CardHeader = () => {
    return (
      <Card className='bodyCard_style'>
        <Card
          bordered={false}
          style={{minHeight: '300px'}}>
            <Row gutter={[30]}>
              <Col span={12} style={{marginBottom: 8,marginTop: 8}}>
                <Text className="form-label">Thumbnail <RedStar /></Text>
                <Form.Item name='thumbnailUrl'
                  rules={[{required: true,message: 'File Thumbnail harus di-upload!' }
                ]}>
                  <UploadComponentValues
                    pathName='banner'
                    changeFile={e => changeFileThumbnail(e, false)}
                    dataFile={imageDbThumbnail}
                    loading={isImageThumbnailChange}
                    name={'thumbailPath'}
                    accept=".jpg,.png,.jpeg"
                  />
                </Form.Item>
              </Col>
              <Col span={12}/>
              <Col span={12}>
                <Text className="form-label">Nama Acara <RedStar /></Text>
                <Form.Item name='name'
                  rules={[{required: true,message: 'Nama Acara [IND] harus diisi!' }
                ]}>
                  {
                    props.isEdit ?
                    (
                      <Input
                        placeholder={'Nama Acara'}
                        size="large"
                        className='input_style'
                        maxLength={256}
                        disabled={isDisabled}
                      />
                    ) : (
                      <AutoComplete
                        onChange={changeAutoComplete}
                        onSelect={selectAutoComplete}
                        options={dataLov}
                        onSearch={() => setDataLov(props.data)}
                        placeholder={'Nama Acara'}
                        allowClear
                        autoFocus
                        defaultOpen={isOpen}
                      >
                        <Input maxLength={256} />
                      </AutoComplete>
                    )
                  }
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Event Name <RedStar /></Text>
                <Form.Item name='nameEng'
                  rules={[{required: true,message: 'Nama Acara [ENG] harus diisi!' }
                ]}>
                  <Input placeholder={'Event Name'}  size="large" className='input_style' maxLength={256}
                  disabled={isDisabled}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Hari, Tanggal Acara <RedStar /></Text>
                <Form.Item name='date'
                  rules={[{required: true,message: 'Hari, Tanggal Acara  harus diisi!' }
                ]}>
                  <DatePicker placeholder={'Hari, Tanggal Acara'}  className='input_style'
                  format={'dddd,DD-MM-YYYY'}
                  disabledDate={disabledDate}
                  disabled={isDisabled}/>
                </Form.Item>
              </Col>
              <Col span={12}/>
              <Col span={12}>
                <Text className="form-label">Jam <RedStar /></Text>
                <Form.Item name='waktu'
                  rules={[{required: true,message: 'Jam harus diisi!' }
                ]}>
                  <TimePicker.RangePicker placeholder={['Dari', 'Sampai']} format={'HH:mm'} className='input_style'
                  disabled={isDisabled}/>
                </Form.Item>
              </Col>
              <Col span={12}/>
              <Col span={12}>
                <Text className="form-label">Media <RedStar /></Text>
                <Form.Item  name='isOnline'
                  rules={[{required: true,message: 'Media harus diisi!' }
                ]}>
                  <Radio.Group onChange={handleIsMedia}
                  disabled={isDisabled}>
                    <Row gutter={[0,8]}>
                      {lovMedia.map((res) => (
                        <Col span={12} key={res.key}>
                          <Radio key={res.key} value={res.key}>
                            {res.value}
                          </Radio>
                        </Col>
                      ))}
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12}/>
              <Col span={12}>
                <Text className="form-label">Deskripsi <RedStar /></Text>
                <Form.Item name='desc'
                  rules={[{required: true,message: 'Deskripsi [IND] harus diisi!' }
                ]}>
                  <TextArea placeholder={'Deskripsi'} size="large" className='input_style'
                  disabled={isDisabled}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Description <RedStar /></Text>
                <Form.Item name='descEng'
                  rules={[{required: true,message: 'Deskripsi [ENG] harus diisi!' }
                ]}>
                  <TextArea placeholder={'Description'} size="large" className='input_style'
                  disabled={isDisabled}/>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Row>
                  <Col span={24}>
                    Foto
                  </Col>
                  <Col span={24}>
                    <InputUpload />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <Row>
                  <Col span={24}>
                    Video
                  </Col>
                  <Col span={24}>
                    <InputList name={'eventVideo'}/>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[20]}>
              <Col span={24}>
                <Form.Item
                  style={{
                    textAlign: 'right',
                    marginBottom: '0px'
                  }}
                >
                  <Button
                    type="primary"
                    htmlType='submit'
                    className='blueButton'
                    size={'small'}
                    loading={loadingSubmit}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
        </Card>
      </Card>
    )
  }

  const uploadImageList = data =>{
    if(data && data.data.isUpdate){
      let dataUploadTemp = data
      trackPromise(
        endpoint.uploadBanner(props.token, dataUploadTemp.data.dataFile, 'image?path=galeriList').then(res => {
          if(res == 'error'){
            removeUpload(data.index)
          }else{
            if(res.fileName){
              dataUploadTemp = {
                index: data.index,
                data: {
                  dataFile: res.fileName,
                  isUpdate: false,
                  fileName: res.url
                }
              }
              setListDataFile([...listDataFile,res.fileName])
              addUpload(dataUploadTemp)
            }
          }
        })
      )
    }
  }

  const addUpload = (props) =>{
    let tempData = [...listImage]
    if(props){
      tempData[props.index] = props.data
    }else{
      tempData.push(defaultListImage)
    }
    setListImage(tempData)

    if(props){
      uploadImageList(props)
    }
  }
  const removeUpload = (index) =>{
    const list = [...listImage]
    const listData = [...listDataFile]
    list.splice(index,1)
    listData.splice(index,1)
    setListImage(list)
    setListDataFile(listData)
  }

  const InputUpload = props => {
    return(
      <Row align="middle" gutter={[8, 10]}>
        <Col span={24}>
          {
            listImage.map((res,index) => {
              return(
                <Row gutter={[8,8]}>
                  <Col span={22}>
                    <UploadComponentValues
                      pathName='listImage'
                      changeFile={e => changeFilesEventImage(e,index)}
                      dataFile={res.fileName}
                      loading={res.isUpdate}
                      name={'eventImage'+index}
                      accept={".jpg,.jpeg,.png"}
                    />
                  </Col>
                  <Col span={2} style={{textAlign: "center"}}>
                    <MinusCircleOutlined onClick={() => removeUpload(index)}/>
                  </Col>
                </Row>
              )
            })
          }
        </Col>
        <Col span={24} style={{textAlign: "center"}}>
          <Button type="text" onClick={() => addUpload()} block icon={<PlusCircleOutlined />}>
            Tambah Foto
          </Button>
        </Col>
      </Row>
    )
  }

  const InputList = (props) => {
    return (
      <Form.List name={props.name}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Row gutter={[8, 10]} key={index} align='middle'>
                <Col span={22}>
                  <Form.Item
                    {...field}
                    name={[field.name]}
                    fieldKey={[field.fieldKey]}
                    style={{ marginBottom: 0 }}
                    rules={[
                      {
                        required: true,
                        message: 'URL harus diisi',
                      },
                      // {
                      //   pattern: /^http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/ ,
                      //   message: 'Harap masukkan URL Video YouTube yang valid!'
                      // }
                    ]}
                  >
                    <Input
                      placeholder="URL"
                      className="input_style"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={2} style={{textAlign: "center"}}>
                  <MinusCircleOutlined onClick={() => remove(field.name)}/>
                </Col>
              </Row>
            ))}
            <Row align="middle" gutter={[8, 10]}>
              <Col span={24} style={{textAlign: "center"}}>
                <Button type="text" onClick={() => add()} block icon={<PlusCircleOutlined />}>
                  Tambah Video
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    )
  }

	return (
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <Row gutter={[30,30]}>
          <Col span={24}>
            <CardHeader/>
          </Col>
          {/* <pre>
            {JSON.stringify(listDataFile)}
          </pre> */}
        </Row>
    </Form>
	)
}

export const UIFormGaleri = FormGaleri;
