import React, { useEffect} from 'react'

import { 
  Modal, 
  Form, 
  Row, 
  Col,
  Typography,
  Input,
  Select,
  Button,
  DatePicker,
  Radio
} from 'antd'

import { EyeInvisibleOutlined, UploadOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { usePromiseTracker } from "react-promise-tracker";
import { RedStar } from '../../components';

const {Title, Text} = Typography
const {Option} = Select

const {RangePicker} = DatePicker

const ModalCreate = props => {

  const { promiseInProgress } = usePromiseTracker();
  const [form] = Form.useForm()
  useEffect(() => {
    if (props.visible === true) {
      form.resetFields();
      form.setFieldsValue(props.initialValues)
    }
  }, [props.visible])

  const handleClose = () => {
    form.resetFields();
    props.close()
  }

  const checkFinish = values => {
    props.checkFinish(values)
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const handleResetForm = () => {
    props.onReset().then(res => {
      form.resetFields(); 
      form.setFieldsValue(res)
    })
  };

  const defaultForm = [
    {
      name: 'formDefault',
      rules: [
        {
          required: true,
          message: 'Please input form default!'
        },
      ],
      placeholder: 'Form'
    }
  ]
  const radioChange = (val, data) => {
    const splitName = data.split(':')
    form.setFieldsValue({
      ['file:'+splitName[1]]: undefined
    })
    props.changeRadio(val, data)
  }
  
  const formItem = props.formItem || defaultForm

  const proseccesType = data => {
    return(
      data.type === 'LOV' ? 
        <Select
          placeholder={data.placeholder}
          disabled={data.disabled}
          className="select"
          // onChange={data.onChange}
          // onChange={(event) => handleChange(event)}
          >
          {
            data.dataLOV ?
            data.dataLOV.map((item, index) => (
                <Option key={item.id + 'LOV' + index} value={item.id}>{item.name}</Option>  
              )
            ):(
              <></>
            )
          }
        </Select>
      :
      data.type === 'password' ?
        <Input.Password 
          placeholder={data.placeholder}
          suffix={<EyeInvisibleOutlined 
          color={data.passowrdColor}/>}
        />
      :
      data.type === 'textArea' ? 
        <Input.TextArea 
          maxLength={data.maxLength} 
          placeholder={data.placeholder}
          size="large"
          rows={data.rowsText}
          className='input_style' 
        />
      : data.type === 'input' ?
        <Input 
          maxLength={data.maxLength} 
          placeholder={data.placeholder}
          size="large"
          disabled={data.disabled ? data.disabled : false}
          onChange={e => props.changeInput(e, data.name)}
          className='input_style'
        /> 
      : data.type === 'dateRange' ?
        <RangePicker 
          placeholder={['Dari', 'Sampai']}
          picker={data.picker}
        />
      : data.type === 'radio' ?
        <Radio.Group onChange={val => radioChange(val, data.name)}>
          <Row gutter={[0,8]}>
            {data.radioLOV.map((res, index) => (
              <Col span={12} key={res.key + 'radio' + index}>
                <Radio key={res.key} value={res.key+','+res.isUrl+','+res.value}>
                  {res.value}
                </Radio>
              </Col>
            ))}
          </Row>
        </Radio.Group>
      : data.type === 'label' ?
        <span>
          {data.values}{data.isRedStar && <RedStar/>}
        </span>
      :data.type === 'file' ?
        <Row>
          <Col span={12}>
            <label htmlFor={`upload-file${data.name}`} className='custom-file-upload'>
            <UploadOutlined style={{color: '#232F9D', fontSize: '24px'}} /><br />
              Pilih File
            </label>
            <input type='file' id={`upload-file${data.name}`} onChange={e => props.changeFile(e, data.name)} />
          </Col>
          <Col span={12}>
            <span>
              {props.dataFile[data.name.split(':')[1]].url}
            </span>
          </Col>
        </Row>
        
      : <></>
    )
  }
  
  return (
    <Modal 
      visible={props.visible}
      footer={null}
      className='modal_galery'
      width={633}
      centered
      onCancel={() => handleClose()}
      destroyOnClose={true}
    >
      <Row>
        <Col span={24}>
          <div className='headerForm'>
            <Title className='titleModalGalery' level={4}>{props.title}</Title>
          </div>
        </Col>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={values => checkFinish(values)}
            onFinishFailed={onFinishFailed}
            initialValues={props.initialValues}
          >
            <Row gutter={[16, 0]}>
              {formItem.map((val, index) => (
                val.isAditional ? (
                  <Col span={24} key={val.name + props.title + index}>
                    <Row>
                      {val.dataAditional.map((dataNew, indexNew) => (
                        <Col span={dataNew.colNumber} key={dataNew.name + props.title + indexNew}>
                          <Text className="form-label">{dataNew.placeholder} {dataNew.placeholder && dataNew.rules && <RedStar />}</Text>
                          <Form.Item 
                            // label={dataNew.placeholder}
                            name={dataNew.name}
                            rules={dataNew.rules}
                          >
                            {proseccesType(dataNew)}
                          </Form.Item>
                        </Col>
                      ))}
                      <Col span={24} style={{textAlign: 'center'}}>
                        <span 
                          className="text-dokumentasi" 
                          style={{
                            marginRight: '8px', 
                            verticalAlign: 'text-bottom',
                            cursor: 'pointer'
                          }}
                          onClick={() => props.addAditional()}
                          >
                          Tambah Dokumentasi
                        </span>
                        <PlusCircleOutlined 
                          onClick={() => props.addAditional()}
                          style={{ 
                            color: '#232F9D', 
                            fontSize: '24px',
                            cursor: 'pointer'
                            }} 
                        />
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col span={val.colNumber} key={val.name + props.title + index}>
                    <Text className="form-label">{val.placeholder} {val.placeholder && val.rules && <RedStar />}</Text>
                    <Form.Item 
                      // label={val.placeholder}
                      // key={val.name + props.title + index}
                      name={val.name}
                      rules={val.rules}
                    >
                      {proseccesType(val)}
                    </Form.Item>
                  </Col>
                )
              ))
              }
              <Col span={24}>
                <Form.Item
                  style={{
                    textAlign: 'right',
                    marginBottom: '0px'
                  }}
                >
                  <Button
                    type="link"
                    className='resetButton'
                    size={'small'}
                    onClick={() => handleResetForm()}
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    htmlType='submit'
                    className='blueButton'
                    loading={promiseInProgress}
                    size={'small'}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

export const UIModalCreate = ModalCreate