import React, { Component } from 'react'
import '../../../global/global.css'
// import lov from '../../actions/lov'

import { trackPromise } from 'react-promise-tracker';
import {
  Card,
  Button,
  Row,
  Col,
  Typography,
  Popconfirm
} from 'antd'
import { UItable, UISearch } from '../../../components'
import moment from 'moment';
import { ModalDaftarKekl } from '../modal';
import { alert } from '../../../api';
import { endtpointCeritaKekl } from '../../../api/ceritakekl';

const { Text } = Typography
const initialSorting = 'kekl_grad_year,desc'
const initialDataTemp = {
  name: undefined,
  proffesion: undefined,
  proffesionEng: undefined,
  yearGraduate: undefined,
  desc: undefined,
  descEng: undefined,
  urlVideo: undefined
}
class ListDaftarCeritaKekl extends Component {
	constructor(props) {
		super(props)
		this.state = {
		initial: '',
		orderBy: initialSorting,
		size: 10,
		page: 1,
		search: '',
		visibleCreate: false,
		initalData: initialDataTemp,
		dataId: undefined,
		isEdit: false,
		dataProfil: {
      data: [],
      totalData: 0
    },
		dataLOV: [],
    loadingSubmit: false,
    loadingExport: false
		}
	}

	componentDidMount() {
    const {orderBy, size, page, search} = this.state
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: page,
      search: search,
    }
    this.getData(body)
  }

	getData = param => {
		trackPromise(
      endtpointCeritaKekl.getData(param).then(res => {
        this.setState({
          dataProfil: res
        })
      })
    )
	}

	handleSearch = key => {
    const {orderBy, size, page } = this.state
    this.setState({
      search: key,
      page: 1
    })
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: 1,
      search: key,
    }
    this.getData(body)
  }

	handleTableChange = (page, filter, sorters) => {
    const sorter = sorters ? sorters : initialSorting
    const {size, search } = this.state
    const body = {
      token: this.props.token,
      sort: sorter,
      size: size,
      page: page,
      search: search,
    }
    this.setState({
      page: page,
      orderBy: sorter
    }, () => {
      this.getData(body)
    })
  }

	openModal = () => {
    this.setState({
      visibleCreate: true,
      isEdit: false,
      initalData: initialDataTemp,
    })
  }

	handleCloseModal = () => {
    this.setState({
      visibleCreate: false,
      isEdit: false
    })
  }


	handleFinish = values => {
      this.setState({
        loadingSubmit: true
      })
      let body = {
        token: this.props.token,
        sort: this.state.orderBy,
        size: 10,
        page: 1,
        search: this.state.search,
      }
      let dataTemp = {
        ...values,
        yearGraduate: moment(values.yearGraduate).format('YYYY'),
        usersId: this.props.userId,
      }
      if (this.state.isEdit) {
        this.handleSubmitEdit(body, dataTemp)
      }else {
        body = {
          token: this.props.token,
          sort: this.state.orderBy,
          size: 10,
          page: 1,
          search: this.state.search,
        }
        this.handleSubmitCreate(body, dataTemp)
      }
  }

	handleSubmitCreate = (body, bodySend) => {
    trackPromise(
      endtpointCeritaKekl.create(this.props.token, bodySend).then(res => {
        if (res === 'success') {
          this.setState({
            page: 1,
            orderBy: initialSorting,
            visibleCreate: false,
            loadingSubmit: false
          }, () => {
            this.getData(body)
          })
        }else{
          this.setState({
            loadingSubmit: false
          })
        }
      })
    )
  }

	handleSubmitEdit = (body, bodySend) => {
    trackPromise(
      endtpointCeritaKekl.edit(this.props.token, this.state.dataId, bodySend).then(res => {
        if (res === 'success') {
          this.setState({
            page: 1,
            orderBy: initialSorting,
            visibleCreate: false,
            loadingSubmit: false
          }, () => {
            this.getData(body)
          })
        }else{
          this.setState({
            loadingSubmit: false
          })
        }
      })
    )
  }

	handleEditClick = data => {
		this.setState({
      initalData: {
        name: data.name,
        proffesion: data.proffesion,
        proffesionEng: data.proffesionEng,
        yearGraduate: moment(data.yearGraduate).format('YYYY'),
        desc: data.desc,
        descEng: data.descEng,
        urlVideo: data.urlVideo
      },
      dataId: data.keklId,
      visibleCreate: true,
      isEdit: true
    })
	}

	handleReject = id => {
		const body = {
      token: this.props.token,
      sort: this.state.orderBy,
      size: 10,
      page: 1,
      search: this.state.search,
    }
		trackPromise(
      endtpointCeritaKekl.deleteData(this.props.token, this.props.userId, id).then(res => {
        if (res === 'success') {
          this.setState({
            search: this.state.search || '',
            page: 1,
            orderBy: this.state.orderBy || initialSorting,
            visibleCreate: false
          }, () => {
            this.getData(body)
          })
        }
      })
    )
	}

  handleExport = () => {
    this.setState({
      loadingExport: true
    })
    trackPromise(
      endtpointCeritaKekl.getExport(this.props.token).then(res => {
        if(res !== 'error'){
          window.open(res)
          this.setState({
            loadingExport: false
          })
        }
      })
    )
  }

	handleRedirectDetail = id => {
    this.props.history.push(`daftar-cerita-kekl-detail/${id}`)
	}

	render() {

		const {page, visibleCreate, initalData, isEdit, dataProfil, dataLOV, loadingSubmit, loadingExport } = this.state

    const text = 'Apakah Anda yakin akan menghapus data ini?'

    const columnsTable = [
      {
        title: 'Nama Lengkap',
        dataIndex: 'name',
        key: 'kekl_name',
        sorter: true,
        render: name => <Text style={{ color: '#222222', fontWeight: 600, cursor: 'pointer' }}>{name}</Text>,
        onCell: record => {
          return{
            onClick: () => {
              this.handleRedirectDetail(record.keklId)
            }
          }
        }
      },
      {
        title: 'Profesi',
        dataIndex: 'proffesion',
        key: 'kekl_profession',
        sorter: true,
      },
      {
        title: 'Tahun Lulus',
        dataIndex: 'yearGraduate',
        key: 'kekl_grad_year',
        sorter: true,
      },
      {
        title: 'Aksi',
        dataIndex: '',
        key: '',
        render: (data) => <>
          <Button
            onClick={() => this.handleEditClick(data)}
            type='link'
            style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600 }}
          >
            Ubah
          </Button>
          <Popconfirm
            placement="bottomRight"
            title={text}
            onConfirm={() => this.handleReject(data.keklId)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type='link'
              style={{ color: '#E22828', cursor: 'pointer', fontWeight: 600 }}
            >
              Hapus
            </Button>
          </Popconfirm>
        </>
      },
    ]

		return(
			<div className='root'>
				<Card className='bodyCard_style'>
					<Row gutter={[16,0]}>
            <Col span={8} xs={12} md={8}>
              <Button onClick={() => this.openModal()} className='addButton'>+ Tambah</Button>
            </Col>
            <Col span={8} xs={12} md={8} style={{textAlign: 'right'}}>
              <Button className='blueButton' loading={loadingExport} onClick={() => this.handleExport()}>Export</Button>
            </Col>
            <Col span={8} xs={24} md={8}>
              <UISearch placeholder='Nama Lengkap' handleSearch={key => this.handleSearch(key)} />
            </Col>
          </Row>
					<Card 
            bordered={false}
            style={{minHeight: '300px'}}>
            <UItable
              scroll={{x: 800}}
              dataTable={dataProfil.data}
              columns={columnsTable}
              withRows={false}
              page={page}
              totalData={dataProfil.totalData}
              sortDefault=''
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
					<ModalDaftarKekl
						visible={visibleCreate}
						initialValues={initalData}
						title={ isEdit ? 'Ubah Data Cerita KEKL' : 'Tambah Data Cerita KEKL'}
						widthDivider='250px'
						close={() => this.handleCloseModal()}
						checkFinish={ values => this.handleFinish(values)}
            dataLOV={dataLOV}
            loadingSubmit={loadingSubmit}
            isEdit={isEdit}
					/>
				</Card>
			</div>
		)
	}
}


export default ListDaftarCeritaKekl