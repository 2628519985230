import { Card, Col, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useRef, useState } from 'react'
// import { RadialChart } from 'react-vis';
import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  RadialChart,
  GradientDefs,
  ChartLabel,
  XYPlot,
  LineSeries,
  LineMarkSeries,
  VerticalBarSeriesCanvas,
  LabelSeries,
  DiscreteColorLegend,
  Hint
} from 'react-vis';

const defaultWidth = 200;

function PieChart(props) {
  const { data, title, showLegend, colors = ['#E43077', '#462947', '#FB5724', '#fbbc04', '#dc6f80'] } = props;
  const [chartData, setChartData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [chartWidth, setChartWidth] = useState(defaultWidth);
  const [value, setValue] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const newWidth = ref.current ? ref.current.offsetWidth : defaultWidth;
    setChartWidth(newWidth > defaultWidth ? defaultWidth : newWidth);
  }, [ref.current]);

  useEffect(_ => {
    updateData();
  }, [data]);

  const updateData = () => {
    let newTotalData = 0;
    let newData;
    if (!data) {
      newData = [{ angle: 1, innerRadius: 0.875, color: '#eee' }];
    } else {
      newData = data.map((e, i) => {
        newTotalData += e.value;
        return {
          angle: e.value,
          value: e.value,
          color: colors[i],
          label: e.label,
        };
      });
    }
    setTotalData(newTotalData);
    setChartData(newData);
  }

  return (
    <div>
      {showLegend && (
        <div className="legends">
          {data.map((cd, i) => (
            <div className="legend">
              <div className="dot" style={{background: colors[i]}} />
              <span>{cd.label}</span>
            </div>
          ))}
        </div>
      )}
      <div style={{position: 'relative'}} ref={ref}>
        <RadialChart
          className="centerChart"
          data={chartData}
          colorType="literal"
          width={chartWidth}
          height={chartWidth}
          onValueMouseOver={v => setValue(v)}
          onSeriesMouseOut={v => setValue(false)}
          getLabel={d => d.value}
          labelsRadiusMultiplier={.75}
          style={{stroke: '#fff', strokeWidth: 2}}
          labelsStyle={{fontSize: 14, fill: '#ffffff', fontWeight: 'bold'}}
          showLabels
        >
          {/* {value !== false && <Hint value={value} />} */}
        </RadialChart>
      </div>
      {title && <Title className="chart-name">{title}</Title>}
    </div>
  )
}


function RingChart(props) {
  const { data, title, showLegend, colors = ['#E43077', '#462947', '#FB5724', '#fbbc04', '#dc6f80'] } = props;
  const [chartData, setChartData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [chartWidth, setChartWidth] = useState(defaultWidth);
  const [value, setValue] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const newWidth = ref.current ? ref.current.offsetWidth : defaultWidth;
    setChartWidth(newWidth > defaultWidth ? defaultWidth : newWidth);
  }, [ref.current]);

  useEffect(_ => {
    updateData();
  }, [data]);

  const updateData = () => {
    let newTotalData = 0;
    let newData;
    if (!data) {
      newData = [{ angle: 1, innerRadius: 0.875, color: '#eee' }];
    } else {
      newData = data.map((e, i) => {
        newTotalData += e.value;
        return {
          angle: e.value,
          value: e.value,
          innerRadius: 0.90,
          color: colors[i],
          label: e.label,
        };
      });
    }
    setTotalData(newTotalData);
    setChartData(newData);
  }

  return (
    <div>
      <div style={{position: 'relative'}} ref={ref}>
        {/* <div className="centerTitle">{totalData}</div> */}
        {showLegend && (
          <div className="legends">
            {data.map((cd, i) => (
              <div className="legend">
                <div className="dot" style={{background: colors[i]}} />
                <span>{cd.label}</span>
              </div>
            ))}
          </div>
        )}
        <RadialChart
          className="centerChart"
          data={chartData}
          colorType="literal"
          width={chartWidth}
          height={chartWidth}
          onValueMouseOver={v => setValue(v)}
          onSeriesMouseOut={v => setValue(false)}
          getLabel={d => d.value}
          labelsRadiusMultiplier={.85}
          labelsStyle={{fontSize: 14, fill: '#462947', fontWeight: 'bold'}}
          showLabels
        >
          {/* {value !== false && <Hint value={value} />} */}
        </RadialChart>
      </div>
      {title && <Title className="chart-name">{title}</Title>}
    </div>
  )
}


function PercentageChart(props) {
  const { data = 0, title = '{ Title }', colors = ['#39dd8e', '#ff4d4f', '#4697f2', '#fbbc04'] } = props;
  const [chartData, setChartData] = useState([]);
  const [chartWidth, setChartWidth] = useState(200);
  const ref = useRef(null);

  useEffect(() => {
    const newWidth = ref.current ? ref.current.offsetWidth : 200;
    setChartWidth(newWidth > 200 ? 200 : newWidth);
  }, [ref.current]);

  useEffect(_ => {
    let newData = [
      {
        angle: data,
        innerRadius: 0.875,
        gradientLabel: 'grad1'
      },
      {
        angle: 100 - data,
        innerRadius: 0.875,
        color: '#777',
        gradientLabel: 'grad2'
      },
    ];
    // setTotalData(newTotalData);
    setChartData(newData);
  }, [data])

  return (
    <div>
      <div style={{position: 'relative'}} ref={ref}>
        <div className="centerTitle">{`${data}%`}</div>
        <RadialChart
          className="centerChart"
          data={chartData}
          padAngle={0.1}
          colorType="literal"
          width={chartWidth}
          getColor={d => `url(#${d.gradientLabel})`}
          height={chartWidth} >
          <GradientDefs>
            <linearGradient id="grad1" x1="0" x2="0" y1="0" y2="1">
              <stop offset="0%" stopColor="#dc6f80" />
              <stop offset="100%" stopColor="#ae5ad8" />
            </linearGradient>
            <linearGradient id="grad2" x1="0" x2="0" y1="0" y2="1">
              <stop offset="0%" stopColor="#eee" />
            </linearGradient>
          </GradientDefs>
        </RadialChart>
      </div>
      <Title className="chart-name">{title}</Title>
    </div>
  )
}


function BarChart(props) {
  const { data, title, colors = ['#E43077', '#462947', '#FB5724', '#fbbc04', '#dc6f80']} = props;
  const [chartData, setChartData] = useState([]);

  const labelData = data.map((d, idx) => ({
    x: d.key,
    y: d.value,
    color: '#fff'
  }));

  useEffect(_ => {
    let newData = data.map((e, i) => ({
      x: e.key,
      y: e.value,
      color: colors[i],
      barWidth: .5
    }))
    setChartData(newData);
  }, [data])

  return (
    <div>
      <div style={{position: 'relative'}}>
        {chartData && data && (
          <XYPlot colorType="literal" xType="ordinal" width={300} height={300} xDistance={100}>
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis />
            <YAxis />
            <VerticalBarSeries className="vertical-bar-series-example" data={chartData} barWidth={0.5}/>
            <LabelSeries data={labelData} yDistance={100} getLabel={d => d.y} style={{color: '#ffffff', background: '#ffffff', textAlign: 'center', justifyContent: 'center'}} color='#ffffff' fill='2'/>
          </XYPlot>
        )}
      </div>
      {title && <Title className="chart-name">{chartData.length}</Title>}
    </div>
  )
}


function ProgressChart(props) {
  const { data = 0, title, label, color = '#E43077'} = props;

  return (
    <div>
      <div className="app-progress-chart">
        <div className="app-progress-bar">
          <div className="app-progress-label">{data+`%`}</div>
          <div className="app-progress-content" style={{ height: `${data}%`, background: color }} ></div>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          {label && <div className="app-progress-label" style={{color: color}}>{label}</div>}
        </div>
      </div>
      {title && <Title className="chart-name">{title}</Title>}
    </div>
  )
}

function HybridChart(props) {
  const { data = [], title, colors = ['#E43077', '#462947', '#FB5724', '#fbbc04', '#dc6f80']} = props;
  const [value, setValue] = useState({x:0,y:0,y2:0})
  const [isValue, setIsValue] = useState(false)

  const data1 = data ? data.map(e => ({
    x: e.x,
    y: e.y2
  })) : []

  return (
    <div>
      <div style={{position: 'relative'}}>
        {data && (
          <>
            <FlexibleWidthXYPlot colorType="literal" xType="ordinal" margin={{left: 50, right: 50}} height={300}>
              <HorizontalGridLines />
              <XAxis  />
              <YAxis title="Jumlah Nominal (Dalam Juta Rupiah)" tickTotal={5} tickSize={10} />
              <VerticalBarSeries
                color={colors[0]}
                data={data1}
                barWidth={.5}
                onSeriesMouseOver={(datapoint)=>{
                  console.log('data d click onValueMouseOver : ',datapoint)
                  setValue(datapoint)
                  setIsValue(true)
                }}
                onSeriesMouseOut={e=>setIsValue(false)}
              />
            </FlexibleWidthXYPlot>
            <FlexibleWidthXYPlot colorType="literal" xType="ordinal" margin={{left: 50, right: 50}} height={300} style={{marginTop: '-300px'}}>
              <YAxis title="Jumlah Donatur (Orang)" orientation="right"/>
              <LineMarkSeries
                color={colors[1]}
                data={data}
                onValueMouseOver={(datapoint)=>{
                  console.log('data d click onValueMouseOver : ',datapoint)
                  let body = {
                    x: datapoint.x,
                    y: datapoint.y,
                    y2: datapoint.y2 ? formatRupiah(datapoint.y2 * 1000000,'Rp. ') : `Rp. 0`,
                  }
                  setValue(body)
                  setIsValue(true)
                }}
                onValueClick={(datapoint)=>{
                  console.log('data d click onValueMouseOver : ',datapoint)
                  let body = {
                    x: datapoint.x,
                    y: datapoint.y,
                    y2: datapoint.y2 ? formatRupiah(datapoint.y2 * 1000000,'Rp. ') : `Rp. 0`,
                  }
                  setValue(body)
                  setIsValue(true)
                }}
                onSeriesMouseOut={e=>setIsValue(false)}
              >
              </LineMarkSeries>
              {
                isValue && <Hint style={{background: 'rgba(0,0,0,0.5)'}} value={value}>
                  <Row gutter={[10,10]}>
                    <Col style={{margin: 5}}>
                      <table>
                        <tr>
                          <td>Bulan</td>
                          <td>:</td>
                          <td>{value && value.x}</td>
                        </tr>
                        <tr>
                          <td>Donatur</td>
                          <td>:</td>
                          <td>{value && value.y}</td>
                        </tr>
                        <tr>
                          <td>Nominal</td>
                          <td>:</td>
                          <td>{value && value.y2}</td>
                        </tr>
                      </table>
                    </Col>
                  </Row>
                </Hint>
              }
            </FlexibleWidthXYPlot>
          </>
        )}

      </div>
      {title && <Title className="chart-name">{data.length}</Title>}
    </div>
  )
}

function formatRupiah(angka, prefix){
  var angkas = angka.toString()
	var number_string = angkas.replace(/[^,\d]/g, '').toString(),
	split   		= number_string.split(','),
	sisa     		= split[0].length % 3,
	rupiah     		= split[0].substr(0, sisa),
	ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);

	// tambahkan titik jika yang di input sudah menjadi angka ribuan
	if(ribuan){
    let separator = ''
		separator = sisa ? '.' : '';
		rupiah += separator + ribuan.join('.');
	}

	rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
	return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : 'Rp.0');
}

export {PieChart, RingChart, PercentageChart, BarChart, ProgressChart, HybridChart};
