import { Button, Card, Col, Row, Select, Form, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import UploadComponent from '../../../components/uploadComponent/upload-component';
import financeApi from '../../../api/adminKeuangan';
import './index.scss';
import config from '../../../services/config'
import { confirmDialog, dialog } from '../../../utils/alert';
const { BASE_URL_LOGIN, BASE_URL_NOAUTH, TIMEOUT } = config;

export default function Laporan() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [reportKey, setReportKey] = useState(0);
  const [value, setValue] = useState({});
  const usersId = sessionStorage.getItem('userId');
  const token = sessionStorage.getItem('token');
  const currentReport = data[reportKey] || {};
  const requestConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  }

  useEffect(() => {
    loadData();
  }, []);


  const mockValue = (val) => {
    return val ? {
      response: {
        data: 'report-project/'+val
      },
      fileName: val,
      fileList: [{
        "name": val,
        "uid": "rc-upload-1599635148400-27",
        "lastModified": 1597119978744,
        "size": 2708,
        "type": "application/pdf",
        "percent": 0
      }]
    } : null
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const token = sessionStorage.getItem('token');
      const userId = sessionStorage.getItem('userId');
      let body = form.getFieldsValue();
      Object.keys(body).forEach(e => {
        // console.log(e, body[e]);
        body[e] = body[e] ? body[e].response.data : null
      });
      body = {
        ...body,
        usersId: userId,
        fundId: currentReport.key
      }
      const res = await financeApi.saveLaporan(token, body);
      if (!res.data || res.data.responseType !== 'Success') { throw res.data; }
      dialog({
        icon: 'success',
        title: `File Laporan ${currentReport.fundName} Berhasil Di-upload`
      })
      loadData();
    } catch (err) {
      console.log(err);
      dialog({ icon: 'error', title: `Error`, text: err.data })
    }
    setIsLoading(false);
  }

  const loadData = async (reportKey) => {
    setIsLoading(true);
    try {
      const token = sessionStorage.getItem('token');
      const res = await financeApi.getLaporan(token);
      if (!res.data || res.data.responseType !== 'Success') { throw res.data; }
      const listReport = res.data.data;
      const current = listReport[reportKey ? reportKey : 0];
      setData(listReport);
      setReportKey(reportKey ? reportKey : 0);
      setTimeout(_ => {
        form.setFieldsValue({
          reportFinance: mockValue(current.reportKeuanganName),
          reportProgress: mockValue(current.reportProgressName),
        })
      }, 300);
    } catch (err) {
      dialog({ icon: 'error', title: `Error`, text: err.data })
    }
    setIsLoading(false);
  }

  const handleChangeReport = async (val) => {
    setReportKey(val);
    const current = data[val]
    form.resetFields();
    setTimeout(_ => {
      form.setFieldsValue({
        reportFinance: mockValue(current.reportKeuanganName),
        reportProgress: mockValue(current.reportProgressName),
      })
    }, 300);
  }

  const handleValuesChange = (e) => {
    const values = Object.values(e);
    if (!!values[0]) {
      loadData(reportKey);
    }
  }

  return (
    <div className="root">
      <Card className="app-card">
        <Spin spinning={isLoading}>
          <Form form={form} onValuesChange={handleValuesChange}>
            <Row gutter={28} style={{marginBottom: '4em'}} justify="center">
              <Col span={8} xs={24} md={8}>
                <Select style={{width: '100%'}} size="large" value={reportKey} onChange={handleChangeReport}>
                  {data.map((e, i) => <Select.Option key={`reportItem-${e.key}`} value={i}>{e.fundName}</Select.Option> )}
                </Select>
              </Col>
            </Row>
            {/* <hr className="app-hr" /> */}
            <Row gutter={28} justify="center" style={{ marginBottom: '1.5em' }}>
              <Col span={6} style={{textAlign: 'right'}} xs={24} md={12}>
                <div style={{fontSize: '1.2em', fontWeight: 'bold'}}>Laporan Keuangan</div>
                <div>Last Uploaded: {currentReport.reportKeuanganDate || ' - '}</div>
              </Col>
              <Col span={6} xs={24} md={12}>
                <Form.Item name="reportFinance" style={{ marginTop: '8px', marginBottom: '0px' }}>
                  <UploadComponent
                    url={`${BASE_URL_LOGIN}be/api/admin/keuangan/upload/reportKeuangan?fundId=${currentReport.key}&usersId=${usersId}`}
                    requestConfig={requestConfig}
                    classBtn="app-btn ant-btn-primary ant-btn-lg mt-10"
                    defaultBtn={true}
                    maxSize={10}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={28} justify="center" style={{ marginBottom: '2em' }}>
              <Col span={6} style={{textAlign: 'right'}} xs={24} md={12}>
                <div style={{fontSize: '1.2em', fontWeight: 'bold'}}>Laporan Progress</div>
                <div>Last Uploaded: {currentReport.reportProgressDate || ' - '}</div>
              </Col>
              <Col span={6} xs={24} md={12}>
                <Form.Item name="reportProgress" style={{ marginTop: '8px', marginBottom: '0px' }}>
                  <UploadComponent
                    url={`${BASE_URL_LOGIN}be/api/admin/keuangan/upload/reportProgress?fundId=${currentReport.key}&usersId=${usersId}`}
                    requestConfig={requestConfig}
                    classBtn="app-btn ant-btn-primary ant-btn-lg mt-10"
                    defaultBtn={true}
                    maxSize={10}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Card>
    </div>
  )
}
