import React, {Component} from 'react'
import '../../../global/global.css'
import { endpoint } from '../../../api/pengaturanUmum'
import { trackPromise } from 'react-promise-tracker';
import {
  Spin,
} from 'antd'
import { UIForm } from '../Crowdfunding/component';

class PengaturanDonorBeasiswa extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      loadingSubmit: false,
      initialData: {}
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    this.setState({
      loading: true
    })
		trackPromise(
      endpoint.getDonorBeasiswa(this.props.token).then(res => {
        let dataTemp = {
          ...res.data.data
        }
        this.setState({
          initialData: dataTemp,
          loading: false
        })
      })
    )
  }

  editData = (data) => {
    this.setState({loadingSubmit: true})
    let body = {
      ...data,
      usersId: this.props.userId
    }
    console.log("di edit ",body)
    trackPromise(
      endpoint.editDonorBeasiswa(this.props.token, body).then((res) => {
        if(res == 'success'){
        }
      })
    )
    this.setState({loadingSubmit: false})
  }

  render(){
    const { loading, initialData, loadingSubmit } = this.state
    return (
      <div className='root'>
        <Spin spinning={loading}>
          <UIForm 
            title={'Donor Beasiswa'}
            onFinish={(e)=>this.editData(e)}
            initialData = {initialData}
            loadingSubmit={loadingSubmit}
            token={this.props.token}
          />
        </Spin>
      </div>
    )
  }
}


export default PengaturanDonorBeasiswa
