import React, { useEffect, useState } from "react";
import {
   Row, Col, Button, Form, Card,
   Input,Typography
} from "antd";
import "../../../global/global.css";
import TextArea from 'antd/lib/input/TextArea';
import UploadComponentValues from "../../../components/uploadComponentValues";
import { trackPromise } from 'react-promise-tracker';
import { endpoint } from '../../../api/pengaturanUmum';
import Title from "antd/lib/typography/Title";
import { RedStar } from '../../../components';

const {Text} = Typography

function FormGaleri(props) {
	const [form] = Form.useForm();
  const [imageDbThumbnail, setImageDbThumbnail] = useState('')
  const [isImageThumbnailChange, setIsImageThumbnailChange] = useState(false)
  const [imageDbQr, setImageDbQr] = useState('')
  const [isImageQrChange, setIsImageQrChange] = useState(false)

  useEffect(() => {
    if(props.initialData){
      setImageDbThumbnail(props.initialData.thumbnailPath)
      setImageDbQr(props.initialData.qrCode)
      let valueTemp = {
        ...props.initialData
      }
      form.setFieldsValue(valueTemp)
    }
  }, [props.initialData])

	const onFinish = async(values) => {
    props.onFinish(values);
	}

	const onFinishFailed = error => {
		console.error(error);
	}

  const changeFileThumbnail = (e) => {
    let valueTemp = form.getFieldsValue()
    setIsImageThumbnailChange(true)
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    trackPromise(
      endpoint.uploadImage(props.token, formData, 'imageSmall?path=dana-pengembangan').then(res => {
        if(res == 'error'){
          setImageDbThumbnail('Failed')
          setIsImageThumbnailChange(false)
          form.setFieldsValue({...valueTemp, fileName: ''})
        }else{
          if(res.fileName){
            setImageDbThumbnail(res.url)
            setIsImageThumbnailChange(false)
            form.setFieldsValue({...valueTemp, fileName: res.fileName})
          }
        }
      })
    )
  }
  const changeFileQr = (e) => {
    let valueTemp = form.getFieldsValue()
    setIsImageQrChange(true)
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    trackPromise(
      endpoint.uploadImage(props.token, formData, 'imageSmall?path=qr').then(res => {
        if(res == 'error'){
          setImageDbQr('Failed')
          setIsImageQrChange(false)
          form.setFieldsValue({...valueTemp, qrCodePath: ''})
        }else{
          if(res.fileName){
            setImageDbQr(res.url)
            setIsImageQrChange(false)
            form.setFieldsValue({...valueTemp, qrCodePath: res.fileName})
          }
        }
      })
    )
  }

  const handleResetForm = () => {
    setImageDbThumbnail(props.initialData.thumbnailPath)
    setImageDbQr(props.initialData.qrCode)
    let valueTemp = {
      ...props.initialData
    }
    form.setFieldsValue(valueTemp)
  }

	return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
    >
    <Card className='bodyCard_style'>
      <Card
        bordered={false}
        style={{minHeight: '300px'}}>
          <Row gutter={[30,30]}>
            <Col span={24}>
              <Title level={4}>{props.title}</Title>
            </Col>
            <Col span={12}>
              <Text className="form-label">Subjudul <RedStar /></Text>
              <Form.Item name='subtitleInd'
                rules={[{required: true,message: 'Subjudul [IND] harus diisi!' }
              ]}>
                <Input placeholder={'Subjudul'}  size="large" className='input_style' maxLength={500}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Subtitle <RedStar /></Text>
              <Form.Item name='subtitleEng'
                rules={[{required: true,message: 'Subjudul [ENG] harus diisi!' }
              ]}>
                <Input placeholder={'Subtitle'}  size="large" className='input_style' maxLength={500}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Deskripsi <RedStar /></Text>
              <Form.Item name='desInd'
                rules={[{required: true,message: 'Deskripsi [IND] harus diisi!' }
              ]}>
                <TextArea placeholder={'Deskripsi'}  size="large" className='input_style' style={{minHeight: 200}}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Description <RedStar /></Text>
              <Form.Item name='descEng'
                rules={[{required: true,message: 'Deskripsi [ENG] harus diisi!' }
              ]}>
                <TextArea placeholder={'Description'}  size="large" className='input_style' style={{minHeight: 200}}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Syarat & Ketentuan <RedStar /></Text>
              <Form.Item name='termsInd'
                rules={[{required: true,message: 'Syarat & Ketentuan [IND] harus diisi!' }
              ]}>
                <TextArea placeholder={'Syarat & Ketentuan'}  size="large" className='input_style' style={{minHeight: 200}}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Terms & Conditions <RedStar /></Text>
              <Form.Item name='termsEng'
                rules={[{required: true,message: 'Syarat & Ketentuan [ENG] harus diisi!' }
              ]}>
                <TextArea placeholder={'Terms & Conditions'}  size="large" className='input_style' style={{minHeight: 200}}/>
              </Form.Item>
            </Col>
            <Col span={12} style={{marginBottom: 8,marginTop: 8}}>
              <Text className="form-label">Thumbnail <RedStar /></Text>
              <Form.Item name='fileName'
                rules={[{required: true,message: 'Thumbnail harus di-upload!' }
              ]}>
                <UploadComponentValues
                  pathName='dana-pengembangan'
                  changeFile={e => changeFileThumbnail(e, false)}
                  dataFile={imageDbThumbnail}
                  loading={isImageThumbnailChange}
                  name={'fileName'}
                  accept={'.jpg,.jpeg,.png'}
                />
              </Form.Item>
            </Col>
            <Col span={12}/>
            <Col span={12} style={{marginBottom: 8,marginTop: 8}}>
              <Text className="form-label">QR Code <RedStar /></Text>
              <Form.Item name='qrCodePath'
                rules={[{required: true,message: 'QR Code harus di-upload!' }
              ]}>
                <UploadComponentValues
                  pathName='qr'
                  changeFile={e => changeFileQr(e, false)}
                  dataFile={imageDbQr}
                  loading={isImageQrChange}
                  name={'qrCodePath'}
                  accept={'.jpg,.jpeg,.png'}
                />
              </Form.Item>
            </Col>
            <Col span={12}/>
            <Col span={12}>
              <Text className="form-label">Tautan Konfirmasi Pembayaran <RedStar /></Text>
              <Form.Item name='linkGform'
                rules={[{required: true,message: 'Tautan Konfirmasi Pembayaran harus diisi!' }
              ]}>
                <Input placeholder={'Tautan Konfirmasi Pembayaran'}  size="large" className='input_style' type='url'/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20]}>
            <Col span={24}>
              <Form.Item
                style={{
                  textAlign: 'right',
                  marginBottom: '0px'
                }}
              >
                <Button
                  type="link"
                  className='resetButton'
                  size={'small'}
                  onClick={() => handleResetForm()}
                  disabled={props.loadingSubmit}
                >
                  Reset
                </Button>
                <Button
                  type="primary"
                  htmlType='submit'
                  className='blueButton'
                  size={'small'}
                  loading={props.loadingSubmit}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
      </Card>
    </Card>
  </Form>
	)
}

export const UIForm = FormGaleri;
