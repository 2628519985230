/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Col, Modal, PageHeader, Row, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { dialog } from '../../../utils/alert';
import { useHistory, useRouteMatch } from 'react-router';
import informasiApi from '../../../api/informasi';
import Text from 'antd/lib/typography/Text';
import { downloadFile } from '../../../utils/download';

import './index.scss';
import { ArrowLeftOutlined, FileTextOutlined } from '@ant-design/icons';import Title from 'antd/lib/typography/Title';
;


export default function DetailPengumuman() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [activeCategory, setActiveCategory] = useState(0);
  const history = useHistory();
  const match = useRouteMatch();
  const idPengumuman = match.params.id;
  const listKey = ['announceImage', 'announceVideo', 'announceFile'];

  useEffect(() => {
    if (idPengumuman) {
      loadData();
    } else {
      history.push('/pengumuman');
    }
  }, []);


  const loadData = async (param) => {
    setIsLoading(true);
    const token = sessionStorage.getItem('token');
    await informasiApi.getDetailPengumuman(token, idPengumuman).then(res => {
      console.log(res.data);
      let data = res.data.data || {};
      data = {
        ...data,
      }
      setData(data);
    }).catch(err => {
      dialog({
        title: 'Error',
        message: err.message || 'Unknown Error',
        icon: 'error'
      });
    })
    setIsLoading(false);
  }

  function CreateMarkup(html) {
    return <div dangerouslySetInnerHTML={{__html: html}} ></div>;
  }

  const handleBack = () => {
    history.push('/pengumuman')
  }

  const handleClick = async (item) => {
    if (item.format === 'Video' || item.format === 'Foto') {
      setShowModal(item);
    } else {
      setIsLoading(true);
      await downloadFile(item.url).then(_ => {}).catch(err => {})
      setIsLoading(false);
    }
  }

  const showThumbNail = () => {
    setShowModal({
      urlVideoEmbed: data.announceThumbnailPath,
      url: data.announceThumbnailUrl,
      "format": data.isThumbnailVideo ? "Video" : "Foto"
    })
  }

  return (
    <div className="root detail-pengumuman">
      <Card className="app-card">
        <Spin spinning={isLoading}>
          <Row style={{padding: '2em 3em'}}>
            <Col span={24}>
              <ValueSet label="Thumbnail" value={
                <div style={{ overflow: 'hidden', cursor: 'pointer' }} onClick={showThumbNail}>
                  <img src={data.announceThumbnailUrl} style={{height: '200px'}} />
                </div>
              } />
              <ValueSet label="Judul Pengumuman" value={data.announceTitleIndVer} />
              <ValueSet label="Kategori Pengumuman" value={data.announceCategoryName} />
              <ValueSet label="Tanggal" value={data.announceDate} />
              <ValueSet label="Isi Konten" value={CreateMarkup(data.announceDescIndVer)} />
            </Col>
            <Col span={24} style={{display: 'flex', justifyContent: 'space-between'}}>
              <Text className={`category-link ${activeCategory === 0 && 'active'}`} onClick={_ => setActiveCategory(0)} level={4}>Gambar</Text>
              <Text className={`category-link ${activeCategory === 1 && 'active'}`} onClick={_ => setActiveCategory(1)} level={4}>Video</Text>
              <Text className={`category-link ${activeCategory === 2 && 'active'}`} onClick={_ => setActiveCategory(2)} level={4}>Lampiran</Text>
            </Col>
            <hr className="app-hr" style={{width: '100%', marginBottom: '2em'}} />
            <Col span={24}>
              <Row gutter={[28, 28]}>
                { data[listKey[activeCategory]] && data[listKey[activeCategory]].length > 0 ?  (data[listKey[activeCategory]] || []).map(item => {
                  const src = activeCategory === 1 ? item.urlVideoImg : activeCategory === 0 ? item.url : '';
                  const text = item.fileName.split('/');
                  return (
                    <Col span={8} xs={24} md={12} lg={8} style={{ cursor: 'pointer' }} onClick={_ => handleClick(item)}>
                      <Image src={src} text={activeCategory === 2 ? text[text.length-1] : ''} />
                      {/* {text[text.length-1]} */}
                    </Col>
                  )
                }) :
                <Col span={24} style={{ textAlign: 'center'}}>
                  {
                    <Title level={4}>{activeCategory == 2 ? 'Lampiran' : activeCategory == 1 ?  'Video' : 'Gambar' } Tidak Tersedia</Title> 
                  }
                </Col>
              }
              </Row>
            </Col>
          </Row>

        </Spin>
      </Card>
      {showModal && (
        <Modal
          visible={showModal}
          centered
          className="card-video"
          footer={null}
          width="75%"
          onCancel={_ => setShowModal(false)}
        >
          {showModal.format === 'Foto' ? (
            <img src={showModal.url} />
          ) : (
            <iframe
              title="Preview"
              style={{ width: '100%', height: '500px' }}
              id="modalVideo"
              src={showModal.urlVideoEmbed}
              frameBorder="0"
              allow="accelerometer;
              autoplay;
              encrypted-media;
              gyroscope;
              picture-in-picture"
              allowFullScreen="allowfullscreen"
            />
          )}
        </Modal>
      )}
    </div>
  )
}


const ValueSet = (props) => {
  return (
    <Row className="value-set">
      <Col span={6} xs={24} md={6} className="value-set-label">{props.label}</Col>
      <Col span={18} xs={24} md={18} className="value-set-value">{props.value}</Col>
    </Row>
  )
}


const Image = (props) => {
  const [src, setSrc] = useState();
  const [errored, setErrored] = useState(false);
  const {
    fallbackSrc,
    alt = '',
    iconStyle = {},
    text,
    ...rest
  } = props;

  useEffect(() => {
    setSrc(props.src)
  }, [props])

  const onError = () => {
    if (!this.state.errored) {
      setErrored(true);
      setSrc(props.fallbackSrc);
    }
  };

  return (
    <div className="detail-pengumuman-thumbnail" title={text || src || alt}>
      <Typography.Paragraph ellipsis={{ rows: 1 }} className="text">{text}</Typography.Paragraph>
      {(errored && !fallbackSrc && !alt) || (!src && !alt) ? (
        <FileTextOutlined
          style={{ fontSize: '3em', margin: 'auto', ...iconStyle }}
        />
      ) : (
        <img
          src={src}
          style={{ textAlign: 'center', verticalAlign: 'center', height: '100%', width: '100%', objectFit: 'cover' }}
          onError={onError}
          {...rest}
          alt={alt}
        />
      )}
    </div>
  );
}
