import { get, put, post } from 'axios'
import config from '../services/config'
import qs from 'querystring'
import { alert } from './index'
const { BASE_URL_LOGIN, TIMEOUT, BASE_URL_NOAUTH } = config

const normalizeQuery = (url) => {
  return qs.stringify(url)
}

const normalizeData = res => {
  let dataTemp = res.data
  let response = {
    data: dataTemp.data.listKEKL,
    totalData: dataTemp.data.totalItem,
    status: 'success',
    rc: dataTemp.rc
  }
  return response
}


const endtpointCeritaKekl = {
  getData(param){
    const urlString = normalizeQuery({
      sort: param.sort,
      size: param.size,
      page: param.page - 1,
      keyword: param.search,
      isEng: 0
    })
    return get(`${BASE_URL_LOGIN}be/api/admin/ceritaKEKL/load?${urlString}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${param.token}`,
      },
      timeout: TIMEOUT
    }).then(res => {
      if(res.data.rc == "00"){
        return normalizeData(res)
      }else{
        alert.showAlert(res.data.message, res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {
        data: [],
        totalData: 0,
        status: 'error',
      }
    })
  },
  getDataDetail(param){
    const urlString = normalizeQuery({
      keklId: param.id,
    })
    return get(`${BASE_URL_LOGIN}be/api/admin/ceritaKEKL/detail?${urlString}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${param.token}`,
      },
      timeout: TIMEOUT
    }).then(response => {
      return response
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {}
    })
  },
  getExport(token){
    return post(`${BASE_URL_LOGIN}be/api/admin/ceritaKEKL/export`, {},{
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }).then(res => {
      if(res.data.rc == "00"){
        // alert.alertCreateEditSuccess(res.data.message)
        return res.data.data.link
      }else{
        alert.alertCreateEditError(res.data.message, 'Data', 'Create', res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return 'error'
    })
  },
  create(token, body) {
    return post(`${BASE_URL_LOGIN}be/api/admin/ceritaKEKL/add`, 
      body,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        timeout: TIMEOUT
      }
    ).then(res => {
      if(res.data.rc == "00"){
        alert.alertCreateEditSuccess(res.data.message)
        return 'success'
      }else{
        alert.alertCreateEditError(res.data.message, 'Data', 'Create', res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Data', 'Create', err.response.status)
      return 'error'
    })
  },
  edit(token, id, body) {
    const urlString = normalizeQuery({
      keklId: id,
    })
    return put(`${BASE_URL_LOGIN}be/api/admin/ceritaKEKL/edit?${urlString}`,
      body,
      {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          timeout: TIMEOUT
      }
    ).then(res => {
      if(res.data.rc == "00"){
        alert.alertCreateEditSuccess(res.data.message)
        return 'success'
      }else{
        alert.alertCreateEditError(res.data.message, 'Data', 'Edit', res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Data', 'Edit', err.response.status)
      return 'error'
    })
  },
  deleteData(token, userId, id) {
    const urlString = normalizeQuery({
      keklId: id,
      userId: userId
    })
    return put(`${BASE_URL_LOGIN}be/api/admin/ceritaKEKL/delete?${urlString}`,
      {},
      {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          timeout: TIMEOUT
      }
    ).then(res => {
      if(res.data.rc == "00"){
        alert.alertCreateEditSuccess(res.data.message)
        return 'success'
      }else{
        alert.alertCreateEditError(res.data.message, 'Data', 'Delete', res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Data', 'Delete', err.response.status)
      return 'error'
    })
  },

}
export { endtpointCeritaKekl }