import React, { Component } from 'react'
import '../../../global/global.css'
// import lov from '../../actions/lov'

import { trackPromise } from 'react-promise-tracker';
import {
  Card,
  Button,
  Row,
  Col,
  Typography,
  Popconfirm
} from 'antd'
import { UItable, UISearch } from '../../../components'
import moment from 'moment';
import { endpointUser } from '../../../api/user';
import { ModalUser } from '../modal';
import { alert } from '../../../api';

const { Text } = Typography
const initialSorting = 'ud_name'
const initialDataValue = {
  name: undefined,
  jenisKelamin: undefined,
  tanggalLahir: undefined,
  alamat: undefined,
  pekerjaan: undefined,
  namaTempatBekerja: undefined,
  jenjangPendidikanTerakhir: undefined,
  namaTempatPendidikanTerakhir: undefined,
  noTelepon: undefined,
  whatsapp: undefined,
  email: undefined,
  username: undefined,
  password: undefined,
  status: true
}
const blackListData = id => {
  const blacklist = [1,2]
  let isStatus = false
  blacklist.map(res => {
    if(res == id){
      isStatus = true
    }
  })
  return isStatus
}

class ListUsers extends Component {
	constructor(props) {
		super(props)
		this.state = {
		initial: '',
		orderBy: initialSorting,
		size: 10,
		page: 1,
		search: '',
		visibleCreate: false,
		initalData: initialDataValue,
		dataId: undefined,
		isEdit: false,
		dataProfil: {
      data: [],
      totalData: 0
    },
		dataLOV: [],
    loadingSubmit: false,
    isStatus: false
		}
	}

	componentDidMount() {
    const {orderBy, size, page, search} = this.state
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: page,
      search: search,
    }
    this.getLov(body)
  }

  getLov = param => {
		trackPromise(
      endpointUser.getLov().then(res => {
        if(res !== 'error'){
          this.setState({
            dataLOV: res
          }, ()=> this.getData(param))
        }
      })
    )
  }

	getData = param => {
		trackPromise(
      endpointUser.getData(param).then(res => {
        this.setState({
          dataProfil: res
        })
      })
    )
	}

	handleSearch = key => {
    const {orderBy, size, page } = this.state
    this.setState({
      search: key,
      page: 1
    })
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: 1,
      search: key,
    }
    this.getData(body)
  }

	handleTableChange = (page, filter, sorter) => {
    const {size, search } = this.state
    const body = {
      token: this.props.token,
      sort: sorter,
      size: size,
      page: page,
      search: search,
    }
    this.setState({
      page: page,
      orderBy: sorter
    }, () => {
      this.getData(body)
    })
  }

	openModal = () => {
    this.setState({
      visibleCreate: true,
      isEdit: false,
      isStatus: false,
      initalData: initialDataValue,
    })
  }

	handleCloseModal = () => {
    this.setState({
      visibleCreate: false,
      isEdit: false
    })
  }


	handleFinish = values => {
    if(values.password == values.confirmPassword || this.state.isEdit){
      this.setState({
        loadingSubmit: true
      })
      let body = {
        token: this.props.token,
        sort: this.state.orderBy,
        size: 10,
        page: 1,
        search: this.state.search,
      }
      let dataTemp = {
        ...values,
        tanggalLahir: moment(values.tanggalLahir).format('YYYY-MM-DD'),
        usersId: this.props.userId,
      }
  
      if (this.state.isEdit) {
        this.handleSubmitEdit(body, dataTemp)
      }else {
        body = {
          token: this.props.token,
          sort: this.state.orderBy,
          size: 10,
          page: 1,
          search: this.state.search,
        }
        this.handleSubmitCreate(body, dataTemp)
      }
    }else{
      alert.alertCreateEditInfo('Password Tidak Sesuai')
    }
  }

	handleSubmitCreate = (body, bodySend) => {
    trackPromise(
      endpointUser.create(this.props.token, bodySend).then(res => {
        if (res === 'success') {
          this.setState({
            page: 1,
            orderBy: initialSorting,
            visibleCreate: false,
            loadingSubmit: false
          }, () => {
            this.getData(body)
          })
        }else{
          this.setState({
            loadingSubmit: false
          })
        }
      })
    )
  }

	handleSubmitEdit = (body, bodySend) => {
    trackPromise(
      endpointUser.edit(this.props.token, this.state.dataId, bodySend).then(res => {
        if (res === 'success') {
          this.setState({
            page: 1,
            orderBy: initialSorting,
            visibleCreate: false,
            loadingSubmit: false
          }, () => {
            this.getData(body)
          })
        }else{
          this.setState({
            loadingSubmit: false
          })
        }
      })
    )
  }

	handleEditClick = data => {
    let isStatus = blackListData(data.usersId)
		this.setState({
      initalData: {
        name: data.name,
        jenisKelamin: data.jenisKelamin,
        tanggalLahir: moment(data.tanggalLahir),
        alamat: data.alamat,
        pekerjaan: data.pekerjaan,
        namaTempatBekerja: data.namaTempatBekerja,
        jenjangPendidikanTerakhir: data.jenjangPendidikanTerakhir,
        namaTempatPendidikanTerakhir: data.namaTempatPendidikanTerakhir,
        noTelepon: data.noTelepon,
        whatsapp: data.whatsapp,
        email: data.email,
        username: data.username,
        password: data.password,
        confirmPassword: data.password,
        status: data.status,
        roleId: data.roleId
      },
      dataId: data.usersId,
      visibleCreate: true,
      isEdit: true,
      isStatus: isStatus
    })
	}

	handleReject = id => {
		const body = {
      token: this.props.token,
      sort: this.state.orderBy,
      size: 10,
      page: 1,
      search: this.state.search,
    }
		trackPromise(
      endpointUser.deleteData(this.props.token, this.props.userId, id).then(res => {
        if (res === 'success') {
          this.setState({
            search: this.state.search || '',
            page: 1,
            orderBy: this.state.orderBy || initialSorting,
            visibleCreate: false
          }, () => {
            this.getData(body)
          })
        }
      })
    )
	}

	render() {

		const {page, visibleCreate, initalData, isEdit, dataProfil, dataLOV, loadingSubmit, search, isStatus } = this.state

    const text = 'Apakah Anda yakin akan menghapus data ini?'

    const columnsTable = [
      {
        title: 'Nama Lengkap',
        dataIndex: 'name',
        key: 'ud_name',
        sorter: true,
        render: name => <Text style={{ color: '#222222', fontWeight: 600, cursor: 'pointer' }}>{name}</Text>
      },
      {
        title: 'Username',
        dataIndex: 'username',
        key: 'u.users_username',
        sorter: true,
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'r.role_name',
        sorter: true,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'is_user_active',
        sorter: true,
        render: status => <Text>{status == true ? 'Active' : status == false ? 'Non-Active' : '' }</Text>
      },
      {
        title: 'Aksi',
        dataIndex: '',
        key: '',
        render: (data) => <>
          <Button
            onClick={() => this.handleEditClick(data)}
            type='link'
            style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600 }}
          >
            Ubah
          </Button>
          {
            !(blackListData(data.usersId)) &&
            <Popconfirm
              placement="bottomRight"
              title={text}
              onConfirm={() => this.handleReject(data.usersId)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type='link'
                style={{ color: '#E22828', cursor: 'pointer', fontWeight: 600 }}
              >
                Hapus
              </Button>
            </Popconfirm>
          }
        </>
      },
    ]

		return(
			<div className='root'>
				<Card className='bodyCard_style'>
					<Row gutter={[16,0]}>
            <Col span={16} xs={12} md={16}>
              <Button onClick={() => this.openModal()} className='addButton'>+ Tambah</Button>
            </Col>
            <Col span={8} xs={24} md={8}>
              <UISearch placeholder='Nama Lengkap' handleSearch={key => this.handleSearch(key)}/>
            </Col>
          </Row>
					<Card 
            bordered={false}
            style={{minHeight: '300px'}}>
            <UItable
              scroll={{x: 800}}
              dataTable={dataProfil.data}
              columns={columnsTable}
              withRows={false}
              page={page}
              totalData={dataProfil.totalData}
              sortDefault=''
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
					<ModalUser
						visible={visibleCreate}
						initialValues={initalData}
						title={ isEdit ? 'Ubah Data User' : 'Tambah Data User'}
						widthDivider='250px'
						close={() => this.handleCloseModal()}
						checkFinish={ values => this.handleFinish(values)}
            dataLOV={dataLOV}
            loadingSubmit={loadingSubmit}
            isEdit={isEdit}
            isStatus={isStatus}
					/>
				</Card>
			</div>
		)
	}
}


export default ListUsers