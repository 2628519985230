import React, { useEffect} from 'react'

import { 
  Modal, 
  Form, 
  Row, 
  Col,
  Typography,
  Input,
  Button
} from 'antd'

import { usePromiseTracker } from "react-promise-tracker";
import { RedStar } from '../../../../components';

const {Title,Text} = Typography


const ModalCreate = props => {

  const { promiseInProgress } = usePromiseTracker();
  const [form] = Form.useForm()
  useEffect(() => {
    if (props.visible === true) {
      form.resetFields();
      form.setFieldsValue(props.initialValues)
    }
  }, [props.visible])

  const handleClose = () => {
    form.resetFields();
    props.close()
  }

  const checkFinish = values => {
    props.checkFinish(values)
  }

  const onFinishFailed = errorInfo => {
  };

  const handleResetForm = () => {
      form.resetFields(); 
  };

  return (
    <Modal 
      visible={props.visible}
      footer={null}
      className='modal_galery'
      width={633}
      centered
      onCancel={() => handleClose()}
      destroyOnClose={true}
    >
      <Row>
        <Col span={24}>
          <div className='headerForm'>
            <Title className='titleModalGalery' level={4}>{props.title}</Title>
          </div>
        </Col>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={values => checkFinish(values)}
            onFinishFailed={onFinishFailed}
            initialValues={props.initialValues}
          >
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Text className="form-label">Kategori Proyek <RedStar /></Text>
                <Form.Item name='value'
                  rules={[{required: true,message: 'Kategori Proyek [IND] harus diisi! ' }
                ]}>
                  <Input placeholder={'Kategori Proyek'} size="large" className='input_style' maxLength={256}/> 
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Project Category <RedStar /></Text>
                <Form.Item name='valueEng'
                  rules={[{required: true,message: 'Kategori Proyek [ENG] harus diisi! ' }
                ]}>
                  <Input placeholder={'Project Category'} size="large" className='input_style' maxLength={256}/> 
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  style={{
                    textAlign: 'right',
                    marginBottom: '0px'
                  }}
                >
                  <Button
                    type="link"
                    className='resetButton'
                    size={'small'}
                    onClick={() => handleResetForm()}
                    disabled={props.loadingSubmit}
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    htmlType='submit'
                    className='blueButton'
                    loading={promiseInProgress}
                    size={'small'}
                    loading={props.loadingSubmit}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

export const ModalForm = ModalCreate