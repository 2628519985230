import React, { Component } from 'react'
import '../../../global/global.css'
// import lov from '../../actions/lov'

import { trackPromise } from 'react-promise-tracker';
import {
  Card,
  Button,
  Row,
  Col,
  Typography,
  Popconfirm,
  Menu,
  Dropdown
} from 'antd'
import { UItable, UISearch } from '../../../components'
import moment from 'moment';
import { ModalDaftarCrowdFunding } from '../modal';
import { endtpointDaftarCrowdFunding } from '../../../api/daftarcrowdfunding';
import { EyeOutlined,EyeInvisibleOutlined } from '@ant-design/icons';

const { Text } = Typography
const initialOrderBy = ''
const initialDataTemp = {
  projectName: undefined,
  projectNameEng: undefined,
  projectCatId: undefined,
  projectStartDate: undefined,
  projectDuration: undefined,
  projectValue: undefined,
  projectFiscalYear: undefined,
  projectDesc: undefined,
  projectDescEng: undefined,
  projectThumbnail: undefined,
  projectContactPerson: undefined,
  projectId: undefined,
  projectCat: undefined,
  projectStatus: undefined,
  isHide: undefined,
  type: 'Hari'
}

const keyActions = [
  {
    key: false,
    label: "Tampilkan",
    icon: <EyeOutlined /> },
  {
    key: true,
    label: "Sembunyikan",
    icon: <EyeInvisibleOutlined /> },
]

class ListDaftarProyekCrowdfunding extends Component {
	constructor(props) {
		super(props)
		this.state = {
		initial: '',
		orderBy: initialOrderBy,
		size: 10,
		page: 1,
		search: '',
		visibleCreate: false,
		initalData: initialDataTemp,
		dataId: undefined,
		isEdit: false,
		dataProfil: {
      data: [],
      totalData: 0
    },
		dataLOV: [],
    dataLOVStatus: [],
    loadingSubmit: false,
    loadingExport: false
		}
	}

	componentDidMount() {
    const {orderBy, size, page, search} = this.state
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: page,
      search: search,
    }
    this.getLov(body)
  }
  getLov = param => {
		trackPromise(
      endtpointDaftarCrowdFunding.getLov().then(res => {
        if(res !== 'error'){
          this.setState({
            dataLOV: res
          }, ()=> this.getLovStatus(param))
        }
      })
    )
  }
  getLovStatus = param => {
		trackPromise(
      endtpointDaftarCrowdFunding.getLovStatus().then(res => {
        if(res !== 'error'){
          this.setState({
            dataLOVStatus: res
          }, ()=> this.getData(param))
        }
      })
    )
  }

	getData = param => {
		trackPromise(
      endtpointDaftarCrowdFunding.getData(param).then(res => {
        this.setState({
          dataProfil: res
        })
      })
    )
	}

	handleSearch = key => {
    const {orderBy, size, page } = this.state
    this.setState({
      search: key,
      page: 1
    })
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: 1,
      search: key,
    }
    this.getData(body)
  }

	handleTableChange = (page, filter, sorter) => {
    const {size, search } = this.state
    const body = {
      token: this.props.token,
      sort: sorter,
      size: size,
      page: page,
      search: search,
    }
    this.setState({
      page: page,
      orderBy: sorter
    }, () => {
      this.getData(body)
    })
  }

	openModal = () => {
    this.setState({
      visibleCreate: true,
      isEdit: false,
      initalData: initialDataTemp,
    })
  }

	handleCloseModal = () => {
    this.setState({
      visibleCreate: false,
      isEdit: false
    })
  }


	handleFinish = values => {
    this.setState({
      loadingSubmit: true
    })
    let body = {
      token: this.props.token,
      sort: this.state.orderBy,
      size: 10,
      page: 1,
      search: this.state.search,
    }
    let dataTemp = {
      ...values,
      projectStartDate: moment(values.projectStartDate).format('DD-MM-YYYY'),
      usersId: this.props.userId,
    }
    delete dataTemp.durasi
    delete dataTemp.type
    console.log('kirim data : ',dataTemp)
    if (this.state.isEdit) {
      this.handleSubmitEdit(body, dataTemp)
    }else {
      body = {
        token: this.props.token,
        sort: this.state.orderBy,
        size: 10,
        page: 1,
        search: this.state.search,
      }
      this.handleSubmitCreate(body, dataTemp)
    }
  }

	handleSubmitCreate = (body, bodySend) => {
    trackPromise(
      endtpointDaftarCrowdFunding.create(this.props.token, bodySend).then(res => {
        if (res === 'success') {
          this.setState({
            search: '',
            page: 1,
            orderBy: initialOrderBy,
            visibleCreate: false,
            loadingSubmit: false
          }, () => {
            this.getData(body)
          })
        }else{
          this.setState({
            loadingSubmit: false
          })
        }
      })
    )
  }

	handleSubmitEdit = (body, bodySend) => {
    trackPromise(
      endtpointDaftarCrowdFunding.edit(this.props.token, this.state.dataId, bodySend).then(res => {
        if (res === 'success') {
          this.setState({
            search: '',
            page: 1,
            orderBy: initialOrderBy,
            visibleCreate: false,
            loadingSubmit: false
          }, () => {
            this.getData(body)
          })
        }else{
          this.setState({
            loadingSubmit: false
          })
        }
      })
    )
  }

	handleEditClick = data => {
		this.setState({
      initalData: {
        ...data,
        projectStartDate: moment(data.projectStartDate, 'DD-MM-YYYY'),
        durasi: data.projectDuration.split(" ",1),
        type: data.projectDuration.split(" ")[1]
      },
      dataId: data.projectId,
      visibleCreate: true,
      isEdit: true
    })
	}

	handleReject = id => {
		const body = {
      token: this.props.token,
      sort: this.state.orderBy,
      size: 10,
      page: 1,
      search: this.state.search,
    }
		trackPromise(
      endtpointDaftarCrowdFunding.deleteData(this.props.token, this.props.userId, id).then(res => {
        if (res === 'success') {
          this.setState({
            search: this.state.search || '',
            page: 1,
            orderBy: this.state.orderBy || initialOrderBy,
            visibleCreate: false
          }, () => {
            this.getData(body)
          })
        }
      })
    )
	}

  handleExport = () => {
    this.setState({
      loadingExport: true
    })
    trackPromise(
      endtpointDaftarCrowdFunding.getExport(this.props.token).then(res => {
        if(res !== 'error'){
          window.open(res)
          this.setState({
            loadingExport: false
          })
        }
      })
    )
  }

	handleRedirectDetail = id => {
    this.props.history.push(`daftar-proyek-crowdfunding-detail/${id}`)
	}

  setVisible = (key,record) => {
    let body = {
      projectId: record.projectId,
      isHide: key == "true" ? true : false,
      usersId: this.props.userId
    }
    trackPromise(
      endtpointDaftarCrowdFunding.setVisible(this.props.token,body).then(res => {
        if(res !== 'error'){
          this.getData({
            token: this.props.token,
            sort: this.state.orderBy,
            size: this.state.size,
            page: this.state.page,
            search: this.state.search,
          })
        }
      })
    )
  }

  actions = (status,record) => {
    return (
      <Menu onClick={e => this.setVisible(e.key,record)}>
        { keyActions.map(({key, label, icon}, index) => {
          return <Menu.Item key={key}>{icon} {label}</Menu.Item>
        })}
      </Menu>
    )
  }
	render() {

		const {page, visibleCreate, initalData, isEdit, dataProfil, dataLOV, loadingSubmit, loadingExport, dataLOVStatus } = this.state

    const text = 'Apakah Anda yakin akan menghapus data ini?'

    const columnsTable = [
      {
        title: 'Nama Proyek',
        dataIndex: 'projectName',
        key: 'name',
        sorter: true,
        render: name => <Text style={{ color: '#222222', fontWeight: 600, cursor: 'pointer' }}>{name}</Text>,
        onCell: record => {
          return{
            onClick: () => {
              this.handleRedirectDetail(record.projectId)
            }
          }
        }
      },
      {
        title: 'Kategori',
        dataIndex: 'projectCat',
        key: 'category',
        sorter: true,
      },
      {
        title: 'Tanggal Mulai',
        dataIndex: 'projectStartDate',
        key: 'date',
        sorter: true,
      },
      {
        title: 'Durasi',
        dataIndex: 'projectDuration',
        key: 'duration',
        sorter: true,
      },
      {
        title: 'Tahun Anggaran',
        dataIndex: 'projectFiscalYear',
        key: 'fiscal_year',
        sorter: true,
      },
      {
        title: 'Status Proyek',
        dataIndex: 'projectStatus',
        key: 'status',
        sorter: true,
        render: status => <div style={{
          height: 'auto',
          padding: '2px 35px',
          color: '#fff',
          textAlign: 'center',
          width: '100%',
          borderRadius: '10px',
          backgroundColor: status == 'Pelaksanaan' ? '#85B6ED' : status == 'Selesai' ? '#2EC57C' : '#FBBC04',
          cursor: 'default' }}>{status}</div>
      },
      {
        title: 'Visibilitas',
        dataIndex: 'isHide',
        key: 'isHide',
        sorter: true,
        render: (status,record) => {
          return (
            <Dropdown overlay={this.actions(status,record)}>
              <a className="ant-dropdown-link" style={{color: '#222'}} onClick={e => e.preventDefault()}>
                {!status ? <div><EyeOutlined/> Tampilkan</div> : <div><EyeInvisibleOutlined/> Sembunyikan</div>}
              </a>
            </Dropdown>
          )
        }
      },
      {
        title: 'Aksi',
        dataIndex: '',
        key: '',
        render: (data) => <>
          <Button
            onClick={() => this.handleEditClick(data)}
            type='link'
            style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600 }}
          >
            Ubah
          </Button>
          <Popconfirm
            placement="bottomRight"
            title={text}
            onConfirm={() => this.handleReject(data.projectId)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type='link'
              style={{ color: '#E22828', cursor: 'pointer', fontWeight: 600 }}
            >
              Hapus
            </Button>
          </Popconfirm>
        </>
      },
    ]

		return(
			<div className='root'>
				<Card className='bodyCard_style'>
					<Row gutter={[16,0]}>
            <Col span={8} xs={12} md={8}>
              <Button onClick={() => this.openModal()} className='addButton'>+ Tambah</Button>
            </Col>
            <Col span={8} xs={12} md={8} style={{textAlign: 'right'}}>
              <Button className='blueButton' loading={loadingExport} onClick={() => this.handleExport()}>Export</Button>
            </Col>
            <Col span={8} xs={24} md={8}>
              <UISearch placeholder='Nama Proyek' handleSearch={key => this.handleSearch(key)} />
            </Col>
          </Row>
					<Card
            bordered={false}
            style={{minHeight: '300px'}}>
            <UItable
              scroll={{ x: 1300 }}
              dataTable={dataProfil.data}
              columns={columnsTable}
              withRows={false}
              page={page}
              totalData={dataProfil.totalData}
              sortDefault=''
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
					<ModalDaftarCrowdFunding
						visible={visibleCreate}
						initialValues={initalData}
						title={ isEdit ? 'Ubah Data Proyek Crowdfunding' : 'Tambah Data  Proyek Crowdfunding'}
						widthDivider='250px'
						close={() => this.handleCloseModal()}
						checkFinish={ values => this.handleFinish(values)}
            dataLOV={dataLOV}
            dataLOVStatus={dataLOVStatus}
            loadingSubmit={loadingSubmit}
            isEdit={isEdit}
            token={this.props.token}
					/>
				</Card>
			</div>
		)
	}
}


export default ListDaftarProyekCrowdfunding
