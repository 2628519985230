const listMenu = {
  'Admin': [
    {
      label: 'Dashboard',
      path: 'dashboard',
    },
    {
      label: 'Yayasan',
      children: [
        {
          label: 'Daftar Profil Pengurus',
          path: 'profil-pengurus',
        },
        {
          label: 'Detail Profil Pengurus',
          path: 'profil-pengurus-detail',
          isHidden: true
        },
        {
          label: 'Daftar Cerita KEKL',
          path: 'daftar-cerita-kekl',
        },
        {
          label: 'Detail Cerita KEKL',
          path: 'daftar-cerita-kekl-detail',
          isHidden: true
        },
        {
          label: 'Jadwal',
          path: 'jadwal',
        },
        {
          label: 'Detail Jadwal',
          path: 'jadwal-detail',
          isHidden: true
        },
        {
          label: 'Galeri',
          path: 'galeri',
        },
        {
          label: 'Detail Galeri',
          path: 'galeri-detail',
          isHidden: true
        },
        {
          label: 'Tambah Data Galeri',
          path: 'galeri-add',
          isHidden: true
        },
        {
          label: 'Ubah Data Galeri',
          path: 'galeri-edit',
          isHidden: true
        },
      ]
    },
    {
      label: 'Informasi',
      children: [
        {
          label: 'Pendirian s.d. Sebelum Tahun 2017',
          path: 'pendirian-sebelum',
        },
        {
          label: 'Detail Pendirian s.d. Sebelum Tahun 2017',
          path: 'pendirian-detail',
          isHidden: true
        },
        {
          label: 'Tahun 2017 s.d. Sekarang',
          path: 'pendirian-setelah',
        },
        {
          label: 'Detail Tahun 2017 s.d. Sekarang',
          path: 'pendirian-detail-setelah',
          isHidden: true
        },
        {
          label: 'Daftar Persetujuan Informasi',
          path: 'daftar-persetujuan-informasi',
        },
        {
          label: 'Detail Daftar Persetujuan Informasi',
          path: 'daftar-persetujuan-informasi-detail',
          isHidden: true
        },
        {
          label: 'Daftar Proyek Langkah ke Depan',
          path: 'daftar-proyek-langkah-ke-depan',
        },
        {
          label: 'Detail Proyek Langkah ke Depan',
          path: 'daftar-proyek-langkah-ke-depan-detail',
          isHidden: true
        },
        {
          label: 'Pengumuman',
          path: 'pengumuman',
        },
        {
          label: 'Tambah Data Pengumuman',
          path: 'tambah-pengumuman',
          isHidden: true
        },
        {
          label: 'Ubah Data Pengumuman',
          path: 'edit-pengumuman',
          isHidden: true
        },
        {
          label: 'Detail Pengumuman',
          path: 'detail-pengumuman',
          isHidden: true
        }
      ]
    },
    {
      label: 'Dana Pengembangan',
      children: [
        {
          label: 'Daftar Proyek Crowdfunding',
          path: 'daftar-proyek-crowdfunding',
        },
        {
          label: 'Detail Proyek Crowdfunding',
          path: 'daftar-proyek-crowdfunding-detail',
          isHidden: true
        },
        {
          label: 'Daftar Kategori Donor Beasiswa',
          path: 'daftar-kategori-donor-beasiswa',
        },
        {
          label: 'Detail Kategori Donor Beasiswa',
          path: 'daftar-kategori-donor-beasiswa-detail',
          isHidden: true
        },
        {
          label: 'Daftar Penerima Beasiswa',
          path: 'daftar-penerima-beasiswa',
        },
      ]
    },
    {
      label: 'Master',
      children: [
        {
          label: 'Kategori Pengumuman',
          path: 'master-kategori-pengumuman',
        },
        {
          label: 'Kategori Proyek',
          path: 'master-kategori-proyek',
        },
        {
          label: 'Status Proyek',
          path: 'master-status-proyek',
        },
        {
          label: 'User',
          path: 'user',
        },
      ]
    },
    {
      label: 'Pengaturan Umum',
      children: [
        {
          label: 'Homepage Yayasan',
          path: 'pengaturan-homepage-yayasan',
        },
        {
          label: 'Tentang Kami',
          path: 'pengaturan-tentang-kami',
        },
        {
          label: 'Cerita KEKL',
          path: 'pengaturan-cerita-kekl',
        },
        {
          label: 'Informasi',
          path: 'pengaturan-informasi',
        },
        {
          label: ' Homepage Dana Pengembangan',
          path: 'pengaturan-homepage-dana-pengembangan',
        },
        {
          label: 'Crowdfunding',
          path: 'pengaturan-crowdfunding',
        },
        {
          label: 'Donor Beasiswa',
          path: 'pengaturan-donor-beasiswa',
        },
        {
          label: 'Donasi Sukarela',
          path: 'pengaturan-donasi-sukarela',
        },
        {
          label: 'Header & Footer',
          path: 'header-footer',
        },
        {
          label: 'FAQ',
          path: 'faq',
        },
        {
          label: 'Detail FAQ',
          path: 'faq-detail',
          isHidden: true
        }
      ]
    },
  ],
  'Admin Keuangan': [
    {
      label: 'Crowdfunding',
      path: 'crowd-funding',
    },
    {
      label: 'Donor Beasiswa',
      path: 'donor-beasiswa',
    },
    {
      label: 'Donasi Sukarela',
      path: 'donasi-sukarela',
    },
    {
      label: 'Laporan',
      path: 'laporan',
    },
  ]
};

const getListMenuByRole = () => {
  const currentRole = sessionStorage.getItem('userRole');
  return listMenu[currentRole] || [];
}

export {listMenu, getListMenuByRole};
