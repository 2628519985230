import React, { useEffect, useState } from "react";
import {
   Row, Col, Button, Form, Card,
   Input,
} from "antd";
import "../../../global/global.css";
import TextArea from 'antd/lib/input/TextArea';
import UploadComponentValues from "../../../components/uploadComponentValues";
import { trackPromise } from 'react-promise-tracker';
import { endpoint } from '../../../api/pengaturanUmum';
import Title from "antd/lib/typography/Title";
import { ListUploadComponent } from "./listUpload";
import Text from "antd/lib/typography/Text";
import { RedStar } from '../../../components';

function FormGaleri(props) {
	const [form] = Form.useForm();
  const [imageDbThumbnail, setImageDbThumbnail] = useState('')
  const [isImageThumbnailChange, setIsImageThumbnailChange] = useState(false)
  const [listImageBanner, setListImageBanner] = useState([])
  const [isBannerValidasi, setIsBannerValidasi] = useState(false)

  useEffect(() => {
    if(props.initialData){
      setImageDbThumbnail(props.initialData.thumbnailPath)
      setListImageBanner(mappingBaner(props.initialData.banner))
      let valueTemp = {
        ...props.initialData
      }
      form.setFieldsValue(valueTemp)
    }
  }, [props.initialData])

  const mappingBaner = (data) => {
    let dataTemp = []
    if(data){
      data.map(res => {
        dataTemp.push({
          fileName: res.bannerName,
          url: res.bannerPath,
          isUpdate: false,
          record: res
        })
      })
    }
    return dataTemp
  }

	const onFinish = async(values) => {
    let dataTemp = values
    let bannerTemp = []
    let isTrigerNull = false
    listImageBanner.map(res => {
      if(res.record){
        bannerTemp.push(res.record)
      }else{
        if(res.url == 'failed' || res.url == '' || res.url == 'Failed'){
          isTrigerNull = true
        }else{
          bannerTemp.push({
            bannerPath: res.url,
            bannerName: res.fileName,
            bannerId: res.id
          })
        }
      }
    })
    if(isTrigerNull || listImageBanner.length == 0){
      setIsBannerValidasi(true)
    }else{
      dataTemp = {
        ...values,
        banner: bannerTemp
      }
      props.onFinish(dataTemp);
    }
	}

	const onFinishFailed = error => {
		console.error(error);
	}

  const changeFileThumbnail = (e) => {
    let valueTemp = form.getFieldsValue()
    setIsImageThumbnailChange(true)
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    trackPromise(
      endpoint.uploadImage(props.token, formData, 'imageSmall?path=dana-pengembangan').then(res => {
        if(res == 'error'){
          setImageDbThumbnail('Failed')
          setIsImageThumbnailChange(false)
          form.setFieldsValue({...valueTemp, fileName: ''})
        }else{
          if(res.fileName){
            setImageDbThumbnail(res.url)
            setIsImageThumbnailChange(false)
            form.setFieldsValue({...valueTemp, fileName: res.fileName})
          }
        }
      })
    )
  }

  const handleResetForm = () => {
    setIsBannerValidasi(false)
    setImageDbThumbnail(props.initialData.thumbnailPath)
    setListImageBanner(mappingBaner(props.initialData.banner))
    let valueTemp = {
      ...props.initialData
    }
    form.setFieldsValue(valueTemp)
  }

	return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
    >
    <Card className='bodyCard_style'>
      <Card
        bordered={false}
        style={{minHeight: '300px'}}>
          <Row gutter={[30,30]}>
            <Col span={24}>
              <Title level={4}>{props.title}</Title>
            </Col>
            <Col span={12}>
              <Text className="form-label">Subjudul <RedStar /></Text>
              <Form.Item name='subtitleInd'
                rules={[{required: true,message: 'Subjudul [IND] harus diisi!' }
              ]}>
                <Input placeholder={'Subjudul'}  size="large" className='input_style' maxLength={500}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Subtitle <RedStar /></Text>
              <Form.Item name='subtitleEng'
                rules={[{required: true,message: 'Subjudul [ENG] harus diisi!' }
              ]}>
                <Input placeholder={'Subtitle'}  size="large" className='input_style' maxLength={500}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Deskripsi <RedStar /></Text>
              <Form.Item name='descInd'
                rules={[{required: true,message: 'Deskripsi [IND] harus diisi!' }
              ]}>
                <TextArea placeholder={'Deskripsi'}  size="large" className='input_style' style={{minHeight: 200}} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Description <RedStar /></Text>
              <Form.Item name='descEng'
                rules={[{required: true,message: 'Deskripsi [ENG] harus diisi!' }
              ]}>
                <TextArea placeholder={'Description'}  size="large" className='input_style' style={{minHeight: 200}} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Title level={4}>{"Profil Pengurus"}</Title>
            </Col>
            <Col span={12}>
              <Text className="form-label">Subjudul <RedStar /></Text>
              <Form.Item name='subtitleManagementInd'
                rules={[{required: true,message: 'Subjudul [IND] harus diisi!' }
              ]}>
                <Input placeholder={'Subjudul'}  size="large" className='input_style' maxLength={500}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Subtitle <RedStar /></Text>
              <Form.Item name='subtitleManagementEng'
                rules={[{required: true,message: 'Subjudul [ENG] harus diisi!' }
              ]}>
                <Input placeholder={'Subtitle'}  size="large" className='input_style' maxLength={500}/>
              </Form.Item>
            </Col>
            <Col span={12} style={{marginBottom: 8,marginTop: 8}}>
              <Text className="form-label">Thumbnail <RedStar /></Text>
              <Form.Item name='fileName'
                rules={[{required: true,message: 'Thumbnail harus di-upload!' }
              ]}>
                <UploadComponentValues
                  pathName='dana-pengembangan'
                  changeFile={e => changeFileThumbnail(e, false)}
                  dataFile={imageDbThumbnail}
                  loading={isImageThumbnailChange}
                  name={'fileName'}
                  accept=".jpg,.jpeg,.png"
                />
              </Form.Item>
            </Col>
            <Col span={12}/>
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <Text>Banner <RedStar/></Text>
                </Col>
                {
                  isBannerValidasi &&
                  <Col span={24}>
                    <Text style={{color: '#f00'}}>Banner harus di-upload!</Text>
                  </Col>
                }
                <Col span={24}>
                  <ListUploadComponent
                    initialData={listImageBanner}
                    updateList={e => {
                      setIsBannerValidasi(false)
                      setListImageBanner(e)
                    }}
                    token={props.token}
                    lengthUpload={3}
                    path='banner'
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[20]}>
            <Col span={24}>
              <Form.Item
                style={{
                  textAlign: 'right',
                  marginBottom: '0px'
                }}
              >
                <Button
                  type="link"
                  className='resetButton'
                  size={'small'}
                  onClick={() => handleResetForm()}
                  disabled={props.loadingSubmit}
                >
                  Reset
                </Button>
                <Button
                  type="primary"
                  htmlType='submit'
                  className='blueButton'
                  size={'small'}
                  loading={props.loadingSubmit}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
      </Card>
    </Card>
  </Form>
	)
}

export const UIForm = FormGaleri;
