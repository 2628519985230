import { Button, Card, Col, Form, Input, PageHeader, Radio, Row, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { RedStar } from '../../../components';
import { confirmDialog, dialog } from '../../../utils/alert';
import { useHistory, useRouteMatch } from 'react-router';
import { useForm } from 'antd/lib/form/Form';
import informasiApi from '../../../api/informasi';
import Text from 'antd/lib/typography/Text';
import TextFormatter from '../../../components/text-formatter';
import Title from 'antd/lib/typography/Title';

import './index.scss';
import { ArrowLeftOutlined, CloseOutlined, MinusCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import UploadComponent from '../../../components/uploadComponent/upload-component';
import UploadComponentValues from '../../../components/uploadComponentValues';
import informasi from '../../../api/informasi';
import config from '../../../services/config'
const { BASE_URL_LOGIN, TIMEOUT } = config


export default function TambahPengumuman() {
  const [data, setData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initValue, setInitValue] = useState({});
  const [categories, setCategories] = useState([]);
  const [value, setValue] = useState({});
  const [form] = useForm();
  const history = useHistory();
  const match = useRouteMatch();
  const idPengumuman = match.params.id;

  useEffect(() => {
    if (idPengumuman) {
      loadData();
    } else {

      form.setFieldsValue({
        announceImage: [''],
        announceVideo: [''],
        announceFile: [''],
        thumbnailType: false,
      });
    }
    getCategory();
  }, []);

  const mockFile = (url, id) => {
    return {
      "fileName": url,
      id: id,
      "fileList": [
        {
          "uid": "rc-upload-1619664768573-14",
          "lastModified": 1615663022674,
          "name": url,
          "size": 1044995,
          "type": "image/png",
          "percent": 0
        }
      ],
    }
  }

  const getCategory = async () => {
    setIsLoading(true);
    const token = sessionStorage.getItem('token');
    await informasi.getLovKategoriPengumuman(token).then(res => {
      console.log(res.data);
      setCategories(res.data.data);
    }).catch(err => {
      dialog({
        icon: 'error',
        title: err ? err.message : 'Unknown error',
      })
    })
    setIsLoading(false);
  }

  const loadData = async (param) => {
    setIsLoading(true);
    const token = sessionStorage.getItem('token');
    await informasiApi.getDetailPengumuman(token, idPengumuman).then(res => {
      console.log(res.data);
      const data = res.data.data || {};
      let existedData = {
        ...data,
        announceImage: data.announceImage.map(e => (mockFile(e.fileName, e.amId))),
        announceThumbnailPath: data.isThumbnailVideo ? data.announceThumbnailPath : mockFile(data.announceThumbnailPath),
        announceVideo: data.announceVideo.map(e => ({url: e.fileName, id: e.amId})),
        announceFile: data.announceFile.map(e => (mockFile(e.fileName, e.fileId))),
        announceCatId: data.announceCategoryId,
        thumbnailType: data.isThumbnailVideo,
      }
      setInitValue(existedData);
      setValue(existedData);
      form.setFieldsValue({
        ...existedData
      });
    }).catch(err => {
      dialog({
        title: 'Error',
        message: err.message || 'Unknown Error',
        icon: 'error'
      });
    })
    setIsLoading(false);
  }

  const handleValueChange = (ev) => {
    if ('thumbnailType' in ev) {
      form.setFieldsValue({announceThumbnailPath: ''});
    }
    setValue(form.getFieldsValue());
  }

  const handleBack = () => {
    history.push('/pengumuman')
  }

  const onReset = () => {
    form.resetFields();
  }

  const handleSubmit = () => {
    const confirmMessage = idPengumuman ? {
      title: 'Ubah Data Pengumuman',
      text: 'Apakah Anda yakin akan mengubah data ini?',
      icon: 'warning',
    } : {
      title: 'Tambah Data Pengumuman',
      text: 'Pastikan data Pengumuman sudah sesuai!',
      icon: 'info',
    }
    form.validateFields().then(_ => {
      confirmDialog(confirmMessage).then(_ => {
        let body = form.getFieldsValue();
        const usersId = sessionStorage.getItem('userId');
        body = {
          ...body,
          usersId,
          announceThumbnailPath: body.thumbnailType ? body.announceThumbnailPath : body.announceThumbnailPath.fileName,
        }

        body.announceImage = body.announceImage.map(e => ({url: e.fileName, amId: e.id}));
        body.announceFile = body.announceFile.map(e => ({url: e.fileName, fileId: e.id}));
        body.announceVideo = body.announceVideo.map(e => ({url: e.url, amId: e.id}));
        console.log(body);
        delete body.thumbnailType;
        if (idPengumuman) {
          updatePengumuman(body)
        } else {
          simpanPengumuman(body);
        }
      }).catch(_ => {})
    }).catch(err => {

    })
  }

  const simpanPengumuman = async (body) => {
    setIsLoading(true);
    const token = sessionStorage.getItem('token')
    try {
      const res = await informasiApi.tambahPengumuman(token, body);
      if (!res.data || res.data.responseType !== 'Success') { throw res.data; }
      dialog({
        icon: 'success',
        title: 'Data Pengumuman Berhasil Ditambah',
      });
      history.push('/pengumuman');
    } catch (err) {
      dialog({
        icon: 'error',
        title: err.message || '',
      })
    }
    setIsLoading(false);
  }

  const updatePengumuman = async (body) => {
    setIsLoading(true);
    const token = sessionStorage.getItem('token')
    try {
      const res = await informasiApi.updatePengumuman(token, body, idPengumuman);
      if (!res.data || res.data.responseType !== 'Success') { throw res.data; }
      dialog({
        icon: 'success',
        title: 'Data Pengumuman Berhasil Diubah',
      });
      history.push('/pengumuman');
    } catch (err) {
      dialog({
        icon: 'error',
        title: err.message || '',
      })
    }
    setIsLoading(false);
  }


  return (
    <div className="root form-pengumuman">
      {/* <PageHeader
        backIcon={<ArrowLeftOutlined />}
        style={{ marginLeft: '-20px', paddingTop: '0px' }}
        onBack={handleBack}
        title={'Detail Pengumuman'}
      /> */}
      <Card className="app-card">
        <Spin spinning={isLoading}>
          <Form form={form} onValuesChange={handleValueChange}>
            <Row gutter={28}>
              {/* <Col span={24}>
                <Title level={4}>Tambah Pengumuman</Title>
              </Col> */}
              <Col span={12}>
                <Text className="form-label">Tipe Thumbnail <RedStar /></Text>
                <Form.Item name="thumbnailType" style={{marginBottom: '.5em'}}>
                  <Radio.Group>
                    <Radio value={false}>Gambar</Radio>
                    <Radio value={true}>Video</Radio>
                  </Radio.Group>
                </Form.Item>

                {value.thumbnailType ? (
                  <Form.Item
                    name="announceThumbnailPath"
                    rules={[{required: 'true', message: 'URL Video Thumbnail harus diisi!'}]}
                  >
                    <Input placeholder="URL Video" />
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="announceThumbnailPath"
                    rules={[{required: 'true', message: 'File Gambar Thumbnail harus di-upload!'}]}
                  >
                    <UploadComponent
                      fluidBtn
                      url={BASE_URL_LOGIN+'noAuth/be/api/yayasanLoyola/upload/imageSmall?path=announcement/thumbnail'}
                      accept=".jpg,.jpeg,.png"
                    />
                  </Form.Item>
                )}

              </Col>
              <Col span={24}>
                <Text className="form-label">Kategori Pengumuman <RedStar /></Text>
                <Form.Item
                  name="announceCatId"
                  rules={[{required: 'true', message: 'Kategori Pengumuman harus diisi!'}]}
                >
                  <Select placeholder="Kategori Pengumuman">
                    {categories.map((category, i) => (
                      <Select.Option key={`category-${i}`} value={category.key}>{category.value}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Judul Pengumuman <RedStar /></Text>
                <Form.Item
                  name="announceTitleIndVer"
                  rules={[{required: 'true', message: 'Judul Pengumuman (IND) harus diisi!'}]}
                >
                  <Input placeholder="Judul Pengumuman" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Announcement Title <RedStar /></Text>
                <Form.Item
                  name="announceTitleEngVer"
                  rules={[{required: 'true', message: 'Judul Pengumuman (ENG) harus diisi!'}]}
                >
                  <Input placeholder="Announcement Title" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Isi Konten <RedStar /></Text>
                <Form.Item
                  name="announceDescIndVer"
                  rules={[{required: 'true', message: 'Isi Konten (IND) harus diisi!'}]}
                >
                  <TextFormatter initValue={initValue.announceDescIndVer} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Content <RedStar /></Text>
                <Form.Item
                  name="announceDescEngVer"
                  rules={[{required: 'true', message: 'Isi Konten (ENG) harus diisi!'}]}
                >
                  <TextFormatter initValue={initValue.announceDescEngVer} />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Title level={4}>Gambar</Title>
                <hr className="app-hr" />
                <Form.List name="announceImage">
                  {(fields, {add, remove}) => (
                    <>
                      {fields.map((field, i) => {
                        return (
                          <Row key={`gambar-${i}`} style={{display: 'flex', justifyContent: 'center', marginBottom: '1em'}}>
                            <Form.Item
                              name={field.name}
                              style={{marginBottom: '0em', width: 'calc(100% - 30px)'}}
                              // rules={[{required: 'true', message: 'File Gambar harus di-upload!'}]}
                            >
                              <UploadComponent fluidBtn url={BASE_URL_LOGIN+'noAuth/be/api/yayasanLoyola/upload/imageSmall?path=announcement/img'} />
                            </Form.Item>
                            <div style={{width: '30px', lineHeight: '34px'}}>
                              {i !== 0 &&  <CloseOutlined onClick={_ => remove(field.name)} style={{fontSize: '1.1em', color: '#ff4d4f', marginLeft: '.75em'}} /> }
                            </div>
                          </Row>
                        )
                      })}
                      <Button onClick={_ => add('')} type="link" className="app-btn" style={{width: '100%'}} disabled={(value.announceImage || []).length >= 10}>
                        <PlusOutlined /> Tambah Gambar
                      </Button>
                    </>
                  )}
                </Form.List>
              </Col>
              <Col span={8}>
                <Title level={4}>Video</Title>
                <hr className="app-hr" />
                <Form.List name="announceVideo">
                  {(fields, {add, remove}) => (
                    <>
                      {fields.map((field, i) => {
                        return (
                          <Row key={`video-${i}`} style={{display: 'flex', justifyContent: 'center', marginBottom: '1em'}}>
                            <Form.Item
                              name={[field.name, 'url']}
                              style={{marginBottom: '0em', width: 'calc(100% - 30px)'}}
                              rules={[
                              { 
                                pattern: /^http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/ , 
                                message: 'Harap masukkan URL Video YouTube yang valid!' 
                              }]}
                            >
                              <Input fluidBtn placeholder="URL Video" />
                            </Form.Item>
                            <div style={{width: '30px', lineHeight: '34px'}}>
                              {i !== 0 &&  <CloseOutlined onClick={_ => remove(field.name)} style={{fontSize: '1.1em', color: '#ff4d4f', marginLeft: '.75em'}} /> }
                            </div>
                          </Row>
                        )
                      })}
                      <Button onClick={_ => add('')} type="link" className="app-btn" style={{width: '100%'}} disabled={(value.announceVideo || []).length >= 10}>
                        <PlusOutlined /> Tambah Video
                      </Button>
                    </>
                  )}
                </Form.List>
              </Col>
              <Col span={8}>
                <Title level={4}>Lampiran</Title>
                <hr className="app-hr" />
                <Form.List name="announceFile">
                  {(fields, {add, remove}) => (
                    <>
                      {fields.map((field, i) => {
                        return (
                          <Row key={`lampiran-${i}`} style={{display: 'flex', justifyContent: 'center', marginBottom: '1em'}}>
                            <Form.Item
                              name={field.name}
                              style={{marginBottom: '0em', width: 'calc(100% - 30px)'}}
                              // rules={[{required: 'true', message: 'File Lampiran harus di-upload!'}]}
                            >
                              <UploadComponent
                                fluidBtn
                                url={BASE_URL_LOGIN+'noAuth/be/api/yayasanLoyola/upload/other?path=announcement/file'}
                                accept=".pdf,.xls,.doc,.xlsx,.docx,.ppt,.pptx"
                              />
                            </Form.Item>
                            <div style={{width: '30px', lineHeight: '34px'}}>
                              {i !== 0 &&  <CloseOutlined onClick={_ => remove(field.name)} style={{fontSize: '1.1em', color: '#ff4d4f', marginLeft: '.75em'}} /> }
                            </div>
                          </Row>
                        )
                      })}
                      <Button onClick={_ => add('')} type="link" className="app-btn" style={{width: '100%'}} disabled={(value.announceFile || []).length >= 10}>
                        <PlusOutlined /> Tambah Lampiran
                      </Button>
                    </>
                  )}
                </Form.List>
              </Col>
              <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '4em' }}>
                <Button className="app-btn" size="large" type="link" style={{marginLeft: '1em'}} onClick={onReset}>Reset</Button>
                <Button className="app-btn" size="large" style={{marginLeft: '1em'}} type="primary" onClick={handleSubmit} >Submit</Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Card>
    </div>
  )
}
