import React, { Component } from "react";
import 'antd/dist/antd.css';
import './global/global.css';
import './styles/index.scss';
import 'react-vis/dist/style.css';
import { Layout } from 'antd'
import Routes from './Routes'

export default class App extends Component {
  render() {
    return (
      <Layout className='container_App'>
        <Routes />
      </Layout>
    );
  }
}
