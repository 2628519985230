import React, { useEffect, useState } from "react";
import {
   Row, Col, Button, Form, Card,
   Input,
} from "antd";
import "../../../global/global.css";
import TextArea from 'antd/lib/input/TextArea';
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";
import { RedStar } from '../../../components';

function FormGaleri(props) {
	const [form] = Form.useForm();

  useEffect(() => {
    if(props.initialData){
      form.setFieldsValue(props.initialData)
    }
  }, [props.initialData])

	const onFinish = async(values) => {
    props.onFinish(values);
	}

	const onFinishFailed = error => {
		console.error(error);
	}
  const handleResetForm = () => {
    form.setFieldsValue(props.initialData)
  }

  const ItemForm = (props) => {
    return (
      <Row gutter={[30,30]}>
        <Col span={24}>
          <Title level={4}>{props.title}</Title>
        </Col>
        <Col span={12}>
          <Text className="form-label">Subjudul <RedStar /></Text>
          <Form.Item name={[props.parent,'subtitleInd']}
            rules={[{required: true,message: 'Subjudul [IND] harus diisi!' }
          ]}>
            <Input placeholder={'Subjudul'}  size="large" className='input_style' maxLength={500}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Text className="form-label">Subtitle <RedStar /></Text>
          <Form.Item name={[props.parent,'subtitleEng']}
            rules={[{required: true,message: ' Subjudul [ENG] harus diisi!' }
          ]}>
            <Input placeholder={'Subtitle'}  size="large" className='input_style' maxLength={500}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Text className="form-label">Deskripsi <RedStar /></Text>
          <Form.Item name={[props.parent,'desInd']}
            rules={[{required: true,message: 'Deskripsi [IND] harus diisi!' }
          ]}>
            <TextArea placeholder={'Deskripsi'}  size="large" className='input_style' style={{minHeight: 200}}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Text className="form-label">Description <RedStar /></Text>
          <Form.Item name={[props.parent,'desEng']}
            rules={[{required: true,message: 'Deskripsi [ENG] harus diisi!' }
          ]}>
            <TextArea placeholder={'Description'}  size="large" className='input_style' style={{minHeight: 200}}/>
          </Form.Item>
        </Col>
      </Row>
    )
  }

	return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
    >
    <Card className='bodyCard_style'>
      <Card
        bordered={false}
        style={{minHeight: '300px'}}>
          <ItemForm
            parent={'sebelum2017'}
            title={'Pendirian s.d. Sebelum Tahun 2017'}
          />
          <ItemForm
            parent={'sejak2017'}
            title={'Tahun 2017 s.d. Sekarang'}
          />
          <ItemForm
            parent={'langkahKedepan'}
            title={'Langkah ke Depan'}
          />
          <Row gutter={[20]}>
            <Col span={24}>
              <Form.Item
                style={{
                  textAlign: 'right',
                  marginBottom: '0px'
                }}
              >
                <Button
                  type="link"
                  className='resetButton'
                  size={'small'}
                  onClick={() => handleResetForm()}
                  disabled={props.loadingSubmit}
                >
                  Reset
                </Button>
                <Button
                  type="primary"
                  htmlType='submit'
                  className='blueButton'
                  size={'small'}
                  loading={props.loadingSubmit}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
      </Card>
    </Card>
  </Form>
	)
}

export const UIForm = FormGaleri;
