import React, { useState } from 'react'
import { Button, Col, DatePicker, Form, Input, Modal, Radio, Row, Spin } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import { RedStar } from '../../../components';
import MoneyInput from '../../../components/money-input';
import moment from 'moment';
import { confirmDialog } from '../../../utils/alert';

const pendidikan = [
  { label: 'SD', key: '' },
  { label: 'SMP', key: '' },
  { label: 'SMA/SMK', key: '' },
  { label: 'D3', key: '' },
  { label: 'D4', key: '' },
  { label: 'S1', key: '' },
  { label: 'S2', key: '' },
  { label: 'S3', key: '' },
];

const dummy = {
  "udName": "Jackie Brown",
  "udGender": 2,
  "udDateOfBirth": moment("2021-04-04T01:59:33.237Z"),
  "date": moment("2021-04-04T01:59:33.237Z"),
  "udAddress": "jalan washington",
  "udProfession": "Pramugari",
  "udInstitution": "Garuda ID",
  "udLastEducation": 1,
  "udPhone": "412312",
  "udWa": "21312",
  "usersEmail": "jackiebrown@gmail.com",
  "usersUsername": "jackiebrown",
  "usersPassword": "dawdaweffsf",
  "confirmPassword": "dawdaweffsf",
  "isUmum": true,
  "nominal": 1,
}

export default function ModalCreateDonatur(props) {
  const {visible, onCancel, title = 'Tambah Data Donatur', onAddDonatur} = props;
  const [form] = Form.useForm();
  const [value, setValue] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (ev) => {
    if ('usersPassword' in ev) {
      form.setFieldsValue({confirmPassword: null});
    }
    setValue(form.getFieldsValue())
  }

  const handleSubmit = () => {
    form.validateFields().then(_ => {
      confirmDialog({
        title: 'Tambah Data Donatur',
        text: 'Pastikan data Donatur dan Nominal Donasi sudah sesuai!'
      }).then(async _ => {
        setIsLoading(true);
        const data = form.getFieldsValue();
        delete data.confirmPassword;
        await onAddDonatur(data).then(_ => {
          resetForm();
          onCancel();
        }).catch(_ => {});
        setIsLoading(false);
      }).catch(_ => {});
    }).catch(_ => {});

  }

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  }

  const resetForm = () => {
    form.resetFields();
  }

  const autoFill = () => {
    form.setFieldsValue(dummy);
  }

  const validatePwd = (rule, val) => {
    return new Promise((resolve, reject) => {
      if (val && val !== value.usersPassword) {
        reject('Password tidak sesuai');
      } else { resolve(); }
    })
  };

  const validateNominal = (rule, val) => {
    return new Promise((resolve, reject) => {
      if ((val || val === 0) && val <= 0) {
        reject('Nominal Donasi harus lebih dari Rp0,00!');
      } else { resolve(); }
    })
  };


  return (
    <Modal
      className="app-modal"
      width={800}
      footer={null}
      visible={visible}
      onCancel={onCancel}
    >
      <Title level={4}>{title}</Title>
      <hr className="app-hr" />
      <Spin spinning={isLoading}>
        <Form form={form} onValuesChange={handleChange}>
          <Row gutter={14}>
            <Col span={24}>
              <Text className="form-label">Nama Lengkap <RedStar /></Text>
              <Form.Item
                rules={[{required: true, message: 'Nama Lengkap harus diisi!'}]}
                name="udName">
                <Input placeholder="Nama Lengkap" maxLength={256} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Jenis Kelamin <RedStar /></Text>
              <Form.Item
                rules={[{required: true, message: 'Jenis Kelamin harus diisi!'}]}
                name="udGender">
                <Radio.Group>
                  <Radio value={1}>Laki-Laki</Radio>
                  <Radio value={2}>Perempuan</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Tanggal Lahir <RedStar /></Text>
              <Form.Item
                rules={[{required: true, message: 'Tanggal Lahir harus diisi!'}]}
                name="udDateOfBirth">
                <DatePicker placeholder="Tanggal Lahir" style={{width: '100%'}} disabledDate={disabledDate} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Text className="form-label">Alamat </Text>
              <Form.Item name="udAddress">
                <TextArea placeholder="Alamat" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Text className="form-label">Pekerjaan </Text>
              <Form.Item name="udProfession">
                <Input placeholder="Pekerjaan" maxLength={256} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Text className="form-label">Nama Institusi Tempat Bekerja </Text>
              <Form.Item name="udInstitution">
                <Input placeholder="Nama Institusi Tempat Bekerja" maxLength={500} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Text className="form-label">Jenjang Pendidikan Terakhir </Text>
              <Form.Item name="udLastEducation">
                <Radio.Group style={{ width: '100%' }}>
                  <Row gutter={[10, 10]}>
                    {pendidikan.map((e, i) => (
                      <Col><Radio value={i}>{e.label}</Radio></Col>
                    ))}
                  </Row>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Text className="form-label">Nama Institusi Pendidikan Terakhir</Text>
              <Form.Item name="udLastPlaceOfEdu">
                <Input placeholder="Nama Institusi Pendidikan Terakhir" maxLength={500} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">No. Telepon <RedStar /></Text>
              <Form.Item
                rules={[{required: true, message: 'No. Telepon harus diisi!'},
                {
                  pattern: new RegExp('^[0-9]+$'),
                  message: 'Harap masukan angka!',
                },
                {
                  min: 9,
                  message: 'No. Telepon minimal 9 digit!',
                }]}
                name="udPhone">
                <Input placeholder="No. Telepon" maxLength={20} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">WhatsApp <RedStar /></Text>
              <Form.Item
                rules={[{required: true, message: 'WhatsApp harus diisi!'},
                {
                  pattern: new RegExp('^[0-9]+$'),
                  message: 'Harap masukan angka!',
                },
                {
                  min: 10,
                  message: 'WhatsApp minimal 10 digit!',
                }]}
                name="udWa">
                <Input placeholder="WhatsApp" maxLength={20} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">E-mail <RedStar /></Text>
              <Form.Item
                rules={[
                  {required: true, message: 'E-mail harus diisi!'},
                  {type: 'email', message: 'Harap masukkan format e-mail!'}
                ]}
                name="usersEmail">
                <Input placeholder="E-mail" maxLength={50} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Username <RedStar /></Text>
              <Form.Item
                rules={[{required: true, message: 'Username harus diisi!'}]}
                name="usersUsername">
                <Input placeholder="Username" maxLength={15} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Password <RedStar /></Text>
              <Form.Item
                rules={[{required: true, message: 'Password harus diisi!'}]}
                name="usersPassword">
                <Input.Password placeholder="Password" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Confirm Password <RedStar /></Text>
              <Form.Item
                rules={[
                  {required: true, message: 'Confirm Password harus diisi!'},
                  {validator: validatePwd}
                ]}
                name="confirmPassword">
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Text className="form-label">Apakah User mendaftar sebagai siswa Loyola/guru/tenaga kependidikan/KEKL? <RedStar /></Text>
              <Form.Item
                rules={[{required: true, message: 'Konfirmasi Role User harus diisi!'}]}
                name="isUmum">
                <Radio.Group>
                  <Radio value={false}>Ya</Radio>
                  <Radio value={true}>Tidak</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Title level={4}>Detail Donasi</Title>
          <hr className="app-hr" />
          <Row gutter={14}>
            <Col span={12}>
              <Text className="form-label">Nominal <RedStar /></Text>
              <Form.Item
                rules={[
                  {required: true, message: 'Nominal harus diisi!'},
                  {validator: validateNominal},
                  { pattern: new RegExp('^[0-9]+$'), message: "Harap masukkan angka!" },
                ]}
                name="nominal">
                <MoneyInput placeholder="Nominal" maxLength={18} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Tanggal <RedStar /></Text>
              <Form.Item
                rules={[{required: true, message: 'Tanggal harus diisi!'}]}
                name="date">
                <DatePicker placeholder="Tanggal" style={{width: '100%'}} disabledDate={disabledDate} />
              </Form.Item>
            </Col>
            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '1em' }}>
              {/* <Button className="app-btn" size="large" onClick={autoFill}>Autofill</Button> */}
              <Button className="app-btn" size="large" type="link" onClick={resetForm}>Reset</Button>
              <Button className="app-btn" size="large" type="primary" onClick={handleSubmit}>Submit</Button>
            </Col>
          </Row>
        </Form>
      </Spin>
      {/* <pre>{JSON.stringify(value, '', 2)}</pre> */}
    </Modal>
  )
}
