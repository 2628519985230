import React, { useEffect, useState } from "react";
import {
   Row, Col, Button, Spin
} from "antd";
import "../../../global/global.css";
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import UploadComponentValues from "../../../components/uploadComponentValues";
import { trackPromise } from 'react-promise-tracker';
import { endpoint } from '../../../api/pengaturanUmum';
import PropTypes from 'prop-types';

const defaultListImage={
  url: '', //image preview url
  fileName: null, //path / data upload
  isUpdate: false, //hit api
}

ListFormUpload.propTypes = {
  initialData: PropTypes.array,
  token: PropTypes.string,
  updateList: PropTypes.func,
  lengthUpload: PropTypes.string,
  path: PropTypes.string
};

ListFormUpload.defaultProps = {
  initialData: [],
  token: '',
  path: 'banner',
  updateList: [],
  lengthUpload: 1000
};

function ListFormUpload(props) {
  const [listImage, setListImage] = useState([])

  useEffect(() => {
    if(props.initialData){
      let dataTemp = []
      props.initialData.map(res => {
        dataTemp.push(res)
      })
      setListImage(dataTemp)
    }
  }, [props.initialData])

  const changeFilesEventImage = (e, index, record) => {
    let dataList = [...listImage]
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    let dataUploadTemp = {
      index: index,
      data: {
        fileName: formData,
        isUpdate: true,
        url: URL.createObjectURL(e.target.files[0]),
        record: record
      }
    }
    dataList[index] = dataUploadTemp.data
    setListImage(dataList)
    uploadImageList(dataUploadTemp)
  }

  const uploadImageList = data =>{
    if(data && data.data.isUpdate){
      let dataUploadTemp = data
      let bannerIdTemp = data.data.record.record ? data.data.record.record.bannerId : null
      let dataList = [...listImage]
      trackPromise(
        endpoint.uploadImage(props.token, dataUploadTemp.data.fileName, 'imageSmall?path='+props.path).then(res => {
          if(res && res == 'error'){
            dataUploadTemp = {
              ...dataUploadTemp,
              data: {
                fileName: '',
                isUpdate: false,
                url: 'Failed',
                record: null
              }
            }
            dataList[dataUploadTemp.index] = dataUploadTemp.data
            setListImage(dataList)
            props.updateList(dataList)
          }else{
            if(res.fileName){
              dataUploadTemp = {
                ...dataUploadTemp,
                data: {
                  fileName: res.fileName,
                  isUpdate: false,
                  url: res.url,
                  id: bannerIdTemp
                }
              }
              dataList[dataUploadTemp.index] = dataUploadTemp.data
              setListImage(dataList)
              props.updateList(dataList)
            }
          }
        })
      )
    }
  }

  const addUpload = () =>{
    let tempData = [...listImage]
    tempData.push(defaultListImage)
    setListImage(tempData)
    props.updateList(tempData)
  }

  const removeUpload = (index) =>{
    const list = [...listImage]
    list.splice(index,1)
    setListImage(list)
    props.updateList(list)
  }

	return (
    <Row align="middle" gutter={[8, 10]}>
      <Col span={24}>
        {
          listImage.map((res,index) => {
            return(
              <Row gutter={[8,8]}>
                <Col span={22}>
                  <UploadComponentValues
                    pathName='listImage'
                    changeFile={e => changeFilesEventImage(e,index,res)}
                    dataFile={res.url}
                    name={'eventImage'+index}
                    status={res.status}
                    loading={res.isUpdate}
                    accept=".jpg,.png,.jpeg"
                  />
                </Col>
              <Col span={2} style={{textAlign: "center"}}>
                <MinusCircleOutlined onClick={() => removeUpload(index)}/>
              </Col>
              </Row>
            )
          })
        }
      </Col>
      {
        listImage.length < props.lengthUpload &&
        <Col span={24} style={{textAlign: "center"}}>
          <Button type="text" onClick={() => addUpload()} block icon={<PlusCircleOutlined />}>
            Tambah Image
          </Button>
        </Col>
      }
      {/* <pre>
        {JSON.stringify(listImage, '', 2)}
      </pre> */}
    </Row>
	)
}

export const ListUploadComponent = ListFormUpload;
