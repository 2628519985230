import React, { Children, useEffect, useState } from "react";
import { Layout, Menu, Row, Col, Typography } from 'antd'
import { withRouter, NavLink, useHistory } from "react-router-dom";
import { getListMenuByRole } from '../../global/constant/list-menu';
import './sidebar.scss';
import { BookOutlined, CopyOutlined, FileTextOutlined, InfoCircleOutlined, PieChartOutlined, ReadOutlined, ReconciliationOutlined, SettingOutlined, UserAddOutlined, UserOutlined, WalletOutlined } from "@ant-design/icons";

const { Sider } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;
const iconMenu = {
  Dashboard: { icon: <PieChartOutlined/>},
  Yayasan: { icon: <ReadOutlined/>},
  Informasi: { icon: <BookOutlined/>},
  Dana: { icon: <WalletOutlined/>},
  Master: { icon: <FileTextOutlined/>},
  Pengaturan: { icon: <SettingOutlined/>},
  Laporan: { icon: <ReconciliationOutlined/>},
}

const SideBarComponent = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory()


  const currentRole = sessionStorage.getItem('userRole');
  const listMenu = getListMenuByRole();

  const onCollapse = val => {
    setCollapsed(val)
  }

  useEffect(() => {
    if(window.innerWidth <= 600 && !collapsed){
      setCollapsed(true)
    }
  },[]);

  const changeMenu = (e) => {
    console.log('change menu : ',e.key)
    if(window.innerWidth <= 600 && !collapsed){
      setCollapsed(true)
    }
  }

  return (
    <Sider style={{ height: '100vh', position: 'fixed', left: 0,zIndex: 1 }} theme="light" collapsible collapsed={collapsed} onCollapse={onCollapse}>
      <div className={collapsed ? "logo-collapsed" : "logo"} />
      <Row justify='center' style={{textAlign: 'center', display: collapsed ? 'none' : 'block'}}>
        <Col span={24}>
          <Text className={collapsed ? 'headerTitle-collapsed':'headerTitle'}>PORTAL ADMIN</Text>
        </Col>
        <Col span={24}>
          <Text className={collapsed ? 'headerTitle-collapsed':'headerTitle'}>YAYASAN LOYOLA</Text>
        </Col>
        <Col span={24} style={{padding: '30px 20px 10px'}}>
          <hr style={{margin: '0px'}} />
        </Col>
      </Row>
      <Menu theme="light" defaultSelectedKeys={props.location.pathname} mode="inline" style={{background: '#DFE0E1' }} onClick={e=> changeMenu(e)}>
        {/* Generate menu */}
        {listMenu.map((menu, i) => {
          const hasChildren = menu.children && menu.children.length > 0;
          const key = `navItem-${i}`;
          const menuName = menu.label.split(' ')
          const iconName = menuName[0]
          return !hasChildren ? (
            !menu.isHidden &&
            <Menu.Item key={menu.path}  title={menu.label} icon={iconMenu[iconName] ? iconMenu[iconName].icon : <WalletOutlined/>} style={{paddingRight: 0}}>
              <NavLink to={`/${menu.path}`}>{ collapsed ? '' : menu.label}</NavLink>
            </Menu.Item>
          ) : (
            <SubMenu key={key} title={menu.label} icon={iconMenu[iconName] ? iconMenu[iconName].icon : <PieChartOutlined/>}>
              {menu.children.map((submenu, si) => {
                const subMenuKey = `navItem-${i}-${si}`;
                return !submenu.isHidden ? (
                  <Menu.Item key={submenu.path} title={submenu.label}>
                    <NavLink to={`/${submenu.path}`}>{submenu.label}</NavLink>
                  </Menu.Item>
                ) : (<></>);
              })}
            </SubMenu>
          );
        })}
      </Menu>
    </Sider>
  )
};

export default withRouter(SideBarComponent);
