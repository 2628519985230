/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./index.scss";

const toolbarSetting = {
  options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'remove'],
};

const editorSetting = {

}

export default function TextFormatter(props) {
  const {onChange, initValue} = props;
  const [editorState, setEditorState] = useState();
  let debouncedFn;

  useEffect(() => {
    if (initValue) {
      console.log({initValue})
      setValue(initValue);
    }
  }, [initValue]);

  useEffect(() => {

  }, []);


  const setValue = (val) => {
    const blocksFromHtml = htmlToDraft(val);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const eState = EditorState.createWithContent(contentState);
    setEditorState(eState);
  }

  const handleChange = async (e) => {
    setEditorState(e);
    const rawContentState = convertToRaw(e.getCurrentContent());
    const markup = draftToHtml(rawContentState, {}, {}, {});
    onChange(markup);
    // console.log(markup);
  }

  return (
    <div className="app-editor">
      <Editor
        editorState={editorState}
        toolbarClassName="app-editor-toolbar"
        wrapperClassName="app-editor-wrapper"
        editorClassName="app-editor-editor"
        onEditorStateChange={handleChange}
        toolbar={toolbarSetting}
      />
    </div>
  )
}
