import React, { useEffect, useState} from 'react'
import {
  Modal,
  Form,
  Row,
  Col,
  Typography,
  Input,
  Button
} from 'antd'
import { usePromiseTracker } from "react-promise-tracker";
import TextArea from 'antd/lib/input/TextArea';
import UploadComponentValues from '../../../components/uploadComponentValues';
import { trackPromise } from 'react-promise-tracker';
import { endpoint } from '../../../api/daftarKategoriDonorBeasiswa';
import { alert } from '../../../api';
import { RedStar } from '../../../components';
import moment from 'moment'

const {Title, Text} = Typography

const ModalCreate = props => {

  const { promiseInProgress } = usePromiseTracker();
  const [form] = Form.useForm()
  const [loadingUploadImage, setLoadingImage] = useState(false)
  const [dataFile, setDataFile] = useState(null)
  const [isImageChange, setIsImageChange] = useState(false)
  const [imageDb, setImageDb] = useState('')
  const isEdit = Object.values(props.initialValues).length > 0;
  const [imageDbThumbnail, setImageDbThumbnail] = useState('')
  const [isImageThumbnailChange, setIsImageThumbnailChange] = useState(false)

  const changeFileThumbnail = (e) => {
    let valueTemp = form.getFieldsValue()
    setIsImageThumbnailChange(true)
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    trackPromise(
      endpoint.uploadImage(props.token, formData, 'imageSmall?path=donor-beasiswa').then(res => {
        if(res == 'error'){
          setImageDbThumbnail('Failed')
          setIsImageThumbnailChange(false)
          form.setFieldsValue({...valueTemp, thumbailPath: ''})
        }else{
          if(res.fileName){
            setImageDbThumbnail(res.url)
            setIsImageThumbnailChange(false)
            form.setFieldsValue({...valueTemp, thumbailPath: res.fileName})
          }
        }
      })
    )
  }

  useEffect(() => {
    if (props.visible === true) {
      form.resetFields();
      setImageDbThumbnail(props.initialValues.imageUrl)
      form.setFieldsValue(props.initialValues)
    }
  }, [props.visible])

  const handleClose = () => {
    setImageDbThumbnail(null)
    setIsImageThumbnailChange(false)
    form.resetFields();
    props.close()
  }

  const checkFinish = values => {
    let dataTemp = values
    props.checkFinish(dataTemp)
  }

  const onFinishFailed = errorInfo => {
  };

  const handleResetForm = () => {
      form.resetFields();
  };

  const validateYear = (rule, value, callback) => {
    return new Promise((resolve, reject) => {
      if (value && value < 1949) {
        reject(`Tahun Lulus harus dalam kisaran tahun 1949 - ${moment().year()}!`);
      } else { resolve(); }
    })
  };

  return (
    <Modal
      visible={props.visible}
      footer={null}
      className='modal_galery'
      width={633}
      centered
      onCancel={() => handleClose()}
      destroyOnClose={true}
    >
      <Row>
        <Col span={24}>
          <div className='headerForm'>
            <Title className='titleModalGalery' level={4}>{props.title}</Title>
          </div>
        </Col>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={values => checkFinish(values)}
            onFinishFailed={onFinishFailed}
            initialValues={props.initialValues}
          >
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Text className="form-label">Kategori Beasiswa <RedStar /></Text>
                <Form.Item
                  rules={[{required: true, message: 'Kategori Beasiswa [IND] harus diisi!'}]}
                  name="nameInd">
                  <Input placeholder="Kategori Beasiswa" maxLength={100}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Scholarship Category <RedStar /></Text>
                <Form.Item
                  rules={[{required: true, message: 'Kategori Beasiswa [ENG] harus diisi!'}]}
                  name="nameEng">
                  <Input placeholder="Scholarship Category" maxLength={100}/>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text className="form-label">Tahun Anggaran <RedStar /></Text>
                <Form.Item
                  name="fiscalYear"
                  rules={[{required: true,message: 'Tahun Anggaran harus diisi!' },
                  { pattern: /^[0-9]*$/gm, message: 'Harap masukkan angka!'},
                  { min: 4,message: 'Tahun Anggaran harus 4 digit!'},
                  { validator: validateYear }
                ]}>
                  <Input placeholder="Tahun Anggaran" maxLength={4} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text className="form-label">Contact Person <RedStar /></Text>
                <Form.Item
                  rules={[
                    {required: true, message: 'Contact Person harus diisi!'},
                    { min: 10,message: 'Contact Person minimal 10 digit!'},
                    {pattern: new RegExp('^[0-9]+$'),message: 'Harap masukan angka!'}
                ]}
                  name="contactPerson">
                  <Input placeholder="Contact Person" maxLength={20}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Deskripsi <RedStar /></Text>
                <Form.Item
                  rules={[{required: true, message: 'Deskripsi [IND] harus diisi!'}]}
                  name="descriptionInd">
                  <TextArea placeholder="Deskripsi" autoSize={{minRows: '6'}} maxLength={256} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Description <RedStar /></Text>
                <Form.Item
                  rules={[{required: true, message: 'Deskripsi [ENG] harus diisi!'}]}
                  name="descriptionEng">
                  <TextArea placeholder="Description" autoSize={{minRows: '6'}} maxLength={256} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Banner Kategori Beasiswa <RedStar /></Text>
                <Form.Item name='thumbailPath'
                  rules={[{required: true,message: 'Banner Kategori Beasiswa harus diisi!' }
                ]}>
                  <UploadComponentValues
                    pathName='banner'
                    accept=".jpg,.png,.jpeg"
                    changeFile={e => changeFileThumbnail(e, false)}
                    dataFile={imageDbThumbnail}
                    loading={isImageThumbnailChange}
                    name={'thumbailPath'}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  style={{
                    textAlign: 'right',
                    marginBottom: '0px'
                  }}
                >
                  <Button
                    type="link"
                    className='resetButton'
                    size={'small'}
                    onClick={() => handleResetForm()}
                    disabled={props.loadingSubmit}
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    htmlType='submit'
                    className='blueButton'
                    loading={promiseInProgress}
                    size={'small'}
                    loading={props.loadingSubmit}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

export const ModalKategoriDOnor = ModalCreate
