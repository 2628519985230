import React, { useEffect} from 'react'

import { 
  Modal, 
  Form, 
  Row, 
  Col,
  Typography,
  Input,
  Button,
  DatePicker
} from 'antd'

import { usePromiseTracker } from "react-promise-tracker";
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment'
import { GlobalVideoCheck } from '../../../global/checkingVideo';
import { RedStar } from '../../../components';

const {Title,Text} = Typography


const ModalCreate = props => {

  const { promiseInProgress } = usePromiseTracker();
  const [form] = Form.useForm()
  useEffect(() => {
    if (props.visible === true) {
      form.resetFields();
      form.setFieldsValue(props.initialValues)
    }
  }, [props.visible])

  const handleClose = () => {
    form.resetFields();
    props.close()
  }

  const checkFinish = values => {
    props.checkFinish(values)
  }

  const onFinishFailed = errorInfo => {
  };

  const handleResetForm = () => {
      form.resetFields(); 
  };
  
  const validateYear = (rule, value, callback) => {
    return new Promise((resolve, reject) => {
      if (value && value < 1949) {
        reject(`Tahun Lulus harus dalam kisaran tahun 1949 - ${moment().year()}!`);
      } else { resolve(); }
    })
  };

  const validateURLYoutube = (rule, value, callback) => {
    let isUrl = GlobalVideoCheck.checkingValidate(value && value)
    return new Promise((resolve, reject) => {
      if (!isUrl < 1949) {
        reject(`Tahun Lulus harus dalam kisaran tahun 1949 - ${moment().year()}!`);
      } else { resolve(); }
    })
  };

  return (
    <Modal 
      visible={props.visible}
      footer={null}
      className='modal_galery'
      width={633}
      centered
      onCancel={() => handleClose()}
      destroyOnClose={true}
    >
      <Row>
        <Col span={24}>
          <div className='headerForm'>
            <Title className='titleModalGalery' level={4}>{props.title}</Title>
          </div>
        </Col>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={values => checkFinish(values)}
            onFinishFailed={onFinishFailed}
            initialValues={props.initialValues}
          >
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Text className="form-label">Nama Lengkap <RedStar /></Text>
                <Form.Item name='name'
                  rules={[{required: true,message: 'Nama Lengkap harus diisi!' }
                ]}>
                  <Input placeholder={'Nama Lengkap'} size="large" className='input_style' maxLength={256}/> 
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Profesi <RedStar /></Text>
                <Form.Item name='proffesion'
                  rules={[{required: true,message: 'Profesi [IND] harus diisi!' }
                ]}>
                  <Input placeholder={'Profesi'} size="large" className='input_style' maxLength={256}/> 
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Profession <RedStar /></Text>
                <Form.Item name='proffesionEng'
                  rules={[{required: true,message: 'Profesi [ENG] harus diisi!' }
                ]}>
                  <Input placeholder={'Profession'} size="large" className='input_style' maxLength={256}/> 
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Tahun Lulus <RedStar /></Text>
                <Form.Item name='yearGraduate'
                  rules={[{required: true,message: 'Tahun Lulus harus diisi!' },
                  { pattern: /^[0-9]*$/gm, message: 'Harap masukkan angka!'},
                  { min: 4,message: 'Tahun Lulus harus 4 digit!'},
                  { validator: validateYear }
                ]}>
                  <Input placeholder={'Tahun Lulus'} size="large" className='input_style' maxLength={4}/> 
                </Form.Item>
              </Col>
              <Col span={12}/>
              <Col span={12}>
                <Text className="form-label">Kesan & Pesan <RedStar /></Text>
                <Form.Item name='desc'
                  rules={[{required: true,message: 'Kesan & Pesan [IND] harus diisi!' }
                ]}>
                  <TextArea placeholder={'Kesan & Pesan'} size="large" className='input_style'/> 
                </Form.Item>
              </Col>
              <Col span={12}>
                <Text className="form-label">Testimonial <RedStar /></Text>
                <Form.Item name='descEng'
                  rules={[{required: true,message: 'Kesan & Pesan [ENG] harus diisi!' }
                ]}>
                  <TextArea placeholder={'Testimonial'} size="large" className='input_style'/> 
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text className="form-label">URL Video <RedStar /></Text>
                <Form.Item name='urlVideo'
                  rules={[{required: true,message: 'URL Video harus diisi!' },
                  { 
                    pattern: /^http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/ , 
                    message: 'Harap masukkan URL Video YouTube yang valid!' 
                  }
                ]}>
                  <Input placeholder={'URL Video'} size="large" className='input_style'/> 
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  style={{
                    textAlign: 'right',
                    marginBottom: '0px'
                  }}
                >
                  <Button
                    type="link"
                    className='resetButton'
                    size={'small'}
                    onClick={() => handleResetForm()}
                    disabled={props.loadingSubmit}
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    htmlType='submit'
                    className='blueButton'
                    loading={promiseInProgress}
                    size={'small'}
                    loading={props.loadingSubmit}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

export const ModalDaftarKekl = ModalCreate