import { Button, Col, DatePicker, Form, Input, Modal, Row, Spin } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react'
import { RedStar, UItable } from '../../../components';
import financeApi from '../../../api/adminKeuangan';
import SearchInput from '../../../components/search-input';
import ModalTambahDonatur from './modal-tambah-donatur';

export default function ModalListDonatur(props) {
  const {visible, onCancel, onAddDonatur, loadDataFn = (e) => {
    return new Promise((resolve, reject) => {resolve()});
  }, title = 'Pilih Donatur'} = props;
  const [data, setData] = useState(false);
  const [pagination, setPagination] = useState({current: 1, total: 0});
  const [showModalDonatur, setShowModalDonatur] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const defaultParams = {keyword: '', page: pagination.current, sort: 'ud_name,asc', size: 10}

  const handleChoose = (record) => {
    setShowModalDonatur(record);
  }

  const handleTambahDonatur = (donatur) => {
    setShowModalDonatur(false);
    onAddDonatur(donatur);
  }

  const columns = [
    {
      title: 'Nama Lengkap',
      dataIndex: 'nama',
      key: 'ud_name',
      sorter: true,
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'u.users_email',
      sorter: true,
    },
    {
      title: 'No. HP',
      dataIndex: 'noHp',
      key: 'ud_phone',
      sorter: true,
    },
    {
      title: 'Aksi',
      width: '15%',
      align: 'center',
      render: (val, record) => (
        <Button className="app-btn round fluid gray" style={{background: '#85B6ED', borderColor: 'transparent'}} onClick={_ => {handleChoose(record)}}>Pilih</Button>
      )
    },
  ];

  useEffect(() => {
    loadData(defaultParams);
  }, [])

  const handleSearch = (search) => {
    const param = { ...defaultParams, keyword: search, page: 1 };
    setPagination({current: 1, total: 0})
    loadData(param);
  }

  const handleTableChange = (page, filter, sort) => {
    loadData({
      ...defaultParams,
      page: page,
      sort: sort || 'ud_name,asc'
    })
  }

  const loadData = async (param) => {
    setIsLoading(true);
    await loadDataFn(param).then(res => {
      console.log(res.data.data);
      const { payload, totalItem } = res.data.data;
      setData(payload);
      setPagination({current: param.page, total: totalItem});
    }).catch(err => {

    })
    setIsLoading(false);
  }

  return (
    <Modal
      className="app-modal"
      width={800}
      footer={null}
      visible={visible}
      onCancel={onCancel}
    >
      <Title level={4} style={{marginBottom: '1em'}}>{title}</Title>
      <Spin spinning={isLoading}>
        <Row>
          <Col span={8} offset={16} style={{marginBottom: '2em'}}>
            <SearchInput onChange={search => {handleSearch(search)}} placeholder={'Nama Donatur'} />
          </Col>
          <Col span={24}>
            <UItable
              size="small"
              dataTable={data}
              columns={columns}
              withRows={false}
              page={pagination.current}
              totalData={pagination.total}
              sortDefault=''
              handleTableChange={handleTableChange}
            />
          </Col>
        </Row>
      </Spin>
      <ModalTambahDonatur
        title={props.addTitle || 'Tambah Data Donatur'}
        visible={!!showModalDonatur}
        data={showModalDonatur}
        onCancel={_ => setShowModalDonatur(false)}
        onFinish={handleTambahDonatur}
      />
    </Modal>
  )
}
