import React, { useEffect, useState } from "react";
import {
   Row, Col, Button, Form, Card,
   Input,
} from "antd";
import "../../../global/global.css";
import TextArea from 'antd/lib/input/TextArea';
import Text from "antd/lib/typography/Text";
import { RedStar } from '../../../components';

function FormGaleri(props) {
	const [form] = Form.useForm();

  useEffect(() => {
    if(props.initialData){
      console.log('data tentang kami : ',props.initialData)
      form.setFieldsValue(props.initialData)
    }
  }, [props.initialData])

	const onFinish = async(values) => {
    props.onFinish(values);
	} 

	const onFinishFailed = error => {
		console.error(error);
	}
  
  const handleResetForm = () => {
    form.setFieldsValue(props.initialData)
  }

	return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
    >
    <Card className='bodyCard_style'>
      <Card 
        bordered={false}
        style={{minHeight: '300px'}}>
          <Row gutter={[30,30]}>
            <Col span={12}>
              <Text className="form-label">Visi <RedStar /></Text>
              <Form.Item name='visionInd'
                rules={[{required: true,message: 'Visi [IND] harus diisi!' }
              ]}>
                <TextArea placeholder={'Visi'}  size="large" className='input_style' style={{minHeight: 200}}/> 
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Vision <RedStar /></Text>
              <Form.Item name='visionEng'
                rules={[{required: true,message: 'Visi [ENG] harus diisi!' }
              ]}>
                <TextArea placeholder={'Vision'}  size="large" className='input_style' style={{minHeight: 200}}/> 
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[30,30]}>
            <Col span={12}>
              <Text className="form-label">Misi <RedStar /></Text>
              <Form.Item name='missionInd'
                rules={[{required: true,message: 'Misi [IND] harus diisi!' }
              ]}>
                <TextArea placeholder={'Misi'}  size="large" className='input_style' style={{minHeight: 200}}/> 
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Mision <RedStar /></Text>
              <Form.Item name='missionEng'
                rules={[{required: true,message: 'Misi [ENG] harus diisi!' }
              ]}>
                <TextArea placeholder={'Mision'}  size="large" className='input_style' style={{minHeight: 200}}/> 
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20]}>
            <Col span={24}>
              <Form.Item
                style={{
                  textAlign: 'right',
                  marginBottom: '0px'
                }}
              >
                <Button
                  type="link"
                  className='resetButton'
                  size={'small'}
                  onClick={() => handleResetForm()}
                  disabled={props.loadingSubmit}
                >
                  Reset
                </Button>
                <Button
                  type="primary"
                  htmlType='submit'
                  className='blueButton'
                  size={'small'}
                  loading={props.loadingSubmit}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
      </Card>
    </Card>
  </Form>
	)
}

export const UIForm = FormGaleri;