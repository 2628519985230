import React, { useEffect, useState } from 'react'
import { Button, Col, DatePicker, Form, Input, Modal, Radio, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import { RedStar } from '../../../components';
import MoneyInput from '../../../components/money-input';
import { confirmDialog } from '../../../utils/alert';
import moment from 'moment';

export default function ModalTambahDonatur(props) {
  const {visible, onCancel, onFinish, title, data = {}} = props;
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.validateFields().then(_ => {
      confirmDialog({
        title: 'Tambah Data Donatur',
        text: 'Pastikan data Donatur dan Nominal Donasi sudah sesuai!'
      }).then(_ => {
        onFinish({
          ...data,
          ...form.getFieldsValue()
        });
        onReset();
      }).catch(_ => {});
    }).catch(_ => {});

  }

  const onReset = () => {
    form.resetFields();
    form.setFieldsValue(data);
  }

  const validateNominal = (rule, val) => {
    return new Promise((resolve, reject) => {
      if ((val || val === 0) && val <= 0) {
        reject('Nominal Donasi harus lebih dari Rp0,00!');
      } else { resolve(); }
    })
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  }

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data])

  return (
    <Modal
      className="app-modal"
      width={600}
      footer={null}
      visible={visible}
      onCancel={_ => {
        onCancel();
        onReset();
      }}
    >
      <Title level={4}>{title}</Title>
      <hr className="app-hr" />
      <Form form={form}>
        <Row gutter={14}>
          <Col span={24}>
            <Text className="form-label">Nama Lengkap <RedStar /></Text>
            <Form.Item
              rules={[{required: true, message: 'Nama Lengkap harus diisi!'}]}
              name="nama">
              <Input placeholder="Nama Lengkap" disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Text className="form-label">WhatsApp <RedStar /></Text>
            <Form.Item
              rules={[{required: true, message: 'WhatsApp harus diisi!'}]}
              name="noHp">
              <Input placeholder="WhatsApp" disabled />
            </Form.Item>
          </Col>
        </Row>

        <Title level={4}>Detail Donasi</Title>
        <hr className="app-hr" />
        <Row gutter={14}>
          <Col span={12}>
            <Text className="form-label">Nominal <RedStar /></Text>
            <Form.Item
              rules={[
                {required: true, message: 'Nominal harus diisi!'},
                {validator: validateNominal},
                { pattern: new RegExp('^[0-9]+$'), message: "Harap masukkan angka!" },
              ]}
              name="nominal">
              <MoneyInput placeholder="Nominal" maxLength={18} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Text className="form-label">Tanggal <RedStar /></Text>
            <Form.Item
              rules={[{required: true, message: 'Tanggal harus diisi!'}]}
              name="tanggalBayar">
              <DatePicker placeholder="Tanggal" style={{width: '100%'}} disabledDate={disabledDate} />
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: 'right', paddingTop: '1em' }}>
            <Button className="app-btn" size="large" type="link" style={{marginLeft: '1em'}} onClick={onReset}>Reset</Button>
            <Button className="app-btn" size="large" style={{marginLeft: '1em'}} type="primary" onClick={handleSubmit} >Submit</Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
