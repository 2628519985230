import React, { Component } from 'react'
import '../../../global/global.css'
import { trackPromise } from 'react-promise-tracker';
import {
  Card,
  Row,
  Col,
  Typography,
  Modal,
  Button,
  Tabs,
  Table
} from 'antd'
import ImagePreviewComponent from '../../../components/imagePreview'
import { endtpointDaftarCrowdFunding } from '../../../api/daftarcrowdfunding';

const { Text } = Typography
const { TabPane } = Tabs;
const columns = [
  {
    title: 'Nama Lengkap',
    dataIndex: 'participantName',
    // sorter: true
  }
];

class DaftarProyekCrowdfundingDetail extends Component {
	constructor(props) {
		super(props)
		this.state = {
      initial: {},
      quota:{},
      validateVideo: '',
      visible: false,
      isTab: "1"
		}
	}

	componentDidMount() {
    const id = this.props.location.pathname.split('/')[2]
    const body = {
      token: this.props.token,
      id: id
    }
    this.getData(body)
  }

	getData = param => {
		trackPromise(
      endtpointDaftarCrowdFunding.getDataDetail(param).then(res => {
        this.setState({
          initial: res.data.data
        })
      })
    )
	}

changeTab = key => {
  this.setState({
    isTab: key
  })
}

	render() {
    const {initial, quota, isTab} = this.state

		return(
			<div className='root'>
        <Card className='bodyCard_style'>
          <Row gutter={[8,8]}>
            <Col span={24} xs={24} md={12}>
                <div style={{ width: '70%'}}>
                  {/* <CaretRightOutlined
                    className='playButton'
                    onClick={() => this.handleOpenVideo(initial.urlVideoImage)}
                  /> */}
                  <ImagePreviewComponent
                    isMobile={false}
                    styles={{ width: '100%' }}
                    uri={initial.projectThumbnail}
                    imageBorder={'6px'}
                  />
                </div>
            </Col>
            <Col span={24} xs={24} md={12}/>
            <Col span={24} xs={24} md={12}>
              <Row gutter={[8,8]}>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Nama Proyek
                  </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi'>
                    {initial.projectName}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Kategori
                  </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi'>
                    {initial.projectCat}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Deskripsi
                  </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi' style={{whiteSpace: 'pre-line'}}>
                    {initial.projectDesc}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={24} xs={24} md={12}>
              <Row gutter={[8,8]}>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Status
                  </span>
                </Col>
                <Col lg={12} md={24} span={24}>
                  <span className='text_detail_institusi'>
                  <div style={{
                    height: 'auto',
                    padding: '2px 35px',
                    color: '#fff',
                    textAlign: 'center',
                    borderRadius: '100px',
                    backgroundColor: initial.projectStatus == 'Pelaksanaan' ? '#85B6ED' : initial.projectStatus == 'Selesai' ? '#2EC57C' : '#FBBC04',
                    cursor: 'default' }}>{initial.projectStatus}</div>
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Tanggal Mulai
                  </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi'>
                    {initial.projectStartDate}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Durasi
                  </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi'>
                    {initial.projectDuration}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Tahun Anggaran
                  </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi'>
                    {initial.projectFiscalYear}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Nilai Proyek
                  </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi'>
                    {initial.projectValue}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Contact Person
                  </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi'>
                    {initial.projectContactPerson}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
			</div>
		)
	}
}


export default DaftarProyekCrowdfundingDetail
