import React, { Component } from 'react'
import '../../../global/global.css'
// import lov from '../../actions/lov'

import { trackPromise } from 'react-promise-tracker';
import {
  Card,
  Button,
  Row,
  Col,
  Typography,
  Popconfirm
} from 'antd'
import { UItable, UISearch } from '../../../components'
import moment from 'moment';
import { ModalFAQ } from '../modal';
import { alert } from '../../../api';
import { endpoint } from '../../../api';

const { Text } = Typography
const sortOrderBy = ''
const initialDataTemp = {
  faqQuestionInd: undefined,
  faqQuestionEng: undefined,
  faqAnswerInd: undefined,
  faqAnswerEng: undefined,
  faqCategoryId: undefined
}
class ListFAQ extends Component {
	constructor(props) {
		super(props)
		this.state = {
		initial: '',
		orderBy: sortOrderBy,
		size: 10,
		page: 1,
		search: '',
		visibleCreate: false,
		initalData: {
      name: undefined,
      proffesion: undefined,
      proffesionEng: undefined,
      yearGraduate: undefined,
      desc: undefined,
      descEng: undefined,
      urlVideo: undefined
		},
		dataId: undefined,
		isEdit: false,
		dataProfil: {
      data: [],
      totalData: 0
    },
		dataLOV: [],
    loadingSubmit: false,
    loadingExport: false
		}
	}

	componentDidMount() {
    const {orderBy, size, page, search} = this.state
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: page,
      search: search,
    }
    this.getLov(body)
  }

  getLov = param => {
		trackPromise(
      endpoint.getLovFaq().then(res => {
        if(res !== 'error'){
          this.setState({
            dataLOV: res
          }, ()=> this.getData(param))
        }
      })
    )
  }

	getData = param => {
		trackPromise(
      endpoint.getDataFaq(param).then(res => {
        this.setState({
          dataProfil: res
        })
      })
    )
	}

	handleSearch = key => {
    const {orderBy, size, page } = this.state
    this.setState({
      search: key,
      page: 1
    })
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: 1,
      search: key,
    }
    this.getData(body)
  }

	handleTableChange = (page, filter, sorter) => {
    const {size, search } = this.state
    const body = {
      token: this.props.token,
      sort: sorter,
      size: size,
      page: page,
      search: search,
    }
    this.setState({
      page: page,
      orderBy: sorter
    }, () => {
      this.getData(body)
    })
  }

	openModal = () => {
    this.setState({
      visibleCreate: true,
      isEdit: false,
      initalData: initialDataTemp,
    })
  }

	handleCloseModal = () => {
    this.setState({
      visibleCreate: false,
      isEdit: false
    })
  }


	handleFinish = values => {
      this.setState({
        loadingSubmit: true
      })
      let body = {
        token: this.props.token,
        sort: this.state.orderBy,
        size: 10,
        page: 1,
        search: this.state.search,
      }
      let dataTemp = {
        ...values,
        yearGraduate: moment(values.yearGraduate).format('YYYY'),
        usersId: this.props.userId,
      }
  
      console.log('kirim data : ',dataTemp)
      if (this.state.isEdit) {
        // console.log('kirim data edit: ',dataTemp)
        this.handleSubmitEdit(body, dataTemp)
      }else {
        body = {
          token: this.props.token,
          sort: this.state.orderBy,
          size: 10,
          page: 1,
          search: this.state.search,
        }
        // console.log('kirim data : ',dataTemp)
        this.handleSubmitCreate(body, dataTemp)
      }
  }

	handleSubmitCreate = (body, bodySend) => {
    trackPromise(
      endpoint.createFaq(this.props.token, bodySend).then(res => {
        if (res === 'success') {
          this.setState({
            page: 1,
            orderBy: sortOrderBy,
            visibleCreate: false,
            loadingSubmit: false
          }, () => {
            this.getData(body)
          })
        }else{
          this.setState({
            loadingSubmit: false
          })
        }
      })
    )
  }

	handleSubmitEdit = (body, bodySend) => {
    trackPromise(
      endpoint.editFaq(this.props.token, this.state.dataId, bodySend).then(res => {
        if (res === 'success') {
          this.setState({
            page: 1,
            orderBy: sortOrderBy,
            visibleCreate: false,
            loadingSubmit: false
          }, () => {
            this.getData(body)
          })
        }else{
          this.setState({
            loadingSubmit: false
          })
        }
      })
    )
  }

	handleEditClick = data => {
		this.setState({
      initalData: {
        faqQuestionInd: data.faqQuestionInd,
        faqQuestionEng: data.faqQuestionEng,
        faqAnswerInd: data.faqAnswerInd,
        faqAnswerEng: data.faqAnswerEng,
        faqCategoryId: data.faqCategoryId
      },
      dataId: data.faqId,
      visibleCreate: true,
      isEdit: true
    })
	}

	handleReject = id => {
		const body = {
      token: this.props.token,
      sort: this.state.orderBy,
      size: 10,
      page: 1,
      search: this.state.search,
    }
		trackPromise(
      endpoint.deleteDataFaq(this.props.token, this.props.userId, id).then(res => {
        if (res === 'success') {
          this.setState({
            search: this.state.search || '',
            page: 1,
            orderBy: this.state.orderBy || sortOrderBy,
            visibleCreate: false
          }, () => {
            this.getData(body)
          })
        }
      })
    )
	}

	handleRedirectDetail = id => {
    this.props.history.push(`faq-detail/${id}`)
	}

	render() {

		const {page, visibleCreate, initalData, isEdit, dataProfil, dataLOV, loadingSubmit, loadingExport } = this.state

    const text = 'Apakah Anda yakin akan menghapus data ini?'

    const columnsTable = [
      {
        title: 'Kategori',
        dataIndex: 'faqCategory',
        key: 'category',
        sorter: true
      },
      {
        title: 'Pertanyaan',
        dataIndex: 'faqQuestionInd',
        key: 'question',
        sorter: true,        
        render: record => <Text style={{ color: '#222222', fontWeight: 600, cursor: 'pointer' }}>{record}</Text>,
        onCell: record => {
          return{
            onClick: () => {
              this.handleRedirectDetail(record.faqId)
            }
          }
        }
      },
      {
        title: 'Aksi',
        dataIndex: '',
        key: '',
        render: (data) => <>
          <Button
            onClick={() => this.handleEditClick(data)}
            type='link'
            style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600 }}
          >
            Ubah
          </Button>
          <Popconfirm
            placement="bottomRight"
            title={text}
            onConfirm={() => this.handleReject(data.faqId)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type='link'
              style={{ color: '#E22828', cursor: 'pointer', fontWeight: 600 }}
            >
              Hapus
            </Button>
          </Popconfirm>
        </>
      },
    ]

		return(
			<div className='root'>
				<Card className='bodyCard_style'>
					<Row gutter={[16,0]}>
            <Col span={16}>
              <Button onClick={() => this.openModal()} className='addButton'>+ Tambah</Button>
            </Col>
            <Col span={8}>
              <UISearch placeholder='Pertanyaan' handleSearch={key => this.handleSearch(key)} />
            </Col>
          </Row>
					<Card 
            bordered={false}
            style={{minHeight: '300px'}}>
            <UItable
              dataTable={dataProfil.data}
              columns={columnsTable}
              withRows={false}
              page={page}
              totalData={dataProfil.totalData}
              sortDefault=''
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
					<ModalFAQ
						visible={visibleCreate}
						initialValues={initalData}
						title={ isEdit ? 'Ubah Data FAQ' : 'Tambah Data FAQ'}
						widthDivider='250px'
						close={() => this.handleCloseModal()}
						checkFinish={ values => this.handleFinish(values)}
            dataLOV={dataLOV}
            loadingSubmit={loadingSubmit}
            isEdit={isEdit}
					/>
				</Card>
			</div>
		)
	}
}


export default ListFAQ