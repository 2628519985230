import React, { useEffect, useState } from "react";
import {
   Row, Col, Button, Modal, Form, Select,
   Switch, Input, Typography, Card, Spin
} from "antd";
import "../../global/global.css";
import { PlusOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import UploadComponent from '../uploadComponent/upload-component-image-preview.js'
import config from '../../services/config'
import { endpoint } from "../../api";
import { RedStar } from '../../components'
const { BASE_URL_LOGIN, TIMEOUT } = config

const { Title, Text } = Typography;
const { Option } = Select;
const lovNegara = [{
  key: 1,
  value: 'English'
},{
  key: 2,
  value: 'Indonesia'
}]

function FormHeaderFooter(props) {
	const [form] = Form.useForm();
  const [lovNegara, setLovCountry] = useState([])
  const [lovProvince, setLovProvince] = useState([])
  const [lovCity, setLovCity] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [defaultImage, setDefaultImage] = useState('')

  useEffect(() => {
    if(props.data){
      handleInit()
    }
  }, [props.data])

  const handleInit = () => {
    let dataTemp = props.data.data
    let formTemp = {
      ...props.data.data,
      hfLogoName:{
        fileName: dataTemp.hfLogoName,
        url: dataTemp.hfLogoPath
      }
    }
    getCountry()
    getProvince(formTemp.hfCountryId ? formTemp.hfCountryId : 1)
    getCity(formTemp.hfProvinceId ? formTemp.hfProvinceId : 1)
    setDefaultImage(dataTemp.hfLogoPath)
    form.setFieldsValue(formTemp)
    setLoading(false)
  }

  const countryChange = e => {
    const temp = {
      hfCountryId: e,
      hfCityId: undefined,
      hfProvinceId: undefined,
    };
    form.setFieldsValue(temp);
    getProvince(e)
  };

  const provinceChange = e => {
    const temp = {
      hfCityId: undefined,
    };
    form.setFieldsValue(temp);
    getCity(e)
  };

  const getCountry = () => {
    endpoint.getLovCountry().then(res => {
      setLovCountry(res.data ? res.data : [])
    })
  }
  const getProvince = (id) => {
    endpoint.getLovProvincy(id).then(res => {
      setLovProvince(res.data ? res.data : [])
    })
  }
  const getCity = (id) => {
    endpoint.getLovCity(id).then(res => {
      setLovCity(res && res.data ? res.data : [])
    })
  }

	const onFinish = (values) => {
		props.onFinish(values);
	}

	const onFinishFailed = error => {
		console.error(error);
	}

	const handleResetForm = () => {
    setLoading(true)
    handleInit()
		// form.resetFields();
		// props.handleReset();
    // let dataTemp = props.data.data
    // let formTemp = {
    //   ...props.data.data,
    //   hfLogoName:{
    //     fileName: dataTemp.hfLogoName,
    //     url: dataTemp.hfLogoPath
    //   }
    // }
    // form.setFieldsValue(formTemp)
	}

  const validateYoutube = (rule, value, callback) => {
    return new Promise((resolve, reject) => {
      if (value && !value.includes('www.youtube.')) {
        reject('Youtube URL tidak valid');
      } else { resolve(); }
    })
  };

  const validateInstagram = (rule, value, callback) => {
    return new Promise((resolve, reject) => {
      if (value && !value.includes('www.instagram.')) {
        reject('Instagram URL tidak valid');
      } else { resolve(); }
    })
  };

  const validateDecimal = (rule, value, callback) => {
    return new Promise((resolve, reject) => {
      if (value && !value.includes('.',0)) {
        reject('Harap masukan nilai desimal!');
      } else { resolve(); }
    })
  };

  const InputList = (props) => {
    return (
      <Form.List name={props.name}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Row gutter={[8, 10]} key={index} align='middle'>
              <Col span={11}>
                <Form.Item
                  {...field}
                  name={[field.name, 'label']}
                  fieldKey={[field.fieldKey, 'label']}
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      message: 'Label harus diisi!',
                    }
                  ]}
                >
                  <Input
                    placeholder="Label"
                    className="input_style"
                    size="large"
                    maxLength={100}
                  />
                </Form.Item>
              </Col>
                <Col span={11}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'url']}
                    fieldKey={[field.fieldKey, 'url']}
                    style={{ marginBottom: 0 }}
                    rules={[
                      {
                        required: true,
                        message: 'URL harus diisi!',
                      },
                      {
                        type: 'url',
                        message: 'URL tidak valid',
                      }
                    ]}
                  >
                    <Input
                      placeholder="URL"
                      className="input_style"
                      size="large"
                      maxLength={100}
                    />
                  </Form.Item>
                </Col>
                <Col span={2} style={{textAlign: "center"}}>
                  <MinusCircleOutlined onClick={() => remove(field.name)}/>
                </Col>
              </Row>
            ))}
            <Row align="middle" gutter={[8, 10]}>
              <Col span={24} style={{textAlign: "center"}}>
                <Button type="text" onClick={() => add()} block icon={<PlusCircleOutlined />}>
                  Tambah Link
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    )
  }

  const CardHeader = () => {
    return (
      <Card className='bodyCard_style'>
        <Card
          bordered={false}
          style={{minHeight: '300px'}}>
            <Row gutter={[30]}>
              <Col span={24}>
                <Title level={4}>Header</Title>
              </Col>
              <Col xs={24} md={12}>
                <Row>
                  <Col xs={24} md={12}>
                    Logo Loyola <RedStar/>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      name="hfLogoName"
                      rules={[
                        {
                          required: true,
                          message: 'Logo harus diisi!',
                        }
                    ]}>
                      <UploadComponent fluidBtn
                        showPreview = {true}
                        defaultPreview= {defaultImage}
                        accept=".jpg,.jpeg,.png"
                        url={BASE_URL_LOGIN+'noAuth/be/api/yayasanLoyola/upload/imageSmall?isEng=false'}
                        title="Upload Logo"
                        path='logo'
                        onChange={_ => {}}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    Link Sekolah Kami <RedStar/>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="linkSekolah"
                      rules={[
                        {
                          required: true,
                          message: 'Link Sekolah Kami harus diisi!',
                        },
                        {
                          type: 'url',
                          message: 'Link Sekolah Kami tidak valid',
                        }
                    ]}>
                      <Input className="input_style" size="large"
                    maxLength={256}/>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <Row>
                  <Col xs={24} md={6}>
                  Link Magis !
                  </Col>
                  <Col xs={24} md={18}>
                    <InputList name={'magis'}/>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={6}>
                    Link Alumni
                  </Col>
                  <Col xs={24} md={18}>
                    <InputList name={'alumni'}/>
                  </Col>
                </Row>
              </Col>
            </Row>
        </Card>
      </Card>
    )
  }

  const CardFooter = () => {
    return (
      <Card className='bodyCard_style'>
        <Card
          bordered={false}
          style={{minHeight: '300px'}}>
            <Row gutter={[30]}>
              <Col span={24}>
                <Title level={4}>Footer</Title>
              </Col>
              {/* right_form */}
                <Col xs={24} md={12}>
                  <Row gutter={[30]}>
                    <Col span={24}>
                      Nama Institusi <RedStar/>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="namaInstitusi"
                        rules={[
                          {
                            required: true,
                            message: 'Nama Institusi harus diisi!',
                          }
                      ]}>
                        <Input className="input_style" size="large"
                    maxLength={256}/>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      Alamat <RedStar/>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="hfAddress"
                        rules={[
                          {
                            required: true,
                            message: 'Alamat harus diisi!',
                          }
                      ]}>
                        <Input.TextArea className="input_style" size="large"
                    maxLength={256}/>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Row>
                        <Col span={24}>
                          Negara <RedStar/>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name="hfCountryId"
                            rules={[
                              {
                                required: true,
                                message: 'Negara harus diisi!',
                              }
                          ]}>
                            <Select placeholder="Nama Acara" className="select" onChange={e => countryChange(e)}>
                              { lovNegara &&
                                lovNegara.map( (res, index) => {
                                  return (
                                    <Option key={index} value={res.key}>{res.value}</Option>
                                  )
                                })
                              }
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={24} md={12}>
                      <Row>
                        <Col span={24}>
                          Provinsi <RedStar/>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name="hfProvinceId"
                            rules={[
                              {
                                required: true,
                                message: 'Provinsi harus diisi!',
                              }
                          ]}>
                            <Select placeholder="Provinsi" className="select" onChange={e => provinceChange(e)}>
                              { lovProvince &&
                                lovProvince.map( (res, index) => {
                                  return (
                                    <Option key={index} value={res.key}>{res.value}</Option>
                                  )
                                })
                              }
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={24} md={12}>
                      <Row>
                      <Col span={24}>
                        Kota <RedStar/>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="hfCityId"
                          rules={[
                            {
                              required: true,
                              message: 'Provinsi harus diisi!',
                            }
                        ]}>
                          <Select placeholder="Kota" className="select" dropdownMatchSelectWidth dropdownStyle={{minWidth: '30%'}}>
                            { lovCity &&
                              lovCity.map( (res, index) => {
                                return (
                                  <Option key={index} value={res.key}>{res.value}</Option>
                                )
                              })
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={24} md={12}>
                    <Row>
                      <Col span={24}>
                        Kode Pos <RedStar/>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="hfPostalCode"
                          rules={[
                            {
                              required: true,
                              message: 'Kode Pos harus diisi!',
                            },
                            {
                              pattern: new RegExp('^[0-9]+$'),
                              message: 'Harap masukan angka!',
                            },
                            {
                              min: 5,
                              message: 'Kode Pos minimal 5 digit!',
                            }
                        ]}>
                          <Input className="input_style" size="large"
                    maxLength={5}/>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={24} md={12}>
                    <Row>
                      <Col span={24}>
                        Latitude <RedStar/>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                        style={{marginBottom: 0}}
                          name="hfMapLat"
                          rules={[
                            {
                              required: true,
                              message: 'Latitude harus diisi!',
                            }
                        ]}>
                          <Input className="input_style" size="large" type='number'/>
                        </Form.Item>
                        <Text style={{color: 'lightgrey', fontStyle: 'italic', marginBottom: 24, fontSize: '0.8em'}}>E.g.: -6,981904</Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} md={12}>
                    <Row>
                      <Col span={24}>
                        Longitude <RedStar/>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                        style={{marginBottom: 0}}
                          name="hfMapLong"
                          rules={[
                            {
                              required: true,
                              message: 'Longitude harus diisi!',
                            }
                        ]}>
                          <Input className="input_style" size="large" type='number'/>
                        </Form.Item>
                        <Text style={{color: 'lightgrey', fontStyle: 'italic', marginBottom: 24, fontSize: '0.8em'}}>E.g.: 110,426239</Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              {/* right_form */}
              {/* left_form */}
                <Col xs={24} md={12}>
                  <Row gutter={[30]}>
                    <Col xs={24} md={12}>
                      <Row>
                        <Col span={24}>
                          Instagram ID <RedStar/>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name="hfInstagramName"
                            rules={[
                              {
                                required: true,
                                message: 'Instagram ID harus diisi!',
                              }
                          ]}>
                            <Input className="input_style" size="large"
                    maxLength={256}/>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} md={12}>
                      <Row>
                        <Col span={24}>
                          WhatsApp <RedStar/>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name="hfWa"
                            rules={[
                              {
                                required: true,
                                message: 'WhatsApp harus diisi!',
                              },
                              {
                                min: 10,
                                message: 'WhatsApp minimal 10 digit!',
                              }
                          ]}>
                            <Input className="input_style" size="large"
                    maxLength={15}/>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>


                    <Col span={24}>
                      Instagram URL <RedStar/>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="hfInstagramUrl"
                        rules={[
                          {
                            required: true,
                            message: 'Instagram URL harus diisi!',
                          },
                          {
                            validator: validateInstagram
                          }
                      ]}>
                        <Input className="input_style" size="large"
                    maxLength={256}/>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      YouTube ID <RedStar/>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="hfYoutubeName"
                        rules={[
                          {
                            required: true,
                            message: 'YouTube ID harus diisi!',
                          }
                      ]}>
                        <Input className="input_style" size="large"
                    maxLength={256}/>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      YouTube URL <RedStar/>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="hfYoutube"
                        rules={[
                          {
                            required: true,
                            message: 'YouTube URL harus diisi!',
                          },
                          {
                            validator: validateYoutube
                          }
                      ]}>
                        <Input className="input_style" size="large" type='url' />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      E-mail <RedStar/>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="hfEmail"
                        rules={[
                          {
                            required: true,
                            message: 'E-mail harus diisi!',
                          },
                          {
                            type: 'email',
                            message: 'Harap masukkan format e-mail!'
                          }
                      ]}>
                        <Input className="input_style" size="large"
                    maxLength={125}/>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Row>
                        <Col span={24}>
                          No. Telepon 1 <RedStar/>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name="hfTelp1"
                            rules={[
                              {
                                required: true,
                                message: 'No. Telepon 1 harus diisi!',
                              },
                              {
                                min: 6,
                                message: 'No. Telepon 1 minimal 6 digit!',
                              }
                          ]}>
                            <Input className="input_style" size="large"
                            maxLength={16}/>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} md={12}>
                      <Row>
                        <Col span={24}>
                          No. Telepon 2
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name="hfTelp2"
                            rules={[
                              {
                                min: 6,
                                message: 'No. Telepon 2 minimal 6 digit!',
                              }
                          ]}>
                            <Input className="input_style" size="large"
                    maxLength={16}/>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              {/* left_form */}
          </Row>
          <Row gutter={[30]}>
            <Col span={24}>
              <Form.Item
                style={{
                  textAlign: 'right',
                  marginBottom: '0px'
                }}
              >
                <Button
                  type="link"
                  className='resetButton'
                  size={'small'}
                  onClick={handleResetForm}
                >
                  Reset
                </Button>
                <Button
                  type="primary"
                  htmlType='submit'
                  className='blueButton'
                  size={'small'}
                  loading={props.loading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Card>
    )
  }

	return (
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Spin spinning={loading}>
          <Row gutter={[30,30]}>
            <Col span={24}>
              <CardHeader/>
            </Col>
            <Col span={24}>
              <CardFooter/>
            </Col>
          </Row>
        </Spin>
    </Form>
	)
}

export const UIFormHeaderFooter = FormHeaderFooter;
