import { Card, Col, Row, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useState, useEffect } from 'react';
import {XYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries} from 'react-vis';
import { alert } from '../../api';
import masterApi from '../../api/master';
import { PieChart, HybridChart, BarChart, RingChart, ProgressChart } from './charts';
import  './index.scss';

export default function Dashboard(props) {
  const [data, setData] = useState({
    dokumenBar: [],
    dokumenBar2: [],
    dokumenBar3: [],
    subjudul: '',
    statusPenerimaanDana: {percent: 0, value: 'Rp.0'},
    jumlahProyek: [],
    kebutuhanDana: '',
  });
  const [dataDonor, setDataDonor] = useState({
    statusPenerimaanDanaDonor: {percent: 0, value: 'Rp.0'},
    jumlahProyekDonor: [],
    kebutuhanDanaDonor: '',
  });
  const [dataSukarela, setDataSukarela] = useState({
    data: [],
    totalPenerimaanDonasiSukarela: 0,
    totalDonaturDonasiSukarela: 0,
    fiscalYear: ""
  });
  const [isReload, setReload] = useState({
    isDokumen: false,
    isDonor: false,
    isSukarela: false
  });
  const [isLoading, setIsLoading] = useState({
    isDokumen: true,
    isDonor: true,
    isSukarela: true
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    await masterApi.getDashboardDokumenCrowdFunding(props.token).then(res => {
      console.log(res.data.rc);
      if(res.data.rc == "00"){
        const { 
          document: {sebelum2017, sesudah2017, statusPersetujuan}, 
          crowdfunding:{statusPenerimaanDana, jumlahProyek, kebutuhanDana, subjudul}
        } = res.data.data;
        setData({
          ...data,
          dokumenBar: sebelum2017,
          dokumenBar2:sesudah2017,
          dokumenBar3: statusPersetujuan,
          subjudul: subjudul,
          statusPenerimaanDana: statusPenerimaanDana,
          jumlahProyek: jumlahProyek,
          kebutuhanDana: kebutuhanDana
        });
        loadDataDonor()
        setIsLoading({
          ...isReload,
          isDokumen: false
        })
      }else{
        setReload({
          ...isLoading,
          isDokumen: true
        })
        alert.showAlert(res.data.message,res.data.responseType)
      }
    }).catch(err => {
      setReload({
        ...isLoading,
        isDokumen: true
      })
    })
  }

  const loadDataDonor = async () => {
    await masterApi.getDashboardDonorBeasiswa(props.token).then(res => {
      console.log(res.data.data);
      if(res.data.rc == "00"){
      const { 
        progressBar,
        jumlahPenerima,
      } = res.data.data;
      setDataDonor({
        ...dataDonor,
        statusPenerimaanDanaDonor: progressBar,
        jumlahProyekDonor: jumlahPenerima,
        kebutuhanDanaDonor: progressBar.valueReached
      });
      loadDataSukarela()
      setIsLoading({
        ...isReload,
        isDonor: false
      })}else{
        setReload({
          ...isLoading,
          isDonor: true
        })
        alert.showAlert(res.data.message,res.data.responseType)
      }
    }).catch(err => {
      setReload({
        ...isLoading,
        isDonor: true
      })
    })
  }

  const loadDataSukarela = async () => {
    await masterApi.getDashboardSukarela(props.token).then(res => {
      console.log(res.data.data);
      if(res.data.rc == "00"){
        const dataTemp = res.data.data;
        console.log('dana sukarela : ',dataTemp)
        setDataSukarela({
          ...dataTemp,
          data: dataTemp.donasiSukarela
        });
        setIsLoading({
          ...isReload,
          isSukarela: false
        })
      }else{
        setReload({
          ...isLoading,
          isSukarela: true
        })
        alert.showAlert(res.data.message,res.data.responseType)
      }
    }).catch(err => {
      setReload({
        ...isLoading,
        isSukarela: true
      })
    })
  }

  return (
    <div className="root">
      <Spin spinning={isLoading.isDokumen || isLoading.isDonor || isLoading.isSukarela}>
        <Row gutter={[30, 30]}>
          <Col span={24}>
            <Card className="app-card full-height">
              <Row gutter={[72,30]}>
                <Col span={24} style={{textAlign: 'center', margin: 10}}>
                  <Title className="chart-title" style={{margin: '10px'}}>Dokumen</Title>
                </Col>
                <Col span={8} lg={8} md={24} xs={24}>
                  <Row justify='center'>
                    <Col span={24} style={{textAlign: 'center'}}>
                      <BarChart data={data.dokumenBar}/>
                    </Col>
                    <Col span={24} style={{textAlign: 'center'}}>
                      <Title className="chart-title" style={{marginBottom: '0px', marginTop: '1em'}}>Pendirian s.d. Sebelum Tahun 2017</Title>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}  lg={8} md={24} xs={24}>
                  <Row justify='center'>
                    <Col span={24} style={{textAlign: 'center'}}>
                      <BarChart data={data.dokumenBar2}/>
                    </Col>
                    <Col span={24} style={{textAlign: 'center'}}>
                      <Title className="chart-title" style={{marginBottom: '0px', marginTop: '1em'}}>Tahun 2017 s.d. Sekarang</Title>
                    </Col>
                  </Row>
                </Col>
                <Col span={8} lg={8} md={24} xs={24}>
                  <Row justify='center'>
                    <Col span={24}>
                  <RingChart showLegend={true} data={data.dokumenBar3} />
                    </Col>
                    <Col span={24} style={{textAlign: 'center'}}>
                      <Title className="chart-title" style={{marginBottom: '0px', marginTop: '1em'}}>Status Persetujuan</Title>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={24} xl={12}>
            <Card className="app-card full-height">
              <Row gutter={60}>
                <Col span={24} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <Title className="chart-title" style={{marginBottom: '.5em'}}>Crowdfunding</Title>
                  <Title className="chart-title">{data.subjudul}</Title>
                </Col>
                <Col span={12} xs={24} md={12}>
                  <Row>
                    <Col span={12} xs={24}>
                      <PieChart showLegend={true} data={data.jumlahProyek} />
                    </Col>
                    <Col span={12} xs={24}>
                      <div style={{textAlign: 'center', width: '100%'}}><span className="chart-names" >Jumlah Proyek (Status)</span></div>
                    </Col>
                  </Row>
                </Col>
                <Col span={12} xs={24} md={12}>
                  <Row>
                    <Col span={12} xs={24}>
                      <div style={{textAlign: 'center', width: '100%'}}><span className="chart-names" >Kebutuhan Dana</span></div>
                      <Title style={{fontSize: '1em', margin: '0px', textAlign: 'center'}}>{data.kebutuhanDana}</Title>
                      <ProgressChart data={data.statusPenerimaanDana.percent} label={data.statusPenerimaanDana.value} />
                    </Col>
                    <Col span={12} xs={24}>
                      <div style={{textAlign: 'center', width: '100%'}}><span className="chart-names" >Status Penerimaan Dana</span></div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={24} xl={12}>
            <Card className="app-card full-height">
              <Row gutter={60}>
                <Col span={24} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <Title className="chart-title" style={{marginBottom: '.5em'}}>Donor Beasiswa</Title>
                  <Title className="chart-title">{data.subjudul}</Title>
                </Col>

                <Col span={12} xs={24} md={12}>
                  <Row>
                    <Col span={12} xs={24}>
                      <div style={{textAlign: 'center', width: '100%'}}><span className="chart-names" >Kebutuhan Dana</span></div>
                      <Title style={{fontSize: '1em', margin: '0px', textAlign: 'center'}}>{dataDonor.kebutuhanDanaDonor}</Title>
                      <ProgressChart data={dataDonor.statusPenerimaanDanaDonor.percent} label={dataDonor.statusPenerimaanDanaDonor.value}  />
                    </Col>
                    <Col span={12} xs={24}>
                      <div style={{textAlign: 'center', width: '100%'}}><span className="chart-names" >Status Penerimaan Dana</span></div>
                    </Col>
                  </Row>
                </Col>

                <Col span={12} xs={24} md={12}>
                  <Row>
                    <Col span={12} xs={24}>
                      <PieChart showLegend={true} data={dataDonor.jumlahProyekDonor} />
                    </Col>
                    <Col span={12} xs={24}>
                      <div style={{textAlign: 'center', width: '100%'}}><span className="chart-names" >Jumlah Penerima (Orang)</span></div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={24}>
            <Card className="app-card full-height">
              <Row>
                <Col span={24} style={{textAlign: 'center'}}>
                  <Title level={4} className="chart-title" style={{whiteSpace: 'pre-line'}}>Donasi Sukarela{`\n`}Tahun Anggaran {dataSukarela.fiscalYear}</Title>
                </Col>
                <Col span={24}>
                  <HybridChart data={dataSukarela.data}/>
                </Col>
                <Col span={12} xs={24} md={12}>
                  <Title className="chart-name">Total Penerimaan Donasi Sukarela</Title>
                  <Title style={{fontSize: '1.8em', margin: '0px', textAlign: 'center'}}>{dataSukarela.totalPenerimaanDonasiSukarela}</Title>
                </Col>
                <Col span={12} xs={24} md={12}>
                  <Title className="chart-name">Total Donatur Donasi Sukarela</Title>
                  <Title style={{fontSize: '1.8em', margin: '0px', textAlign: 'center'}}>{dataSukarela.totalDonaturDonasiSukarela}</Title>
                </Col>
                <Col span={12} xs={24} md={12} style={{marginTop: 20}}>
                  <Row justify='end'>
                    <Col>
                      <div style={{width: 20, height: 20, backgroundColor: '#E43077', marginLeft: 10, marginRight: 5}}/> 
                    </Col>
                    <Col>
                      : Dana Yang Diterima
                    </Col>
                    <Col/>
                  </Row>
                </Col>
                <Col span={12}  xs={24} md={12} style={{marginTop: 20}}>
                  <Row>
                    <Col>
                      <div style={{width: 20, height: 20, backgroundColor: '#462947', borderRadius: 20, marginLeft: 10, marginRight: 5}}/> 
                    </Col>
                    <Col>
                      : Jumlah Donatur
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Spin>
    </div>
  )
}
