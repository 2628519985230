import { DeleteOutlined, DownloadOutlined, DownOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, notification, Popconfirm, Row, Select, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { UItable } from '../../../components';
import SearchInput from '../../../components/search-input';
import { confirmDialog, dialog } from '../../../utils/alert';
// import '../index.scss';
import financeApi from '../../../api/adminKeuangan';
import danaPengembanganApi from '../../../api/danaPengembangan';
import moment from 'moment';
import { downloadFile } from '../../../utils/download';
import ModalTambahPenerima from './modalTambahPenerima';
import ModalUpload from './modalUpload';
const defaultPeriod = new Date().getFullYear();

export default function DaftarPenerimaBeasiswa() {
  const [showModal, setShowModal] = useState(false);
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [data, setData] = useState(false);
  const [pagination, setPagination] = useState({current: 1, total: 0});
  const [templateUrl, setTemplateurl] = useState('');
  const [category, setCategory] = useState(1);
  const [categories, setCategories] = useState([{id: 1}]);
  const [isLoading, setIsLoading] = useState(false);
  const defaultParams = {keyword: '', page: pagination.current, sort: 'sg_nisn,asc', size: 10, category: category}

  const columns = [
    {
      title: 'NISN',
      dataIndex: 'nisn',
      key: 'sg_nisn',
      sorter: true,
    },
    {
      title: 'Nama Lengkap',
      dataIndex: 'name',
      key: 'sg_name',
      sorter: true,
    },
    {
      title: 'Nominal',
      dataIndex: 'nominal',
      key: 'sg_nominal',
      sorter: true,
    },
    {
      title: 'Keterangan',
      dataIndex: 'reasonInd',
      key: 'sg_reason_ind_ver',
      sorter: true,
      width: '40%',
    },
    {
      title: 'Aksi',
      align: 'center',
      width: '240px',
      render: (e, row) => (
        <Row justify="center">
          <Button type="link" className="app-btn warning" onClick={_ => handleEdit(row)}>Ubah</Button>
          <Popconfirm
            placement="bottomRight"
            title="Apakah Anda yakin akan menghapus data ini?"
            onConfirm={() => deleteData(row)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" className="app-btn danger" style={{marginLeft: '1em'}}>Hapus</Button>
          </Popconfirm>
        </Row>
      )
    },
  ];


  useEffect(() => {
    getCategory()
  }, []);

  const onChangeCategory = (category) => {
    const param = { ...defaultParams, category };
    setCategory(category);
    loadData(param);
  }

  const loadData = async (param) => {
    setIsLoading(true);
    const token = sessionStorage.getItem('token');
    await danaPengembanganApi.getListPenerimaDonor(token, param).then(res => {
      console.log(res.data.data);
      const { siswaList, totalData, urlTemplate } = res.data.data;
      setData(siswaList);
      setTemplateurl(urlTemplate);
      setPagination({current: param.page, total: totalData});
    }).catch(err => {
      dialog({
        title: 'Error',
        message: err.message || 'Unknown Error',
        icon: 'error'
      });
    })
    setIsLoading(false);
  }

  const getCategory = async () => {
    setIsLoading(true);
    const token = sessionStorage.getItem('token');
    await danaPengembanganApi.getLovKategoriBeasiswa(token).then(res => {
      console.log(res.data);
      setCategories(res.data.data);
      setCategory(res.data.data[0].id)
      loadData({...defaultParams,category:res.data.data[0].id});
    }).catch(err => {
      dialog({
        icon: 'error',
        title: err ? err.message : 'Unknown error',
      })
    })
    setIsLoading(false);
  }

  const deleteData = async (data) => {
    const token = sessionStorage.getItem('token');
    const usersId = sessionStorage.getItem('userId');
    const params = {
      usersId,
      granteeId: data.id,
      donationId: category,
    }
    try {
      const res = await danaPengembanganApi.deletePenerimaDonasiBeasiswa(token, params);
      if (!res.data || res.data.responseType !== 'Success') { throw res.data; }
      dialog({
        icon: 'success',
        title: 'Data Penerima Beasiswa Berhasil Dihapus',
      });
      loadData(defaultParams);
    } catch (err) {
      dialog({
        icon: 'error',
        title: err ? err.message : 'Unknown error',
      })
    }
    setIsLoading(false);
  }

  const handleSearch = (search) => {
    const param = { ...defaultParams, keyword: search, page: 1 };
    setPagination({current: 1, total: 0})
    loadData(param);
  }

  const handleTableChange = (page, filter, sort) => {
    console.log(sort, typeof sort)
    loadData({
      ...defaultParams,
      page: page,
      sort: sort || 'sg_nisn,asc'
    })
  }

  const handleUpload = async () => {
    setShowModalUpload(true);
  }

  const handleDownload = async () => {
    setIsLoading(true);
    try {
      await downloadFile(templateUrl);
    } catch (err) {
      notification.error({
        message: 'Error',
        description: err.message
      });
    }
    setIsLoading(false);
  }

  const handleFinish = () => {
    setShowModal(false);
    setShowModalUpload(false);
    loadData({...defaultParams, category: category});
  }

  const handleTambah = () => {
    setShowModal(true);
  };

  const handleEdit = (data) => {
    setShowModal({
      ...data,
      nominal: data.value
    });
  }

  return (
    <div className="root">
      <Card className="app-card">
        <Spin spinning={isLoading}>
          <Row gutter={14}>
            <Col span={8} xs={24} md={24} lg={8}>
              <Row gutter={[10,10]}>
                <Col>
                  <Button className="addButton" onClick={handleTambah}><PlusOutlined /> Tambah</Button>
                </Col>
                <Col flex='auto'>
                  <Select style={{width: '100%'}} value={category} onSelect={onChangeCategory} placeholder="Kategori Beasiswa">
                    {categories.map((e, i) => <Select.Option key={`yearOption-${e.i}`} value={e.id}>{e.name}</Select.Option>)}
                  </Select>
                </Col>
              </Row>
            </Col>
            <Col span={8} xs={24} md={24} lg={8}>
              <Row gutter={[10,10]}>
                <Col flex='auto'>
                  <Button className="app-btn" type="link" onClick={handleDownload}>
                    <DownloadOutlined />Download Template
                  </Button>
                </Col>
                <Col>
                  <Button className="blueButton" onClick={handleUpload}>Upload</Button>
                </Col>
              </Row>
            </Col>
            <Col span={8} md={24} xs={24} lg={8}>
              <SearchInput onChange={search => {handleSearch(search)}} placeholder={'Nama Lengkap'}  />
            </Col>
          </Row>
          <Row style={{padding: '24px'}}>
            <Col span={24}>
              <UItable
                scroll={{x: 800}}
                dataTable={data}
                columns={columns}
                withRows={false}
                page={pagination.current}
                totalData={pagination.total}
                sortDefault=''
                handleTableChange={handleTableChange}
              />
            </Col>
          </Row>
        </Spin>
      </Card>
      <ModalTambahPenerima
        visible={!!showModal}
        data={showModal}
        donationId={category}
        onCancel={_ => setShowModal(false)}
        onFinish={handleFinish}
      />
      {showModalUpload && (
        <ModalUpload
          visible={showModalUpload}
          donationId={category}
          onCancel={_ => setShowModalUpload(false)}
          onFinish={handleFinish}
        />
      )}
    </div>
  )
}
