import React, { Component } from 'react'
import '../../../global/global.css'
// import lov from '../../actions/lov'

import { trackPromise } from 'react-promise-tracker';
import {
  Card,
  Button,
  Row,
  Col,
  Typography,
  Popconfirm
} from 'antd'
import { UItable, UISearch } from '../../../components'
import moment from 'moment';
import { alert } from '../../../api';
import { endpoint } from '../../../api/galeri';

const { Text } = Typography
const initialOrderBy = 'finish_time,desc'

class ListGaleri extends Component {
	constructor(props) {
		super(props)
		this.state = {
		initial: '',
		orderBy: initialOrderBy,
		size: 10,
		page: 1,
		search: '',
		visibleCreate: false,
		initalData: {},
		dataId: undefined,
		isEdit: false,
		dataProfil: {
      data: [],
      totalData: 0
    },
		dataLOV: [],
    loadingSubmit: false,
    loadingExport: false
		}
	}

	componentDidMount() {
    const {orderBy, size, page, search} = this.state
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: page,
      search: search,
    }
    this.getData(body)
  }

	getData = param => {
		trackPromise(
      endpoint.getData(param).then(res => {
        this.setState({
          dataProfil: res
        })
      })
    )
	}

	handleSearch = key => {
    const {orderBy, size, page } = this.state
    this.setState({
      search: key,
      page: 1
    })
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: 1,
      search: key,
    }
    this.getData(body)
  }

	handleTableChange = (page, filter, sorters) => {
    const sorter = sorters ? sorters : initialOrderBy
    const {size, search } = this.state
    const body = {
      token: this.props.token,
      sort: sorter,
      size: size,
      page: page,
      search: search,
    }
    this.setState({
      page: page,
      orderBy: sorter
    }, () => {
      this.getData(body)
    })
  }

	handleRedirectDetail = id => {
    this.props.history.push(`galeri-detail/${id}`)
	}
	handleRedirectAdd = () => {
    this.props.history.push(`galeri-add`)
	}
	handleRedirectEdit = id => {
    this.props.history.push(`galeri-edit/${id}`)
	}

	handleReject = id => {
		const body = {
      token: this.props.token,
      sort: this.state.orderBy,
      size: this.state.size,
      page: 1,
      search: this.state.search,
    }
		trackPromise(
      endpoint.deleteData(this.props.token, this.props.userId, id).then(res => {
        if (res === 'success') {
          this.setState({
            search: this.state.search || '',
            page: 1,
            orderBy: this.state.orderBy || initialOrderBy,
            visibleCreate: false
          }, () => {
            this.getData(body)
          })
        }
      })
    )
	}

	render() {

		const {page, visibleCreate, initalData, isEdit, dataProfil, dataLOV, loadingSubmit, loadingExport } = this.state

    const text = 'Apakah Anda yakin akan menghapus data ini?'

    const columnsTable = [
      {
        title: 'Nama Acara',
        dataIndex: 'name',
        key: 'f_event_name',
        sorter: true,
        render: name => <Text style={{ color: '#222222', fontWeight: 600, cursor: 'pointer' }}>{name}</Text>,
        onCell: record => {
          return{
            onClick: () => {
              this.handleRedirectDetail(record.eventId)
            }
          }
        }
      },
      {
        title: 'Hari',
        dataIndex: 'day',
        key: 'f_event_day',
        sorter: true,
      },
      {
        title: 'Tanggal',
        dataIndex: 'date',
        key: 'f_event_date',
        sorter: true,
      },
      {
        title: 'Jam',
        dataIndex: 'time',
        key: 'f_event_time',
        sorter: true,
      },
      {
        title: 'Media',
        dataIndex: 'media',
        key: 'presentasi',
        sorter: true,
      },
      {
        title: 'Aksi',
        dataIndex: '',
        key: '',
        render: (data) => <>
          <Button
            onClick={() => this.handleRedirectEdit(data.eventId)}
            type='link'
            style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600 }}
          >
            Ubah
          </Button>
          <Popconfirm
            placement="bottomRight"
            title={text}
            onConfirm={() => this.handleReject(data.eventId)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type='link'
              style={{ color: '#E22828', cursor: 'pointer', fontWeight: 600 }}
            >
              Hapus
            </Button>
          </Popconfirm>
        </>
      },
    ]

		return(
			<div className='root'>
				<Card className='bodyCard_style'>
					<Row gutter={[16,0]}>
            <Col span={16} xs={12} md={16}>
              <Button onClick={() => this.handleRedirectAdd()} className='addButton'>+ Tambah</Button>
            </Col>
            <Col span={8} xs={24} md={8}>
              <UISearch placeholder='Nama Acara' handleSearch={key => this.handleSearch(key)} />
            </Col>
          </Row>
					<Card 
            bordered={false}
            style={{minHeight: '300px'}}>
            <UItable
              scroll={{x: 800}}
              dataTable={dataProfil.data}
              columns={columnsTable}
              withRows={false}
              page={page}
              totalData={dataProfil.totalData}
              sortDefault=''
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
				</Card>
			</div>
		)
	}
}


export default ListGaleri