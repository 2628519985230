import React, { useEffect, useState } from "react";
import {
   Row, Col, Button, Form, Card,
   Input,Typography
} from "antd";
import "../../../global/global.css";
import TextArea from 'antd/lib/input/TextArea';
import { RedStar } from '../../../components';

const {Text} = Typography

function FormGaleri(props) {
	const [form] = Form.useForm();

  useEffect(() => {
    if(props.initialData){
      form.setFieldsValue(props.initialData)
    }
  }, [props.initialData])

	const onFinish = async(values) => {
    props.onFinish(values);
	} 

	const onFinishFailed = error => {
		console.error(error);
	}

  const handleResetForm = () => {
    form.setFieldsValue(props.initialData)
  }

	return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
    >
    <Card className='bodyCard_style'>
      <Card 
        bordered={false}
        style={{minHeight: '300px'}}>
          <Row gutter={[30,30]}>
            <Col span={12}>
              <Text className="form-label">Subjudul <RedStar /></Text>
              <Form.Item name='subtitleInd'
                rules={[{required: true,message: 'Subjudul [IND] harus diisi!' }
              ]}>
                <Input placeholder={'Subjudul'}  size="large" className='input_style'/> 
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Subtitle <RedStar /></Text>
              <Form.Item name='subtitleEng'
                rules={[{required: true,message: 'Subjudul [ENG] harus diisi!' }
              ]}>
                <Input placeholder={'Subtitle'}  size="large" className='input_style'/> 
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Deskripsi <RedStar /></Text>
              <Form.Item name='desInd'
                rules={[{required: true,message: 'Deskripsi [IND] harus diisi!' }
              ]}>
                <TextArea placeholder={'Deskripsi'}  size="large" className='input_style' style={{minHeight: 200}}/> 
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text className="form-label">Description <RedStar /></Text>
              <Form.Item name='desEng'
                rules={[{required: true,message: 'Deskripsi [ENG] harus diisi!' }
              ]}>
                <TextArea placeholder={'Description'}  size="large" className='input_style' style={{minHeight: 200}}/> 
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20]}>
            <Col span={24}>
              <Form.Item
                style={{
                  textAlign: 'right',
                  marginBottom: '0px'
                }}
              >
                <Button
                  type="link"
                  className='resetButton'
                  size={'small'}
                  onClick={() => handleResetForm()}
                  disabled={props.loadingSubmit}
                >
                  Reset
                </Button>
                <Button
                  type="primary"
                  htmlType='submit'
                  className='blueButton'
                  size={'small'}
                  loading={props.loadingSubmit}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
      </Card>
    </Card>
  </Form>
	)
}

export const UIForm = FormGaleri;