import { DeleteOutlined, DownloadOutlined, DownOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, notification, Row, Select, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { UItable } from '../../../components';
import SearchInput from '../../../components/search-input';
import { confirmDialog, dialog } from '../../../utils/alert';
// import '../index.scss';
import masterApi from '../../../api/master';
import ModalEditStatus from './modalEditStatus';

export default function MasterStatusProyek() {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(false);
  const [pagination, setPagination] = useState({current: 1, total: 0});
  const [isLoading, setIsLoading] = useState(false);
  const defaultParams = {keyword: '', page: pagination.current, sort: '', size: 10, category: 1}

  const columns = [
    {
      title: 'Status Proyek',
      dataIndex: 'value',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Aksi',
      width: '120px',
      align: 'center',
      render: (e, row) => (
        <Row justify="center">
          <Button className="app-btn warning" type="link" onClick={_ => handleEdit(row)}>Ubah</Button>
        </Row>
      )
    },
  ];


  useEffect(() => {
    loadData(defaultParams);
  }, []);

  const handleFinish = () => {
    setShowModal(false);
    loadData(defaultParams);
  }

  const handleTableChange = (page, filter, sort) => {
    console.log(sort, typeof sort)
    loadData({
      ...defaultParams,
      page: page,
      sort: sort || '',
    })
  }

  const loadData = async (param) => {
    setIsLoading(true);
    const token = sessionStorage.getItem('token');
    await masterApi.getListMasterStatusProyek(token, param).then(res => {
      console.log(res.data.data);
      const { listMaster, totalData } = res.data.data;
      setData(listMaster);
      setPagination({current: param.page, total: totalData});
    }).catch(err => {
      dialog({
        title: 'Error',
        message: err.message || 'Unknown Error',
        icon: 'error'
      });
    })
    setIsLoading(false);
  }

  const handleEdit = (data) => {
    setShowModal(data);
  }

  return (
    <div className="root">
      <Card className="app-card">
        <Spin spinning={isLoading}>
          <Row style={{padding: '24px'}}>
            <Col span={24}>
              <UItable
                size="small"
                dataTable={data}
                columns={columns}
                withRows={false}
                page={pagination.current}
                totalData={pagination.total}
                sortDefault=''
                handleTableChange={handleTableChange}
              />
            </Col>
          </Row>
        </Spin>
      </Card>
      <ModalEditStatus
        visible={!!showModal}
        data={showModal}
        onCancel={_ => setShowModal(false)}
        onFinish={handleFinish}
      />
    </div>
  )
}
