/* eslint-disable no-unused-expressions */
import swal from 'sweetalert2';

export function confirmDialog(config) {
  return new Promise(async (resolve, reject) => {
    const result = await swal.fire({
      customClass: {
        confirmButton: 'swal-app-btn confirm',
        cancelButton: 'swal-app-btn cancel',
        title: 'swal-app-title',
        content: 'swal-app-text',
      },
      icon: 'info',
      // text: 'Apakah Anda Yakin?',
      confirm: true,
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
      showClass: {
        popup: 'animate__animated animate__fadeInDown animate__faster'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp animate__faster'
      },
      ...config,
    });
    // console.log(result);
    result.isConfirmed ? resolve(result) : reject(result);
  });
}

export function dialog(config) {
  return new Promise(async (resolve, reject) => {
    const result = await swal.fire({
      icon: 'success',
      showConfirmButton: true,
      customClass: {
        confirmButton: 'swal-app-btn confirm',
        title: 'swal-app-title',
        content: 'swal-app-text',
      },
      showClass: {
        popup: 'animate__animated animate__fadeInDown animate__faster'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp animate__faster'
      },
      ...config,
    });
    resolve(result);
  });
}
