import { get, put, post } from 'axios'
import config from '../services/config'
import qs from 'querystring'
import { alert } from './index'
const { BASE_URL_LOGIN, TIMEOUT, BASE_URL_NOAUTH } = config

const normalizeQuery = (url) => {
  return qs.stringify(url)
}

const normalizeData = res => {
  let dataTemp = res.data
  let response = {
    data: dataTemp.data.list,
    totalData: dataTemp.data.totalItem,
    status: 'success',
    rc: dataTemp.rc
  }
  return response
}
const normalizeDataTentangKami = res => {
  let dataTemp = res.data
  let response = {
    data: dataTemp.data,
    status: 'success',
    rc: dataTemp.rc
  }
  return response
}


const endpoint = {
  getCeritaKekl(token){
    const urlString = normalizeQuery({
    })
    let url = `${BASE_URL_LOGIN}be/api/admin/setting/kekl`
    return get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }).then(response => {
      return response
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {}
    })
  },
  editCeritaKekl(token, body) {
    const urlString = normalizeQuery({
    })
    return put(`${BASE_URL_LOGIN}be/api/admin/setting/kekl/submit`,
      body,
      {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          timeout: TIMEOUT
      }
    ).then(res => {
      if(res.data.rc == "00"){
        alert.alertCreateEditSuccess(res.data.message)
        return 'success'
      }else{
        alert.alertCreateEditError(res.data.message, 'Data', 'Edit', res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Data', 'Edit', err.response.status)
      return 'error'
    })
  },


  getTentangKami(token){
    const urlString = normalizeQuery({
    })
    let url = `${BASE_URL_LOGIN}be/api/admin/setting/aboutUs`
    return get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }).then(response => {
      return response
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {}
    })
  },
  editTentangKami(token, body) {
    const urlString = normalizeQuery({
    })
    return put(`${BASE_URL_LOGIN}be/api/admin/setting/aboutUs/submit`,
      body,
      {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          timeout: TIMEOUT
      }
    ).then(res => {
      if(res.data.rc == "00"){
        alert.alertCreateEditSuccess(res.data.message)
        return 'success'
      }else{
        alert.alertCreateEditError(res.data.message, 'Data', 'Edit', res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Data', 'Edit', err.response.status)
      return 'error'
    })
  },

  getInformasi(token){
    const urlString = normalizeQuery({
    })
    let url = `${BASE_URL_LOGIN}be/api/admin/setting/information`
    return get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }).then(response => {
      return response
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {}
    })
  },
  editInformasi(token, body) {
    const urlString = normalizeQuery({
    })
    return put(`${BASE_URL_LOGIN}be/api/admin/setting/information/submit`,
      body,
      {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          timeout: TIMEOUT
      }
    ).then(res => {
      if(res.data.rc == "00"){
        alert.alertCreateEditSuccess(res.data.message)
        return 'success'
      }else{
        alert.alertCreateEditError(res.data.message, 'Data', 'Edit', res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Data', 'Edit', err.response.status)
      return 'error'
    })
  },


  getCrowdfunding(token){
    const urlString = normalizeQuery({
    })
    let url = `${BASE_URL_LOGIN}be/api/admin/setting/crowdFunding`
    return get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }).then(response => {
      return response
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {}
    })
  },
  editCrowdfunding(token, body) {
    const urlString = normalizeQuery({
    })
    return put(`${BASE_URL_LOGIN}be/api/admin/setting/crowdFunding/submit`,
      body,
      {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          timeout: TIMEOUT
      }
    ).then(res => {
      if(res.data.rc == "00"){
        alert.alertCreateEditSuccess(res.data.message)
        return 'success'
      }else{
        alert.alertCreateEditError(res.data.message, 'Data', 'Edit', res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Data', 'Edit', err.response.status)
      return 'error'
    })
  },
  uploadImage(token, body, url){
    return post(`${BASE_URL_LOGIN}be/api/yayasanLoyola/upload/${url} `, 
      body,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        timeout: TIMEOUT
      }
    ).then(res => {
      return res.data
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Image', 'Upload', err.response.status)
      return 'error'
    })
  },


  getDonorBeasiswa(token){
    const urlString = normalizeQuery({
    })
    let url = `${BASE_URL_LOGIN}be/api/admin/setting/scholarshipDonation`
    return get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }).then(response => {
      return response
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {}
    })
  },
  editDonorBeasiswa(token, body) {
    const urlString = normalizeQuery({
    })
    return put(`${BASE_URL_LOGIN}be/api/admin/setting/scholarshipDonation/submit`,
      body,
      {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          timeout: TIMEOUT
      }
    ).then(res => {
      if(res.data.rc == "00"){
        alert.alertCreateEditSuccess(res.data.message)
        return 'success'
      }else{
        alert.alertCreateEditError(res.data.message, 'Data', 'Edit', res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Data', 'Edit', err.response.status)
      return 'error'
    })
  },
  getDonasiSukarela(token){
    const urlString = normalizeQuery({
    })
    let url = `${BASE_URL_LOGIN}be/api/admin/setting/voluntaryDonation`
    return get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }).then(response => {
      return response
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {}
    })
  },
  editDonasiSukarela(token, body) {
    const urlString = normalizeQuery({
    })
    return put(`${BASE_URL_LOGIN}be/api/admin/setting/voluntaryDonation/submit`,
      body,
      {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          timeout: TIMEOUT
      }
    ).then(res => {
      if(res.data.rc == "00"){
        alert.alertCreateEditSuccess(res.data.message)
        return 'success'
      }else{
        alert.alertCreateEditError(res.data.message, 'Data', 'Edit', res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Data', 'Edit', err.response.status)
      return 'error'
    })
  },

  getHomepageYayasan(token){
    const urlString = normalizeQuery({
    })
    let url = `${BASE_URL_LOGIN}be/api/admin/setting/homepage`
    return get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }).then(response => {
      return response
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {}
    })
  },
  editHomepageYayasan(token, body) {
    const urlString = normalizeQuery({
    })
    return put(`${BASE_URL_LOGIN}be/api/admin/setting/homepage/submit`,
      body,
      {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          timeout: TIMEOUT
      }
    ).then(res => {
      if(res.data.rc == "00"){
        alert.alertCreateEditSuccess(res.data.message)
        return 'success'
      }else{
        alert.alertCreateEditError(res.data.message, 'Data', 'Edit', res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Data', 'Edit', err.response.status)
      return 'error'
    })
  },


  getHomepageDanaPengembangan(token){
    const urlString = normalizeQuery({
    })
    let url = `${BASE_URL_LOGIN}be/api/admin/setting/fundDevelopment`
    return get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }).then(response => {
      return response
    }).catch(err => {
      alert.showAlert(err.response.data.message, err.response.status)
      return {}
    })
  },
  editHomepageDanaPengembangan(token, body) {
    const urlString = normalizeQuery({
    })
    return put(`${BASE_URL_LOGIN}be/api/admin/setting/fundDevelopment/submit`,
      body,
      {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          timeout: TIMEOUT
      }
    ).then(res => {
      if(res.data.rc == "00"){
        alert.alertCreateEditSuccess(res.data.message)
        return 'success'
      }else{
        alert.alertCreateEditError(res.data.message, 'Data', 'Edit', res.data.status)
        return 'error'
      }
    }).catch(err => {
      alert.alertCreateEditError(err.response.data.message, 'Data', 'Edit', err.response.status)
      return 'error'
    })
  },

}
export { endpoint }