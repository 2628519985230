const GlobalVideoCheck = {
  checking(url) {
    let isValid = false
    if (url.includes('https://www.youtube.com/embed/', 0)) {
      isValid = true
    }
    return isValid
  },
  
  checkingValidate(url) {
    let isValid = false
    if (url.includes('https://www.youtube.com/watch', 0)) {
      isValid = true
    }
    return isValid
  }
}
 
export {GlobalVideoCheck};