import { FlagOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import Title from 'antd/lib/typography/Title'
import React from 'react'
import { useHistory } from 'react-router'

export default function NotFound() {
  const history = useHistory();
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', fontFamily: 'Poppins' }}>
      <div style={{ padding: '40px', background: '#ddd', borderRadius: '100px' }}>
        <FlagOutlined style={{fontSize: '4em', transform: 'rotateZ(-20deg)'}} />
      </div>
      <span style={{ fontWeight: 'bold', fontSize: '3em' }}>404 Error</span>
      <span style={{ fontSize: '1.2em', marginBottom: '2em' }}>Page not found</span>
      <Button type="primary" style={{ padding: '.75em 3em', height: 'auto', borderRadius: '4px' }} onClick={_ => history.push('/')}>Go Back</Button>
    </div>
  )
}
