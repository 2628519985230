import React, {Component, useEffect, useState} from 'react';
import { Route, Redirect, HashRouter, Switch, withRouter, useHistory, useLocation } from 'react-router-dom';
import SideBarComponent from './components/SideBar';
import Login from './module/Login';
import HeaderComponent from './components/Header';
import ForgetPassword from "./module/ForgetPassword";
import HeaderFooter from './module/header-footer';
import ListUsers from './module/User/list';
import ListDaftarCeritaKekl from './module/DaftarCeritaKekl/list';
import ListJadwal from './module/Jadwal/list';
import ListGaleri from './module/Galeri/list'
import GaleriDetail from './module/Galeri/detail'
import GaleriCreate from './module/Galeri/create'
import JadwalDetail from './module/Jadwal/detail';
import FAQDetail from './module/FAQ/detail';
import ListFAQ from './module/FAQ/list';
import ListProfilPengurus from './module/profilPengurus/list';
import ProfilPengurusDetail from './module/profilPengurus/detail';
import ListPendirianSebelum from './module/Pendirian/sebelum/list';
import ListPendirianSetelah from './module/Pendirian/setelah/list'
import DetailInformasiPendirian from './module/Pendirian/sebelum/detail';
import DetailInformasiPendirianSetelah from './module/Pendirian/setelah/detail'
import { notification } from 'antd';
import NotFound from './module/NotFound';
import CrowdFunding from './module/AdminKeuangan/crowdFunding';
import DonorBeasiswa from './module/AdminKeuangan/donorBeasiswa';
import DonasiSukarela from './module/AdminKeuangan/donasiSukarela';
import Laporan from './module/AdminKeuangan/laporan';
import Dashboard from './module/Dashboard';
import DaftarCeritaKeklDetail from './module/DaftarCeritaKekl/detail';
import ListDaftarProyekCrowdfunding from './module/DaftarProyekCrowdfunding/list';
import DaftarProyekCrowdfundingDetail from './module/DaftarProyekCrowdfunding/detail';
import ListDaftarProyekLangkahKedepan from './module/DaftarProyekLangkahKedepan/list';
import DaftarProyekLangkahKedepanDetail from './module/DaftarProyekLangkahKedepan/detail';
import DaftarPenerimaBeasiswa from './module/DanaPengembangan/daftarPenerimaBeasiswa';
import DaftarKategoriDonorBeasiswa from './module/DanaPengembangan/daftarKategoriDonorBeasiswa';
import DetailKategoriDonorBeasiswa from './module/DanaPengembangan/detailKategoriDonorBeasiswa';
import MasterStatusProyek from './module/Master/statusProyek';
import PengaturanUmumCeritaKekl from './module/PengaturanUmum/CeritaKekl';
import PengaturanTentangKami from './module/PengaturanUmum/TentangKami';
import PengaturanInformasi from './module/PengaturanUmum/Informasi';
import PengaturanCrowdfunding from './module/PengaturanUmum/Crowdfunding';
import PengaturanDonorBeasiswa from './module/PengaturanUmum/DonorBeasiswa';
import PengaturanDonasiSukarela from './module/PengaturanUmum/DonasiSukarela';
import PengaturanHomePageYayasan from './module/PengaturanUmum/Homepage';
import ListMasterKategoriProyek from './module/Master/Kategori-proyek/list';
import ListMasterKategoriPengumuman from './module/Master/Kategori-pengumuman/list';
import PengaturanHomePageDanaPengembangan from './module/PengaturanUmum/HomepageDanaPengembangan';
import Pengumuman from './module/Informasi/pengumuman';
import TambahPengumuman from './module/Informasi/tambahPengumuman';
import DetailPengumuman from './module/Informasi/detailPengumuman';
import ListPersetujuanInformasi from './module/Pendirian/persetujuan/list';
import DetailPersetujuanInformasi from './module/Pendirian/persetujuan/detail';


function PrivateRoute ({ ...props }) {
  if (
    sessionStorage.getItem('token') &&
    sessionStorage.getItem('name') &&
    sessionStorage.getItem("userId") &&
    sessionStorage.getItem("userData")
  ) {
    return <Route { ...props } />
  } else {
    sessionStorage.clear();
    return <Redirect to="/" />
  }
}


function DashboardRoute (props) {
  const name = sessionStorage.getItem('name') ? sessionStorage.getItem('name') : '';
  const isLogin = !!sessionStorage.getItem('token');
  if (isLogin) {
    return (
      <div style={{width: '100%', overflowY: 'auto'}}>
        <SideBarComponent {...props} />
        {/* <div>
        </div> */}
        <div className='body-content'>
          <HeaderComponent {...props} name={name}/>
          <Route { ...props } />
        </div>
      </div>
    )
  } else {
    sessionStorage.clear();
    notification.info({
      message: 'Info',
      description: 'Harap login untuk mengakses Portal Admin Yayasan Loyola!'
    })
    return <Redirect to="/" />
  }
}

function OpenRoute (props) {
  const isLogin = !!sessionStorage.getItem('token');
  if (isLogin) {
    const currentRole = sessionStorage.getItem('userRole') || 'Admin';
    const redirection = {
      'Admin': '/dashboard',
      'Admin Keuangan': '/crowd-funding',
    }
    return (<Redirect to={redirection[currentRole]} />)
  } else {
    return <Route { ...props } />
  }
}

export function RouteWrapper(props) {
  const location = useLocation();

  useEffect(() => {
    props.onRouteUpdate();
  },[location.pathname]);

  return (props.children)
}


export default class Routes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: sessionStorage.getItem('token'),
      name: sessionStorage.getItem('name'),
      userId: sessionStorage.getItem("userId"),
      userData: sessionStorage.getItem("userData")
    }
  }

  updateContext = () => {
    const token = sessionStorage.getItem('token');
    const name = sessionStorage.getItem('name');
    const userId = sessionStorage.getItem('userId');
    const userData = sessionStorage.getItem("userData");
    this.setState({token, name, userId, userData});
  }

  render() {
    const context = { ...this.state };

    return(
      <HashRouter>
        <RouteWrapper onRouteUpdate={this.updateContext}>
          <Switch>
            <OpenRoute exact path="/" render={props => <Login onLogin={() => this.updateContext()}  {...props}/>} />
            <OpenRoute exact path="/ForgetPassword" render={ props => <ForgetPassword/>}  />
            <DashboardRoute exact path="/dashboard" render={ props => <Dashboard {...props} {...context} />}  />
            <DashboardRoute exact path="/user" render={ props => <ListUsers {...props} {...context} />}  />
            <DashboardRoute exact path="/jadwal" render={ props => <ListJadwal {...props} {...context} />}  />
            <DashboardRoute exact path="/jadwal-detail/:id" render={ props => <JadwalDetail {...props} {...context} />}  />
            <DashboardRoute exact path="/galeri" render={ props => <ListGaleri {...props} {...context} />}  />
            <DashboardRoute exact path="/galeri-detail/:id" render={ props => <GaleriDetail {...props} {...context} />}  />
            <DashboardRoute exact path="/galeri-add" render={ props => <GaleriCreate {...props} {...context} />}  />
            <DashboardRoute exact path="/galeri-edit/:id" render={ props => <GaleriCreate {...props} {...context} />}  />
            <DashboardRoute exact path="/daftar-proyek-crowdfunding" render={ props => <ListDaftarProyekCrowdfunding {...props} {...context} />}  />
            <DashboardRoute exact path="/daftar-proyek-crowdfunding-detail/:id" render={ props => <DaftarProyekCrowdfundingDetail {...props} {...context} />}  />
            <DashboardRoute exact path="/daftar-proyek-langkah-ke-depan" render={ props => <ListDaftarProyekLangkahKedepan {...props} {...context} />}  />
            <DashboardRoute exact path="/daftar-proyek-langkah-ke-depan-detail/:id" render={ props => <DaftarProyekLangkahKedepanDetail {...props} {...context} />}  />
            <DashboardRoute exact path="/daftar-persetujuan-informasi" render={ props => <ListPersetujuanInformasi {...props} {...context} />}  />
            <DashboardRoute exact path="/daftar-persetujuan-informasi-detail/:id" render={ props => <DetailPersetujuanInformasi {...props} {...context} />}  />
            <DashboardRoute exact path="/daftar-cerita-kekl" render={ props => <ListDaftarCeritaKekl {...props} {...context} />}  />
            <DashboardRoute exact path="/daftar-cerita-kekl-detail/:id" render={ props => <DaftarCeritaKeklDetail {...props} {...context} />}  />
            <DashboardRoute exact path="/faq" render={ props => <ListFAQ {...props} {...context} />}  />
            <DashboardRoute exact path="/faq-detail/:id" render={ props => <FAQDetail {...props} {...context} />}  />
            <DashboardRoute exact path="/header-footer" render={props => <HeaderFooter {...props} {...context} />} />
            <DashboardRoute exact path="/pengaturan-cerita-kekl" render={props => <PengaturanUmumCeritaKekl {...props} {...context} />} />
            <DashboardRoute exact path="/pengaturan-tentang-kami" render={props => <PengaturanTentangKami {...props} {...context} />} />
            <DashboardRoute exact path="/pengaturan-informasi" render={props => <PengaturanInformasi {...props} {...context} />} />
            <DashboardRoute exact path="/pengaturan-crowdfunding" render={props => <PengaturanCrowdfunding {...props} {...context} />} />
            <DashboardRoute exact path="/pengaturan-donor-beasiswa" render={props => <PengaturanDonorBeasiswa {...props} {...context} />} />
            <DashboardRoute exact path="/pengaturan-donasi-sukarela" render={props => <PengaturanDonasiSukarela {...props} {...context} />} />
            <DashboardRoute exact path="/pengaturan-homepage-yayasan" render={props => <PengaturanHomePageYayasan {...props} {...context} />} />
            <DashboardRoute exact path="/pengaturan-homepage-dana-pengembangan" render={props => <PengaturanHomePageDanaPengembangan {...props} {...context} />} />
            <DashboardRoute exact path="/profil-pengurus" render={props => <ListProfilPengurus {...props} {...context} />} />
            <DashboardRoute exact path="/profil-pengurus-detail/:id" render={props => <ProfilPengurusDetail {...props} {...context} />} />
            <DashboardRoute exact path="/pendirian-sebelum" render={props => <ListPendirianSebelum {...props} {...context}  />}/>
            <DashboardRoute exact path="/pendirian-setelah" render={props => <ListPendirianSetelah {...props} {...context}  />}/>
            <DashboardRoute exact path="/pengumuman" render={props => <Pengumuman {...props} {...context}  />}/>
            <DashboardRoute exact path="/tambah-pengumuman" render={props => <TambahPengumuman {...props} {...context}  />}/>
            <DashboardRoute exact path="/edit-pengumuman/:id" render={props => <TambahPengumuman {...props} {...context}  />}/>
            <DashboardRoute exact path="/detail-pengumuman/:id" render={props => <DetailPengumuman {...props} {...context}  />}/>
            <DashboardRoute exact path="/pendirian-detail/:id" render={props => <DetailInformasiPendirian {...props} {...context} />} />
            <DashboardRoute exact path="/pendirian-detail-setelah/:id" render={props => <DetailInformasiPendirianSetelah {...props} {...context} />} />
            <DashboardRoute exact path="/daftar-penerima-beasiswa" render={props => <DaftarPenerimaBeasiswa {...props} {...context} />} />
            <DashboardRoute exact path="/daftar-kategori-donor-beasiswa" render={props => <DaftarKategoriDonorBeasiswa {...props} {...context} />} />
            <DashboardRoute exact path="/daftar-kategori-donor-beasiswa-detail/:id" render={props => <DetailKategoriDonorBeasiswa {...props} {...context} />} />
            <DashboardRoute exact path="/master-kategori-proyek" render={props => <ListMasterKategoriProyek {...props} {...context} />} />
            <DashboardRoute exact path="/master-kategori-pengumuman" render={props => <ListMasterKategoriPengumuman {...props} {...context} />} />
            <DashboardRoute exact path="/master-status-proyek" render={props => <MasterStatusProyek {...props} {...context} />} />

            {/* Admin Keuangan */}
            <DashboardRoute exact path="/crowd-funding" render={props => <CrowdFunding {...props} {...context} />} />
            <DashboardRoute exact path="/donor-beasiswa" render={props => <DonorBeasiswa {...props} {...context} />} />
            <DashboardRoute exact path="/donasi-sukarela" render={props => <DonasiSukarela {...props} {...context} />} />
            <DashboardRoute exact path="/laporan" render={props => <Laporan {...props} {...context} />} />

            <Route path="*" component={NotFound} />
          </Switch>
        </RouteWrapper>
      </HashRouter>
    )
  }
}
