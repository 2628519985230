import { get, put, post } from 'axios'
import config from '../services/config'
const { BASE_URL_LOGIN, BASE_URL_NOAUTH, TIMEOUT } = config


const getDetailKategoriDonorBeasiswa = (token, param,id) => {
  return get(`${BASE_URL_LOGIN}be/api/admin/scholarshipDonation/list?&id=${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}
const getDetailKategoriDonorBeasiswaListPenerima = (token, param,id) => {
  // let url = `https://demo5436246.mockable.io/listApapun?sort=${param.sort}&page=${param.page - 1}&size=${param.size}&scholarshipDonationId=${id}&keyword=${param.keyword}`
  let url = `${BASE_URL_LOGIN}be/api/admin/recipient/list?sort=${param.sort}&page=${param.page - 1}&size=${param.size}&scholarshipDonationId=${id}&keyword=${param.keyword}`
  return get(url,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const getListPenerimaDonor = (token, param = {sort: '', keyword: '', page: 1, size: '', category: 1}) => {
  return get(`${BASE_URL_LOGIN}be/api/admin/recipient/list?sort=${param.sort}&page=${param.page - 1}&size=${param.size}&scholarshipDonationId=${param.category}&id=&keyword=${param.keyword}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const getLovKategoriBeasiswa = (token) => {
  return get(`${BASE_URL_LOGIN}be/api/admin/scholarshipDonation/lov`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const postPenerimaDonasiBeasiswa = (token, body) => {
  return post(`${BASE_URL_LOGIN}be/api/admin/recipient/insert`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const putPenerimaDonasiBeasiswa = (token, body) => {
  return put(`${BASE_URL_LOGIN}be/api/admin/recipient/update`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}


const deletePenerimaDonasiBeasiswa = (token, params = {}) => {
  return put(`${BASE_URL_LOGIN}be/api/admin/recipient/update?usersId=${params.usersId}&scholarshipGranteeId=${params.granteeId}&scholarshipDonationId=${params.donationId}&isDeleted=true`, null,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}

const uploadPenerimaDonasiBeasiswa = (token, params, body) => {
  return post(`${BASE_URL_LOGIN}be/api/admin/recipient/upload?usersId=${params.usersId}&scholarshipDonationId=${params.scholarshipDonationId}`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}



const getListKategoriDonor = (token, param = {sort: '', keyword: '', page: 1, size: '', category: 1}) => {
  return get(`${BASE_URL_LOGIN}be/api/admin/scholarshipDonation/list?sort=${param.sort}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  )
}





export default {
  getDetailKategoriDonorBeasiswa,
  getDetailKategoriDonorBeasiswaListPenerima,
  getListPenerimaDonor,
  getLovKategoriBeasiswa,
  putPenerimaDonasiBeasiswa,
  postPenerimaDonasiBeasiswa,
  deletePenerimaDonasiBeasiswa,
  uploadPenerimaDonasiBeasiswa,
  getListKategoriDonor
}
